import React from 'react'
import { NavBar } from '../../../components/navbar'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { ClientButton, Loading } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { saveLastUpdateByPark } from '../../../store/collections/lastUpdateWorker';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getPark } from '../../../store/collections/parkWorker';
import { reloadWindow, reloadWithAlert, warningConfirm } from '../../../shared/utils';
import { docsToItems } from '../../../store/transform.docs';

class DeveloperLastUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            messageUpdate: "",
            items: [],
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const results = await firebase.firestore().collection('last-update').get()
        var items = docsToItems(results)
        this.setState({ items: items.sort((a, b) => b.lastUpdate.toDate() - a.lastUpdate.toDate()) })
        this.setState({ isLoading: false })
    }

    showUpdateModal = async () => {
        this.setState({ isShowUpdateModal: true })
        this.setState({ messageUpdate: "" })
    }

    confirm = async () => {
        for (const item of this.state.items) {
            this.setState({ messageUpdate: `Atualizando tabelas de: ${item.park?.nome ?? "Nome não disponível"}` })
            await this.onForceRefreshToPark(item.estacionamentoId)
        }
        reloadWithAlert("Tabelas atualizadas com sucesso!")
    }

    onForceRefreshToPark = async (parkId) => {
        const result = await getPark({ id: parkId })
        if (result.success) {
            await saveLastUpdateByPark(result.data)
        }
    }

    onForceRefresh = async (item) => {
        const parkName = (item.park?.nome ?? "")
        var confirm = await warningConfirm({ message: `Deseja mesmo forçar a atualização das tabelas do estacionamento ${parkName}?` })
        if (confirm) {
            await this.onForceRefreshToPark(item.estacionamentoId)
            reloadWindow()
        }
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Atualização de tabelas
                        <div className='btn-group'>
                            <button type="button" onClick={() => { this.showUpdateModal() }} className="btn btn-sm btn-danger">
                                <i className="fas fa-sync mx-2"></i>
                                Forçar atualização
                            </button>
                        </div>
                    </div>
                    {
                        this.state.isLoading ?
                            <Loading />
                            :
                            !this.state.isLoading ?
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td><strong>Estacionamento</strong></td>
                                                <td className="d-none d-sm-table-cell"><strong>Usuário</strong></td>
                                                <td className="d-none d-sm-table-cell"><strong>E-mail</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Última atualização</strong></td>
                                                <td width="80"></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            {item.park?.nome ?? "Não disponível"}
                                                        </td>
                                                        <td>
                                                            {item.alterBy?.name ?? "Não disponível"}
                                                        </td>
                                                        <td>
                                                            {item.alterBy?.email ?? "Não disponível"}
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{Moment(item.lastUpdate.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                        <td align='right'>
                                                            <div className="btn-group">
                                                                <button type="button" onClick={() => { this.onForceRefresh(item) }} className="btn btn-warning text-white">
                                                                    <i className="fas fa-sync" />
                                                                </button>
                                                                <ClientButton item={item} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <Dialog maxWidth={'xs'} fullWidth open={this.state.isShowUpdateModal}>
                    <MuiDialogContent>
                        <h5 className='text-center'>Deseja mesmo atualizar as tabelas de todos os estabelecimentos?</h5>
                        <h6 className='text-center'>{this.state.messageUpdate}</h6>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        {
                            this.state.messageUpdate === "" &&
                            <div className='btn-group m-3'>
                                <button onClick={() => this.setState({ isShowUpdateModal: false })} className='btn btn-secondary'>Cancelar</button>
                                <button onClick={this.confirm} className='btn btn-danger'>Confirmar</button>
                            </div>
                        }
                    </MuiDialogActions>
                </Dialog>
            </NavBar>)
    }
}


export { DeveloperLastUpdate };