import { normalizedField } from "../../shared/utils"
import { getParameterByParkId } from "../../store/collections/parameterWorker"
import { getParkById } from "../../store/collections/parkWorker"
import { addQueue } from "../../store/collections/queueWorker"

export const sendMessageByWhatsApp = async ({ estacionamentoId, referenceId, phone, message, messages }) => {
	const park = await getParkById({ id: estacionamentoId })
	const parameters = await getParameterByParkId({ estacionamentoId: estacionamentoId })
	const data = {
		origin: "WhatsApp",
		type: "WHATSAPP_MESSAGE_WITH_TEMPLATE",
		referenceId: referenceId,
		body: {
			X_API_KEY: parameters.X_API_KEY,
			clienteId: park.clienteId,
			estacionamentoId: park.id,
			template: "NO_TEMPLATE",
			message: message,
			messages: messages,
			phone: normalizedField(phone)
		}
	}
	await addQueue({ data: data })
}