import firebase from '../../config/firebase';
import { getEstacionamento, getUsuario, getCliente, getRevendaIdByPark } from '../../config/auth';
import Moment from 'moment';
import { getAllChagesByDateRangeAndParksIds, getAllChagesByMonthlyId, getFirstPendingChageByMonthlyId, getLastPaidChageByMonthlyId, getLastPendingChageByMonthlyId } from './monthlyChagesWorker';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { Collection, WhereField } from '../../shared/constants';
import { addDoc, getAllDocsByClientId, getAllDocsByParkId, updateDoc } from './collectionBaseWorker';
import { chunkItemsByQuantity } from '../../shared/utils';

export const getMonthlyById = async (id) => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).doc(id).get()
    var monthly = docToItem(result)
    if (monthly) {
        const firstChange = await getFirstPendingChageByMonthlyId({ monthlyId: monthly.id })
        if (monthly.plano) {
            if (firstChange) {
                const lastChange = await getLastPendingChageByMonthlyId({ monthlyId: monthly.id })
                monthly.plano.dataFatura = firstChange?.changeDate
                monthly.plano.validadeContrato = lastChange?.changeDate
            } else {
                const lastPaid = await getLastPaidChageByMonthlyId({ monthlyId: monthly.id })
                monthly.plano.dataFatura = lastPaid?.changeDate
                monthly.plano.validadeContrato = lastPaid?.changeDate
            }
        }
        return monthly
    }
    return null
}

export const getAllMonthliesByIds = async ({ ids }) => {
    var results = []
    var items = chunkItemsByQuantity({ items: ids })
    for (const idss of items) {
        const result = await firebase.firestore().collection(Collection.MONTHLIES)
            .where(WhereField.ID, 'in', idss).get()
        results.push(docsToItems(result))
    }
    return results.flatMap(e => e)
}

export const getMonthlyByParksIds = async ({ estacionamentosIds }) => {
    try {
        var items = []
        const chunkSize = 10;
        for (let i = 0; i < estacionamentosIds.length; i += chunkSize) {
            const estacionamentosSliced = estacionamentosIds.slice(i, i + chunkSize);
            if (estacionamentosSliced.length > 0) {
                const result = await firebase.firestore().collection(Collection.MONTHLIES)
                    .where(WhereField.PARKID, 'in', estacionamentosSliced).get()
                var results = docsToItems(result)
                items.push(results)
            }
        }
        return items.flatMap(e => e)
    } catch (error) {
        console.log(error);
    }
}

export const getAllChargesWithNoCashier = async ({ estacionamentoId }) => {
    try {
        const result = await firebase.firestore().collection(Collection.MONTHLY_HISTORIES)
            .where('estacionamentoId', '==', estacionamentoId)
            .where('cashier.id', '==', "SEM_CAIXA_ABERTO").get()
        return docsToItems(result).sort((a, b) => a.changeDate?.toDate() - b.changeDate?.toDate())
    } catch (error) {
        console.log("logs", error);
    }
}

export const getMonthlyByDocumentAndParkId = async ({ document, estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).where("documento", "==", document).where("estacionamentoId", "==", estacionamentoId).get()
    return docsToItem(result)
}

export const getAllMonthly = async () => {
    const id = getCliente()?.id
    if (id === undefined) {
        return []
    }
    const result = await firebase.firestore().collection(Collection.MONTHLIES).where(WhereField.CLIENTEID, '==', id).get()
    return docsToItems(result)
}

export const getAllMonthliesMigrate = async () => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).get()
    return docsToItems(result)
}

export const getAllMonthlies = async () => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).get()
    return docsToItems(result)
}

export const getAllMonthlyByClientId = async ({ clienteId }) => {
    return await getAllDocsByClientId({ collection: Collection.MONTHLIES, clienteId: clienteId })
}

export const getAllMonthlyWithChages = async () => {
    try {
        const result = await firebase.firestore().collection(Collection.MONTHLIES)
            .where(WhereField.PARKID, '==', getEstacionamento().id).get()
        var monthlies = docsToItems(result)
        for (let index = 0; index < monthlies.length; index++) {
            const chages = await getAllChagesByMonthlyId(monthlies[index].id)
            const chagesByMonthly = chages.filter(e => e.monthlyId = monthlies[index].id)
            monthlies[index].chages = {
                quantity: {
                    payed: chagesByMonthly.filter(e => e.payment).length,
                    payedTotal: chagesByMonthly.filter(e => e.payment).map(e => e.payment.total).reduce((a, b) => a + b, 0),
                    overdue: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).length,
                    overdueTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    pending: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).length,
                    pendingTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    changesTotal: chagesByMonthly.map(e => e.payment?.total ?? e.netValue).reduce((a, b) => a + b, 0),
                },
                status: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate > new Date()).length ? "Em dia" : "Atrasado",
                items: chagesByMonthly
            }
        }
        return monthlies
    } catch (error) {
    }
    return []
}

export const getAllMonthlyByRangeWithChages = async ({ startDate, endDate, estacionamentosIds }) => {
    try {
        var newStartDate = Moment(startDate).add(-1, 'days').toDate()
        var newEndDate = Moment(endDate).add(1, 'days').toDate()
        var monthlies = []
        var changes = []
        const chunkSize = 10;
        for (let i = 0; i < estacionamentosIds.length; i += chunkSize) {
            const estacionamentosSliced = estacionamentosIds.slice(i, i + chunkSize);
            if (estacionamentosSliced.length > 0) {
                const result = await firebase.firestore().collection(Collection.MONTHLIES)
                    .where(WhereField.PARKID, 'in', estacionamentosSliced).get()
                var results = docsToItems(result).filter(e => e.status !== "inativo")
                monthlies.push(results)
                const changes2 = await getAllChagesByDateRangeAndParksIds({ startDate: newStartDate, endDate: newEndDate, estacionamentosIds: estacionamentosSliced })
                changes.push(changes2)
            }
        }
        monthlies = monthlies.flatMap(e => e)
        changes = changes.flatMap(e => e)
        for (let index = 0; index < monthlies.length; index++) {
            const chagesByMonthly = changes.filter(e => e.monthlyId = monthlies[index].id)
            const firstChange = chagesByMonthly.sort((a, b) => b.changeDate.toDate() - a.changeDate.toDate())[0]
            const lastChange = chagesByMonthly.sort((a, b) => a.changeDate.toDate() - b.changeDate.toDate())[0]
            if (monthlies[index].plano) {
                monthlies[index].plano.dataFatura = firstChange?.changeDate
                monthlies[index].plano.validadeContrato = lastChange?.changeDate
            }
            monthlies[index].chages = {
                quantity: {
                    payed: chagesByMonthly.filter(e => e.payment).length,
                    payedTotal: chagesByMonthly.filter(e => e.payment).map(e => e.payment.total).reduce((a, b) => a + b, 0),
                    overdue: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).length,
                    overdueTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() > new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    pending: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).length,
                    pendingTotal: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate.toDate() <= new Date()).map(e => e.netValue).reduce((a, b) => a + b, 0),
                    changesTotal: chagesByMonthly.map(e => e.payment?.total ?? e.netValue).reduce((a, b) => a + b, 0),
                },
                status: chagesByMonthly.filter(e => e.status === "pending" && e.changeDate > new Date()).length ? "Em dia" : "Atrasado",
                items: chagesByMonthly
            }
        }
        return monthlies
    } catch (error) {
        console.log(error);
    }
    return []
}

export const getAllActivatedMonthlyByParkId = async ({ estacionamentoId, withCharges }) => {
    const monthlies = await getAllMonthlyByParkId({ estacionamentoId: estacionamentoId, withCharges: withCharges })
    return monthlies.filter(e => e.status !== "inativo")
}

export const getAllMonthlyByParkId = async ({ estacionamentoId, withCharges, getInativos }) => {
    const items = await getAllDocsByParkId({ collection: Collection.MONTHLIES, estacionamentoId: estacionamentoId })
    if (withCharges) {
        for (const monthly of items) {
            const firstChange = await getFirstPendingChageByMonthlyId({ monthlyId: monthly.id })
            const lastChange = await getLastPendingChageByMonthlyId({ monthlyId: monthly.id })
            if (monthly.plano) {
                monthly.plano.dataFatura = firstChange?.changeDate
                monthly.plano.validadeContrato = lastChange?.changeDate
            }
        }
    }
    return items.filter(e => getInativos || e.status !== "inativo").sort((a, b) => a.nome.localeCompare(b.nome))
}

export const addMonthly = async ({ clientId, estacionamentoId, nome, documento, tipoDocumento, email, celular, telefone, endereco, dataFatura, diaVencimento, validadeContrato, plano }) => {
    const data = {
        clienteId: clientId,
        estacionamentoId: estacionamentoId,
        nome: nome,
        documento: documento,
        tipoDocumento: tipoDocumento,
        revendaId: getRevendaIdByPark(),
        email: email,
        celular: celular,
        telefone: telefone,
        endereco: {
            cep: endereco?.cep,
            logradouro: endereco?.logradouro,
            numero: endereco?.numero,
            bairro: endereco?.bairro,
            cidade: endereco?.cidade,
            estado: endereco?.estado,
            complemento: endereco?.complemento
        },
        plano: {
            validadeContrato: validadeContrato?.toDate() ?? Moment().add(365, 'days').toDate(),
            dataFatura: dataFatura.toDate(),
            diaVencimento: diaVencimento.toString(),
            horarioFim: plano?.horarioFim,
            horarioInicio: plano?.horarioInicio,
            planoId: plano?.id,
            nome: plano?.nome,
            valor: plano?.valorMensal
        },
        createdAt: new Date(),
        createdBy: getUsuario().email,
        isDeleted: false,
    }
    const doc = addDoc({ collection: Collection.MONTHLIES, data: data })
    return doc.id
}

export const getAllMonthlyByParkIdMigration = async (id) => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).where(WhereField.PARKID, '==', id).get()
    return docsToItems(result)
}

export const getMonthlyByParkAndCardNumber = async ({ parkId, cardNumber }) => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).where(WhereField.PARKID, '==', parkId).where('cartao', '==', cardNumber).get()
    return docsToItems(result)
}

export const getAllMonthliesByPlanId = async ({ planId, estacionamentoId }) => {
    const result = await firebase.firestore().collection(Collection.MONTHLIES).where(WhereField.PARKID, '==', estacionamentoId).where('plano.planoId', '==', planId).get()
    return docsToItems(result).filter(e => e.status !== "inativo")
}

export const updateVencimentoFatura = async ({ mensalistaId, plano, dataFatura }) => {
    const data = {
        plano: {
            ...plano,
            dataFatura: dataFatura
        }
    }
    await updateDoc({ collection: Collection.MONTHLIES, id: mensalistaId, data: data })
}

export const updateMonthlyById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.MONTHLIES, id: id, data: data })
}

export const updateValidadeContrato = async ({ mensalistaId, validadeContrato }) => {
    var plano = {
        validadeContrato: validadeContrato
    }
    const data = {
        plano: plano
    }
    await updateDoc({ collection: Collection.MONTHLIES, id: mensalistaId, data: data })
}

export const updateDueDate = async ({ mensalistaId, plano, dueDate }) => {
    const data = {
        plano: {
            ...plano,
            dataFatura: dueDate
        }
    }
    await updateDoc({ collection: Collection.MONTHLIES, id: mensalistaId, data: data })
}