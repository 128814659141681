import { getParameter } from "../store/collections/parameterWorker";
import firebase from '../config/firebase'
import { getRevendaIdByPark } from "../config/auth";

export const fetchFunction = async (path, option, apiKey) => {
    const parameter = await getParameter()
    const defaultHeaders = {
        'Content-Type': 'application/json',
        "X_API_KEY": apiKey ?? parameter?.X_API_KEY ?? "",
        "user": {
            "uid": firebase.auth().currentUser?.uid
        }
    }
    if (option.method === "POST" || option.method === "PUT") {
        const body = {
            X_API_KEY: apiKey ?? parameter?.X_API_KEY ?? "",
            ...option.body
        }
        option.body = JSON.stringify(body)
    }
    option.headers = defaultHeaders
    const url = (process.env.REACT_APP_FUNCTION_REQUEST + path)
    return new Promise(function (resolve, reject) {
        fetch(url, option)
            .then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const fetchFunctionCall = async (path, option) => {
    const parameter = await getParameter()
    const defaultHeaders = {
        'Content-Type': 'application/json',
        "X_API_KEY": parameter?.X_API_KEY ?? "",
        "user": {
            "uid": firebase.auth().currentUser?.uid
        }
    }
    if (option.body) {
        const body = {
            X_API_KEY: parameter?.X_API_KEY ?? "",
            ...option.body
        }
        option.body = JSON.stringify({
            data: body
        })
    }
    option.headers = defaultHeaders
    const url = (process.env.REACT_APP_FUNCTION_REQUEST + path)
    return new Promise(function (resolve, reject) {
        fetch(url, option)
            .then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                console.log(error)
                reject({
                    result: {
                        data: {
                            succes: false,
                            message: "Não foi possível realizar esta ação, tente novamente mais tarde."
                        }
                    }
                })
            })
    })
}

export const fetchRelaseFunction = async (path, option) => {
    const resaleId = getRevendaIdByPark()
    const defaultHeaders = {
        'Content-Type': 'application/json',
        "resaleId": resaleId,
        "user": {
            "uid": firebase.auth().currentUser?.uid
        }
    }
    if (option.body) {
        const body = {
            X_API_KEY: resaleId,
            ...option.body
        }
        option.body = JSON.stringify(body)
    }
    option.headers = defaultHeaders
    const url = (process.env.REACT_APP_FUNCTION_REQUEST + path)
    return new Promise(function (resolve, reject) {
        fetch(url, option)
            .then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}

export const fetchRest = async (option) => {
    return new Promise(function (resolve, reject) {
        fetch(option.url, option)
            .then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch(error => {
                reject(error)
            })
    })
}