import React from 'react'
import { NavBar } from '../../components/navbar'
import { ClientButton, EmptyContent, Loading } from '../../components/common/commons'
import Moment from 'moment';
import 'firebase/auth';
import 'firebase/storage';
import { getAllUsersByAssociatedRessales, getAllUsersByRessaleId } from '../../store/collections/userWorker';
import { exportAndDownloadXLS, isNullOrEmpty, toastDismissLoading, toastLoading, valueByMaximunChars } from '../../shared/utils';
import { getRevenda, getUsuario } from '../../config/auth';
import { getAllClientsByRevendaId } from '../../store/collections/clienteWorker';

class AnalyticsLogin extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <AnalyticsLoginBody props={this.props} />
                </NavBar>
            </>)
    }
}

class AnalyticsLoginBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            typeAccess: "ACESSOU",
            today: Moment(new Date()).format("DDMMYYYY"),
            yesterday: Moment(new Date()).add(-1, 'days').format("DDMMYYYY"),
            days2: Moment(new Date()).add(-2, 'days').format("DDMMYYYY"),
            days3: Moment(new Date()).add(-3, 'days').format("DDMMYYYY"),
            days4: Moment(new Date()).add(-4, 'days').format("DDMMYYYY"),
            days5: Moment(new Date()).add(-5, 'days').format("DDMMYYYY"),
            accessToday: 0,
            accessTodayPercent: 0,
            accessYesterday: 0,
            accessYesterdayPercent: 0,
            accessDays2: 0,
            accessDays2Percent: 0,
            accessMoreThan2Days: 0,
            accessMoreThan2DaysPercent: 0,
            isLoading: true,
            adquirente: ""
        }
        this.load()
    }

    load = async () => {
        const revenda = getRevenda()
        var items = await getAllUsersByRessaleId({ ressaleId: revenda?.id })
        var clients = await getAllClientsByRevendaId({ revendaId: revenda?.id })
        switch (this.state.typeAccess) {
            case "ACESSOU": items = items.filter(e => e.lastAccess !== undefined); break
            case "NUNCA-ACESSOU": items = items.filter(e => e.lastAccess === undefined); break
        }
        if (!isNullOrEmpty(this.state.adquirente)) {
            items = items.filter(e => e.flavor?.toLowerCase().includes(this.state.adquirente.toLowerCase()) === true)
        }
        items = items.filter(e => e.lastAccess?.seconds && e.revendedor !== true).map(e => {
            var version = ""
            !isNullOrEmpty(e.version) && e.version !== "site" && (version = "Site")
            { e.flavor?.includes("pagSeguro") || e.flavor?.includes("PagSeguro") && (version = ('PagSeguro')) }
            { e.flavor?.includes("cielo") && (version = ('Cielo')) }
            { e.flavor?.includes("getnet") && (version = ('GetNet')) }
            { e.flavor?.includes("Stone") && (version = ('Stone')) }
            { e.flavor?.includes("safra") && (version = ('Safra')) }
            { (e.flavor?.includes("store") || e.flavor?.includes("Store")) && (version = ('Google Play')) }
            { e.flavor?.includes("rede") && (version = ('Rede')) }
            { e.flavor?.includes("site") && (version = 'Site') }
            return {
                ...e,
                cliente: clients.find(c => c.id === e.clienteId),
                lastAccess: Moment(e.lastAccess.seconds * 1000),
                version: `${e.version} - ${version}`
            }
        })
        this.setState({ items: items.sort((a, b) => (b.lastAccess.isAfter(a.lastAccess) ? 1 : -1)) })
        var accessToday = items.filter(e => e.lastAccess?.format("DDMMYYYY") === this.state.today).length
        var accessYesterday = items.filter(e => e.lastAccess?.format("DDMMYYYY") === this.state.yesterday).length
        var accessDays2 = items.filter(e => e.lastAccess?.format("DDMMYYYY") === this.state.days2).length
        this.setState({ accessToday: accessToday })
        this.setState({ accessTodayPercent: (accessToday / items.length * 100).toFixed(0) })
        this.setState({ accessYesterday: accessYesterday })
        this.setState({ accessYesterdayPercent: (accessYesterday / items.length * 100).toFixed(0) })
        this.setState({ accessDays2: accessDays2 })
        this.setState({ accessDays2Percent: (accessDays2 / items.length * 100).toFixed(0) })
        this.setState({ accessMoreThan2Days: items.length - (accessToday - accessYesterday - accessDays2) })
        this.setState({ accessMoreThan2DaysPercent: ((items.length - (accessToday + accessYesterday + accessDays2)) / items.length * 100).toFixed(0) })
        this.setState({ isLoading: false })
        toastDismissLoading()
    }

    handlerType = (e) => {
        toastLoading("Alterando filtro...")
        this.setState({ typeAccess: e.target.value }, () => {
            this.load()
        })
    }

    handlerAdquirente = (e) => {
        toastLoading("Alterando filtro...")
        this.setState({ adquirente: e.target.value }, () => {
            this.load()
        })
    }

    exportData = async () => {
        toastLoading("Exportando...")
        const revenda = getRevenda()
        const data = this.state.items.map(e => {
            return {
                "Cliente": e.cliente?.nome,
                "Nome": e.nome,
                "E-mail": e.email,
                "Último acesso": e.lastAccess.format("DD/MM/YYYY [às] HH:mm"),
                "Cliente": e.cliente?.nome,
                "Sistema": e.version,
                "Link": e.cliente?.id ? `${revenda.siteAdmin}/revenda/cliente/${e.cliente.id}` : ""
            }
        })
        exportAndDownloadXLS(data, "acessos_usuaios.xls")
        toastDismissLoading()
    }

    render() {
        return (
            <>
                {
                    !this.state.isLoading &&
                    <div className='col-lg-12'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-success mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Hoje</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessToday}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessTodayPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-info mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Ontem</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessYesterday}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessYesterdayPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-warning mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Há 2 dias</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessDays2}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessDays2Percent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-3'>
                                <div className="card text-light bg-danger mb-3">
                                    <div className="card-body">
                                        <h5 className="card-title text-center text-white">Acima de 2 dias</h5>
                                        <h3 className="card-text text-center text-white">
                                            {this.state.accessMoreThan2Days}
                                        </h3>
                                        <div className='text-center'>
                                            <small className="card-text text-white">
                                                {this.state.accessMoreThan2DaysPercent}%
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Login de usuários
                        <div className='btn-group'>
                            <select className='form-select form-select-sm' value={this.state.adquirente} onChange={this.handlerAdquirente}>
                                <option value="">Todas as adquirente</option>
                                <option value="pagSeguro">PagSeguro</option>
                                <option value="cielo">Cielo</option>
                                <option value="getnet">GetNet</option>
                                <option value="stone">Stone</option>
                                <option value="safra">Safra</option>
                                <option value="store">Google Play</option>
                                <option value="rede">Rede</option>
                                <option value="site">Site</option>
                            </select>
                            <select className='form-select form-select-sm' value={this.state.typeAccess} onChange={this.handlerType}>
                                <option value="TODOS">Todos</option>
                                <option value="ACESSOU">Já acessou</option>
                                <option value="NUNCA-ACESSOU">Nunca acessou</option>
                            </select>
                            <button type="button" onClick={() => { this.exportData() }} className="btn btn-sm btn-primary">
                                Exportar
                            </button>
                        </div>
                    </div>
                    {
                        this.state.isLoading ? <Loading /> :
                            <>
                                {
                                    isNullOrEmpty(this.state.items) ? <EmptyContent text="Nenhum acesso encontrado." /> : <>
                                        <div className="row">
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td scope="col"></td>
                                                            <td scope="col">Cliente</td>
                                                            <td scope="col" className="d-none d-sm-table-cell">Nome</td>
                                                            <td scope="col" className="d-none d-sm-table-cell">E-mail</td>
                                                            <td scope="col" className="d-none d-sm-table-cell" align='center'>Último acesso</td>
                                                            <td scope="col" className="d-none d-sm-table-cell" align='center'>Sistema</td>
                                                            <td scope="col"></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.items.map(usuario =>
                                                                <tr>
                                                                    <td align='center' width={10}>
                                                                        {
                                                                            usuario.lastAccess &&
                                                                            <>
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.today && <span className="badge text-bg-success w-100 text-white">Hoje</span>
                                                                                }
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.yesterday && <span className="badge text-bg-info w-100 text-white">Ontem</span>
                                                                                }
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.days2 && <span className="badge text-bg-warning w-100 text-white">Há 2 dias</span>
                                                                                }
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.days3 && <span className="badge text-bg-warning w-100 text-white">Há 3 dias</span>
                                                                                }
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.days4 && <span className="badge text-bg-danger w-100 text-white">Há 4 dias</span>
                                                                                }
                                                                                {
                                                                                    usuario.lastAccess.format("DDMMYYYY") === this.state.days5 && <span className="badge text-bg-danger w-100 text-white">Há 5 dias</span>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </td>
                                                                    <td>{valueByMaximunChars(usuario.cliente?.nome, 20)}</td>
                                                                    <td className="d-none d-sm-table-cell">{valueByMaximunChars(usuario.nome, 20)}</td>
                                                                    <td className="d-none d-sm-table-cell" >{valueByMaximunChars(usuario.email, 20)}</td>
                                                                    <td className="d-none d-sm-table-cell" align='center'>
                                                                        {
                                                                            usuario.lastAccess !== undefined ?
                                                                                <span>{usuario.lastAccess.format(" DD/MM/YYYY [às] HH:mm A")}</span>
                                                                                :
                                                                                <span className="badge text-bg-warning mx-2 w-100 text-white">Nunca</span>
                                                                        }
                                                                    </td>
                                                                    <td className="d-none d-sm-table-cell" width={10}>
                                                                        <span className="badge text-bg-success mx-2 w-100 text-white">{usuario.version}</span>
                                                                    </td>
                                                                    <td width='10'>
                                                                        <ClientButton item={usuario} />
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                    }
                </div >
            </>)
    }
}

export { AnalyticsLogin };