import firebase from '../../config/firebase';
import { deleteDoc, getDocById, updateDoc } from './collectionBaseWorker';
import { Collection, WhereField } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { isNullOrEmpty } from '../../shared/utils';

export const getBookingApointmentById = async ({ id }) => {
    return await getDocById({ collection: Collection.BOOKINGS_APPOINTMENTS, id: id })
}

export const getAllBookingsApointmentByParkId = async ({ estacionamentoId, startDate, endDate }) => {
    if (!isNullOrEmpty(startDate) || !isNullOrEmpty(endDate)) {
        return []
    }
    const docs = await firebase.firestore().collection(Collection.BOOKINGS_APPOINTMENTS)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .where(WhereField.START_DATE, ">=", startDate.toDate())
        .where(WhereField.START_DATE, "<=", endDate.toDate())
        .get()
    return docsToItems(docs)
}

export const getBookingsApointmentById = async ({ id }) => {
    return await getDocById({ collection: Collection.BOOKINGS_APPOINTMENTS, id: id })
}

export const getAllBookingsApointmentByCashiers = async ({ caixasIds }) => {
    var result = [];
    const chunkSize = 10;
    for (let i = 0; i < caixasIds.length; i += chunkSize) {
        const cashiersSliced = caixasIds.slice(i, i + chunkSize);
        if (cashiersSliced.length > 0) {
            const results = await firebase.firestore().collection(Collection.BOOKINGS_APPOINTMENTS)
                .where('cashier.id', 'in', cashiersSliced)
                .get()
            const items = docsToItems(results)
            result.push(...items)
        }
    }
    return result
}

export const getAllBookingsApointmentByIdAndParkId = async ({ bookingId, estacionamentoId }) => {
    const docs = await firebase.firestore().collection(Collection.BOOKINGS_APPOINTMENTS)
        .where(WhereField.BOOKING__ID, "==", bookingId)
        .where(WhereField.PARKID, "==", estacionamentoId)
        .get()
    return docsToItems(docs)
}

export const updateBookingApointmentById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.BOOKINGS_APPOINTMENTS, id: id, data: data })
}

export const removeBookingApointmentById = async ({ id }) => {
    await deleteDoc({ collection: Collection.BOOKINGS_APPOINTMENTS, id: id })
}
