import React from 'react'
import { getRevenda, isPlatformOwner } from '../../config/auth'
import { ClientButton, Loading } from '../../components/common/commons'
import { EmptyContent } from '../../components/common/commons'
import Moment from 'moment';
import { NavBar } from '../../components/navbar'
import { getAllTerms, getTermsByRevendaId } from '../../store/collections/userTermsWorker'
import { getAllUsersByRessaleId, updateUserById } from '../../store/collections/userWorker'
import { dangerConfirm, getResellerIdByEnv, reloadWithAlert } from '../../shared/utils'

class ListarContratos extends React.Component {

    render() {
        return (
            <NavBar>
                <ListarContratosBody showAll={true} />
            </NavBar>
        )
    }
}

class ListarContratosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsOpen: [],
            isLoading: true,
            revendaId: getRevenda()?.id,
            platformOwner: isPlatformOwner()
        }
    }

    componentDidMount = async () => {
        await this.loading()
    }

    loading = async () => {
        this.setState({ isLoading: true })
        var items = []
        if (this.state.platformOwner) {
            items = await getAllTerms()
        } else {
            items = await getTermsByRevendaId({ revendaId: this.state.revendaId })
        }
        this.setState({ items: items })
        this.setState({ isLoading: false })
    }

    forceReread = async () => {
        var confirm = await dangerConfirm({ message: `Deseja obrigar que os usuários aceitem o novo contrato?` });
        if (confirm) {
            const ressaleId = getResellerIdByEnv()
            const users = await getAllUsersByRessaleId({ ressaleId: ressaleId })
            for (const user of users) {
                user.isTermsUpdated = true
                await updateUserById({ id: user.id, data: user })
            }
            reloadWithAlert("Novo contrato solicitado para assinatura com sucesso.")
        }
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Todos os contratos
                        <div className='btn-group'>
                            <button type="button" onClick={(e) => this.forceReread()} className="btn btn-sm btn-primary">
                                <i className="fas fa-file-import mx-2" />
                                Forçar leitura de novo contrato
                            </button>
                        </div>
                    </div>
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <>
                                <EmptyContent text='Nenhum contrato disponível.' />
                            </>
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='table-responsive'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>Usuário</strong></th>
                                                    <th scope="col" align='center'><strong>E-mail</strong></th>
                                                    <th scope="col" align='center'><strong>Aceite</strong></th>
                                                    <th scope="col" align='center'><strong>Hash único</strong></th>
                                                    <th scope="col" align='center'><strong>IP</strong></th>
                                                    <th scope="col" width={10}></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.items.map(item =>
                                                        <tr>
                                                            <td>
                                                                {item.createdBy.nome}
                                                            </td>
                                                            <td align='center'>
                                                                {item.createdBy.email}
                                                            </td>
                                                            <td className="d-none d-sm-table-cell">{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                            <td align='center'>
                                                                {item.guid}
                                                            </td>
                                                            <td align='center'>
                                                                {item.info.ip}
                                                            </td>
                                                            <td width='10'>
                                                                <ClientButton item={item} />
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
            </>
        )
    }
}

export { ListarContratos, ListarContratosBody }