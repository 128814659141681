import React from 'react'
import { NavBar } from '../../../components/navbar'

import firebase from '../../../config/firebase';
import { getCliente, getEstacionamento, getRevendaIdByPark, getUsuario, isAdministrador } from '../../../config/auth'
import { Loading, EmptyContent, HelpButton } from '../../../components/common/commons'
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import { sendLogByUser } from '../../../shared/analytics'
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import 'firebase/auth';
import 'firebase/storage';
import NumberFormat from 'react-number-format';
import { dangerConfirm, defaultConfirm, generateUUIDV7, goToFirebase, isNullOrEmpty, reloadWindow, reloadWithAlert, takeIfIsNotNullOrEmpty, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning } from '../../../shared/utils';
import Select from 'react-select';
import { AlertSuccess, AlertWarning } from '../../../shared/alert-manager';
import { getAllMonthliesByPlanId, updateMonthlyById } from '../../../store/collections/mensalistaWorker';
import { getAllPendingChagesByMonthlyId, updateChageById } from '../../../store/collections/monthlyChagesWorker';
import { addPlan, deletePlanById, updatePlanById } from '../../../store/collections/monthlyPlans';
import { Collection } from '../../../shared/constants';
import { DuplicateDataButton } from '../../../shared/buttons/duplication.buttons';
import Tooltip from '@mui/material/Tooltip';

const initialState = {
    id: null,
    nome: '',
    horarioInicio: '',
    horarioFim: '',
    estacionamentoId: getEstacionamento()?.id,
    vagasDisponiveis: 10,
    valorMensal: 0.0,
    segunda: false,
    terca: false,
    quarta: false,
    quinta: false,
    sexta: false,
    sabado: false,
    domingo: false,
    online: false,
    tipo: "todos",
    status: 'Ativo',
    maximumCharges: "24",
    dueDays: [{ value: "Todos os dias", label: "Todos os dias" }],
    allDueDays: [],
    monthlies: [],
    random: Math.random(),
    randomSelect: Math.random()
};

class CriarPlano extends React.Component {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    reset = () => {
        this.setState({ random: Math.random() })
        this.setState({ ...initialState })
    }

    componentDidMount = () => {
        var allDueDays = [{ value: "Todos os dias", label: "Todos os dias" }]
        for (var i = 1; i < 29; i++) {
            allDueDays.push({ value: i.toString(), label: i.toString() })
        }
        this.setState({ allDueDays: allDueDays })
    }

    onSelecTallMonthly = (e) => {
        const selectAll = e.target.checked
        var monthlies = this.state.monthlies
        for (let index = 0; index < monthlies.length; index++) {
            monthlies[index].isChecked = selectAll
        }
        this.setState({ monthlies: monthlies, selectAll: selectAll })
    }

    onCheckedMonthly = (e, index) => {
        var monthlies = this.state.monthlies
        monthlies[index].isChecked = e.target.checked
        this.setState({ monthlies: monthlies })
    }

    verifyUpdatePlan = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        toastLoading("Verificando plano...")
        const monthlies = await getAllMonthliesByPlanId({ planId: this.state.id, estacionamentoId: this.state.estacionamentoId })
        if (monthlies.length > 0) {
            toastDismissLoading()
            this.setState({ monthlies: monthlies })
            this.setState({ isShowingMonthlyUpdatePlan: true })
        } else {
            this.updatePlano(false)
        }
    }

    updatePlano = async (updateMonthlies) => {
        if (updateMonthlies && this.state.monthlies.filter(e => e.isChecked === true).length === 0) {
            toastWarning("Selecione pelo menos um mensalista para atualizar.")
            return
        }
        this.setState({ isShowingMonthlyUpdatePlan: false })
        toastLoading("Atualizando plano...")
        const plan = {
            planoId: this.state.id,
            nome: this.state.nome,
            horarioInicio: this.state.horarioInicio,
            horarioFim: this.state.horarioFim,
            vagasDisponiveis: parseInt(this.state.vagasDisponiveis),
            valorMensal: this.state.valorMensal,
            valorMensalOnline: this.state.valorMensalOnline,
            segunda: this.state.segunda,
            terca: this.state.terca,
            quarta: this.state.quarta,
            tipo: this.state.tipo,
            quinta: this.state.quinta,
            sexta: this.state.sexta,
            sabado: this.state.sabado,
            domingo: this.state.domingo,
            dueDays: this.state.dueDays.map(e => e.value),
            maximumCharges: parseInt(this.state.maximumCharges),
            online: this.state.online,
            status: this.state.status
        }
        await updatePlanById({ id: this.state.id, data: plan })
        if (updateMonthlies) {
            const monthlies = this.state.monthlies.filter(e => e.isChecked === true)
            for (let index = 0; index < monthlies.length; index++) {
                const monthly = monthlies[index];
                toastLoading(`${index + 1} de ${monthlies.length} | Atualizando mensalista ${monthly.nome}...`)
                const pendingCharges = await getAllPendingChagesByMonthlyId(monthly.id)
                for (const charge of pendingCharges) {
                    charge.plan = plan
                    charge.netValue = parseFloat(plan.valorMensal.toFixed(2))
                    await updateChageById({ id: charge.id, data: charge })
                }
                monthly.plano = plan
                sendLogByUser("Planos", `Plano do mensalista atualizado ${monthly.nome} para ${plan.nome}`, monthly)
                await updateMonthlyById({ id: monthly.id, data: monthly })
            }
        }
        await saveLastUpdate()
        toastDismissLoading()
        toastSuccess("Plano atualizado com sucesso!")
        sendLogByUser("Planos", `Atualizou o plano ${plan.nome}`, plan)
        this.reset()
    }

    addPlano = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        toastLoading("Salvando plano...")
        const id = generateUUIDV7()
        const data = {
            id: id,
            planoId: id,
            clienteId: getCliente().id,
            estacionamentoId: this.state.estacionamentoId,
            nome: this.state.nome,
            horarioInicio: this.state.horarioInicio,
            revendaId: getRevendaIdByPark(),
            horarioFim: this.state.horarioFim,
            vagasDisponiveis: parseInt(this.state.vagasDisponiveis),
            valorMensal: this.state.valorMensal,
            valorMensalOnline: this.state.valorMensalOnline,
            status: this.state.status,
            segunda: this.state.segunda,
            terca: this.state.terca,
            quarta: this.state.quarta,
            quinta: this.state.quinta,
            sexta: this.state.sexta,
            sabado: this.state.sabado,
            domingo: this.state.domingo,
            tipo: this.state.tipo,
            dueDays: this.state.dueDays.map(e => e.value),
            maximumCharges: parseInt(this.state.maximumCharges),
            online: this.state.online,
            createdAt: new Date(),
            createdBy: getUsuario().email,
            isDeleted: false,
        }
        await addPlan({ data: data })
        await saveLastUpdate()
        sendLogByUser("Planos", `Cadastrou o plano ${data.nome}`, data)
        toastDismissLoading()
        reloadWithAlert("Plano cadastrado com sucesso!")
    }

    onEdit = (item) => {
        this.setState({ id: item.id })
        this.onDuplicate(item)
    }

    onDuplicate = (item) => {
        this.setState({ nome: item.nome })
        this.setState({ horarioInicio: item.horarioInicio })
        this.setState({ horarioFim: item.horarioFim })
        this.setState({ vagasDisponiveis: item.vagasDisponiveis })
        this.setState({ valorMensal: item.valorMensal })
        this.setState({ valorMensalOnline: item.valorMensalOnline ?? item.valorMensal })
        this.setState({ estacionamentoId: item.estacionamentoId })
        this.setState({ status: item.status })
        this.setState({ segunda: item.segunda ?? true })
        this.setState({ terca: item.terca ?? true })
        this.setState({ quarta: item.quarta ?? true })
        this.setState({ quinta: item.quinta ?? true })
        this.setState({ sexta: item.sexta ?? true })
        this.setState({ sabado: item.sabado ?? true })
        this.setState({ domingo: item.domingo ?? true })
        this.setState({ tipo: item.tipo ?? "todos" })
        this.setState({ online: item.online ?? false })
        this.setState({ dueDays: item.dueDays?.map(e => { return { value: e, label: e } }) ?? [{ value: "Todos os dias", label: "Todos os dias" }] })
        this.setState({ maximumCharges: item.maximumCharges ?? 24 })
        this.setState({ randomSelect: Math.random() })
    }

    onRemove = async (item) => {
        const monthlies = await getAllMonthliesByPlanId({ planId: item.id, estacionamentoId: this.state.estacionamentoId })
        if (!isNullOrEmpty(monthlies)) {
            const names = monthlies.map(e => `${e.nome}\n`)
            toastWarning("Não é possível remover um plano que esteja associado a um mensalista, antes você deve altera-los\n\nMensalistas com este este plano: \n\n" + names)
            return
        }
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover o plano ${item.nome}?` });
        if (confirm) {
            await deletePlanById({ id: item.id })
            await saveLastUpdate()
            reloadWindow()
        }
    }

    onEstacionamentoSelected = (item) => {
        this.setState({ estacionamentoId: item.id })
        this.setState({ random: Math.random() })
    }

    atualizarValorMensal = (event, maskedvalue, floatvalue) => {
        this.setState({ valorMensal: floatvalue })
    }

    atualizarValorMensalOnline = (event, maskedvalue, floatvalue) => {
        this.setState({ valorMensalOnline: floatvalue })
    }

    atualizarValorDecontoVencimento = (event, maskedvalue, floatvalue) => {
        this.setState({ valorDescontoVencimento: floatvalue })
    }

    checkFields = () => {
        return (this.state.nome === '' ||
            this.state.horarioInicio === '' ||
            this.state.horarioFim === '' ||
            this.state.vagasDisponiveis === 0)
    }

    handleDueDays = (dueDays) => {
        if (dueDays.map(e => e.value).indexOf("Todos os dias") !== -1) {
            this.setState({ dueDays: [{ value: "Todos os dias", label: "Todos os dias" }] })
            this.setState({ randomSelect: Math.random() })
        } else {
            this.setState({ dueDays: dueDays })
        }
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Cadastrar plano
                        <HelpButton />
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className='col-lg-4'>
                                <h5>Dados do plano</h5>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <label>Status</label>
                                        <select value={this.state.status && this.state.status} onChange={(e) => this.setState({ status: e.target.value })} className="form-select">
                                            <option value='Ativo'>Ativo</option>
                                            <option value='Inativo'>Inativo</option>
                                        </select>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Tipo</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select" onChange={(e) => this.setState({ tipo: e.target.value })} value={this.state.tipo}>
                                                <option value="todos">Todos</option>
                                                <option value="carro">Carro</option>
                                                <option value="carro_grande">Carro Grande</option>
                                                <option value="moto">Moto</option>
                                                <option value="bicicleta">Bicicleta</option>
                                                <option value="onibus">Ônibus</option>
                                                <option value="caminhao">Caminhão</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Nome</label>
                                        <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Valor</label>
                                        <CurrencyInput value={this.state.valorMensal && this.state.valorMensal} prefix="R$" className='form-control' onChangeEvent={this.atualizarValorMensal} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <h5>Disponibilidade</h5>
                                <div className='row'>
                                    <div className="col-lg-6">
                                        <label>Horário início</label>
                                        <NumberFormat className="form-control" value={this.state.horarioInicio} mask='_' onChange={(e) => this.setState({ horarioInicio: e.target.value })} format="##:##" />
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Horário fim</label>
                                        <NumberFormat className="form-control" value={this.state.horarioFim} mask='_' onChange={(e) => this.setState({ horarioFim: e.target.value })} format="##:##" />
                                    </div>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="segunda" checked={this.state.segunda} onChange={(e) => this.setState({ segunda: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="segunda">
                                        Segunda-Feira
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="terca" checked={this.state.terca} onChange={(e) => this.setState({ terca: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="terca">
                                        Terça-Feira
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="quarta" checked={this.state.quarta} onChange={(e) => this.setState({ quarta: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="quarta">
                                        Quarta-Feira
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="quinta" checked={this.state.quinta} onChange={(e) => this.setState({ quinta: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="quinta">
                                        Quinta-Feira
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="sexta" checked={this.state.sexta} onChange={(e) => this.setState({ sexta: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="sexta">
                                        Sexta-Feira
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="sabado" checked={this.state.sabado} onChange={(e) => this.setState({ sabado: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="sabado">
                                        Sábado
                                    </label>
                                </div>
                                <div className="form-check form-check-inline">
                                    <input className="form-check-input" id="domingo" checked={this.state.domingo} onChange={(e) => this.setState({ domingo: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="domingo">
                                        Domingo
                                    </label>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <AlertSuccess tag="Novidade" message="Agora você pode oferecer aos seus clientes nosso cadastro online para que virem mensalistas.">
                                    <div className='row mt-2'>
                                        <div className="col-lg-12">
                                            <div className="form-check form-check-inline">
                                                <input className="form-check-input" id="planoOnline" checked={this.state.online} onChange={(e) => this.setState({ online: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="planoOnline">
                                                    Plano disponível para assinatura online
                                                </label>
                                            </div>
                                        </div>
                                        {
                                            this.state.online === true && <>
                                                <div className="col-lg-6">
                                                    <label>Valor online</label>
                                                    <CurrencyInput value={this.state.valorMensalOnline && this.state.valorMensalOnline} prefix="R$" className='form-control' onChangeEvent={this.atualizarValorMensalOnline} />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Vagas disponíveis</label>
                                                    <input type="text" onChange={(e) => this.setState({ vagasDisponiveis: e.target.value })} value={this.state.vagasDisponiveis} className="form-control" />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Quantidade máxima de cobranças</label>
                                                    <select className="form-select" value={this.state.maximumCharges} onChange={e => this.setState({ maximumCharges: e.target.value })} >
                                                        <option value='1'>1</option>
                                                        <option value='2'>2</option>
                                                        <option value='3'>3</option>
                                                        <option value='4'>4</option>
                                                        <option value='5'>5</option>
                                                        <option value='6'>6</option>
                                                        <option value='6'>6</option>
                                                        <option value='7'>7</option>
                                                        <option value='8'>8</option>
                                                        <option value='9'>9</option>
                                                        <option value='10'>10</option>
                                                        <option value='11'>11</option>
                                                        <option value='12'>12</option>
                                                        <option value='13'>13</option>
                                                        <option value='14'>14</option>
                                                        <option value='15'>15</option>
                                                        <option value='16'>16</option>
                                                        <option value='16'>16</option>
                                                        <option value='17'>17</option>
                                                        <option value='18'>18</option>
                                                        <option value='19'>19</option>
                                                        <option value='20'>20</option>
                                                        <option value='21'>21</option>
                                                        <option value='22'>22</option>
                                                        <option value='23'>23</option>
                                                        <option value='24'>24</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Dias de vencimento</label>
                                                    <Select
                                                        key={this.state.randomSelect}
                                                        isMulti
                                                        name="dueDays"
                                                        defaultValue={this.state.dueDays}
                                                        options={this.state.allDueDays}
                                                        onChange={this.handleDueDays}
                                                        className="basic-multi-select"
                                                    />
                                                </div>
                                            </>
                                        }
                                    </div>
                                </AlertSuccess>
                            </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="btn-group">
                            {
                                this.state.id && <button type="button" onClick={this.reset} className="btn btn-secondary text-white">Cancelar</button>
                            }
                            <button type="button" onClick={(e) => { this.state.id ? this.verifyUpdatePlan() : this.addPlano() }} className="btn btn-primary">{this.state.id ? 'Atualizar' : 'Cadastrar'}</button>
                        </div>
                    </div>
                </div>
                <Dialog onClose={() => this.setState({ isShowingMonthlyUpdatePlan: false })} fullWidth maxWidth={"xs"} open={this.state.isShowingMonthlyUpdatePlan}>
                    <MuiDialogTitle className='text-center'>
                        Atualizar mensalistas
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <AlertWarning message="Deseja atualizar este plano nos mensalistas também?" />
                        <table className="table table-striped">
                            <tbody>
                                <tr key={-1}>
                                    <td width={10}>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" id="fecharCaixaParcial" checked={this.state.selectAll} onChange={(e) => this.onSelecTallMonthly(e)} type="checkbox" />
                                            <label className="form-check-label" htmlFor="fecharCaixaParcial">
                                            </label>
                                        </div>
                                    </td>
                                    <td colSpan={2}><strong>Selecionar todos</strong></td>
                                </tr>
                                {
                                    this.state.monthlies.map((item, index) =>
                                        <tr key={index}>
                                            <td width={10}>
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" id="fecharCaixaParcial" checked={item.isChecked} onChange={(e) => this.onCheckedMonthly(e, index)} type="checkbox" />
                                                </div>
                                            </td>
                                            <td>{item.nome}</td>
                                            <td>{takeIfIsNotNullOrEmpty(item.celular) ?? "-"}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.updatePlano(false)} className='btn btn-secondary'>Salvar sem atualizar</button>
                            <button onClick={() => this.updatePlano(true)} className='btn btn-success'>Atualizar selecionados</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <ListarPlanos key={this.state.random} estacionamentoId={this.state.estacionamentoId} onEdit={this.onEdit} onDuplicate={this.onDuplicate} onRemove={this.onRemove} />
            </NavBar>
        )
    }
}

class ListarPlanos extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: '',
            isAdministrador: isAdministrador(),
            list: [],
            loading: 1
        }
    }

    componentDidMount() {
        this.loading()
    }

    onEdit = (item) => {
        this.props.onEdit(item)
    }

    onRemove = (item) => {
        this.props.onRemove(item)
    }

    onDuplicate = (item) => {
        this.props.onDuplicate(item)
    }

    loading = () => {
        this.setState({ loading: 1 })
        firebase.firestore().collection('planos').where('estacionamentoId', '==', this.props.estacionamentoId).get().then(async (results) => {
            this.setState({ loading: 0 })
            var list = [];
            results.docs.forEach(doc => {
                list.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ list: list.sort((a, b) => a.nome.localeCompare(b.nome)) })
        }).catch(error => {
            toastError(error.message)
            this.setState({ loading: 0 })
        })
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Todos os planos
                </div>
                {
                    this.state.list.length === 0 && this.state.loading === 0 ?
                        <EmptyContent text='Nenhum plano cadastrado' />
                        :
                        this.state.loading === 0 ?
                            <div className='row'>
                                <div className='table-responsive'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                {
                                                    this.state.isAdministrador &&
                                                    <td width={10}></td>
                                                }
                                                <th scope="col">Nome</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Tipo</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Vagas</th>
                                                <th scope="col">Valor</th>
                                                <th scope="col" className="d-none d-sm-table-cell">Status</th>
                                                <th width="80"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.list.map((item, index) =>
                                                    <tr key={index}>
                                                        {
                                                            this.state.isAdministrador &&
                                                            <td className="d-none d-sm-table-cell">
                                                                <div className='btn-group'>
                                                                    <Tooltip title="Firebase" placement="top">
                                                                        <button type="button" onClick={() => { goToFirebase({ collection: Collection.PLANS, id: item.id }) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-database" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <DuplicateDataButton
                                                                        item={item}
                                                                        collection={Collection.PLANS}
                                                                        message="Deseja mesmo duplicar este plano entre os estabelecimentos abaixo?" />
                                                                </div>
                                                            </td>
                                                        }
                                                        <td>
                                                            {item.nome}
                                                            {
                                                                item.online &&
                                                                <span className={`badge text-bg-success text-white mx-2`}>Online</span>
                                                            }
                                                        </td>
                                                        <td className="d-none d-sm-table-cell">{item.tipo ?? "todos"}</td>
                                                        <td className="d-none d-sm-table-cell">{item.vagasDisponiveis ?? "-"}</td>
                                                        <td><NumberFormat value={item.valorMensal} decimalScale={2} fixedDecimalScale={true} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                        <td className="d-none d-sm-table-cell">{item.status}</td>
                                                        <td>
                                                            <div className="btn-group">
                                                                {
                                                                    this.props.onDuplicate &&
                                                                    <button type="button" onClick={() => { this.onDuplicate(item) }} className="btn btn-warning text-white">
                                                                        <i className="fas fa-copy" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onEdit &&
                                                                    <button type="button" onClick={() => { this.onEdit(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-edit" />
                                                                    </button>
                                                                }
                                                                {
                                                                    this.props.onRemove &&
                                                                    <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            :
                            <Loading />
                }
            </div>
        )
    }
}

export { CriarPlano, ListarPlanos };