import { getUsuarioCompact } from "../config/auth"
import { chunkItemsByQuantity } from "../shared/utils"
import { addQueue } from "../store/collections/queueWorker"

export const F360SyncAllTickets = async ({ parameters, tickets }) => {
    if (parameters.habilitarF360ERP === false) {
        return
    }
    const items = chunkItemsByQuantity({ items: tickets, quantity: 5 })
    for (const item of items) {
        const body = {
            ids: item.map(e => e.id),
            X_API_KEY: parameters.X_API_KEY,
            clienteId: parameters.clienteId,
            estacionamentoId: parameters.estacionamentoId,
            action: "PAYMENT",
            type: "TICKET_PAYMENT"
        }
        const data = {
            revendaId: parameters.revendaId,
            clienteId: parameters.clienteId,
            estacionamentoId: parameters.estacionamentoId,
            origin: "F360 - TICKETS",
            type: "F360",
            createdBy: getUsuarioCompact(),
            body: body
        }
        await addQueue({ data: data })
    }
}

export const F360SyncMonthlyPayments = async ({ parameters, monthlyPayments }) => {
    if (parameters.habilitarF360ERP === false) {
        return
    }
    const items = chunkItemsByQuantity({ items: monthlyPayments })
    for (const item of items) {
        const body = {
            ids: item.map(e => e.id),
            X_API_KEY: parameters.X_API_KEY,
            clienteId: parameters.clienteId,
            estacionamentoId: parameters.estacionamentoId,
            action: "PAYMENT",
            type: "MONTHLY_CHARGE_PAYMENT"
        }
        const data = {
            revendaId: parameters.revendaId,
            clienteId: parameters.clienteId,
            estacionamentoId: parameters.estacionamentoId,
            origin: "F360 - MENSALISTAS",
            type: "F360",
            createdBy: getUsuarioCompact(),
            body: body
        }
        await addQueue({ data: data })
    }
}

export const F360SyncCashiers = async ({ parameters, cashiers }) => {
    if (parameters.habilitarF360ERP === false) {
        return
    }
    const body = {
        ids: cashiers.map(e => e.id),
        X_API_KEY: parameters.X_API_KEY,
        clienteId: parameters.clienteId,
        estacionamentoId: parameters.estacionamentoId,
        action: "PAYMENT",
        type: "CASHIER_PAYMENT"
    }
    const data = {
        revendaId: parameters.revendaId,
        clienteId: parameters.clienteId,
        estacionamentoId: parameters.estacionamentoId,
        origin: "F360 - CAIXAS",
        type: "F360",
        createdBy: getUsuarioCompact(),
        body: body
    }
    await addQueue({ data: data })
}