import firebase from '../../config/firebase';
import { allTicketsByCashiers } from '../../store/collections/ticket'
import Moment from 'moment';
import { getAllChangesByCashierId } from './monthlyChagesWorker';
import { docToItem, docsToItems } from '../transform.docs';
import { isAdministrador } from '../../config/auth';
import { getDocById, updateDoc } from './collectionBaseWorker';
import { Collection, TicketStatus } from '../../shared/constants';
import { getAllBookingsApointmentByCashiers } from './bookingApointmentsWorker';

export const getAllOpenCashiersByClientId = async ({ clienteId }) => {
    const results = await firebase.firestore().collection('caixas')
        .where('status', '==', "aberto")
        .where('clienteId', '==', clienteId).get()
    return docsToItems(results)
}

export const getCashiersWithRevenues = async ({ estacionamentosIds, startDate, endDate }) => {
    const newStartDate = Moment(startDate).utc().startOf('day').toDate();
    const newEndDate = Moment(endDate).utc().endOf('day').toDate();
    const chunkSize = 10;
    var items = []
    for (let i = 0; i < estacionamentosIds.length; i += chunkSize) {
        const estacionamentosSliced = estacionamentosIds.slice(i, i + chunkSize);
        if (estacionamentosSliced.length > 0) {
            const results = await firebase.firestore().collection('caixas')
                .where('openAt', '>=', newStartDate)
                .where('openAt', '<=', newEndDate)
                .where('estacionamentoId', 'in', estacionamentosSliced.slice(0, 10)).get()
            items.push(docsToItems(results))
        }
    }
    var after = items.flatMap(e => e).filter(e => e.openAt?.toDate() >= startDate && e.openAt?.toDate() <= endDate)
    return transformResult(after, startDate, endDate)
}

export const getAllCashiersAndTicketsByParksIds = async ({ estacionamentosIds }) => {
    const chunkSize = 10;
    var allCashiers = []
    var allTickets = []
    for (let i = 0; i < estacionamentosIds.length; i += chunkSize) {
        const estacionamentosSliced = estacionamentosIds.slice(i, i + chunkSize);
        if (estacionamentosSliced.length > 0) {
            const ticketsResults = await firebase.firestore().collection('ticket').where('estacionamentoId', 'in', estacionamentosSliced.slice(0, 10)).where("caixaFechado", '==', false).get()
            var tickets = docsToItems(ticketsResults)
            allTickets.push(tickets)

            const cashisersResults = await firebase.firestore().collection('caixas').where('status', '==', 'aberto').where('estacionamentoId', 'in', estacionamentosSliced.slice(0, 10)).get()
            var cashisers = docsToItems(cashisersResults)
            allCashiers.push(cashisers)
        }
    }
    return {
        tickets: allTickets.flatMap(e => e),
        cashiers: allCashiers.flatMap(e => e)
    }
}

export const getCashiersWithRevenuesByIds = async ({ caixaIds, startDate, endDate }) => {
    const cashiers = []
    for (const caixaId of caixaIds) {
        const result = await getDocById({ collection: Collection.CASHIER, id: caixaId })
        cashiers.push(result)
    }
    if (cashiers.length > 0) {
        return transformResult(cashiers, startDate, endDate)
    }
    return null
}

export const getCashiersWithRevenuesById = async ({ caixaId }) => {
    const result = await firebase.firestore().collection('caixas').doc(caixaId).get()
    if (result.exists) {
        return transformResult([docToItem(result)])
    }
    return null
}

export const allPaymentsFromCashiers = ({ tickets, caixa }) => {
    if (tickets === undefined) {
        return []
    }
    return tickets.filter(e => e.status !== TicketStatus.CANCELED).flatMap(e => e.paymentsLogged).filter(e => e && (e.canceled === undefined || e.canceled === false) && e.caixaId === caixa.id)
}

export const allPaymentsFromTickets = (tickets) => {
    if (tickets === undefined) {
        return []
    }
    return tickets.flatMap(e => e.paymentsLogged).filter(e => e !== null && (e.canceled === undefined || e.canceled === false))
}

const transformResult = async (results, startDate, endDate) => {
    if (results.length === 0) {
        return null
    }
    const cashiers = results.sort((a, b) => (b.closeAt?.toDate() ?? new Date()) - (a.closeAt?.toDate() ?? new Date()))
    const cashiersIds = cashiers.map(e => e.id)
    var tickets = await allTicketsByCashiers({
        caixasIds: cashiersIds
    })
    const appoinments = await getAllBookingsApointmentByCashiers({ caixasIds: cashiersIds })
    const revenueAppoinments = calculateAllBookingAppointments({ appointments: appoinments })
    var cashiersComputed = []
    for (const cashier of cashiers) {
        const allTickets = allTicketsFromCashiers(tickets, cashier)
        const allTicketsCanceled = allTicketsFromCashiers(tickets, cashier).filter(e => e.status === "Cancelado")
        const revenuesTicket = calculateAllTickets(allTickets, cashier)
        const revenuesMonthly = await calculateAllMonthlies(cashier)
        const descontoManual = generateManualDiscount(allTickets, cashier)
        const convenios = generateRelationship(allTickets, cashier)
        const paidTickets = allTickets.filter(e => e.status !== "Estacionado" && e.status !== "Cancelado")
        const ticketTotal = revenuesTicket.map(e => e.ticket.valorCobrado).reduce((a, b) => a + b, 0)
        const descontoManualTotal = descontoManual.map(e => e.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0)
        const conveniosTotal = convenios.map(e => e.total).reduce((a, b) => a + b, 0)
        const movements = calculateMovements(cashier)
        const movementEarn = movements.filter(e => e.type === "ENTRADA").map(e => e.price ?? 0).reduce((a, b) => a + b, 0)
        const movementSpends = movements.filter(e => e.type === "SAIDA").map(e => e.price ?? 0).reduce((a, b) => a + b, 0)
        const faturamento = (revenuesMonthly.total + ticketTotal + movementEarn + revenueAppoinments.total)
        const faturamentoLiquido = (faturamento - movementSpends)
        const ticketMedio = faturamentoLiquido > 0 ? (faturamentoLiquido / paidTickets.length) : 0

        cashiersComputed.push({
            ...cashier,
            id: cashier.id,
            tickets: allTickets,
            revenuesMonthly: revenuesMonthly,
            monthlyPayments: revenuesMonthly.monthlyPayments,
            appointments: appoinments,
            revenuesTicket: revenuesTicket,
            tablesUsed: generateTablesUsed(allTickets, cashier),
            productsSold: generateProductsSold(allTickets, cashier),
            ticketsCanceled: allTicketsCanceled,
            retiradas: calculateRetiradas(cashier),
            movements: movements,
            descontoManual: descontoManual,
            convenios: convenios,
            totais: {
                ticket: ticketTotal,
                ticketsQuantity: allTickets.length,
                monthly: revenuesMonthly.total,
                monthlyByCategory: revenuesMonthly.monthlyByCategory,
                appointment: revenueAppoinments.total,
                descontoManual: descontoManualTotal,
                convenios: conveniosTotal,
                faturamento: faturamento,
                movementEarn: movementEarn,
                movementSpends: movementSpends,
                faturamentoLiquido: faturamentoLiquido,
                ticketsLancados: allTickets.length,
                ticketsLancadosPermanenciaMedia: generateMedianPark(allTickets),
                ticketMedio: ticketMedio
            }
        })
    }
    var retiradas = cashiersComputed.flatMap(e => e.retiradas)
    var movements = cashiersComputed.flatMap(e => e.movements ?? [])
    var ticketsQuantity = cashiersComputed.flatMap(e => e.totais.ticketsQuantity ?? 0).reduce((a, b) => a + b, 0)
    var allTicketsTotals = cashiersComputed.map(e => e.totais.ticketsLancados).reduce((a, b) => a + b, 0)
    const ticketsPaymentsWay = mergeWayPayments(cashiersComputed)
    const allPaymentsWay = ticketsPaymentsWay.flatMap(e => e.allRevenues)
    const allMovementsWay = cashiersComputed.flatMap(e => e.movements ?? [])
    const allMonthliesPaymentsWay = cashiersComputed.flatMap(e => e.monthlyPayments)
    const allAppointmentsWay = cashiersComputed.flatMap(e => e.appointments)
    const wayPayments = mergeRevenuesWayPayments(allPaymentsWay, allMonthliesPaymentsWay, allAppointmentsWay, allMovementsWay)

    const movementSpends = cashiersComputed.flatMap(e => e.totais.movementSpends ?? 0).reduce((a, b) => a + b, 0)
    const movementEarn = cashiersComputed.flatMap(e => e.totais.movementEarn ?? 0).reduce((a, b) => a + b, 0)
    const faturamentoTotal = wayPayments.map(e => e.totalBruto).reduce((a, b) => a + b, 0) + movementEarn
    const faturamentoDescontos = wayPayments.map(e => e.totalDescontos).reduce((a, b) => a + b, 0)

    const faturamentoLiquido = faturamentoTotal - faturamentoDescontos - movementSpends
    const ticketsLancadosPermanenciaMedia = generateMedianPark(tickets)
    const ticketMedio = faturamentoLiquido > 0 ? faturamentoLiquido / ticketsQuantity : 0
    const productsSold = mergeProductsSold(cashiersComputed.flatMap(e => e.productsSold))
    const ticketsCanceled = mergeTicketsCanceled(cashiersComputed.flatMap(e => e.ticketsCanceled))
    var result = {
        totais: {
            tickets: {
                valorEstacionamento: allPaymentsWay.map(e => e.ticket.valorEstacionamento).reduce((a, b) => a + b, 0),
                valorProdutos: allPaymentsWay.map(e => e.ticket.valorProdutos).reduce((a, b) => a + b, 0),
                valorCobrado: allPaymentsWay.map(e => e.ticket.valorCobrado).reduce((a, b) => a + b, 0),
                valorDesconto: allPaymentsWay.map(e => e.ticket.valorDesconto).reduce((a, b) => a + b, 0),
                byWayPayment: mergeRevenuesWayPayments(allPaymentsWay, allMonthliesPaymentsWay, allAppointmentsWay, allMovementsWay)
            },
            monthly: cashiersComputed.map(e => e.totais.monthly).reduce((a, b) => a + b, 0),
            appointment: cashiersComputed.map(e => e.totais.appointment).reduce((a, b) => a + b, 0),
            monthlyByCategory: cashiersComputed.flatMap(e => e.totais.monthlyByCategory),
            retiradas: retiradas.filter(e => e.removed === undefined || e.removed === false).map(e => e.price).reduce((a, b) => a + b, 0),
            movements: {
                earn: movements.filter(e => e).filter(e => (e.removed === false || e.isRemoved === false) && e.type === "ENTRADA").map(e => e.price).reduce((a, b) => a + b, 0),
                spends: movements.filter(e => e).filter(e => (e.removed === false || e.isRemoved === false) && e.type === "SAIDA").map(e => e.price).reduce((a, b) => a + b, 0),
                total: movements.filter(e => e).filter(e => (e.removed === false || e.isRemoved === false) && e.type === "ENTRADA").map(e => e.price).reduce((a, b) => a + b, 0) - movements.filter(e => e).filter(e => (e.removed === false || e.isRemoved === false) && e.type === "SAIDA").map(e => e.price).reduce((a, b) => a + b, 0)
            },
            ticketsPaymentsWay: ticketsPaymentsWay,
            faturamento: faturamentoTotal,
            faturamentoLiquido: faturamentoLiquido,
            movementEarn: cashiersComputed.map(e => e.totais.movementEarn).reduce((a, b) => a + b, 0),
            movementSpends: cashiersComputed.map(e => e.totais.movementSpends).reduce((a, b) => a + b, 0),
            convenios: cashiersComputed.map(e => e.totais.convenios).reduce((a, b) => a + b, 0),
            descontoManual: cashiersComputed.flatMap(e => e.descontoManual).map(e => e.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0),
            ticketsLancados: allTicketsTotals,
            ticketsLancadosPermanenciaMedia: ticketsLancadosPermanenciaMedia,
            ticketMedio: ticketMedio
        },
        tabelasUseds: mergeTablesUseds(cashiersComputed.flatMap(e => e.tablesUsed)),
        productsSold: productsSold,
        appointments: appoinments,
        ticketsCanceled: ticketsCanceled,
        descontoManual: cashiersComputed.flatMap(e => e.descontoManual),
        convenios: mergeRelationship(cashiersComputed.flatMap(e => e.convenios)),
        retiradas: retiradas,
        movements: movements,
        mensalistas: cashiersComputed.flatMap(e => e.monthlyPayments),
        caixas: cashiersComputed,
        period: {
            start: startDate,
            end: endDate
        }
    }
    return result;
}

const allTicketsFromCashiers = (tickets, caixa) => {
    if (tickets === undefined) {
        return []
    }
    //primeiro checa se teve pagamento, depois checa se teve algum cancelado(sem pagamento)
    if (isAdministrador()) {
        return tickets.filter(e => e.paymentsLogged?.map(p => p.caixaId).includes(caixa.id) || (e.status === "Cancelado" && e.caixasIds.includes(caixa.id)))
    } else {
        return tickets.filter(e => e.paymentsLogged?.map(p => p.caixaId).includes(caixa.id) || (e.status === "Cancelado" && e.caixasIds.includes(caixa.id))).filter(e => e.interrupted !== true)
    }
}

const calculateAllMonthlies = async (caixa) => {
    var allRevenues = []
    var allMonthlies = await getAllMonthlies(caixa)
    var allPaymentsWay = allMonthlies.map(e => e.payment?.formaPagamento).filter(e => e)
    var allPaymentsWayIdentifiers = [...(new Set(allMonthlies.map(e => e.payment?.formaPagamento?.id ?? "")))].filter(e => e !== "")
    allPaymentsWayIdentifiers.forEach(identifier => {
        var allPaymentsTickets = allMonthlies.filter(e => e.payment?.formaPagamento?.id === identifier)
        const way = allPaymentsWay.filter(e => e.id === identifier)
        if (way.length > 0) {
            allRevenues.push({
                wayPayment: way[0],
                monthly: {
                    total: allPaymentsTickets.map(e => e.payment.total).reduce((a, b) => a + b, 0)
                }
            })
        }
    })
    var items = [];
    var allCategories = [...(new Set(allMonthlies.filter(e => e.category).map(e => e.category)))]
    allCategories.forEach(category => {
        allPaymentsWayIdentifiers.forEach(identifier => {
            var payments = allMonthlies.filter(e => e.category === category && e.payment?.formaPagamento?.id === identifier)
            if (payments.length > 0) {
                items.push({
                    caixa: caixa,
                    category: category,
                    wayPayment: allPaymentsWay.find(e => e.id === identifier),
                    total: payments.map(e => e.payment.total).reduce((a, b) => a + b, 0)
                })
            }
        })
    })
    return {
        ...allRevenues,
        total: allRevenues.map(e => e.monthly?.total || 0).reduce((a, b) => a + b, 0),
        monthlyByCategory: items,
        monthlyPayments: allMonthlies
    }
}

const calculateAllBookingAppointments = ({ appointments }) => {
    var allRevenues = []
    var allPaymentsWay = appointments.map(e => e.payment?.formaPagamento).filter(e => e)
    var allPaymentsWayIdentifiers = [...(new Set(appointments.map(e => e.payment?.formaPagamento?.id ?? "")))].filter(e => e !== "")
    allPaymentsWayIdentifiers.forEach(identifier => {
        var allPaymentsTickets = appointments.filter(e => e.payment?.formaPagamento?.id === identifier)
        const way = allPaymentsWay.filter(e => e.id === identifier)
        if (way.length > 0) {
            allRevenues.push({
                wayPayment: way[0],
                appointment: {
                    total: allPaymentsTickets.map(e => e.payment.total).reduce((a, b) => a + b, 0)
                }
            })
        }
    })
    return {
        ...allRevenues,
        total: allRevenues.map(e => e.appointment?.total || 0).reduce((a, b) => a + b, 0),
    }
}

const generateMedianPark = (tickets) => {
    if (tickets.length === 0) {
        return 0
    }
    const allSeconds = tickets.filter(e => e.closedAt !== null).map(e => ((e.closedAt?.seconds ?? 0) - (e.createAt?.seconds ?? 0)))
    const sumOfSeconds = allSeconds.reduce((a, b) => a + b, 0)
    const mediam = (sumOfSeconds / tickets.length)
    return mediam
}

export const calculateAllTickets = (tickets, caixa) => {
    var allRevenues = []
    var allPaymentsWay = getAllPayments(tickets, caixa)
    var allPaymentsWayIdentifiers = new Set(allPaymentsWay.map(e => e.formaPagamento?.id ?? "").filter(e => e !== ""))
    allPaymentsWayIdentifiers.forEach(identifier => {
        var allPaymentsTickets = allPaymentsWay.filter(e => e.canceled === false && e.formaPagamento?.id === identifier)
        const valorEstacionamento = allPaymentsTickets.map(e => e.resumo?.valorEstacionamento ?? 0).reduce((a, b) => a + b, 0)
        const valorProdutos = allPaymentsTickets.map(e => e.resumo?.valorProdutos ?? 0).reduce((a, b) => a + b, 0) + allPaymentsTickets.map(e => e.resumo?.valorServicos ?? 0).reduce((a, b) => a + b, 0)
        const valorDesconto = (allPaymentsTickets.map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0) + allPaymentsTickets.filter(e => e.resumo?.descontoManual?.discount !== null).map(e => e.resumo?.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0))
        allRevenues.push({
            wayPayment: allPaymentsTickets[0].formaPagamento,
            ticket: {
                valorEstacionamento: valorEstacionamento,
                valorProdutos: valorProdutos,
                valorCobrado: valorProdutos + valorEstacionamento - valorDesconto,
                valorDesconto: valorDesconto
            }
        })
    })
    return allRevenues
}

const mergeWayPayments = (cashiers) => {
    var allCashiers = []
    cashiers.forEach(cashier => {
        var allPaymentsWay = getAllPayments(cashiers.flatMap(e => e.tickets), cashier)
        var allPaymentsWayIdentifiers = new Set(allPaymentsWay.map(e => e.formaPagamento?.nome ?? "").filter(e => e !== ""))
        var allRevenues = []
        allPaymentsWayIdentifiers.forEach(identifier => {
            const allPaymentsTickets = allPaymentsWay.filter(e => e.formaPagamento?.nome === identifier)
            const valorEstacionamento = allPaymentsTickets.map(e => e.resumo?.valorEstacionamento ?? 0).reduce((a, b) => a + b, 0)
            const valorProdutos = allPaymentsTickets.map(e => e.resumo?.valorProdutos ?? 0).reduce((a, b) => a + b, 0) + allPaymentsTickets.map(e => e.resumo?.valorServicos ?? 0).reduce((a, b) => a + b, 0)
            const valorDesconto = allPaymentsTickets.map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0) + allPaymentsTickets.filter(e => e.resumo?.descontoManual?.discount !== null).map(e => e.resumo?.descontoManual?.discount ?? 0).reduce((a, b) => a + b, 0)
            allRevenues.push({
                wayPayment: allPaymentsTickets[0].formaPagamento,
                ticket: {
                    valorEstacionamento: valorEstacionamento,
                    valorProdutos: valorProdutos,
                    valorCobrado: valorProdutos + valorEstacionamento - valorDesconto,
                    valorDesconto: valorDesconto
                }
            })
        })
        allCashiers.push({
            sequence: cashier.sequence ?? "-",
            caixaId: cashier.id,
            openAt: cashier.openAt,
            allRevenues: allRevenues,
        })
    })
    return allCashiers
}

const mergeRevenuesWayPayments = (allPaymentsWay, allMonthliesPaymentsWay, allAppointmentsWay, allMovementsWay) => {
    var allPaymentsWayIdentifiers = new Set((allPaymentsWay?.map(e => e.wayPayment?.nome ?? "").concat(allMonthliesPaymentsWay?.map(e => e.payment?.formaPagamento?.nome))).concat(allAppointmentsWay?.map(e => e.payment?.formaPagamento?.nome)).filter(e => e !== ""))
    var allRevenues = []
    allPaymentsWayIdentifiers.forEach(identifier => {
        const allPaymentsTickets = allPaymentsWay.filter(e => e.wayPayment?.nome === identifier)
        const allPaymentsMonthlies = allMonthliesPaymentsWay.filter(e => e.payment?.formaPagamento?.nome === identifier)
        const allMonthlyPaymentsWay = allMonthliesPaymentsWay.filter(e => e).filter(e => e.payment?.formaPagamento?.nome === identifier)
        const allAppointmentPaymentsWay = allAppointmentsWay.filter(e => e).filter(e => e.payment?.formaPagamento?.nome === identifier)
        const allMovementsPaymentsWay = allMovementsWay?.filter(e => e.type === "ENTRADA" && e.removed !== true).filter(e => e.formaPagamento?.nome === identifier) ?? []
        const cobradoMensalista = allMonthlyPaymentsWay.map(e => e.payment?.total ?? 0).reduce((a, b) => a + b, 0)
        const cobradoAgendamentos = allAppointmentPaymentsWay.map(e => e.payment?.total ?? 0).reduce((a, b) => a + b, 0)
        const cobradoMovimentacoes = allMovementsPaymentsWay.map(e => e.price ?? 0).reduce((a, b) => a + b, 0)
        const valorEstacionamento = allPaymentsTickets.map(e => e.ticket.valorEstacionamento).reduce((a, b) => a + b, 0)
        const valorProdutos = allPaymentsTickets.map(e => e.ticket.valorProdutos).reduce((a, b) => a + b, 0)
        const valorDesconto = allPaymentsTickets.map(e => e.ticket.valorDesconto).reduce((a, b) => a + b, 0)
        const valorCobrado = allPaymentsTickets.map(e => e.ticket.valorCobrado).reduce((a, b) => a + b, 0)

        var wayPayment = null
        if (allPaymentsTickets.length > 0) {
            wayPayment = allPaymentsTickets[0].wayPayment
        } else if (allPaymentsMonthlies.length > 0) {
            wayPayment = allPaymentsMonthlies[0].payment?.formaPagamento
        } else if (allAppointmentPaymentsWay.length > 0) {
            wayPayment = allAppointmentPaymentsWay[0].payment?.formaPagamento
        } else if (cobradoMovimentacoes.length > 0) {
            wayPayment = cobradoMovimentacoes[0]?.formaPagamento
        }
        allRevenues.push({
            wayPayment: wayPayment,
            total: (valorCobrado + cobradoMensalista + cobradoAgendamentos + cobradoMovimentacoes),
            totalBruto: (valorEstacionamento + valorProdutos + cobradoMensalista + cobradoAgendamentos),
            totalDescontos: valorDesconto,
            quantity: (allPaymentsTickets.length + allMonthlyPaymentsWay.length + allAppointmentPaymentsWay.length)
        })
    })
    return allRevenues
}

const mergeTablesUseds = (allTables) => {
    var allTablePayments = []
    var priceTableIdentifiers = new Set(allTables.map(e => e.tableSelected?.resumo?.tabelaPreco?.id ?? "").filter(e => e !== ""))
    priceTableIdentifiers.forEach(identifier => {
        var tableSelected = allTables.filter(e => e.tableSelected?.resumo?.tabelaPreco?.id === identifier)
        allTablePayments.push({
            tableSelected: tableSelected[0].tableSelected,
            quantityUsed: tableSelected.map(e => e.quantityUsed).reduce((a, b) => a + b, 0),
            total: tableSelected.map(e => e.total).reduce((a, b) => a + b, 0),
        })
    })
    return allTablePayments.sort((a, b) => b.quantityUsed - a.quantityUsed)
}

const mergeProductsSold = (products) => {
    return products.flatMap(e => e).sort((a, b) => b.quantity - a.quantity)
}
const mergeTicketsCanceled = (tickets) => {
    return tickets.flatMap(e => e)
}

const generateTablesUsed = (tickets, caixa) => {
    var allTablePayments = []
    var allPayments = getAllPayments(tickets, caixa)
    var priceTableIdentifiers = new Set(allPayments.map(e => e.resumo?.tabelaPreco?.id ?? "").filter(e => e !== ""))
    priceTableIdentifiers.forEach(identifier => {
        var tableSelected = allPayments.filter(e => e.resumo?.tabelaPreco?.id === identifier && e.resumo?.valorEstacionamento !== 0)
        allTablePayments.push({
            tableSelected: tableSelected[0],
            quantityUsed: tableSelected.length,
            total: tableSelected.map(e => e.resumo.valorEstacionamento).reduce((a, b) => a + b, 0),
        })
    })
    return allTablePayments
}

const generateProductsSold = (tickets, caixa) => {
    var allPayments = getAllPayments(tickets, caixa)
    const allProducts = allPayments.map(e => e.resumo.products).flatMap(e => e).filter(e => e)
    var productsIdentifiers = new Set(allProducts.map(e => e.id ?? ""))
    var allProductsSold = []
    productsIdentifiers.forEach(identifier => {
        var productsFound = allProducts.filter(e => e.id === identifier)
        var quantidade = productsFound.map(e => e.quantidade).reduce((a, b) => a + b, 0)
        allProductsSold.push({
            product: productsFound[0],
            descricao: productsFound[0].descricao,
            unitPrice: productsFound[0].valor,
            quantity: quantidade,
            total: productsFound[0].valor * quantidade,
        })
    })
    return allProductsSold
}

const generateManualDiscount = (tickets, caixa) => {
    var allPayments = getAllPayments(tickets, caixa)
    const discount = allPayments.filter(e => e.resumo?.descontoManual !== undefined && e.resumo?.descontoManual !== null).map((e) => {
        return {
            createAt: e.createAt,
            descontoManual: e.resumo?.descontoManual,
            caixaId: caixa.id
        }
    })
    return discount
}

export const generateRelationship = (tickets, caixa) => {
    var allTablePayments = []
    var allPayments = getAllPayments(tickets, caixa).filter(e => e.resumo?.convenio !== null)
    var relationship = new Set(allPayments.map(e => e.resumo?.convenio?.id ?? "").filter(e => e !== ""))
    relationship.forEach(identifier => {
        var tableSelected = allPayments.filter(e => e.resumo?.convenio?.id === identifier)
        allTablePayments.push({
            convenio: tableSelected[0],
            quantityUsed: tableSelected.length,
            total: tableSelected.map(e => e.resumo.valorDesconto).reduce((a, b) => a + b, 0),
        })
    })
    return allTablePayments
}

const mergeRelationship = (relationships) => {
    var list = []
    var priceTableIdentifiers = new Set(relationships.map(e => e.convenio.resumo?.convenio?.id ?? "").filter(e => e !== ""))
    priceTableIdentifiers.forEach(identifier => {
        var convenio = relationships.filter(e => e.convenio.resumo?.convenio?.id === identifier)
        list.push({
            convenio: convenio[0].convenio,
            quantityUsed: convenio.map(e => e.quantityUsed).reduce((a, b) => a + b, 0),
            total: convenio.map(e => e.total).reduce((a, b) => a + b, 0),
        })
    })
    return list.sort((a, b) => b.quantityUsed - a.quantityUsed)
}

const calculateRetiradas = (caixa) => {
    var retradas = []
    if (caixa.retiradas) {
        caixa.retiradas.forEach(item => {
            retradas.push({ ...item, cashierId: caixa.id })
        })
    }
    return retradas.sort((a, b) => b.createAt.toDate() - a.createAt.toDate())
}

const calculateMovements = (caixa) => {
    var movements = []
    if (caixa.movements) {
        caixa.movements.forEach(item => {
            movements.push({ ...item, cashierId: caixa.id })
        })
    }
    return movements.sort((a, b) => b.createAt.toDate() - a.createAt.toDate())
}

const mergeAllMonthlies = (monthlies) => {
    var months = []
    monthlies.forEach(month => {
        months.push(month[0])
    })
    return months;
}

const getAllMonthlies = async (caixa) => {
    const result = await getAllChangesByCashierId({ estacionamentoId: caixa.estacionamentoId, cashierId: caixa.id })
    return result
}

const getAllPayments = (tickets, caixa) => {
    return allPaymentsFromCashiers({ tickets: tickets, caixa: caixa })
}

export const calculateTotalOfTicketAndMonthlyFromCashierId = async ({ estacionamentoId, tickets, cashier }) => {
    const monthlyPayment = await getAllChangesByCashierId({ estacionamentoId: estacionamentoId, cashierId: cashier.id })
    const allPayments = calculateAllTickets(tickets, cashier)
    const newMonthlyPayment = monthlyPayment.map(e => {
        return {
            id: e.payment.formaPagamento.id,
            nome: e.payment.formaPagamento.nome,
            total: e.payment.total,
        }
    })
    const newTicketPayment = allPayments.map(e => {
        return {
            id: e.wayPayment.id,
            nome: e.wayPayment.nome,
            total: e.ticket.valorCobrado,
        }
    })
    return newMonthlyPayment.concat(newTicketPayment)
}

export const calculateTotalMergedOfTicketAndMonthlyFromCashierId = async ({ estacionamentoId, tickets, cashier }) => {
    const payments = []
    const allPayments = await calculateTotalOfTicketAndMonthlyFromCashierId({ estacionamentoId: estacionamentoId, tickets: tickets, cashier: cashier })
    var identifiers = [...(new Set(allPayments.map(e => e.id)))]
    for (const identifier of identifiers) {
        const payment = allPayments.filter(e => e.id === identifier)
        const total = payment.map(e => e.total).reduce((a, b) => a + b, 0)
        payments.push({
            ...payment[0],
            total: total
        })
    }
    return payments.sort((a, b) => -b.nome.localeCompare(a.nome))
}

export const updateCashierById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.CASHIER, id: id, data: data })
}