import React from 'react'
import { getParameterByParkId } from "../../store/collections/parameterWorker"
import { PrinterHeaderComponent, PrinterCaixaHeaderComponent, BodyTicketInComponent, BodyMonthlyChargeComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, PrinterFooterComponent, PrinterQRCodeComponent, MessageInTicketComponent, CashierReportTotalsDocument, CashierReportFormasDePagamentosCaixas, CashierReportListagemMensalistasCaixas, CashierReportTabelasUtilizadasCaixas, CashierReportConveniosCaixas, CashierReportDescontosCaixas, CashierReportCaixasAnalisados, CashierReportMovimentacoesCaixas, CashierReportRetiradasCaixas, CashierReportCancelamentosCaixas } from "./printer-manager"
import { PrintEstacionados, PrintPagosEstacionados, PrintPagosAguardando, PrintPagosEntregues, PrintPatioLegend } from "./printer-patio"

class PrinterTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket,
            park: props.park,
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        <BodyTicketInComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterInternalReviewComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterProductsComponent parameters={this.state.parameters} ticket={this.state.ticket} />
                        <PrinterPaymentsComponent ticket={this.state.ticket} />
                        <PrinterQRCodeComponent ticket={this.state.ticket} parameters={this.state.parameters} />
                        <MessageInTicketComponent parameters={this.state.parameters} position={"fim"} />
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

class PrinterMonthlyChargeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            charge: props.charge,
            park: props.park
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        <BodyMonthlyChargeComponent parameters={this.state.parameters} charge={this.state.charge} />
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

class PrinterCashierComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            park: props.park,
            resultado: props.resultado,
            impressaoCaixaTotais: props.impressaoCaixaTotais,
            impressaoListagemMensalistasCaixas: props.impressaoListagemMensalistasCaixas,
            impressaoTabelasUtilizadasCaixas: props.impressaoTabelasUtilizadasCaixas,
            impressaoConveniosCaixas: props.impressaoConveniosCaixas,
            impressaoDescontosCaixas: props.impressaoDescontosCaixas,
            impressaoMovimentacoesCaixas: props.impressaoMovimentacoesCaixas,
            impressaoRetiradasCaixas: props.impressaoRetiradasCaixas,
            impressaoCancelamentosCaixas: props.impressaoCancelamentosCaixas,
            impressaoListagemMensalistasCaixasCategories: props.impressaoListagemMensalistasCaixasCategories
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '11px', fontWeight: 'bold', maxWidth: '85%', margin: '0 auto', color: 'black' }}>
                        <PrinterCaixaHeaderComponent parameters={this.state.parameters} park={this.state.park} resultado={this.state.resultado}/>
                        <CashierReportCaixasAnalisados resultado={this.state.resultado} />
                        {
                            this.state.impressaoCaixaTotais && <>
                                <CashierReportTotalsDocument resultado={this.state.resultado} impressaoListagemMensalistasCaixasCategories={this.state.impressaoListagemMensalistasCaixasCategories}/>
                                <CashierReportFormasDePagamentosCaixas resultado={this.state.resultado} impressaoListagemMensalistasCaixasCategories={this.state.impressaoListagemMensalistasCaixasCategories}/>
                            </>
                        }
                        {
                            this.state.impressaoTabelasUtilizadasCaixas && <CashierReportTabelasUtilizadasCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoListagemMensalistasCaixas && <CashierReportListagemMensalistasCaixas resultado={this.state.resultado} impressaoListagemMensalistasCaixasCategories={this.state.impressaoListagemMensalistasCaixasCategories}/>
                        }
                        {
                            this.state.impressaoMovimentacoesCaixas && <CashierReportMovimentacoesCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoRetiradasCaixas && <CashierReportRetiradasCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoConveniosCaixas && <CashierReportConveniosCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoDescontosCaixas && <CashierReportDescontosCaixas resultado={this.state.resultado} />
                        }
                        {
                            this.state.impressaoCancelamentosCaixas && <CashierReportCancelamentosCaixas resultado={this.state.resultado} />
                        }
                        <PrinterFooterComponent park={this.state.park} />
                    </div>
                </div>
            }
        </>
        )
    }
}

class PrinterPatioComponent extends React.Component {

    constructor(props) {
        super(props)
        const tickets = props.allTickets.filter((e) => e?.interrupted !== true)
        const impressaoTipos = props.impressaoTipos
        const filteredTickets = tickets.filter(ticket => {
            if (impressaoTipos.mensalista && ticket.monthly) return true
            if (impressaoTipos.credenciado && ticket.accredited) return true
            if (impressaoTipos.vendaAvulsa && ticket.type === "SALES") return true
            if (impressaoTipos.avulso && !ticket.monthly && !ticket.accredited && ticket.type !== "SALES") return true
            return false
        })
        const sortedTickets = filteredTickets.sort((a, b) => {
            const timestampA = a.createAt?.seconds || a.createdAt?.seconds || 0
            const timestampB = b.createAt?.seconds || b.createdAt?.seconds || 0
            return timestampA - timestampB
        })
        this.state = {
            park: props.park,
            allTickets: sortedTickets,
            impressaoEstacionados: props.impressaoEstacionados,
            impressaoPagosEstacionados: props.impressaoPagosEstacionados,
            impressaoPagosAguardando: props.impressaoPagosAguardando,
            impressaoPagosEntregues: props.impressaoPagosEntregues,
            user: props.user,
            cashier: props.cashier
        }
        this.load()
    }

    load = async () => {
        const parameters = await getParameterByParkId({ estacionamentoId: this.state.park.id })
        this.setState({ parameters: parameters })
    }

    

    render() {
        return (<>
            {
                this.state.parameters &&
                <div className='text-center'>
                    <div style={{ fontFamily: 'Arial', fontSize: '10px', fontWeight: 'bold', maxWidth: '80%', margin: '0 auto', color: 'black' }}>
                        <PrinterHeaderComponent parameters={this.state.parameters} park={this.state.park} />
                        {
                            this.state.impressaoEstacionados && <PrintEstacionados allTickets={this.state.allTickets}/>
                        }
                        {
                            this.state.impressaoPagosEstacionados && <PrintPagosEstacionados allTickets={this.state.allTickets}/>
                        }
                        {
                            this.state.impressaoPagosAguardando && <PrintPagosAguardando allTickets={this.state.allTickets}/>
                        }
                        {
                            this.state.impressaoPagosEntregues && <PrintPagosEntregues parameters={this.state.parameters} allTickets={this.state.allTickets} user={this.state.user}/>
                        }
                        <PrintPatioLegend user={this.state.user} cashier={this.state.cashier}/>
                    </div>
                </div>
            }
        </>
        )
    }
}

export { PrinterTicketComponent, PrinterMonthlyChargeComponent, PrinterCashierComponent, PrinterPatioComponent }