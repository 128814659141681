import React from 'react'
import { getRevenda, getUsuarioCompact, isPlatformOwner } from '../../config/auth'
import { Loading } from '../../components/common/commons'
import { EmptyContent } from '../../components/common/commons'
import { sendClickButton } from '../../shared/analytics'
import Moment from 'moment';
import { addHelpdesk, deleteHelpdesk, getAllHelpdesk, getAllHelpdeskByClientId, getAllHelpdeskByRevendaId, updateHelpdesk } from '../../store/collections/helpdeskWorker';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { fetchFunction } from '../../shared/function-request'
import Tooltip from '@mui/material/Tooltip';
import { NavBar } from '../../components/navbar'
import { dangerConfirm, reloadWindow, successConfirm, toastSuccess, toastWarning } from '../../shared/utils'

class CadastrarChamado extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: false,
            clienteId: this.props.clienteId,
            estacionamentoId: "",
            platformSelected: "",
            typeSelected: "",
            types: [
                "Criação de conta PagSeguro",
                "Criação de conta Stone",
                "Associar POS ou TAG",
                "Outros"
            ],
            criticalities: [
                "Baixa",
                "Média",
                "Alta"
            ],
            platforms: [],
            parks: []
        }
    }

    componentDidMount = async () => {
        await this._load()
    }

    _load = async () => {
        var parks = await getAllParksByClientId({ clientId: this.state.clienteId })
        this.setState({ parks: parks })
        const revenda = getRevenda()
        var items = []
        if (revenda.posStone) {
            items.push({
                platform: "Stone",
                orientations: "É preciso solicitar o CNPJ e Stone(s) code do POS do cliente"
            })
        }
        if (revenda.posSafra) {
            items.push({
                platform: "Safra",
                orientations: "É preciso solicitar o EC e Número lógico do POS do cliente"
            })
        }
        if (revenda.posPagSeguro) {
            items.push({
                platform: "PagSeguro",
                orientations: "É preciso solicitar o Número Serial do POS do cliente ( SN )"
            })
        }
        items.push({
            platform: "Sem Parar",
            orientations: "Informe o número de contato do cliente e um representante do Sem Parar entrara em contato para configurar o estabelecimento."
        })
        items.push({
            platform: "Taggy",
            orientations: "Informe o número de contato do cliente e um representante do Taggy entrara em contato para configurar o estabelecimento."
        })
        items.push({
            platform: "Conectcar",
            orientations: "Informe o número de contato do cliente e um representante do Conectcar entrara em contato para configurar o estabelecimento."
        })
        this.setState({ platforms: items })
    }

    onParkSelected = (value) => {
        const items = this.state.parks.filter(e => e.id === value)
        if (items.length > 0) {
            this.setState({ estacionamentoId: items[0].id })
        }
    }

    onPlatformSelected = (value) => {
        const platform = this.state.platforms.filter(e => e.platform === value)
        if (platform.length > 0) {
            this.setState({ platformSelected: platform[0].platform })
            this.setState({ orientations: platform[0].orientations })
        }
    }

    checkAllFields = () => {
        if (this.state.estacionamentoId === '') {
            toastWarning('Selecione o estabelecimento que deseja associar o equipamento.')
            return false
        }
        if (this.state.typeSelected === "") {
            toastWarning('Informe o tipo de chamado.')
            return false
        }
        if (this.state.body === '') {
            toastWarning('Informe a descrição.')
            return false
        }
        return true
    }

    add = async () => {
        if (!this.checkAllFields()) {
            return
        }
        const id = await addHelpdesk({
            platform: this.state.platformSelected,
            type: this.state.typeSelected,
            criticality: this.state.criticalitySelected,
            body: this.state.body,
            estacionamentoId: this.state.estacionamentoId,
            clienteId: this.state.clienteId
        })
        const options = {
            method: "POST"
        }
        sendClickButton("Chamados", `Cadastrar chamado com assunto: ${this.state.typeSelected}`)
        this.setState({ isLoading: true })
        const result = await fetchFunction(`/helpdesk/create/${id}`, options)
        if (result.success) {
            toastSuccess(result.message)
            this.setState({ isLoading: false })
            reloadWindow()
        }
    }

    render() {
        return (
            <>
                <div className="row mb-3">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Novo chamado
                            </div>
                            {
                                !this.state.isLoading &&
                                <>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-4">
                                                <strong>Estacionamentos</strong>
                                                <select className='form-select' value={this.state.estacionamentoId} onChange={(e) => this.onParkSelected(e.target.value)}>
                                                    <option value="">Selecionar</option>
                                                    {
                                                        this.state.parks?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <strong>Nível</strong>
                                                <select className='form-select' value={this.state.criticalitySelected} onChange={(e) => this.setState({ criticalitySelected: e.target.value })} >
                                                    <option value="">Selecionar</option>
                                                    {
                                                        this.state.criticalities?.map(item => { return <option value={item}>{item}</option> })
                                                    }
                                                </select>
                                            </div>
                                            <div className="col-lg-4">
                                                <strong>Tipo</strong>
                                                <select className='form-select' value={this.state.typeSelected} onChange={(e) => this.setState({ typeSelected: e.target.value })} >
                                                    <option value="">Selecionar</option>
                                                    {
                                                        this.state.types?.map(item => { return <option value={item}>{item}</option> })
                                                    }
                                                </select>
                                            </div>
                                            {
                                                this.state.typeSelected?.indexOf("Criação de conta") !== -1 &&
                                                <div className='col-lg-12'>
                                                    <div className="alert alert-info my-3">
                                                        <strong>Instruções:</strong> Mantenha os dados do estabelecimento atualizado, esses dados serão enviados diretamente para a adquirente, aqui você pode preencher por qual meio de comunicação o cliente deseja ser contactado, quantas maquininhas deseja, se já é cliente de outra adquirente e etc.
                                                    </div>
                                                </div>
                                            }
                                            {
                                                this.state.typeSelected === "Associar POS ou TAG" && <>
                                                    <div className="col-lg-4">
                                                        <strong>Plataforma</strong>
                                                        <select className='form-select' value={this.state.platformSelected} onChange={(e) => this.onPlatformSelected(e.target.value)}>
                                                            <option value="">Selecionar</option>
                                                            {
                                                                this.state.platforms?.map(item => { return <option value={item.platform}>{item.platform}</option> })
                                                            }
                                                        </select>
                                                    </div>
                                                    {
                                                        this.state.orientations &&
                                                        <div className='col-lg-12'>
                                                            <div className="alert alert-info my-3">
                                                                <strong>Instruções:</strong> {this.state.orientations}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            }
                                            <div className='col-lg-12'>
                                                <label>Descrição</label>
                                                <textarea type="text" rows={5} placeholder='Descrição do chamado' value={this.state.body} onChange={(e) => this.setState({ body: e.target.value })} className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="btn-group">
                                            <button type="button" onClick={this.add} className="btn btn-success">Registrar</button>
                                        </div>
                                    </div>
                                </>
                            }
                            {
                                this.state.isLoading &&
                                <Loading />
                            }
                        </div>
                    </div>
                </div >
                <ListarChamadosBody clienteId={this.state.clienteId} />
            </>
        )
    }
}

class ListarChamados extends React.Component {

    render() {
        return (
            <NavBar>
                <ListarChamadosBody showAll={true} />
            </NavBar>
        )
    }
}

class ListarChamadosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsOpen: [],
            isLoading: true,
            showAll: props.showAll,
            clienteId: props.clienteId,
            revendaId: getRevenda()?.id,
            platformOwner: isPlatformOwner()
        }
    }

    componentDidMount = async () => {
        await this.loading()
    }

    loading = async () => {
        this.setState({ isLoading: true })
        var items = []
        if (this.state.clienteId) {
            items = await getAllHelpdeskByClientId({ clientId: this.state.clienteId })
        } else if (this.state.platformOwner) {
            items = await getAllHelpdesk()
        } else {
            items = await getAllHelpdeskByRevendaId({ revendaId: this.state.revendaId })
        }
        this.setState({ itemsOpen: items.filter(e => e.status === "CREATED").sort((a, b) => b.criticality.localeCompare(a.criticality)) })
        this.setState({ items: items.filter(e => e.status !== "CREATED").sort((a, b) => b.criticality.localeCompare(a.criticality)) })
        this.setState({ isLoading: false })
    }

    onLink = async (item) => {
        const confirm = await successConfirm({ message: `Confirma que este chamado foi resolvido?` })
        if (confirm) {
            item.status = "RESOLVIDO"
            item.closedAt = new Date()
            await updateHelpdesk({ id: item.id, data: item })
            const options = {
                method: "POST"
            }
            sendClickButton("Chamados", `Chamado resolvido ${item.id} por ${getUsuarioCompact().nome}`)
            const result = await fetchFunction(`/helpdesk/done/${item.id}`, options)
            if (result.success) {
                toastSuccess(result.message)
                this.loading()
            }
        }
    }

    onRemove = async (item) => {
        const confirm = await dangerConfirm({ message: `Deseja mesmo remover este chamado?` })
        if (confirm) {
            await deleteHelpdesk({ id: item.id })
            toastWarning("Chamado removido com sucesso")
            this.loading()
        }
    }

    showAssociatePOSModal = () => {
        this.setState({ isShowAssociatePOSModal: true })
    }

    hideAssociatePOSModal = () => {
        this.setState({ isShowAssociatePOSModal: false })
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h6>Todos os chamados abertos</h6>
                        <span className='btn-group'>
                            {
                                this.state.platformOwner && <>
                                    <button type="button" onClick={this.showAssociatePOSModal} className="btn btn-sm btn-primary">
                                        <i className="fa-solid fa-circle-info mx-2" />
                                        Ajuda
                                    </button>
                                </>
                            }
                        </span>
                    </div>
                    {
                        this.state.itemsOpen.length === 0 && !this.state.isLoading ?
                            <>
                                {this.state.clienteId ? <EmptyContent text='Nenhum chamado aberto para este cliente.' /> : <EmptyContent text='Nenhum chamado aberto neste momento.' />}
                            </>
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='table-responsive'>
                                        <table className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th scope="col"><strong>Revenda</strong></th>
                                                    <th scope="col" align='center'><strong>Nível</strong></th>
                                                    <th scope="col"><strong>Tipo</strong></th>
                                                    <th scope="col"><strong>Usuário</strong></th>
                                                    <th scope="col"><strong>Descrição</strong></th>
                                                    <th scope="col" align='center'><strong>Aberto em</strong></th>
                                                    <th scope="col" align='center'><strong>Fechado em</strong></th>
                                                    <th scope="col" width={10}></th>
                                                    <th scope="col" width={10} align='right'></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.itemsOpen.map(item =>
                                                        <tr>
                                                            <td width={10}>
                                                                {item.revendaId === "UhkTEfwE0GH4wpLpfZZm" && <span className="badge text-bg-success w-100">Dimep</span>}
                                                                {item.revendaId === "feHh4buNASnnh6ahmYJ3" && <span className="badge text-bg-primary w-100">Madis</span>}
                                                                {item.revendaId === "yDDlIMYGASii6Scv1Un8" && <span className="badge w-100" style={{ backgroundColor: "#44377b" }}>SelfParking</span>}
                                                                {item.revendaId === "bjhYBaWdULPY9XO7K0VH" && <span className="badge w-100" style={{ backgroundColor: "#1f2021" }}>NeoTagus</span>}
                                                            </td>
                                                            <td width={10} align='center'>
                                                                {item.criticality === "Baixa" && <span className="badge text-bg-success w-100">{item.criticality}</span>}
                                                                {item.criticality === "Média" && <span className="badge text-bg-warning text-white w-100">{item.criticality}</span>}
                                                                {item.criticality === "Alta" && <span className="badge text-bg-danger w-100">{item.criticality}</span>}
                                                            </td>
                                                            <td>{item.type ?? "Outros"}</td>
                                                            <td>{item.user.nome}</td>
                                                            <td>{item.body}</td>
                                                            <td align='center'>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                            <td align='center'>
                                                                {
                                                                    item.closedAt ? <span>{Moment(item.closedAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</span> : <span>-</span>
                                                                }
                                                            </td>
                                                            <td>
                                                                {item.status === "CREATED" && <span className="badge text-bg-success w-100">Resolvido</span>}
                                                                {item.status !== "CREATED" && <span className="badge text-bg-secondary w-100">Aguardando</span>}
                                                            </td>
                                                            <td width='10' align='right'>
                                                                <div className='btn-group'>
                                                                    {
                                                                        (this.state.platformOwner && item.status === "CREATED") &&
                                                                        <button type="button" onClick={() => { this.onLink(item) }} className="btn btn-success">
                                                                            <i className="fa-solid fa-check" />
                                                                        </button>
                                                                    }
                                                                    <button type="button" onClick={() => { this.onRemove(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                    <Dialog maxWidth={"sm"} open={this.state.isShowAssociatePOSModal} onClose={this.hideAssociatePOSModal}>
                        <MuiDialogTitle className='text-center'>
                            Associar equipamento ao cliente
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <div className='col-lg-12'>
                                <div className="alert alert-success" role="alert">
                                    <strong>PagSeguro:</strong> É preciso solicitar o Número Serial do POS do cliente ( SN ) e selecionar a opção: <strong>Associar terminais</strong> no site abaixo.
                                    <a href='https://dev.pagseguro.uol.com.br/v1.0/reference/smart-pos-quero-integrar-suporte#central-de-atendimento' target={'_blank'} className="btn btn-success mt-3">Associar PagSeguro</a>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="alert alert-success" role="alert">
                                    <strong>Stone:</strong> É preciso solicitar o CNPJ e Stone(s) code do POS do cliente e selecionar a opção: <strong>Adicionar Lojista</strong> <p />
                                    <a href='https://partnerhub.stone.com.br/#/application' target={'_blank'} className="btn btn-success mt-3">Associar Stone</a> <p />
                                    <strong>Orientações ao cliente</strong> <br />
                                    <span>Acesse <a href='conta.stone.com.br'>conta.stone.com.br</a>. <br />
                                        Clique no nome do seu estabelecimento, que aparecere no canto superior direito da tela. <br />
                                        Vá em <strong>Meu perfil</strong>, depois <strong> Tarifas</strong>. <br />
                                        Desça um pouco a tela para visualizar as informações das bandeiras. <br />
                                        O seu Stonecode aparecerá ao lado do nome do seu negócio, acima das taxas das bandeiras. <br /></span>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="alert alert-warning" role="alert">
                                    <strong>Safra:</strong> É preciso solicitar o EC e Número lógico do POS do cliente e encaminhar um e-mail: <strong>sustentacao.pos@safra.com.br</strong> <p />
                                    <strong>Assunto: </strong> Vinculação {this.state.revenda?.nome} - POS <p />
                                    <strong>Corpo do email</strong> <p />
                                    Olá, conseguem por favor vincular o {this.state.revenda?.nome} no POS abaixo?<br />

                                    EC: 000000000 <br />
                                    N° Lógico:0000000 <p />

                                    Obrigado
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="alert alert-primary" role="alert">
                                    <strong>Cielo:</strong> Oriente o cliente a acessar o aplicativo Store no POS da LIO da Cielo e procurar por {this.state.revenda?.nome}.
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="alert alert-danger" role="alert">
                                    <strong>Getnet:</strong> Oriente o cliente a acessar o aplicativo Store no POS da Getnet e procurar por {this.state.revenda?.nome}.
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <div className="alert alert-warning" role="alert">
                                    <strong>Rede:</strong> Oriente o cliente a acessar o aplicativo Store no POS da Rede e procurar por {this.state.revenda?.nome}.
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <button onClick={this.hideAssociatePOSModal} className='btn btn-secondary m-3'>Fechar</button>
                        </MuiDialogActions>
                    </Dialog>
                </div >
                {
                    this.state.showAll &&
                    <div className="card my-3">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            <h6>Todos os chamados fechados</h6>
                        </div>
                        {
                            this.state.items.length === 0 && !this.state.isLoading ?
                                <EmptyContent text='Nenhum chamado solicitado para este cliente.' />
                                :
                                !this.state.isLoading ?
                                    <div className="row">
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        {
                                                            this.state.platformOwner &&
                                                            <td scope="col"><strong>Revenda</strong></td>
                                                        }
                                                        <td scope="col" align='center'><strong>Nível</strong></td>
                                                        <td scope="col"><strong>Tipo</strong></td>
                                                        <td scope="col"><strong>Usuário</strong></td>
                                                        <td scope="col"><strong>Descrição</strong></td>
                                                        <td scope="col" align='center'><strong>Aberto em</strong></td>
                                                        <td scope="col" align='center'><strong>Fechado em</strong></td>
                                                        <td scope="col" align='right'></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map(item =>
                                                            <tr>
                                                                {
                                                                    this.state.platformOwner &&
                                                                    <td>
                                                                        <Tooltip title={this.state.platformOwner ? item.id : ""} placement='top'>
                                                                            <span>{item.revendaNome}</span>
                                                                        </Tooltip>
                                                                    </td>
                                                                }
                                                                <td width={10} align='center'>
                                                                    {item.criticality === "Baixa" && <span className="badge text-bg-success w-100">{item.criticality}</span>}
                                                                    {item.criticality === "Média" && <span className="badge text-bg-warning text-white w-100">{item.criticality}</span>}
                                                                    {item.criticality === "Alta" && <span className="badge text-bg-danger w-100">{item.criticality}</span>}
                                                                </td>
                                                                <td>{item.type ?? "Outros"}</td>
                                                                <td>{item.user.nome}</td>
                                                                <td>{item.body}</td>
                                                                <td align='center'>{Moment(item.createdAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='center'>
                                                                    {
                                                                        item.closedAt ? <span>{Moment(item.closedAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</span> : <span>-</span>
                                                                    }
                                                                </td>
                                                                <td width={10} align='right'>
                                                                    <span className="badge text-bg-success w-100">Resolvido</span>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    :
                                    <Loading />
                        }
                    </div >
                }
            </>
        )
    }
}
export { CadastrarChamado, ListarChamados, ListarChamadosBody }