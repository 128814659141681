import { Collection } from '../../shared/constants';
import { docsToItems } from '../transform.docs';
import { addDoc, deleteDoc, getCollectionBy } from './collectionBaseWorker';

export const addQueue = async ({ data }) => {
    data.status = "in-queue"
    data.createAt = new Date()
    data.times = 0
    return await addDoc({ collection: Collection.CALLBACK_QUEUE, data: data })
}

export const getAllQueuesByDate = async ({ days }) => {
    const date = new Date()
    date.setDate(date.getDate() - days)
    const docs = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("createdAt", ">=", date).orderBy("createdAt", "desc").get()
    return docsToItems(docs)
}

export const getAllQueuesByParkId = async ({ estacionamentoId, limit }) => {
    const docs = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("estacionamentoId", "==", estacionamentoId).limit(limit ?? 500).orderBy("createdAt", "desc").get()
    return docsToItems(docs)
}

export const getAllQueuesByTicketId = async ({ ticketId }) => {
    const docs = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("body.ticketId", "==", ticketId).limit(500).get()
    const items1 = docsToItems(docs)

    const docs2 = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("body.ids", "array-contains", ticketId).limit(500).get()
    const items2 = docsToItems(docs2)
    items1.push(...items2)
    return items1.sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate())
}

export const getAllQueuesByMonthlyId = async ({ monthlyId }) => {
    const docs = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("body.monthlyId", "==", monthlyId).limit(500).get()
    const items1 = docsToItems(docs)

    const docs2 = await getCollectionBy({ collection: Collection.CALLBACK_QUEUE }).where("body.ids", "array-contains", monthlyId).limit(500).get()
    const items2 = docsToItems(docs2)
    items1.push(...items2)
    return items1.sort((a, b) => b.createdAt?.toDate() - a.createdAt?.toDate())
}

export const deleteQueuesById = async ({ id }) => {
    await deleteDoc({ collection: Collection.CALLBACK_QUEUE, id: id })
}