import React from 'react'
import { NavBar } from '../../components/navbar'
import { EmptyContent } from '../../components/common/commons'
import { Button } from 'react-bootstrap';
import { ListarEstacionamentos, VisualizarEstacionamento, EditarEstacionamentoBody } from '../estacionamento/index'
import { SupportCenterRevendaModal } from '../revenda/support-center'
import { alterarEstacionamento, getRevenda, getRevendasAssociadas, getUsuario, getUsuarioCompact, isPlatformOwner } from '../../config/auth'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import firebase from '../../config/firebase';
import NumberFormat from 'react-number-format';
import 'firebase/auth';
import 'firebase/storage';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Loading } from '../../components/common/commons';
import Moment from 'moment';
import { saveLastUpdateByPark } from '../../store/collections/lastUpdateWorker';
import { CriarNovaMatriz, CriarNovoEstacionamento } from './view/client-view';
import '../conta/login.css'
import { importClientByExcell } from './viewmodel/import-client-view-model';
import * as XLSX from 'xlsx';
import { Tabs, Tab } from 'react-bootstrap';
import { getAllClientsByLimit, getAllClientsByRevendaId, getAllClientsByRevendaIdAndStatus, getAllClientsByRevendedorId, getClientById, setClientUserToReseller, updateClientById, updateClientRevendaId, updateClientStatus } from '../../store/collections/clienteWorker';
import { dangerConfirm, documentMask, exportAndDownloadXLS, generateUUIDV7, goToNewWindow, isNullOrEmpty, normalizedField, queryString, reloadWindow, reloadWithAlert, reloadWithAlertError, successConfirm, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning, valueByMaximunChars } from '../../shared/utils';
import { getAllRessellers, getAllUsersByClienteId, updateUserRevendaId } from '../../store/collections/userWorker';
import { getAllRevendas, getRevendaById } from '../../store/collections/revendaWorker';
import { Tooltip } from '@mui/material';
import { ListarAnalyticsBody } from '../analytics/logs';
import { getAllLicencasByClienteId, updateLicenceRevendaId } from '../../store/collections/licencaWorker';
import { getAllParksByClientId, getAllParksByRevendaId, updateParkRevendaId } from '../../store/collections/parkWorker';
import { ListarUsuarios } from '../usuario'
import { fetchFunction } from '../../shared/function-request';
import { CadastrarChamado } from '../helpdesk';
import { WhatsAppMessageListBody } from '../developer/messages'
import { getURLFromURI, uploadClientLogo } from '../../store/filesWorker'
import { AlertDanger, AlertSuccess } from '../../shared/alert-manager'
import { getPrivateParametersRevendaById } from '../../store/collections/privateParametersRevenda';
import { getAllSubscriptionByRevendaId } from '../../store/collections/newSubscriptionWorker';
import { getAllParametersByRevendaId } from '../../store/collections/parameterWorker';

const initialState = {
    isLoading: true,
    id: null,
    revendaId: getRevenda()?.id,
    revenda: getRevenda(),
    mostrarNomeEstacionamento: true,
    key: queryString("key"),
    clienteId: null,
    isEdit: false,
    addNewClient: false,
    exportClientModel: false,
    nome: '',
    documento: '',
    tipoDocumento: 'CNPJ',
    representante: '',
    celular: '',
    cep: '',
    logradouro: '',
    numero: '',
    bairro: '',
    cidade: '',
    estado: '',
    complemento: '',
    revenda: null,
    clientes: [],
    auxClientes: [],
    resultUserLocal: {},
    errors: [],
    tabs: [],
    revendas: [],
    status: "Ativo",
    progress: 0.0,
    stateImport: 'fill',
    idNewClient: "",
    warnings: [],
    exportActivatedClients: true,
    exportInactivatedClients: true,
    exportadeDate: new Moment(new Date()),
    limit: 10,
    revendasAssociadas: [],
};

let listClientes = [];

class CadastrarCliente extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
    }

    componentWillMount = async () => {
        const user = getUsuario()
        const revendasAssociadas = user?.revendasAssociadas ?? []
        if (revendasAssociadas.length > 0) {
            const revendaId = revendasAssociadas.find(e => e.value === this.state.revendaId)?.value
            if (revendaId) {
                this.loadRevendas(revendaId)
            }
        } else {
            this.loadRevendas(user.revendaId)
        }
    }

    componentWillUnmount() {
        listClientes = []
    }

    reset = () => {
        this.setState({ ...initialState })
        this.setState({ clientes: listClientes });
    }

    loadRevendas = async (revendaId) => {
        const user = getUsuario()
        const revendasAssociadas = user?.revendasAssociadas ?? []
        if (revendasAssociadas.length > 0) {
            this.setState({ revendasAssociadas: revendasAssociadas })
        } else {
            const revenda = await getRevendaById({ id: user.revendaId })
            this.setState({
                revendasAssociadas: [{
                    label: revenda.nome,
                    value: revenda.id
                }]
            })
        }
        this.setState({ revendaId: revendaId }, () => {
            this.carregarClientes()
        })
    }

    changeRevendaId = (e) => {
        this.setState({ revendaId: e.target.value }, () => {
            this.carregarClientes()
        })
    }

    changeStatus = (e) => {
        this.setState({ status: e.target.value }, () => {
            this.carregarClientes()
        })
    }

    carregarClientes = async () => {
        this.setState({ isLoading: true })
        var clientes = []
        if (isPlatformOwner()) {
            clientes = await getAllClientsByRevendaIdAndStatus({ status: this.state.status, revendaId: this.state.revendaId })
        } else {
            const user = getUsuario()
            if (user.acessoRevendedorPermissao === "ASSOCIADOS") {
                clientes = await getAllClientsByRevendedorId({ revendedorId: user.id })
            } else {
                clientes = await getAllClientsByRevendaIdAndStatus({ status: this.state.status, revendaId: this.state.revendaId })
            }
        }
        clientes = clientes.sort((a, b) => a.status?.localeCompare(b.status) || a.nome.localeCompare(b.nome))
        this.setState({ clientes: clientes });
        this.setState({ auxClientes: clientes })
        this.setState({ isLoading: false })
    }

    search = (e) => {
        const value = e.target.value.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").replaceAll("(", "").replaceAll(")", "").replaceAll("-", "")
        if (value === "") {
            this.setState({ clientes: this.state.auxClientes })
        } else {
            const result = this.state.auxClientes.filter(e => e.nome.toUpperCase().includes(value) ||
                e.documento?.toUpperCase().replaceAll(".", "").replaceAll("-", "").replaceAll("/", "").includes(value) ||
                e.celular?.toUpperCase().replaceAll("(", "").replaceAll(")", "").replaceAll("-", "").includes(value) ||
                e.email?.toUpperCase().includes(value) ||
                e.representante?.toUpperCase().includes(value) ||
                e.revendedor?.nome?.toUpperCase().includes(value) ||
                e.endereco?.logradouro?.toUpperCase().includes(value) ||
                e.endereco?.numero?.toUpperCase().includes(value))
            this.setState({ clientes: result })
        }
    }

    onShowImportModal = () => {
        this.setState({ addNewClient: true })
    }

    onShowSyncModal = () => {
        this.setState({ showSyncModal: true })
    }

    onShowNewCompany = () => {
        this.setState({ showNewCompanyModal: true })
    }

    mapCliente = (e) => {
        const names = (e.representante ?? "").split(" ")
        const firstName = names[0]
        var zipCode = e.endereco?.cep ?? ""
        var lastName = ""
        if (names.length > 1) {
            lastName = names[1]
        }
        return {
            "First Name": firstName,
            "Last Name": lastName,
            "Email": e.email,
            "Zip": zipCode,
            "Phone": e.celular?.replaceAll("(", "").replaceAll(")", "").replaceAll("-", "") ?? "",
            "Country": "br",
            "Desde": e.createdAt?.toDate() ?? ""
        }
    }

    exportClients = async () => {
        const confirm = await successConfirm({ message: "Deseja mesmo exportar os dados dos clientes?" })
        if (!confirm) return
        const user = getUsuario()
        const revendasAssociadas = user?.revendasAssociadas ?? []
        const revenda = revendasAssociadas.find(e => e.value === this.state.revendaId)
        toastLoading("Aguarde...")
        var clients = await getAllClientsByRevendaId({ revendaId: this.state.revendaId })
        toastLoading("Buscando estabelecimentos...")
        const parks = await getAllParksByRevendaId({ revendaId: this.state.revendaId })
        toastLoading("Buscando parametros...")
        const parameters = await getAllParametersByRevendaId({ revendaId: this.state.revendaId })
        console.log(parameters.map(e => e.habilitarSemParar === true));

        if (!isNullOrEmpty(clients)) {
            toastLoading("Buscando licenças...")
            const licences = await getAllSubscriptionByRevendaId({ revendaId: this.state.revendaId })
            toastLoading("Exportando...")
            clients = clients.map((e) => {
                const licenca = licences.filter(l => l.clienteId === e.id).sort((a, b) => b.changeDate.seconds - a.changeDate.seconds)[0]
                const newParks = parks?.filter(p => p.clienteId === e.id) ?? []
                const parksIds = newParks.map(p => p.id)
                const parametersByPark = parameters.filter(e => parksIds.includes(e.estacionamentoId))

                const habilitarSemParar = parametersByPark.map(p => p.habilitarSemParar === true && !isNullOrEmpty(p.codigoEstabelecimentoSemParar)).length
                var status = ""
                if (!licenca) {
                    status = "Gratis"
                } else if (licenca.status === "GENERATED" || licenca.status === "PAYED") {
                    status = "Pagante"
                } else if (licenca.status === "CANCELED") {
                    status = "Cancelado"
                }
                const unidades = newParks.length ?? 1
                var item = {
                    "Revenda": revenda.label,
                    "Estabelecimento": e.nome,
                    "Representante": e.representante,
                    "Celular": e.celular,
                    "Email": e.email,
                    "Documento": e.documento,
                    "Desde": Moment(e.createdAt.seconds * 1000).format("DD/MM/YYYY"),
                    "Unidades": unidades === 0 ? 1 : unidades,
                    "Status": status,
                    "Frequencia": licenca?.frequence?.toUpperCase() ?? "",
                    "Valor": licenca?.netValue ?? 0,
                    // "Sem Parar": habilitarSemParar
                }
                return item
            }).sort((a, b) => a.Status.localeCompare(b.Status))
            exportAndDownloadXLS(clients, `CLIENTES_EXPORTADOS_${new Moment(new Date()).format("DD_MM_YYYY_HH_mm")}`)
        } else {
            toastWarning("Nenhum cliente encontrado com este filtro, tente alterar a data.")
        }
        toastDismissLoading()
    }

    exportadeDateChange = (newValue) => {
        this.setState({ exportadeDate: newValue })
    }

    goToNewClient = () => {
        window.location.href = `/revenda/cliente/${this.state.idNewClient}`
    }

    handleImportFileChange = (e) => {
        this.setState({ stateImport: 'fill' })
        e.preventDefault();
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = async (e) => {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
            await importClientByExcell({
                data: readedData
            }, status => {
                this.setState({ stateImport: 'importing' })
                this.setState({ status: status.text })
                this.setState({ progress: status.progress })
            }, errors => {
                this.setState({ key: errors[0].type })
                this.setState({ tabs: Array.from(new Set(errors.map(e => e.type))) })
                this.setState({ errors: errors })
                this.setState({ stateImport: 'error' })
            }, (id, warnings) => {
                this.setState({ stateImport: 'success' })
                this.setState({ warnings: warnings })
                this.setState({ idNewClient: id })
            })
        };
        reader.readAsBinaryString(f)
    }

    confirmSyncClients = async () => {
        const option = {
            method: "POST"
        };
        toastSuccess("Processo de sincronização iniciado com sucesso!")
        await fetchFunction('/sendEmail/syncAll', option)
    }

    render() {
        return (
            <NavBar>
                <div className="row">
                    <div className='col-lg-12'>
                        <div className="card mb-4">
                            <div className="card-header d-flex justify-content-between align-items-center">
                                Todos os clientes
                                <div className='btn-group'>
                                    <button type="button" onClick={() => { this.onShowNewCompany() }} className="btn btn-sm btn-success">
                                        <i className="fas fa-plus mx-2" />
                                        Cadastrar nova matriz
                                    </button>
                                    {
                                        isPlatformOwner() &&
                                        <>
                                            <button type="button" onClick={() => { this.exportClients() }} className="btn btn-sm btn-warning text-white">
                                                <i className="fas fa-file-export mx-2"></i>
                                                Exportar clientes
                                            </button>
                                            <button type="button" onClick={() => { this.onShowImportModal() }} className="btn btn-sm btn-primary">
                                                <i className="fas fa-file-import mx-2"></i>
                                                Importar novo cliente
                                            </button>
                                            <button type="button" onClick={() => { this.onShowSyncModal() }} className="btn btn-sm btn-success">
                                                <i className="fas fa-sync mx-2"></i>
                                                Sincronizar e-mails
                                            </button>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className='card-body'>
                                <div className='row'>
                                    {
                                        this.state.revendasAssociadas.length > 0 &&
                                        <div className='col-2'>
                                            <select className='form-select form-selec' value={this.state.revendaId} onChange={this.changeRevendaId}>
                                                {
                                                    this.state.revendasAssociadas.map(item => { return <option key={item.value} value={item.value}>{item.label}</option> })
                                                }
                                            </select>
                                        </div>
                                    }
                                    <div className='col-9'>
                                        <input type="text" onChange={this.search} placeholder="Faça uma busca por nome, endereço, revendedor ou documento..." className="form-control" />
                                    </div>
                                    <div className='col-1'>
                                        <select className='form-select' value={this.state.status} onChange={this.changeStatus}>
                                            <option value="Ativo">Ativos</option>
                                            <option value="Inativo">Inativos</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                this.state.isLoading === false &&
                                <>

                                    {
                                        this.state.clientes.length === 0 ?
                                            <div className="col-lg-12">
                                                <EmptyContent text="Nenhum cliente encontrado com esta busca." />
                                            </div> :
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col" className="d-none d-sm-table-cell"></th>
                                                            <th scope="col">Nome</th>
                                                            <th scope="col" className="d-none d-sm-table-cell">Representante</th>
                                                            <th scope="col" className="d-none d-sm-table-cell">Celular</th>
                                                            <th scope="col" className="d-none d-sm-table-cell">E-mail</th>
                                                            <th scope="col" className="d-none d-sm-table-cell">Documento</th>
                                                            <th scope="col">Revendedor</th>
                                                            <th scope="col">Desde</th>
                                                            <th scope="col"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.clientes.map(cliente =>
                                                                <tr>
                                                                    <td className="d-none d-sm-table-cell" width={10}>
                                                                        <h6>
                                                                            {
                                                                                cliente.status === 'Ativo' && <span className="badge text-bg-success btn-block">Ativo</span>
                                                                            }
                                                                            {
                                                                                cliente.status !== 'Ativo' && <span className="badge text-bg-danger btn-block">Inativo</span>
                                                                            }
                                                                        </h6>
                                                                    </td>
                                                                    <td>{cliente.nome}</td>
                                                                    <td className="d-none d-sm-table-cell">{cliente.representante}</td>
                                                                    <td className="d-none d-sm-table-cell">{cliente.celular ?? "Não informado"}</td>
                                                                    <td className="d-none d-sm-table-cell">{valueByMaximunChars(cliente.email, 30) ?? "Não informado"}</td>
                                                                    <td className="d-none d-sm-table-cell">{cliente.documento ? documentMask(cliente.documento) : "Não informado"}</td>
                                                                    <td className="d-none d-sm-table-cell">{cliente.revendedor?.nome ?? "Não informado"}</td>
                                                                    <td className="d-none d-sm-table-cell">{cliente.createdAt ? Moment(cliente.createdAt.seconds * 1000).format("DD/MM/YYYY") : "Não informado"}</td>
                                                                    <td width='10'>
                                                                        <a href={`/revenda/cliente/${cliente.id}`} rel="noopener noreferrer" className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </>
                            }
                            {
                                this.state.isLoading === true &&
                                <Loading />
                            }
                        </div>
                    </div>
                </div>
                {
                    this.state.showNewCompanyModal && <CriarNovaMatriz revendaId={this.state.revendaId} onClose={(e) => this.setState({ showNewCompanyModal: false })} />
                }
                <Dialog maxWidth={"lg"} open={this.state.addNewClient}>
                    <MuiDialogTitle className='text-center'>
                        Importar novo cliente
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        {
                            this.state.stateImport === "fill" &&
                            <>
                                <div className="alert alert-secondary" role="alert">
                                    Caso não tenha a planilha, faça download, preencha ou envie ao novo cliente <p />
                                    <strong><a href="https://firebasestorage.googleapis.com/v0/b/selfparking-prod-26925.appspot.com/o/files%2Fothers%2Fimportar_novo_cliente.xlsx?alt=media&token=2aaec5dc-65a4-4e6e-856d-6558fc6ef8c6" target="_blank" download>Baixar planilha de exemplo</a></strong>
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="formFile" className="form-label">Selecionar arquivo</label>
                                    <div>
                                        <input type="file" accept=".xlsx" onChange={this.handleImportFileChange} id="formFile" />
                                    </div>
                                </div>
                            </>
                        }
                        {
                            this.state.stateImport === "error" &&
                            <>
                                <div className="col-lg-12">
                                    <div className="alert alert-danger" role="alert">
                                        <strong>Atenção </strong>Foram identificados alguns erros na planilha importada
                                    </div>
                                </div>
                                <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                                    {
                                        this.state.tabs.map((tab) =>
                                            <Tab eventKey={tab} title={tab.replaceAll("_", " ")}>
                                                <div className='table-responsive my-2'>
                                                    <table className="table table-striped">
                                                        <tbody>
                                                            <tr>
                                                                <td align='center'><strong>Linha</strong></td>
                                                                <td><strong>Mensagem</strong></td>
                                                            </tr>
                                                            {
                                                                this.state.errors.filter(e => e.type === tab).map((row) =>
                                                                    <tr>
                                                                        <td align='center'>{row.row}</td>
                                                                        <td>{row.message}</td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Tab>
                                        )
                                    }
                                </Tabs>
                            </>
                        }
                        {
                            this.state.stateImport === 'importing' &&
                            <div className='text-center'>
                                <Loading />
                                <span>{this.state.status}</span>
                                <div className={"divider-" + this.state.progress + " my-3"} />
                            </div>
                        }
                        {
                            this.state.stateImport === 'success' &&
                            <div>
                                {this.state.warnings.length === 0 && <>
                                    <h5 className='text-center'>Novo cliente criado com sucesso!</h5><p />
                                    <strong>E-mail: Preenchido na planilha<p />Senha: 111111</strong>
                                </>}
                                {this.state.warnings.length > 0 && <>
                                    <h5 className='text-center'>Novo cliente criado com sucesso com alguns alertas</h5>
                                    <strong>E-mail: Preenchido na planilha<p />Senha: 111111</strong>
                                    <table className="table table-striped my-3">
                                        <tbody>
                                            <tr>
                                                <td><strong>Alertas</strong></td>
                                            </tr>
                                            {
                                                this.state.warnings.map((row) =>
                                                    <tr>
                                                        <td>{row.message}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </>}
                            </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        {
                            this.state.stateImport === "fill" &&
                            <button onClick={() => this.setState({ addNewClient: false })} className='btn btn-secondary m-3'>Fechar</button>
                        }
                        {
                            this.state.stateImport === "error" &&
                            <div className='btn-group m-3'>
                                <button onClick={() => this.setState({ stateImport: "fill" })} className='btn btn-warning text-white'>Voltar</button>
                                <button onClick={() => this.setState({ addNewClient: false })} className='btn btn-secondary'>Fechar</button>
                            </div>
                        }
                        {
                            this.state.stateImport === "success" &&
                            <button onClick={this.goToNewClient} className='btn btn-success m-3'>Ver novo cliente</button>
                        }
                    </MuiDialogActions>
                </Dialog>
                {/* <Dialog maxWidth="sm" fullWidth open={this.state.exportClientModel} onClose={(e) => this.setState({ exportClientModel: false })}>
                    <MuiDialogTitle className='text-center'>
                        Exportar clientes
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className='col-lg-12'>
                            <div className='col-lg-12'>
                                <label>A partir de</label>
                                <div className='col-lg-12'>
                                    <MobileDatePicker
                                        label="-"
                                        inputFormat="DD/MM/yyyy"
                                        value={this.state.exportadeDate}
                                        onChange={this.exportadeDateChange}
                                        renderInput={(params) => <TextField {...params} size="small" className='btn-block w-100' />}
                                    />
                                </div>
                            </div>
                            <div className="form-check my-2">
                                <input className="form-check-input" id="exportActivatedClients" checked={this.state.exportActivatedClients} onChange={(e) => this.setState({ exportActivatedClients: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="exportActivatedClients">
                                    Clientes ativos
                                </label>
                            </div>
                            <div className="form-check my-2">
                                <input className="form-check-input" id="exportInactivatedClients" checked={this.state.exportInactivatedClients} onChange={(e) => this.setState({ exportInactivatedClients: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="exportInactivatedClients">
                                    Clientes inativos
                                </label>
                            </div>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <button onClick={this.exportClients} className='btn btn-success m-3'>Exportar</button>
                    </MuiDialogActions>
                </Dialog> */}
                <Dialog maxWidth="sm" fullWidth open={this.state.showSyncModal} onClose={(e) => this.setState({ showSyncModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Syncronizar e-mails
                    </MuiDialogTitle>
                    <MuiDialogContent className='text-center'>
                        <h5>Sincronize os dados de contato do cliente <br /> para automatizar os envios com o SendGrid.</h5>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ showSyncModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={this.confirmSyncClients} className='btn btn-success'>Confirmar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </NavBar >
        )
    }
}

class ListClientsSimple extends React.Component {

    constructor(props) {
        super(props)
        this.state = initialState
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var items = await getAllClientsByLimit({ limit: this.state.limit, revendaId: this.state.revendaId, leadStatus: this.state.leadStatus })
        this.setState({ items: items.filter(e => e.leadStatus !== "fechado" && e.leadStatus !== "seminteresse") })
        this.setState({ revendasAssociadas: getRevendasAssociadas() })
        this.setState({ isLoading: false })
    }

    changeRevendaId = (e) => {
        this.setState({ revendaId: e.target.value }, () => {
            this.load()
        })
    }

    changeLeadStatus = (e) => {
        this.setState({ leadStatus: e.target.value }, () => {
            this.load()
        })
    }

    changeLimit = (e) => {
        this.setState({ limit: parseInt(e.target.value) }, () => {
            this.load()
        })
    }

    showLeadModal = (clienteId) => {
        this.setState({ clienteId: clienteId })
        this.setState({ isShowingLeadModal: true })
    }

    render() {
        return (
            <div className="row">
                <div className='col-lg-12'>
                    <div className="card mb-4">
                        <div className="card-header d-flex justify-content-between align-items-center">
                            Últimas contas criadas
                            <div className='col-lg-7'>
                                <div className='row justify-content-end'>
                                    {
                                        this.state.revendasAssociadas.length > 0 &&
                                        <div className='col-lg-4'>
                                            <select className='form-select form-select-sm' value={this.state.revendaId} onChange={this.changeRevendaId}>
                                                {
                                                    this.state.revendasAssociadas.map(item => { return <option key={item.value} value={item.value}>{item.label}</option> })
                                                }
                                            </select>
                                        </div>
                                    }
                                    <div className='col-lg-4'>
                                        <select className='form-select form-select-sm' value={this.state.leadStatus} onChange={this.changeLeadStatus}>
                                            <option value="">Todos</option>
                                            <option value="aguardando">Aguardando</option>
                                            <option value="testando">Testando</option>
                                            <option value="fechado">Fechado</option>
                                            <option value="whatsapp">WhatsApp</option>
                                            <option value="ligacao">Ligação</option>
                                            <option value="seminteresse">Sem interesse</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-4'>
                                        <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                            <option value={10}>10 registros</option>
                                            <option value={30}>30 registros</option>
                                            <option value={50}>50 registros</option>
                                            <option value={100}>100 registros</option>
                                            <option value={200}>200 registros</option>
                                            <option value={300}>300 registros</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            this.state.isLoading === true &&
                            <Loading />
                        }
                        {
                            this.state.isLoading === false &&
                            <>

                                {
                                    this.state.items.length === 0 ?
                                        <div className="col-lg-12">
                                            <EmptyContent text="Nenhum cliente encontrado com esta busca." />
                                        </div> :
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        {/* <th scope="col" width={10}>Revenda</th> */}
                                                        <th scope="col">Nome</th>
                                                        <th scope="col">Celular</th>
                                                        <th scope="col" className="d-none d-sm-table-cell">Desde</th>
                                                        <th scope="col">Status</th>
                                                        <th scope="col"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map(cliente =>
                                                            <tr key={cliente.id}>
                                                                {/* <td width={10}>
                                                                    {cliente.revendaId === "UhkTEfwE0GH4wpLpfZZm" && <span className="badge text-bg-success w-100">Dimep</span>}
                                                                    {cliente.revendaId === "feHh4buNASnnh6ahmYJ3" && <span className="badge text-bg-primary w-100">Madis</span>}
                                                                    {cliente.revendaId === "yDDlIMYGASii6Scv1Un8" && <span className="badge w-100" style={{ backgroundColor: "#44377b" }}>SelfParking</span>}
                                                                    {cliente.revendaId === "bjhYBaWdULPY9XO7K0VH" && <span className="badge w-100" style={{ backgroundColor: "#1f2021" }}>NeoTagus</span>}
                                                                </td> */}
                                                                <td>{cliente.nome}</td>
                                                                <td>{cliente.celular}</td>
                                                                <td className="d-none d-sm-table-cell">{cliente.createdAt ? Moment(cliente.createdAt.seconds * 1000).format("DD/MM [-] HH:mm") : "Não informado"}</td>
                                                                <td width={10}>
                                                                    {!cliente.leadStatus && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-danger text-white w-100"><i className="fas fa-edit m-2" /> Aguardando</span>}
                                                                    {cliente.leadStatus === "testando" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-warning text-white w-100"><i className="fas fa-edit m-2" /> Testando</span>}
                                                                    {cliente.leadStatus === "fechado" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-success text-white w-100"><i className="fas fa-edit m-2" /> Fechado</span>}
                                                                    {cliente.leadStatus === "whatsapp" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-info text-white w-100"><i className="fas fa-edit m-2" /> WhatsApp</span>}
                                                                    {cliente.leadStatus === "ligacao" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-info text-white w-100"><i className="fas fa-edit m-2" /> Ligação</span>}
                                                                    {cliente.leadStatus === "aguardando" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-danger text-white w-100"><i className="fas fa-edit m-2" /> Aguardando</span>}
                                                                    {cliente.leadStatus === "seminteresse" && <span role='button' onClick={() => this.showLeadModal(cliente.id)} className="badge text-bg-secondary text-white w-100"><i className="fas fa-edit m-2" /> Sem interesse</span>}
                                                                </td>
                                                                <td width='10'>
                                                                    <Tooltip title="Visualizar matriz" placement="top">
                                                                        <a href={`/revenda/cliente/${cliente.id}?tab=anotacoes`} target='_blank' rel="noopener noreferrer" className="btn btn-primary">
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </Tooltip>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                }
                            </>
                        }
                        {
                            this.state.isShowingLeadModal && <LeadClientModal clienteId={this.state.clienteId} onClose={(e) => this.setState({ isShowingLeadModal: false })} />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

class LeadClientModal extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            clienteId: props.clienteId,
            isLoading: true,
            isOpenLeadModal: true,
            currentStatus: "",
            message: "",
            client: null,
            currentLeadStatus: "",
            items: [],
        }
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        const client = await getClientById({ id: this.state.clienteId })
        this.setState({ client: client })
        this.setState({ currentLeadStatus: client.currentLeadStatus })
        this.setState({ isLoading: false })
    }

    hideLeadModal = () => {
        this.setState({ isOpenLeadModal: false })
        if (this.props.onClose) {
            this.props.onClose()
        }
    }

    showLeadModal = () => {
        this.setState({ isOpenLeadModal: true })
    }

    confirmLead = async () => {
        if (isNullOrEmpty(this.state.currentLeadStatus)) {
            toastWarning("Por favor, selecione o tipo")
            return
        }
        if (isNullOrEmpty(this.state.message)) {
            toastWarning("Por favor, preencha o campo de mensagem")
            return
        }
        toastLoading()
        const client = this.state.client
        if (!client.leadNotes) {
            client.leadNotes = []
        }
        client.leadNotes.push({
            id: generateUUIDV7(),
            currentLeadStatus: this.state.currentLeadStatus,
            message: this.state.message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        if (this.state.currentLeadStatus !== "anotacao") {
            client.leadStatus = this.state.currentLeadStatus
        }
        await updateClientById({ id: this.state.clienteId, data: client })
        this.setState({ isOpenLeadModal: false })
        toastDismissLoading()
        reloadWithAlert("Anotação adicionada com sucesso!")
    }

    render() {
        return (
            <Dialog fullWidth maxWidth="xs" onClose={this.hideLeadModal} open={this.state.isOpenLeadModal}>
                {
                    this.state.isLoading === true ? <Loading /> :
                        <>
                            <MuiDialogTitle className='text-center'>
                                Nova anotação
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className='form-group'>
                                        <label>Mensagem</label>
                                        <textarea className="form-control" rows={5} value={this.state.message} onChange={(e) => this.setState({ message: e.target.value })} />
                                        <label>Tipo</label>
                                        <div className="input-group mb-3">
                                            <select className="form-select" value={this.state.currentLeadStatus} onChange={(e) => this.setState({ currentLeadStatus: e.target.value })}>
                                                <option value=''>Selecione</option>
                                                <option value="anotacao">Anotação</option>
                                                <option value="" disabled>Status do lead</option>
                                                <option value="aguardando">Aguardando</option>
                                                <option value="testando">Testando</option>
                                                <option value="fechado">Fechado</option>
                                                <option value="whatsapp">WhatsApp</option>
                                                <option value="ligacao">Ligação</option>
                                                <option value="seminteresse">Sem interesse</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </MuiDialogContent >
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={this.hideLeadModal} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={this.confirmLead} className='btn btn-success'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </>
                }
            </Dialog >
        )
    }
}

class ComboListarClientes extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            clienteId: '',
            clientes: []
        }
        this.carregarClientes()
    }

    onClientSelected = (e) => {
        this.setState({ clienteId: e.target.value })
        let client = listClientes.filter((item) => { return item.id === e.target.value })[0]
        this.props.onClientSelected(client)
    }

    carregarClientes = () => {
        firebase.firestore().collection('clientes').get().then(async (results) => {
            listClientes = []
            results.docs.forEach(doc => {
                listClientes.push({
                    ...doc.data(),
                    id: doc.id
                })
            })
            this.setState({ clientes: listClientes });
            this.setState({ clienteId: (this.props.clienteId !== null ? this.props.clienteId : '') })
        }).catch(error => {
            reloadWithAlertError(error.message)
        })
    }

    render() {
        return (
            <>
                <div className="col-lg-12">
                    <label>Selecionar cliente</label>
                    <div className="input-group mb-3">
                        <select className="form-select" value={this.state.clienteId} onChange={this.onClientSelected} required>
                            <option value=''>Selecione</option>
                            {
                                this.state.clientes.map(item => <option key={item.id} value={item.id}>{item.nome}</option>)
                            }
                        </select>
                    </div>
                </div>
            </>
        )
    }
}

class VisualizarCliente extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            key: "revenda",
            revendaId: getUsuario()?.revendaId,
            formaCobranca: getRevenda()?.formaCobranca,
            revendasAssociadas: [],
            random: Math.random(),
            key: queryString("tab") ?? "revenda",
            isLoading: true,
            isEditing: false,
            isOpenInativeClient: false,
            isOpenModalUsersLogs: false,
            isOpenRevendedorModal: false,
            isShowUserModal: false,
            openModalLicences: false,
            isOpenInativeClient: false,
            clienteId: "",
            estacionamentoId: null,
            showEditPark: false,
            isOpenChangeRevendaModal: false,
            revenda: null,
            showNewParkModal: false,
            showHelpDeskModal: false,
            isChangeLoading: true,
            allResellers: [],
            revendas: [],
            nome: '',
            email: '',
            documento: '',
            tipoDocumento: 'CNPJ',
            representante: '',
            celular: '',
            cep: '',
            logradouro: '',
            numero: '',
            bairro: '',
            cidade: '',
            estado: '',
            complemento: '',
            isPayed: false,
            acessoRevendedorClienteAssociarSuporte: false,
            acessoRevendedorClienteAssociarRevendedor: false,
            acessoRevendedorRevendedores: false,
            personalizarApp: true,
            personalizarCobrancas: true,
            personalizarAdministrativo: false,
            cliente: {
                nome: "",
                documento: "",
                tipoDocumento: "",
                representante: "",
                celular: "",
                endereco: {
                    cep: "",
                    logradouro: "",
                    numero: "",
                    bairro: "",
                    cidade: "",
                    estado: "",
                    complemento: ""
                }
            }
        }
    }

    componentDidMount = () => {
        const user = getUsuario()
        this.setState({ acessoRevendedorRevendedores: user.acessoRevendedorRevendedores ?? false })
        this.setState({ acessoRevendedorClienteAssociarSuporte: user.acessoRevendedorClienteAssociarSuporte ?? false })
        this.setState({ acessoRevendedorClienteAssociarRevendedor: user.acessoRevendedorClienteAssociarRevendedor ?? false })
        this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        this.setState({ clienteId: this.props.match.params.id })
        const client = await getClientById({ id: this.props.match.params.id })
        const revendasAssociadas = getUsuario()?.revendasAssociadas ?? []
        if (client && revendasAssociadas.map(e => e.value).includes(client.revendaId)) {
            this.setState({ revendasAssociadas: revendasAssociadas })
            const revenda = await getRevendaById({ id: client.revendaId })
            const privado = await getPrivateParametersRevendaById({ id: revenda.id })
            this.setState({ privado: privado })
            this.setState({ revenda: revenda })
            this.setState({ cliente: client })
            const parks = await getAllParksByClientId({ clientId: client.id })
            this.setState({ parks: parks })
            this.setState({ habilitarEnviarCobrancaPorWhatsApp: this.state.cliente.habilitarEnviarCobrancaPorWhatsApp ?? true })
            this.setState({ habilitarEnviarCobrancaPorEmail: this.state.cliente.habilitarEnviarCobrancaPorEmail ?? true })
            this.setState({ habilitarEmissaoDeNotaFiscal: this.state.cliente.habilitarEmissaoDeNotaFiscal ?? true })
            this.setState({ habilitarAsinanteTagSemParar: this.state.cliente.habilitarAsinanteTagSemParar ?? false })
            this.setState({ habilitarAsinanteTagTaggy: this.state.cliente.habilitarAsinanteTagTaggy ?? false })
            this.setState({ habilitarAsinanteTagConectCar: this.state.cliente.habilitarAsinanteTagConectCar ?? false })
            this.setState({ habilitarAsinanteTagVeloe: this.state.cliente.habilitarAsinanteTagVeloe ?? false })
            this.setState({ isPayed: this.state.cliente.isPayed ?? false })
            this.setState({ personalizarApp: this.state.cliente.tema?.personalizarApp ?? false })
            this.setState({ personalizarCobrancas: this.state.cliente.tema?.personalizarCobrancas ?? false })
            this.setState({ personalizarAdministrativo: this.state.cliente.tema?.personalizarAdministrativo ?? false })
            this.setState({ habilitarCobrancaViaBannerNoApp: this.state.cliente.flags?.habilitarCobrancaViaBannerNoApp ?? true })
            this.setState({ habilitarPagSeguro: this.state.cliente.habilitarPagSeguro ?? false })
            this.setState({ habilitarStone: this.state.cliente.habilitarStone ?? false })
            this.setState({ habilitarCielo: this.state.cliente.habilitarCielo ?? false })
            this.setState({ habilitarRede: this.state.cliente.habilitarRede ?? false })
            this.setState({ habilitarGetnet: this.state.cliente.habilitarGetnet ?? false })
            this.setState({ habilitarSafra: this.state.cliente.habilitarSafra ?? false })
            this.setState({ financeiroIm: this.state.cliente.financeiroIm })
            this.setState({ customerId: this.state.cliente.vindi?.customerId })

            this.setState({ isEditing: false })
            this.setState({ isLoading: false })

            if (client.tema?.logo) {
                const url = await getURLFromURI(client.tema?.logo)
                this.setState({ urlLogo: url })
                this.setState({ logo: client.tema?.logo })
            } else {
                this.setState({ urlLogo: "https://placehold.co/150x150" })
            }
        } else {
            toastWarning('Matriz não encontrada')
            window.location.href = '/revenda/cliente'
        }
    }

    onDetailButton = (park) => {
        this.setState({ estacionamentoId: park.id }, () => {
            this.setState({ random: Math.random() })
        })
        this.setState({ clienteId: park.clienteId })
    }

    onLoadedPark = (parks) => {
        if (parks.length > 0) {
            this.onDetailButton(parks[0])
        }
    }

    onSelectPark = async (park) => {
        toastLoading("Alterando estabelecimento...")
        await alterarEstacionamento(park.id)
        setTimeout(() => {
            reloadWindow()
        }, 500);
    }

    onForceRefresh = async (park) => {
        const parkName = (park.nomeInterno !== undefined || park.nomeInterno !== "") ? park.nomeInterno : park.nome
        var confirm = await dangerConfirm({ message: `Deseja mesmo forçar a atualização das tabelas do estacionamento ${parkName}` });
        if (confirm) {
            await saveLastUpdateByPark(park)
            reloadWindow()
        }
    }

    onEditPark = (park) => {
        this.setState({ parkToEditSelected: park }, () => {
            this.setState({ showEditPark: true })
        })
    }

    onShowImportModal = () => {
        this.setState({ showNewParkModal: true })
    }

    onShowAssociateHelpdesk = () => {
        this.setState({ showHelpDeskModal: true })
    }

    onShowWhatsAppMessages = () => {
        this.setState({ showWhatsAppMessagesModal: true })
    }

    checkFields = () => {
        return (this.state.nome === '' ||
            this.state.documento === '' ||
            this.state.tipoDocumento === '' ||
            this.state.representante === '' ||
            this.state.celular === '' ||
            this.state.cep === '' ||
            this.state.logradouro === '' ||
            this.state.numero === '' ||
            this.state.bairro === '' ||
            this.state.cidade === '' ||
            this.state.estado === '')
    }

    fetchCEP = (value) => {
        this.setState({ cep: value })
        if (value.replace(' ', '').length === 9) {
            fetch("https://viacep.com.br/ws/" + value + "/json/").then(res => res.json()).then((result) => {
                this.setState({ cep: result.cep })
                this.setState({ logradouro: result.logradouro })
                this.setState({ complemento: result.complemento })
                this.setState({ bairro: result.bairro })
                this.setState({ cidade: result.localidade })
                this.setState({ estado: result.uf })
            }, (error) => {
            })
        }
    }

    startEdit = async () => {
        this.setState({ isEditing: true })
        this.setState({ nome: this.state.cliente.nome })
        this.setState({ razaoSocial: this.state.cliente.razaoSocial })
        this.setState({ email: this.state.cliente.email })
        this.setState({ documento: this.state.cliente.documento })
        this.setState({ tipoDocumento: this.state.cliente.tipoDocumento })
        this.setState({ representante: this.state.cliente.representante })
        this.setState({ financeiroRepresentante: this.state.cliente.financeiroRepresentante })
        this.setState({ financeiroCelular: this.state.cliente.financeiroCelular })
        this.setState({ financeiroEmail: this.state.cliente.financeiroEmail })
        this.setState({ celular: this.state.cliente.celular })
        this.setState({ isPayed: this.state.cliente.isPayed ?? false })
        this.setState({ email: this.state.cliente.email })
        this.setState({ habilitarEnviarCobrancaPorWhatsApp: this.state.cliente.habilitarEnviarCobrancaPorWhatsApp ?? true })
        this.setState({ habilitarEnviarCobrancaPorEmail: this.state.cliente.habilitarEnviarCobrancaPorEmail ?? true })
        this.setState({ habilitarEmissaoDeNotaFiscal: this.state.cliente.habilitarEmissaoDeNotaFiscal ?? true })
        this.setState({ habilitarAsinanteTagSemParar: this.state.cliente.habilitarAsinanteTagSemParar ?? false })
        this.setState({ habilitarAsinanteTagTaggy: this.state.cliente.habilitarAsinanteTagTaggy ?? false })
        this.setState({ habilitarAsinanteTagConectCar: this.state.cliente.habilitarAsinanteTagConectCar ?? false })
        this.setState({ habilitarRede: this.state.cliente.habilitarRede ?? false })
        this.setState({ habilitarGetnet: this.state.cliente.habilitarGetnet ?? false })
        this.setState({ habilitarSafra: this.state.cliente.habilitarSafra ?? false })
        this.setState({ habilitarCielo: this.state.cliente.habilitarCielo ?? false })
        this.setState({ financeiroIm: this.state.cliente.financeiroIm })
        this.setState({ habilitarPagSeguro: this.state.cliente.habilitarPagSeguro ?? false })
        this.setState({ habilitarStone: this.state.cliente.habilitarStone ?? false })
        this.setState({ habilitarAsinanteTagVeloe: this.state.cliente.habilitarAsinanteTagVeloe ?? false })
        this.setState({ corPrincipal: this.state.cliente.tema?.corPrincipal })
        this.setState({ personalizarApp: this.state.cliente.tema?.personalizarApp })
        this.setState({ habilitarCobrancaViaBannerNoApp: this.state.cliente.flags?.habilitarCobrancaViaBannerNoApp ?? true })
        this.setState({ personalizarCobrancas: this.state.cliente.tema?.personalizarCobrancas })
        this.setState({ personalizarAdministrativo: this.state.cliente.tema?.personalizarAdministrativo })
        this.setState({ cep: this.state.cliente.endereco?.cep })
        this.setState({ logradouro: this.state.cliente.endereco?.logradouro })
        this.setState({ numero: this.state.cliente.endereco?.numero })
        this.setState({ bairro: this.state.cliente.endereco?.bairro })
        this.setState({ cidade: this.state.cliente.endereco?.cidade })
        this.setState({ estado: this.state.cliente.endereco?.estado })
        this.setState({ complemento: this.state.cliente.endereco?.complemento })
        const logo = this.state.cliente.tema?.logo
        if (logo) {
            const url = await getURLFromURI(logo)
            this.setState({ imageURL: url })
        } else {
            this.setState({ imageURL: "https://placehold.co/200x100" })
        }
    }

    openWhatsApp = () => {
        const phone = this.state.cliente.celular.replace("(", "").replace(")", "").replace("-", "")
        window.open(
            'https://wa.me/55' + phone,
            '_blank'
        );
    }

    update = async () => {
        if (this.checkFields()) {
            toastWarning("Todos os campos são obrigatórios")
            return
        }
        toastLoading("Atualizando...")
        const data = {
            nome: this.state.nome,
            razaoSocial: this.state.razaoSocial,
            email: this.state.email,
            documento: this.state.documento,
            tipoDocumento: this.state.tipoDocumento,
            representante: this.state.representante,
            celular: this.state.celular,
            financeiroRepresentante: this.state.financeiroRepresentante,
            financeiroIm: this.state.financeiroIm,
            financeiroCelular: this.state.financeiroCelular,
            financeiroEmail: this.state.financeiroEmail,
            isPayed: this.state.isPayed,
            habilitarEnviarCobrancaPorWhatsApp: this.state.habilitarEnviarCobrancaPorWhatsApp,
            habilitarEnviarCobrancaPorEmail: this.state.habilitarEnviarCobrancaPorEmail,
            habilitarEmissaoDeNotaFiscal: this.state.habilitarEmissaoDeNotaFiscal,
            habilitarAsinanteTagSemParar: this.state.habilitarAsinanteTagSemParar,
            habilitarAsinanteTagTaggy: this.state.habilitarAsinanteTagTaggy,
            habilitarAsinanteTagConectCar: this.state.habilitarAsinanteTagConectCar,
            habilitarRede: this.state.habilitarRede,
            habilitarGetnet: this.state.habilitarGetnet,
            habilitarSafra: this.state.habilitarSafra,
            habilitarCielo: this.state.habilitarCielo,
            habilitarPagSeguro: this.state.habilitarPagSeguro,
            habilitarStone: this.state.habilitarStone,
            habilitarAsinanteTagVeloe: this.state.habilitarAsinanteTagVeloe,
            vindi: {
                customerId: this.state.customerId
            },
            flags: {
                habilitarCobrancaViaBannerNoApp: this.state.habilitarCobrancaViaBannerNoApp,
            },
            tema: {
                personalizarApp: this.state.personalizarApp,
                personalizarCobrancas: this.state.personalizarCobrancas,
                personalizarAdministrativo: this.state.personalizarAdministrativo ?? false,
                corPrincipal: this.state.corPrincipal,
                logo: this.state.logo
            },
            endereco: {
                cep: this.state.cep,
                logradouro: this.state.logradouro,
                numero: this.state.numero,
                bairro: this.state.bairro,
                cidade: this.state.cidade,
                estado: this.state.estado,
                complemento: this.state.complemento
            }
        }
        await updateClientById({ id: this.props.match.params.id, data: data })
        await this.uploadClientLogoIfNeed({ id: this.props.match.params.id })
        toastDismissLoading()
        reloadWithAlert("Cliente atualizado com sucesso!")
    }

    handleClick = (e) => {
        this.inputImageElement.click();
    }

    onChangeFile = (e) => {
        const imageURL = URL.createObjectURL(e.target.files[0])
        this.setState({ imageURL: imageURL })
    }

    uploadClientLogoIfNeed = async ({ id }) => {
        const file = this.inputImageElement.files[0]
        if (!file) {
            return
        }
        const client = await getClientById({ id: id })
        const destination = await uploadClientLogo(id, file)
        if (!client.tema) {
            client.tema = {}
        }
        client.tema.logo = destination
        await updateClientById({ id: client.id, data: client })
    }

    changeRevenda = async () => {
        const revendas = await getAllRevendas()
        this.setState({ revendas: revendas.sort((a, b) => b.nome.localeCompare(a.nome)) })
        this.setState({ isOpenChangeRevendaModal: true })
    }

    confirmChangeRevenda = async () => {
        const clienteId = this.props.match.params.id
        const revendaId = this.state.revendaSelecionadaId
        if (revendaId) {
            toastLoading("Atualizando revenda...")
            await updateClientRevendaId({ id: clienteId, revendaId: revendaId })
            const parks = await getAllParksByClientId({ clientId: clienteId })
            toastLoading("Atualizando estabelecimentos...")
            for (const park of parks) {
                await updateParkRevendaId({ id: park.id, revendaId: revendaId })
            }
            const users = await getAllUsersByClienteId({ clienteId: clienteId })
            toastLoading("Atualizando usuários...")
            for (const user of users) {
                await updateUserRevendaId({ id: user.id, revendaId: revendaId })
            }
            const licencas = await getAllLicencasByClienteId({ clienteId: clienteId })
            toastLoading("Atualizando licenças...")
            for (const licenca of licencas) {
                await updateLicenceRevendaId({ id: licenca.id, revendaId: revendaId })
            }
            toastDismissLoading()
            reloadWithAlert("Revenda da matriz atualizada com sucesso.")
        } else {
            toastWarning("Selecione a revenda.")
        }
    }

    openReseller = async () => {
        this.setState({ isOpenRevendedorModal: true })
        await this.loadResselersAll()
    }

    openSupport = async () => {
        this.setState({ isOpenSupportModal: true })
    }

    loadResselersAll = async () => {
        const revendasAssociadas = getRevendasAssociadas()
        const results = await getAllRessellers({ associatedResales: revendasAssociadas })
        this.setState({ allResellers: results })
    }

    setUserToReseller = async (user) => {
        const id = this.props.match.params.id
        await setClientUserToReseller({ id: id, user: user })
        reloadWindow()
    }

    onUpdateStatus = async (status) => {
        const id = this.props.match.params.id
        await updateClientStatus({ id: id, status: status })
        reloadWindow()
    }

    openModalUsersLogs = () => {
        this.setState({ isOpenModalUsersLogs: true })
    }

    hideModalUsersLogs = () => {
        this.setState({ isOpenModalUsersLogs: false })
    }

    hideWhatsAppMessages = () => {
        this.setState({ showWhatsAppMessagesModal: false })
    }

    showUserModel = () => {
        this.setState({ isShowUserModal: true })
    }

    hideUserModal = () => {
        this.setState({ isShowUserModal: false })
    }

    openModalLicences = async () => {
        this.setState({ isOpenModalLicences: true })
        const option = {
            method: 'GET'
        }
        const result = await fetchFunction(`/newSubscription/current-licence`, option)
        if (result.data) {
            const licence = result.data
            this.setState({ currentLicence: licence })
        }
    }

    changeKey = ({ key }) => {
        window.history.pushState('', '', `/revenda/cliente/${this.props.match.params.id}?tab=${key}`)
        this.setState({ key: key })
    }

    hideModalLicences = () => {
        this.setState({ isOpenModalLicences: false })
    }

    handleOpenMenuParks = (event) => {
        this.anchorEl = event.currentTarget
        this.setState({ isMenuOpen: true })
    }

    handleCloseMenuParks = () => {
        this.setState({ isMenuOpen: false })
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <div className="card mb-4">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    {this.state.isEditing === false ? "Detalhes da matriz" : "Editar dados da matriz"}
                                </div>
                                <div className='card-body'>
                                    {
                                        this.state.isEditing === true &&
                                        <>
                                            <div className='row'>
                                                <div className="col-lg-127">
                                                    <label>Nome matriz</label>
                                                    <input type="text" onChange={(e) => this.setState({ nome: e.target.value })} value={this.state.nome} className="form-control" required />
                                                </div>
                                                <div className="col-lg-12">
                                                    <label>Razão social</label>
                                                    <input type="text" onChange={(e) => this.setState({ razaoSocial: e.target.value })} value={this.state.razaoSocial} className="form-control" required />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Tipo documento</label>
                                                    <div className="input-group mb-3">
                                                        <select className="form-select" onChange={(e) => this.setState({ tipoDocumento: e.target.value })} value={this.state.tipoDocumento} required>
                                                            <option value='CNPJ'>CNPJ</option>
                                                            <option value='CPF'>CPF</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Número documento</label>
                                                    <NumberFormat className="form-control" mask='_' format={this.state.tipoDocumento === 'CPF' ? '###.###.###-##' : '##.###.###/####-##'} value={this.state.documento} onChange={(e) => this.setState({ documento: e.target.value })} />
                                                </div>
                                                <div className="col-lg-5">
                                                    <label>Inscrição municipal</label>
                                                    <input type="text" onChange={(e) => this.setState({ financeiroIm: e.target.value })} value={this.state.financeiroIm} className="form-control" required />
                                                </div>
                                                <div className="col-lg-5">
                                                    <label>Representante</label>
                                                    <input type="text" onChange={(e) => this.setState({ representante: e.target.value })} value={this.state.representante} className="form-control" required />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Telefone</label>
                                                    <NumberFormat className="form-control" value={this.state.celular} mask='_' onChange={(e) => this.setState({ celular: e.target.value })} format="(##)#####-####" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>E-mail</label>
                                                    <input type="text" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" required />
                                                </div>
                                                <div className='form-group mt-3'>
                                                    <h5>Responsável financeiro</h5>
                                                </div>
                                                <div className="col-lg-5">
                                                    <label>Representante</label>
                                                    <input type="text" onChange={(e) => this.setState({ financeiroRepresentante: e.target.value })} value={this.state.financeiroRepresentante} className="form-control" required />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Celular</label>
                                                    <NumberFormat className="form-control" value={this.state.financeiroCelular} mask='_' onChange={(e) => this.setState({ financeiroCelular: e.target.value })} format="(##)#####-####" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>E-mail</label>
                                                    <input type="text" onChange={(e) => this.setState({ financeiroEmail: e.target.value })} value={this.state.financeiroEmail} className="form-control" required />
                                                </div>
                                                {
                                                    this.state.revenda?.formaCobranca === "SELFPARKING" && this.state.privado?.formaCobrancaProvedor === "VINDI" &&
                                                    <div className="col-lg-12 mt-2">
                                                        <AlertSuccess tag="Vindi">
                                                            <label>Customer Id</label>
                                                            <input text="text" className="form-control" value={this.state.customerId} onChange={(e) => this.setState({ customerId: e.target.value })} />
                                                            <small>Este é o campo que associa o cliente na plataforma de pagamentos Vindi, caso não seja informado iremos gerar um no momento do pagamento.</small> <p />
                                                            <a href='https://app.vindi.com.br/admin/customers' target='_blank' rel="noopener noreferrer">Visualizar clientes no portal da Vindi</a>
                                                        </AlertSuccess>
                                                    </div>
                                                }
                                            </div>
                                            <div className='form-group mt-3'>
                                                <h5>Endereço</h5>
                                            </div>
                                            <div className='row mt-2'>
                                                <div className="col-lg-3">
                                                    <label>CEP</label>
                                                    <NumberFormat autoComplete='off' className="form-control" value={this.state.cep} onChange={(e) => this.fetchCEP(e.target.value)} format="#####-###" />
                                                </div>
                                                <div className="col-lg-9">
                                                    <label>Logradouro</label>
                                                    <input type="text" onChange={(e) => this.setState({ logradouro: e.target.value })} value={this.state.logradouro} className="form-control" required />
                                                </div>
                                                <div className="col-lg-5">
                                                    <label>Número</label>
                                                    <input type="text" onChange={(e) => this.setState({ numero: e.target.value })} value={this.state.numero} className="form-control" required />
                                                </div>
                                                <div className="col-lg-7">
                                                    <label>Complemento</label>
                                                    <input type="text" onChange={(e) => this.setState({ complemento: e.target.value })} value={this.state.complemento} className="form-control" />
                                                </div>
                                            </div>

                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Bairro</label>
                                                    <input type="text" onChange={(e) => this.setState({ bairro: e.target.value })} value={this.state.bairro} className="form-control" required />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Cidade</label>
                                                    <input type="text" onChange={(e) => this.setState({ cidade: e.target.value })} value={this.state.cidade} className="form-control" required />
                                                </div>
                                                <div className="col-lg-3">
                                                    <label>Estado</label>
                                                    <input type="text" onChange={(e) => this.setState({ estado: e.target.value })} value={this.state.estado} className="form-control" required />
                                                </div>
                                            </div>
                                            {
                                                this.state.formaCobranca === "SELFPARKING" &&
                                                <div className='form-group mt-3'>
                                                    <h5>Configurações</h5>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="isPayed" checked={this.state.isPayed} onChange={(e) => this.setState({ isPayed: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="isPayed">
                                                            Estabelecimento(s) pagante(s).
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEnviarCobrancaPorWhatsApp" checked={this.state.habilitarEnviarCobrancaPorWhatsApp} onChange={(e) => this.setState({ habilitarEnviarCobrancaPorWhatsApp: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEnviarCobrancaPorWhatsApp">
                                                            Enviar cobrança por WhatsApp.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEnviarCobrancaPorEmail" checked={this.state.habilitarEnviarCobrancaPorEmail} onChange={(e) => this.setState({ habilitarEnviarCobrancaPorEmail: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEnviarCobrancaPorEmail">
                                                            Enviar cobrança por email.
                                                        </label>
                                                    </div>
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="habilitarEmissaoDeNotaFiscal" checked={this.state.habilitarEmissaoDeNotaFiscal} onChange={(e) => this.setState({ habilitarEmissaoDeNotaFiscal: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="habilitarEmissaoDeNotaFiscal">
                                                            Emitir nota fiscal ao receber pagamento da assinatura.
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            <h5>Flags</h5>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="habilitarCobrancaViaBannerNoApp" checked={this.state.habilitarCobrancaViaBannerNoApp} onChange={(e) => this.setState({ habilitarCobrancaViaBannerNoApp: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="habilitarCobrancaViaBannerNoApp">
                                                    Mostrar banner de pendencia no aplicativo.
                                                </label>
                                            </div>
                                            <h5>Portais</h5>
                                            <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>Cor principal</label>
                                                    <input type="text" placeholder='#44377b' onChange={(e) => this.setState({ corPrincipal: e.target.value })} value={this.state.corPrincipal} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className='col-lg-12' role='button' onClick={this.handleClick}>
                                                <label>Logo</label><br />
                                                <img alt='Logo' style={{ width: 200, "object-fit": "cover" }} className="img-thumbnail my-2" src={this.state.imageURL} />
                                                <input type='file' onChange={this.onChangeFile} accept="image/png, image/jpeg" hidden={true} ref={input => this.inputImageElement = input} />
                                            </div>
                                            <h5>Personalizar</h5>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="personalizarApp" checked={this.state.personalizarApp} onChange={(e) => this.setState({ personalizarApp: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="personalizarApp">
                                                    Aplicativo
                                                </label>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="personalizarCobrancas" checked={this.state.personalizarCobrancas} onChange={(e) => this.setState({ personalizarCobrancas: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="personalizarCobrancas">
                                                    Site de cobranças
                                                </label>
                                            </div>
                                            <div className="form-check form-switch my-2">
                                                <input className="form-check-input" id="personalizarAdministrativo" checked={this.state.personalizarAdministrativo} onChange={(e) => this.setState({ personalizarAdministrativo: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="personalizarAdministrativo">
                                                    Administrativo
                                                </label>
                                            </div>
                                            <div className='form-group mt-3'>
                                                <h5>Tags habilitadas</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAsinanteTagSemParar" checked={this.state.habilitarAsinanteTagSemParar} onChange={(e) => this.setState({ habilitarAsinanteTagSemParar: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAsinanteTagSemParar">
                                                        Sem Parar
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAsinanteTagTaggy" checked={this.state.habilitarAsinanteTagTaggy} onChange={(e) => this.setState({ habilitarAsinanteTagTaggy: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAsinanteTagTaggy">
                                                        Taggy
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAsinanteTagConectCar" checked={this.state.habilitarAsinanteTagConectCar} onChange={(e) => this.setState({ habilitarAsinanteTagConectCar: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAsinanteTagConectCar">
                                                        ConnectCar
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarAsinanteTagVeloe" checked={this.state.habilitarAsinanteTagVeloe} onChange={(e) => this.setState({ habilitarAsinanteTagVeloe: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarAsinanteTagVeloe">
                                                        Veloe
                                                    </label>
                                                </div>
                                                <h5>Adquirentes</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarPagSeguro" checked={this.state.habilitarPagSeguro} onChange={(e) => this.setState({ habilitarPagSeguro: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarPagSeguro">
                                                        PagSeguro
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarStone" checked={this.state.habilitarStone} onChange={(e) => this.setState({ habilitarStone: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarStone">
                                                        Stone
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarCielo" checked={this.state.habilitarCielo} onChange={(e) => this.setState({ habilitarCielo: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarCielo">
                                                        Cielo
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarRede" checked={this.state.habilitarRede} onChange={(e) => this.setState({ habilitarRede: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarRede">
                                                        Rede
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarGetnet" checked={this.state.habilitarGetnet} onChange={(e) => this.setState({ habilitarGetnet: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarGetnet">
                                                        Getnet
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="habilitarSafra" checked={this.state.habilitarSafra} onChange={(e) => this.setState({ habilitarSafra: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="habilitarSafra">
                                                        Safra
                                                    </label>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.isEditing === false &&
                                        <>
                                            {
                                                this.state.isLoading === false ?
                                                    <>
                                                        <div className='row'>
                                                            <div className="col-lg-12">
                                                                <strong>Nome matriz: </strong> {this.state.cliente.nome}
                                                            </div>
                                                            {
                                                                this.state.cliente.razaoSocial &&
                                                                <div className="col-lg-12">
                                                                    <strong>Razão social: </strong> {this.state.cliente.razaoSocial}
                                                                </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <strong>Documento: </strong> {this.state.cliente.documento}
                                                            </div>
                                                            {
                                                                !isNullOrEmpty(this.state.cliente.financeiroIm) &&
                                                                <div className="col-lg-12">
                                                                    <strong>Inscrição municipal: </strong> {this.state.cliente.financeiroIm}
                                                                </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <strong>Representante: </strong> {this.state.cliente.representante}
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>Celular: </strong> {this.state.cliente.celular}
                                                                <Tooltip title="Clique para abrir o WhatsApp" placement="top">
                                                                    <a href={`https://wa.me/+55${normalizedField(this.state.cliente.celular)}`} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-success mx-2">
                                                                        <i className="fa-brands fa-whatsapp" />
                                                                    </a>
                                                                </Tooltip>
                                                            </div>
                                                            <div className="col-lg-12">
                                                                <strong>E-mail: </strong> {this.state.cliente.email}
                                                            </div>
                                                            {
                                                                this.state.cliente.createdAt !== undefined &&
                                                                <div className="col-lg-12">
                                                                    <strong>Desde: </strong> {Moment(this.state.cliente.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}
                                                                </div>
                                                            }
                                                            <div className="col-lg-12">
                                                                <strong>Origem: </strong>
                                                                {this.state.cliente.platform === "" && <span>Não disponível</span>}
                                                                {this.state.cliente.platform?.includes("pagSeguro") && 'PagSeguro'}
                                                                {this.state.cliente.platform?.includes("cielo") && 'Cielo'}
                                                                {this.state.cliente.platform?.includes("getnet") && 'GetNet'}
                                                                {this.state.cliente.platform?.includes("Stone") && 'Stone'}
                                                                {this.state.cliente.platform?.includes("safra") && 'Safra'}
                                                                {this.state.cliente.platform?.includes("store") && 'Google Play'}
                                                                {this.state.cliente.platform?.includes("rede") && 'Rede'}
                                                                {this.state.cliente.platform?.includes("site") && 'Site'}
                                                            </div>
                                                            <div className='col-lg-12'>
                                                                <strong>Status do lead: </strong>
                                                                <div className='col-lg-12' role='button' onClick={() => this.changeKey({ key: "anotacoes" })} >
                                                                    {!this.state.cliente?.leadStatus && <span className="badge text-bg-danger">Aguardando</span>}
                                                                    {this.state.cliente?.leadStatus === "testando" && <span className="badge text-bg-warning text-white">Testando</span>}
                                                                    {this.state.cliente?.leadStatus === "fechado" && <span className="badge text-bg-success">Fechado</span>}
                                                                    {this.state.cliente?.leadStatus === "whatsapp" && <span className="badge text-bg-info text-white">WhatsApp</span>}
                                                                    {this.state.cliente?.leadStatus === "ligacao" && <span className="badge text-bg-info text-white">Ligação</span>}
                                                                    {this.state.cliente?.leadStatus === "aguardando" && <span className="badge text-bg-danger">Aguardando</span>}
                                                                    {this.state.cliente?.leadStatus === "seminteresse" && <span className="badge text-bg-secondary">Sem interesse</span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={tab => this.changeKey({ key: tab })}>
                                                                <Tab eventKey="revenda" title="Revenda">
                                                                    <div className='mt-3'>
                                                                        <Tooltip title={this.state.cliente?.revendedor?.id ?? ""} placement="top">
                                                                            <div className={this.state.cliente?.revendedor ? "alert alert-success" : "alert alert-warning"}>
                                                                                {
                                                                                    this.state.cliente.revendedor ?
                                                                                        <>
                                                                                            <span className="badge text-bg-success">Revendedor</span><br />
                                                                                            <div className="col-lg-12">
                                                                                                <strong>Nome: </strong> {this.state.cliente.revendedor?.nome}
                                                                                            </div>
                                                                                            <div className="col-lg-12">
                                                                                                <strong>E-mail: </strong> {this.state.cliente.revendedor?.email}
                                                                                            </div>
                                                                                            {
                                                                                                this.state.cliente.revendedor?.celular &&
                                                                                                <div className="col-lg-12">
                                                                                                    <strong>Celular: </strong> {this.state.cliente.revendedor?.celular}
                                                                                                </div>
                                                                                            }
                                                                                            <div className="col-lg-12">
                                                                                                <strong>Associado em: </strong> {Moment(this.state.cliente.revendedorAssociatedAt?.toDate()).format("DD/MM/YYYY")}
                                                                                            </div>
                                                                                            {
                                                                                                this.state.acessoRevendedorClienteAssociarRevendedor && this.state.cliente?.revendedor !== undefined &&
                                                                                                <button type="button" onClick={this.openReseller} className="btn btn-sm btn-success mt-2">
                                                                                                    <i className="fas fa-address-book mx-2" />
                                                                                                    Alterar revendedor
                                                                                                </button>
                                                                                            }
                                                                                        </> : <>
                                                                                            {
                                                                                                this.state.acessoRevendedorClienteAssociarRevendedor ?
                                                                                                    <button type="button" onClick={this.openReseller} className="btn btn-sm btn-warning text-white">
                                                                                                        <i className="fas fa-address-book mx-2" />
                                                                                                        Associar revendedor
                                                                                                    </button> : <>
                                                                                                        <strong>Atenção: </strong> Esta matriz esta sem revendedor associado.
                                                                                                    </>
                                                                                            }
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </Tooltip>
                                                                        {
                                                                            this.state.revenda?.tipoSuporte === "CONCESSIONARIO" &&
                                                                            <Tooltip title={this.state.cliente.support?.id ?? ""} placement="top">
                                                                                <div className='alert alert-secondary'>
                                                                                    <span className="badge text-bg-secondary">Central de suporte</span><br />
                                                                                    {
                                                                                        this.state.cliente.support ? <>
                                                                                            <div className="col-lg-12">
                                                                                                <strong>Nome: </strong> {this.state.cliente.support?.name}
                                                                                            </div>
                                                                                            <div className="col-lg-12">
                                                                                                <strong>Celular: </strong> {this.state.cliente.support?.cellphone}<br />
                                                                                            </div>
                                                                                            {
                                                                                                this.state.acessoRevendedorClienteAssociarSuporte &&
                                                                                                <button type="button" onClick={this.openSupport} className="btn btn-sm btn-secondary text-white mt-3">
                                                                                                    <i className="fas fa-headset mx-2" />
                                                                                                    Alterar central de suporte
                                                                                                </button>
                                                                                            }
                                                                                        </> : <div className='mt-3'>
                                                                                            {
                                                                                                this.state.acessoRevendedorClienteAssociarSuporte ?
                                                                                                    <button type="button" onClick={this.openSupport} className="btn btn-sm btn-secondary text-white">
                                                                                                        <i className="fas fa-headset mx-2" />
                                                                                                        Associar central de suporte
                                                                                                    </button> :
                                                                                                    <label>Você não tem permissão para associar uma central de suporte.</label>
                                                                                            }
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                            </Tooltip>
                                                                        }
                                                                        {
                                                                            this.state.revendasAssociadas.length > 0 &&
                                                                            <Tooltip title={this.state.revendaId} placement="top">
                                                                                <div className='alert alert-danger'>
                                                                                    <span className="badge text-bg-danger">Administrar</span><br />
                                                                                    <span><strong>Revenda: </strong> {this.state.revenda?.nome}</span>
                                                                                    <br />
                                                                                    <button type="button" onClick={this.changeRevenda} className="btn btn-sm btn-danger mt-2">
                                                                                        <i className="fas fa-address-book mx-2" />
                                                                                        {this.state.revenda ? <span>Alterar revenda</span> : <span>Associar revenda</span>}
                                                                                    </button>
                                                                                </div>
                                                                            </Tooltip>
                                                                        }
                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="financeiro" title="Financeiro">
                                                                    <div className='row my-1'>
                                                                        <div className='form-group mt-3'>
                                                                            <AlertSuccess tag="Responsável financeiro">
                                                                                <div className="col-lg-12">
                                                                                    <strong>Nome: </strong> {this.state.cliente.financeiroRepresentante ?? this.state.cliente.representante}
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <strong>Celular: </strong> {this.state.cliente.financeiroCelular ?? this.state.cliente.celular}
                                                                                </div>
                                                                                <div className="col-lg-12">
                                                                                    <strong>E-mail: </strong> {this.state.cliente.financeiroEmail ?? this.state.cliente.email}
                                                                                </div>
                                                                            </AlertSuccess>
                                                                            {
                                                                                this.state.customerId &&
                                                                                <div className="col-lg-12">
                                                                                    <AlertSuccess tag="Vindi">
                                                                                        <strong>Customer Id: </strong> {this.state.customerId}
                                                                                    </AlertSuccess>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            this.state.formaCobranca === "SELFPARKING" &&
                                                                            <div className='col-lg-12'>
                                                                                <div className='form-group'>
                                                                                    <h5>Configurações</h5>
                                                                                    <div className="form-check form-switch">
                                                                                        <input className="form-check-input" id="isPayed" checked={this.state.isPayed} disabled onChange={(e) => this.setState({ isPayed: e.target.checked })} type="checkbox" />
                                                                                        <label className="form-check-label" htmlFor="isPayed">
                                                                                            Estabelecimento(s) pagante(s).
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check form-switch my-2">
                                                                                        <input className="form-check-input" id="habilitarEnviarCobrancaPorWhatsApp" checked={this.state.cliente.habilitarEnviarCobrancaPorWhatsApp} disabled type="checkbox" />
                                                                                        <label className="form-check-label" htmlFor="habilitarEnviarCobrancaPorWhatsApp">
                                                                                            Enviar cobrança por WhatsApp.
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check form-switch my-2">
                                                                                        <input className="form-check-input" id="habilitarEnviarCobrancaPorEmail" checked={this.state.cliente.habilitarEnviarCobrancaPorEmail} disabled type="checkbox" />
                                                                                        <label className="form-check-label" htmlFor="habilitarEnviarCobrancaPorEmail">
                                                                                            Enviar cobrança por email.
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="form-check form-switch my-2">
                                                                                        <input className="form-check-input" id="habilitarEmissaoDeNotaFiscal" checked={this.state.cliente.habilitarEmissaoDeNotaFiscal} disabled type="checkbox" />
                                                                                        <label className="form-check-label" htmlFor="habilitarEmissaoDeNotaFiscal">
                                                                                            Emitir nota fiscal ao receber pagamento da assinatura.
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="anotacoes" title="Anotações">
                                                                    {
                                                                        this.state.isShowingLeadModal && <LeadClientModal clienteId={this.state.cliente.id} onClose={(e) => this.setState({ isShowingLeadModal: false })} />
                                                                    }
                                                                    <div className='mt-3'>
                                                                        <div className='row'>
                                                                            <div className='col-lg-6'>
                                                                                <button className='btn btn-primary' onClick={(e) => this.setState({ isShowingLeadModal: true })}>
                                                                                    <i className="fas fa-sticky-note mx-2" />
                                                                                    Adicionar anotação
                                                                                </button>
                                                                            </div>
                                                                            <div className='col-lg-12'>
                                                                                {
                                                                                    (this.state.cliente?.leadNotes?.length ?? 0) === 0 ?
                                                                                        <EmptyContent text="Nenhuma anotação cadastrada." /> :
                                                                                        <table className="table table-responsive mt-3">
                                                                                            <tbody>
                                                                                                <tr>
                                                                                                    <td align='center'><strong>Tipo</strong></td>
                                                                                                    <td><strong>Data</strong></td>
                                                                                                    <td><strong>Observação</strong></td>
                                                                                                    {/* <td><strong>Operador</strong></td> */}
                                                                                                </tr>
                                                                                                {
                                                                                                    this.state.cliente?.leadNotes?.sort((a, b) => b.createdAt.seconds - a.createdAt.seconds).map(item =>
                                                                                                        <tr>
                                                                                                            <td align='center' width={10}>
                                                                                                                {!item.currentLeadStatus && <span className="badge text-bg-danger w-100">Aguardando</span>}
                                                                                                                {item.currentLeadStatus === "testando" && <span className="badge text-bg-warning text-white w-100">Testando</span>}
                                                                                                                {item.currentLeadStatus === "anotacao" && <span className="badge text-bg-secondary text-white w-100">Anotação</span>}
                                                                                                                {item.currentLeadStatus === "fechado" && <span className="badge text-bg-success w-100">Fechado</span>}
                                                                                                                {item.currentLeadStatus === "whatsapp" && <span className="badge text-bg-info text-white w-100">WhatsApp</span>}
                                                                                                                {item.currentLeadStatus === "ligacao" && <span className="badge text-bg-info text-white w-100">Ligação</span>}
                                                                                                                {item.currentLeadStatus === "aguardando" && <span className="badge text-bg-danger text-white w-100">Aguardando</span>}
                                                                                                                {item.currentLeadStatus === "seminteresse" && <span className="badge text-bg-secondary w-100">Sem interesse</span>}
                                                                                                            </td>
                                                                                                            <td>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                                                            <td>{item.message}</td>
                                                                                                            {/* <td>{item.createdBy?.nome}</td> */}
                                                                                                        </tr>
                                                                                                    )
                                                                                                }
                                                                                            </tbody>
                                                                                        </table>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="configuracoes" title="Configurações">
                                                                    <h5 className='mt-2'>Flags</h5>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="habilitarCobrancaViaBannerNoApp" disabled checked={this.state.habilitarCobrancaViaBannerNoApp} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="habilitarCobrancaViaBannerNoApp">
                                                                            Mostrar banner de pendencia no aplicativo.
                                                                        </label>
                                                                    </div>
                                                                    <h5 className='mt-2'>Personalizar</h5>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="personalizarApp" disabled checked={this.state.personalizarApp} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="personalizarApp">
                                                                            Aplicativo
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="personalizarCobrancas" disabled checked={this.state.personalizarCobrancas} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="personalizarCobrancas">
                                                                            Portal
                                                                        </label>
                                                                    </div>
                                                                    <div className="form-check form-switch my-2">
                                                                        <input className="form-check-input" id="personalizarAdministrativo" disabled checked={this.state.personalizarAdministrativo} type="checkbox" />
                                                                        <label className="form-check-label" htmlFor="personalizarAdministrativo">
                                                                            Administrativo
                                                                        </label>
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <strong>Cor principal: </strong> {takeIfIsNotNullOrEmpty(this.state.cliente.tema?.corPrincipal) ?? "Não disponível"}
                                                                    </div>
                                                                    <div className="col-lg-12">
                                                                        <img alt='Logo' style={{ width: 200, "object-fit": "cover" }} className="img-thumbnail my-2" src={this.state.urlLogo} />
                                                                    </div>
                                                                    <h5>Portais</h5>
                                                                    <div className='btn-group'>
                                                                        <a target='_blank' href={`${getRevenda()?.siteTicket}/formulario/cadastrar?id=${this.state.cliente.id}`} className="btn btn btn-success">
                                                                            <i className="fas fa-external-link-square-alt mx-2" />
                                                                            Cadastro
                                                                        </a>
                                                                        <Button
                                                                            anchorEl={el => (this.anchorEl = el)}
                                                                            aria-controls={this.state.isMenuOpen ? 'basic-menu' : undefined}
                                                                            aria-haspopup="true"
                                                                            className='btn btn-success'
                                                                            aria-expanded={this.state.isMenuOpen}
                                                                            onClick={this.handleOpenMenuParks}>
                                                                            <i className="fas fa-external-link-square-alt mx-2" />
                                                                            Ticket
                                                                        </Button>
                                                                        <Menu
                                                                            id="basic-menu"
                                                                            anchorEl={this.anchorEl}
                                                                            onClose={this.handleCloseMenuParks}
                                                                            open={this.state.isMenuOpen}
                                                                            MenuListProps={{
                                                                                'aria-labelledby': 'basic-button',
                                                                            }}>
                                                                            {
                                                                                this.state.parks.map(park =>
                                                                                    <MenuItem onClick={() => goToNewWindow(`${getRevenda()?.siteTicket}/ticket/buscar?e=${park.id}`)}>
                                                                                        <ListItemIcon>
                                                                                            <i className="fas fa-external-link-square-alt" />
                                                                                        </ListItemIcon>
                                                                                        {takeIfIsNotNullOrEmpty(park.nomeInterno) ?? park.nome}
                                                                                    </MenuItem>
                                                                                )
                                                                            }
                                                                        </Menu>
                                                                    </div>
                                                                    <div className='form-group mt-3'>
                                                                        <h5>Tags habilitadas</h5>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarAsinanteTagSemParar" disabled checked={this.state.habilitarAsinanteTagSemParar} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarAsinanteTagSemParar">
                                                                                Sem Parar
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarAsinanteTagTaggy" disabled checked={this.state.habilitarAsinanteTagTaggy} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarAsinanteTagTaggy">
                                                                                Taggy
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarAsinanteTagConectCar" disabled checked={this.state.habilitarAsinanteTagConectCar} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarAsinanteTagConectCar">
                                                                                ConnectCar
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarAsinanteTagVeloe" disabled checked={this.state.habilitarAsinanteTagVeloe} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarAsinanteTagVeloe">
                                                                                Veloe
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='form-group mt-3'>
                                                                        <h5>Adquirentes</h5>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarPagSeguro" disabled checked={this.state.habilitarPagSeguro} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarPagSeguro">
                                                                                PagSeguro
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarStone" disabled checked={this.state.habilitarStone} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarStone">
                                                                                Stone
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarCielo" disabled checked={this.state.habilitarCielo} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarCielo">
                                                                                Cielo
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarRede" disabled checked={this.state.habilitarRede} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarRede">
                                                                                Rede
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarGetnet" disabled checked={this.state.habilitarGetnet} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarGetnet">
                                                                                Getnet
                                                                            </label>
                                                                        </div>
                                                                        <div className="form-check form-switch my-2">
                                                                            <input className="form-check-input" id="habilitarSafra" disabled checked={this.state.habilitarSafra} type="checkbox" />
                                                                            <label className="form-check-label" htmlFor="habilitarSafra">
                                                                                Safra
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </Tab>
                                                                <Tab eventKey="endereco" title="Endereço">
                                                                    <div className='mt-3'>
                                                                        {
                                                                            this.state.cliente.endereco !== null ?
                                                                                <div className='form-group mt-3'>
                                                                                    <h5>Endereço</h5>
                                                                                    <div className="col-lg-12">
                                                                                        <strong>Logradouro: </strong> {this.state.cliente.endereco ? <span>{this.state.cliente.endereco?.logradouro}, {this.state.cliente.endereco?.numero} - {this.state.cliente.endereco?.cidade}</span> : <span>Não informado</span>}
                                                                                    </div>
                                                                                    {
                                                                                        this.state.cliente.endereco?.cep &&
                                                                                        <div className="col-lg-12">
                                                                                            <strong>CEP: </strong> {this.state.cliente.endereco.cep}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        this.state.cliente.endereco?.bairro &&
                                                                                        <div className="col-lg-12">
                                                                                            <strong>Bairro: </strong> {this.state.cliente.endereco.bairro}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        this.state.cliente.endereco?.estado &&
                                                                                        <div className="col-lg-12">
                                                                                            <strong>Estado: </strong> {this.state.cliente.endereco.estado}
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        this.state.cliente.endereco?.complemento &&
                                                                                        <div className="col-lg-12">
                                                                                            <strong>Complemento: </strong> {this.state.cliente.endereco.complemento}
                                                                                        </div>
                                                                                    }
                                                                                </div> :
                                                                                <div className="col-lg-12">
                                                                                    <h6>Endereço não informado</h6>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </Tab>
                                                            </Tabs>
                                                        </div>
                                                    </>
                                                    : <Loading />
                                            }
                                        </>
                                    }
                                </div>
                                {
                                    this.state.isLoading === false &&
                                    <div className='card-footer'>
                                        {
                                            this.state.isEditing === false ?
                                                <div className='row'>
                                                    <div className='btn-group'>
                                                        <button onClick={(e) => { this.startEdit() }} className='btn btn-primary btn-block'>Editar</button>
                                                        {
                                                            this.state.cliente.status === "Ativo" ? <button onClick={(e) => this.setState({ isOpenInativeClient: true })} className='btn btn-danger btn-block'>Desativar</button> :
                                                                <button onClick={(e) => { this.onUpdateStatus("Ativo") }} className='btn btn-success btn-block'>Ativar</button>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <div className='btn-group'>
                                                    <button type="button" onClick={(e) => { this.setState({ isEditing: false }) }} className="btn btn-secondary text-white btn-block">Cancelar</button>
                                                    <button type="button" onClick={(e) => { this.update() }} className="btn btn-primary btn-block">Atualizar dados</button>
                                                </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <div className='row'>
                                <div className='btn-group'>
                                    <button className='btn btn-success text-white' role='button' onClick={this.onShowWhatsAppMessages} >
                                        <i className="fab fa-whatsapp" /> <br />
                                        <div className='text-center'>Mensagens</div>
                                    </button>
                                    <button className='btn btn-info text-white' role='button' onClick={this.onShowAssociateHelpdesk} >
                                        <i className="fas fa-headset" /> <br />
                                        <div className='text-center'>Chamados</div>
                                    </button>
                                    <button className='btn btn-secondary' role='button' onClick={this.showUserModel} >
                                        <i className="fas fa-user" /> <br />
                                        <div className='text-center'>Usuários</div>
                                    </button>
                                    <button className='btn btn-warning text-white text-white' onClick={this.openModalUsersLogs}>
                                        <i className="fas fa-clipboard-list mx-2" /> <br />
                                        <div className='text-center'>Visualizar logs</div>
                                    </button>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className='col-lg-12'>
                                    <ListarEstacionamentos
                                        clienteId={this.props.match.params.id}
                                        OnDetailButton={this.onDetailButton}
                                        onShowImportModal={this.onShowImportModal}
                                        onForceRefresh={this.onForceRefresh}
                                        onShowLicence={this.openModalLicences}
                                        onEditPark={this.onEditPark}
                                        onLoadedPark={this.onLoadedPark}
                                        onSelectPark={this.onSelectPark}
                                        onShowSelectBillsAndNotes={true} />
                                </div>
                                <div className="col-lg-12">
                                    {
                                        this.state.estacionamentoId && <VisualizarEstacionamento key={this.state.random} estacionamentoId={this.state.estacionamentoId} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <>
                        <Dialog fullWidth maxWidth="lg" onClose={this.hideModalUsersLogs} open={this.state.isOpenModalUsersLogs}>
                            <MuiDialogContent>
                                <ListarAnalyticsBody key={this.state.id} clienteId={this.state.clienteId} />
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <button onClick={this.hideModalUsersLogs} className='btn btn-secondary m-3'>Fechar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog fullWidth maxWidth="lg" onClose={this.hideWhatsAppMessages} open={this.state.showWhatsAppMessagesModal}>
                            <MuiDialogContent>
                                <WhatsAppMessageListBody clienteId={this.state.clienteId} />
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <button onClick={this.hideWhatsAppMessages} className='btn btn-secondary m-3'>Fechar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog fullWidth maxWidth="md" open={this.state.showHelpDeskModal} onClose={(e) => this.setState({ showHelpDeskModal: false })}>
                            <MuiDialogContent>
                                <CadastrarChamado clienteId={this.props.match.params.id} />
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <button onClick={(e) => this.setState({ showHelpDeskModal: false })} className='btn btn-secondary m-3'>Fechar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth='lg' fullWidth open={this.state.isShowUserModal} onClose={this.hideUserModal}>
                            <MuiDialogTitle className='text-center'>
                                Usuários
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <ListarUsuarios clienteId={this.props.match.params.id} />
                            </MuiDialogContent>
                            <MuiDialogActions className='m-3'>
                                <button onClick={this.hideUserModal} className='btn btn-secondary'>Fechar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth='lg' fullWidth open={this.state.openModalLicences} onClose={this.hideModalLicences}>
                            <MuiDialogTitle className='text-center'>
                                Licenças
                            </MuiDialogTitle>
                            <MuiDialogContent>

                            </MuiDialogContent>
                            <MuiDialogActions className='m-3'>
                                <button onClick={this.hideModalLicences} className='btn btn-secondary'>Fechar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth={"sm"} open={this.state.isOpenInativeClient}>
                            <MuiDialogTitle className='text-center'>
                                Deseja mesmo desativar este cliente?
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                {
                                    <div className='row text-center'>
                                        <div className='col-lg-12'>
                                            <h5>Este cliente não sera mais listado e nenhum colaborador poderá mais fazer login</h5>
                                        </div>
                                    </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isOpenInativeClient: false })} className='btn btn-secondary'>Cancelar</button>
                                    <button onClick={(e) => { this.onUpdateStatus("Inativo") }} className='btn btn-danger'>Desativar</button>
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth={"md"} fullWidth open={this.state.showEditPark} onClose={e => this.setState({ showEditPark: false })}>
                            <MuiDialogContent>
                                <EditarEstacionamentoBody key={this.state.parkToEditSelected?.id} estacionamentoId={this.state.parkToEditSelected?.id} />
                            </MuiDialogContent>
                        </Dialog>
                        <Dialog maxWidth={"md"} onClose={(e) => this.setState({ isOpenRevendedorModal: false })} open={this.state.isOpenRevendedorModal}>
                            <MuiDialogTitle className='text-center'>
                                Selecionar revendedor
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <div className='col-md-12'>
                                    {
                                        this.state.allResellers.length > 0 ?
                                            <div className='table-responsive'>
                                                <table className="table table-striped ">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col"></th>
                                                            <th scope="col">Usuário</th>
                                                            <th scope="col">E-mail</th>
                                                            <th scope="col">Último acesso</th>
                                                            <th scope="col" width="10"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            this.state.allResellers.map(user =>
                                                                <tr key={user.id}>
                                                                    <td width={10}>
                                                                        {user.revendaId === "UhkTEfwE0GH4wpLpfZZm" && <span className="badge text-bg-success w-100">Dimep</span>}
                                                                        {user.revendaId === "feHh4buNASnnh6ahmYJ3" && <span className="badge text-bg-primary w-100">Madis</span>}
                                                                        {user.revendaId === "yDDlIMYGASii6Scv1Un8" && <span className="badge w-100" style={{ backgroundColor: "#44377b" }}>SelfParking</span>}
                                                                        {user.revendaId === "bjhYBaWdULPY9XO7K0VH" && <span className="badge w-100" style={{ backgroundColor: "#1f2021" }}>NeoTagus</span>}
                                                                    </td>
                                                                    <td>{user.nome}</td>
                                                                    <td>{user.email}</td>
                                                                    <td>{user.lastAccess ? Moment(user.lastAccess?.seconds * 1000).format("DD/MM/YYYY HH:mm") : "Nunca"}</td>
                                                                    <td>
                                                                        <a type="button" onClick={(e) => this.setUserToReseller(user)} className="btn btn-success">
                                                                            <i className="fas fa-user-plus" />
                                                                        </a>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        }
                                                    </tbody>
                                                </table>
                                            </div> :
                                            <>
                                                <EmptyContent text="Nenhum usuário cadastrado" />
                                            </>
                                    }
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <button onClick={(e) => this.setState({ isOpenRevendedorModal: false })} className='btn btn-secondary m-3'>Cancelar</button>
                            </MuiDialogActions>
                        </Dialog>
                        <Dialog maxWidth={"sm"} fullWidth onClose={(e) => this.setState({ isOpenChangeRevendaModal: false })} open={this.state.isOpenChangeRevendaModal}>
                            <MuiDialogContent>
                                <AlertDanger message="Confirmando esta mudança os dados abaixos serão alterados:">
                                    <div className='col-md-12'>
                                        <lu>
                                            <li>Revenda desta matriz;</li>
                                            <li>Revenda de todos os estabelecimentos deste cliente;</li>
                                            <li>Esta matriz ficará sem revendedor associado;</li>
                                        </lu>
                                    </div>
                                    <label>Selecionar nova revenda</label>
                                    <div className="input-group mb-3">
                                        <select className="form-select" value={this.state.revendaSelecionadaId} onChange={(e) => this.setState({ revendaSelecionadaId: e.target.value })} >
                                            <option value=''>Selecione</option>
                                            {this.state.revendasAssociadas.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
                                        </select>
                                    </div>
                                    <div className='btn-group'>
                                        <button onClick={(e) => this.setState({ isOpenChangeRevendaModal: false })} className='btn btn-secondary'>Cancelar</button>
                                        <button onClick={this.confirmChangeRevenda} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </AlertDanger>
                            </MuiDialogContent>
                        </Dialog>
                    </>
                    {
                        this.state.showNewParkModal && <CriarNovoEstacionamento clientId={this.props.match.params.id} onClose={(e) => this.setState({ showNewParkModal: false })} />
                    }
                    {
                        this.state.isOpenSupportModal && <SupportCenterRevendaModal clienteId={this.props.match.params.id} onClose={(e) => this.setState({ isOpenSupportModal: false })} />
                    }
                </NavBar >
            </>
        )
    }
}

export { ComboListarClientes, CadastrarCliente, VisualizarCliente, ListClientsSimple }