import { getEstacionamento, getRevenda, getUsuarioCompact } from '../../config/auth';
import firebase from '../../config/firebase';
import { docsToItem, docsToItems, docToItem } from '../transform.docs';
import { sendLogByUser } from '../../shared/analytics';
import { WhereField } from '../../shared/constants';
import { generateUUIDV7, isNullOrEmpty } from '../../shared/utils';

export const getCollectionBy = ({ collection }) => {
    return firebase.firestore().collection(collection)
}

export const getAllDocsByParkId = async ({ collection, estacionamentoId }) => {
    const docs = await firebase.firestore().collection(collection).where(WhereField.PARKID, "==", estacionamentoId).get()
    return docsToItems(docs)
}

export const getAllDocsByClientId = async ({ collection, clienteId }) => {
    const docs = await firebase.firestore().collection(collection).where(WhereField.CLIENTEID, "==", clienteId).get()
    return docsToItems(docs)
}

export const getAllDocsByRevendaId = async ({ collection, revendaId }) => {
    const docs = await firebase.firestore().collection(collection).where(WhereField.REVENDAID, "==", revendaId).get()
    return docsToItems(docs)
}

export const getAllDocsByCollection = async ({ collection }) => {
    const docs = await firebase.firestore().collection(collection).get()
    return docsToItems(docs)
}

export const getDocById = async ({ collection, id }) => {
    const result = await firebase.firestore().collection(collection).doc(id).get()
    return docToItem(result)
}

export const getAllDocsByQueries = async ({ collection, queries }) => {
    var q = firebase.firestore().collection(collection)
    for (const query of queries) {
        q = query
    }
    const docs = await q.get()
    return docsToItems(docs)
}

export const getDocByQuery = async ({ collection, query }) => {
    var q = firebase.firestore().collection(collection)
    q = query
    const docs = await q.get()
    return docsToItem(docs)
}

export const addDoc = async ({ collection, data }) => {
    if (!data.id) {
        const id = generateUUIDV7();
        data.id = id
    }
    const estacionamento = getEstacionamento()
    if (!data.clienteId) {
        data.clienteId = estacionamento?.clienteId
    }
    if (!data.estacionamentoId) {
        data.estacionamentoId = estacionamento?.id
    }
    if (!data.revendaId) {
        data.revendaId = estacionamento?.revendaId
    }
    if (!data.createdBy) {
        data.createdBy = getUsuarioCompact()
    }
    data.deviceIdentifier = generateUUIDV7()
    data.createdAt = new Date()
    data.isDeleted = false
    if (isNullOrEmpty(data.origin)) {
        data.origin = "admin"
    }
    try {
        await firebase.firestore().collection(collection).doc(data.id).set(data, { merge: true })
        return data
    } catch (error) {
        console.log(error);
        return null
    }
}

export const updateDoc = async ({ collection, id, data }) => {
    data.id = id
    data.updatedBy = getUsuarioCompact()
    data.updatedAt = new Date()
    data.deviceIdentifier = generateUUIDV7()
    if (!data.revendaId) {
        data.revendaId = getRevenda()?.id
    }
    if (data) {
        await firebase.firestore().collection(collection).doc(id).set(data, { merge: true })
    }
}

export const deleteDoc = async ({ collection, id }) => {
    const user = getUsuarioCompact()
    const data = await getDocById({ collection: collection, id: id })
    if (data) {
        try {
            data.isDeleted = true
            data.deletedBy = user
            data.deviceIdentifier = generateUUIDV7()
            data.deletedAt = new Date()
            sendLogByUser(collection, `Removeu o registro ${collection}/${id}`, data)
            await firebase.firestore().collection(collection).doc(id).delete()
            // updateDoc({ collection: collection, id: id, data: data })   
        } catch (error) {
            console.log(error);
        }
    }
}

export const restoreDoc = async ({ collection, id }) => {
    const user = getUsuarioCompact()
    const data = await getDocById({ collection: collection, id: id })
    data.isDeleted = false
    data.restoredBy = user
    data.restoredAt = new Date()
    sendLogByUser("Backup", `Restaurou o registro ${collection}/${id}`, data)
    await updateDoc({ collection: collection, id: id, data: data })
}