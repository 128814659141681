import { getEstacionamento } from "../../config/auth"
import { Collection } from "../../shared/constants";
import { addDoc, deleteDoc, getAllDocsByParkId, updateDoc } from "./collectionBaseWorker";

export const getAllCredentialsByParkId = async ({ estacionamentoId }) => {
    const docs = await getAllDocsByParkId({ collection: Collection.ACCREDITEDS, estacionamentoId: estacionamentoId })
    return docs
}

export const addCredential = async ({ data }) => {
    await addDoc({ collection: Collection.ACCREDITEDS, data: data })
}

export const updateCredential = async ({ id, data }) => {
    await updateDoc({ collection: Collection.ACCREDITEDS, id: id, data: data })
}

export const deleteCredentialById = async ({ id }) => {
    await deleteDoc({ collection: Collection.ACCREDITEDS, id: id })
}