
import React from 'react'
import { NavBar } from '../../../components/navbar/index'
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import { Loading, EmptyContent, ComboBoxCollection } from '../../../components/common/commons'
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import CurrencyInput from '../../../components/currencyInput/CurrencyInput'
import { allCharges, chargeDetail, chargeDelete, allChargesByDocument, createLicenceCharge, createNormalCharge } from '../../../shared/safe2paynew';
import Moment from 'moment';
import { showSuccessToast } from '../../../components/common/toast'
import { getParkById } from "../../../store/collections/parkWorker"
import { getLicencaByParkId } from "../../../store/collections/licencaWorker"
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import TextField from '@mui/material/TextField';
import { sendWhatsApp } from '../../../store/collections/queueMailingWorker';
import { copyLinkAndShare, dangerConfirm, documentMask, goToNewWindow, reloadWithAlert, toastError, toastSuccess, toastWarning, toCurrency } from '../../../shared/utils';

class RevendaFinanceiroGerenciarCobrancas extends React.Component {

    render() {
        return (
            <>
                <NavBar>
                    <RevendaFinanceiroGerenciarCobrancasBody />
                </NavBar>
            </>)
    }
}

class RevendaFinanceiroGerenciarCobrancasBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            isLoadingNewCharge: false,
            isOpenChargeModal: false,
            isLoadingModal: true,
            isOpenGenerateBill: false,
            estacionamentoId: "",
            estacionamento: null,
            parkSelected: null,
            licenceDate: null,
            amount: 0.0,
            typeCharge: '',
            dueDate: new Moment(new Date()),
            licenceDate: new Moment(new Date()),
            notifyCustomer: false,
            isFromMatriz: false,
            list: []
        }
    }

    componentDidMount = async () => {
        await this.listAllCharges()
    }

    listAllCharges = async () => {
        this.setState({ isLoading: true })
        var result = null
        if (this.props.estacionamentoId !== undefined) {
            const park = await getParkById({ id: this.props.estacionamentoId })
            const document = park?.documento?.replaceAll(".", "").replaceAll("-", "").replaceAll("/", "") ?? ""
            if (document) {
                this.setState({ parkSelected: park })
                this.setState({ estacionamentoId: this.props.estacionamentoId })
                this.setState({ isFromMatriz: true })
                result = await allChargesByDocument(document)
            }
        } else {
            result = await allCharges()
        }
        if (result !== null && result.HasError === false) {
            const list = result.ResponseDetail.Objects
            this.setState({ list: list })
            this.setState({ isLoading: false })
        } else {
            reloadWithAlert("Verifique o documento deste estabelecimento e tente novamente.")
        }
    }

    renderStatus(status) {
        return (
            <>
                {status === "1" && <h5><span className="badge text-bg-warning btn-block my-2 text-white">Pendente</span></h5>}
                {status === "2" && <h5><span className="badge text-bg-secondary btn-block my-2 text-white">Processamento</span></h5>}
                {status === "3" && <h5><span className="badge text-bg-success btn-block my-2 text-white">Autorizado</span></h5>}
                {status === "5" && <h5><span className="badge text-bg-danger btn-block my-2 text-white">Em disputa</span></h5>}
                {status === "6" && <h5><span className="badge text-bg-danger btn-block my-2 text-white">Devolvido</span></h5>}
                {status === "7" && <h5><span className="badge text-bg-danger btn-block my-2 text-white">Baixado</span></h5>}
                {status === "8" && <h5><span className="badge text-bg-danger btn-block my-2 text-white">Recusado</span></h5>}
                {status === "11" && <h5><span className="badge text-bg-success btn-block my-2 text-white">Liberado</span></h5>}
                {status === "12" && <h5><span className="badge text-bg-warning btn-block my-2 text-white">Em cancelamento</span></h5>}
                {status === "13" && <h5><span className="badge text-bg-danger btn-block my-2 text-white">Chargeback</span></h5>}
                {status === "14" && <h5><span className="badge text-bg-warning btn-block my-2 text-white">Pré-Autorizado</span></h5>}
            </>
        )
    }

    generateBill = async () => {
        if (this.state.parkSelected === null) {
            toastWarning("Selecione o estacionamento que vai gerar a cobrança.")
            return
        }
        if (this.state.typeCharge === "") {
            toastWarning("Informe o tipo de cobrança.")
            return
        }
        if (this.state.amount === null || this.state.amount === 0.0) {
            toastWarning("Informe o valor da gerar a cobrança.")
            return
        }
        this.setState({ isLoadingModal: true })
        var result = null
        if (this.state.typeCharge === "MENSALIDADE") {
            result = await createLicenceCharge({
                parkId: this.state.parkSelected.id,
                licenceDate: this.state.licenceDate,
                amount: this.state.amount,
                dueDate: this.state.dueDate,
                notifyCustomer: this.state.notifyCustomer
            })
        } else if (this.state.typeCharge === "COBRANCA_MANUAL") {
            result = await createNormalCharge({
                parkId: this.state.parkSelected.id,
                amount: this.state.amount,
                dueDate: this.state.dueDate,
                notifyCustomer: this.state.notifyCustomer
            })
        }
        this.setState({ isLoadingModal: false })
        if (result.success) {
            this.setState({ chargeResult: result.data })
            this.listAllCharges()
        } else {
            toastError(result.message)
        }
    }

    showModalGenerateBill = async () => {
        this.setState({ isOpenGenerateBill: true })
        this.setState({ chargeResult: null })
        this.setState({ isLoadingModal: false })
    }

    onEstacionamentoSelected = async (item) => {
        this.setState({ parkSelected: item })
        const licence = await getLicencaByParkId({ parkId: item.id })
        this.setState({ licenceSelected: licence })
        this.setState({ licenceDate: licence?.vencimento?.toDate() })
    }

    changeAmount = (a, b, floatvalue) => {
        this.setState({ amount: floatvalue })
    }

    onChangeLicenceDate = (e) => {
        this.setState({ licenceDate: e })
    }

    onChangeDueDate = (e) => {
        this.setState({ dueDate: e })
    }

    showDetail = async (item) => {
        this.setState({ isOpenChargeModal: true })
        this.setState({ isLoadingModal: true })
        this.setState({ chargeResult: null })
        const detail = await chargeDetail(item.SingleSaleHash)
        this.setState({ selected: detail.ResponseDetail })
        this.setState({ selectedItem: item })
        this.setState({ isLoadingModal: false })
    }

    openBill = async () => {
        const url = this.state.selected.SingleSaleUrl
        window.open(
            url,
            '_blank'
        );
    }

    openParkDetail = async () => {
        goToNewWindow(`/revenda/cliente/${this.state.parkSelected?.clienteId}?e=${this.state.parkSelected?.id}`)
    }

    shareSelected = async () => {
        this.share(this.state.selectedItem)
    }

    isParkCompleted = () => {
        if (this.state.parkSelected?.endereco?.cep &&
            this.state.parkSelected?.endereco?.logradouro &&
            this.state.parkSelected?.endereco?.cidade &&
            this.state.parkSelected?.endereco?.estado &&
            this.state.parkSelected?.endereco?.numero &&
            this.state.parkSelected?.endereco?.bairro) {
            return true
        }
        return false
    }

    deleteSelected = async () => {
        this.delete(this.state.selectedItem)
    }

    delete = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo cancelar a cobrança do cliente ${item.Customer.Name}` })
        if (confirm) {
            await chargeDelete(item.SingleSaleHash)
            toastSuccess("Cobrança cancelada com sucesso!")
            this.listAllCharges()
        }
    }

    share = async (item) => {
        var url = this.state.selected?.SingleSaleUrl
        if (url === undefined) {
            const detail = await chargeDetail(item.SingleSaleHash)
            url = detail.ResponseDetail.SingleSaleUrl
        }
        copyLinkAndShare({
            title: item.Customer.Name,
            message: "Boleto de cobrança",
            url: url,
        })
    }

    render() {
        return (
            <>
                <div className="card">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Gerenciar cobranças
                        <div className='btn-group'>
                            <button type="button" onClick={() => { this.showModalGenerateBill() }} className="btn btn-sm btn-primary">
                                <i className="fas fa-file-invoice-dollar mx-2"></i>
                                Gerar cobrança avulsa
                            </button>
                        </div>
                    </div>
                    {
                        this.state.isLoading === false ?
                            <>
                                {
                                    this.state.list.length === 0 &&
                                    <EmptyContent text="Nenhuma cobrança avulsa encontrada para este estabelecimento." />
                                }
                                {
                                    this.state.list.length !== 0 &&
                                    <div className='row'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        {
                                                            this.props.estacionamentoId === undefined && <>
                                                                <td><strong>Cliente</strong></td>
                                                                <td><strong>Documento</strong></td>
                                                            </>
                                                        }
                                                        <td align='center'><strong>Valor</strong></td>
                                                        <td align='center'><strong>Pago em</strong></td>
                                                        <td align='center'><strong>Criado em</strong></td>
                                                        <td align='center'><strong>Status</strong></td>
                                                        <td width="80" align='right'></td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.list.map((item, index) =>
                                                            <tr key={index}>
                                                                {
                                                                    this.props.estacionamentoId === undefined && <>
                                                                        <td>{item.Customer.Name}</td>
                                                                        <td>{documentMask(item.Customer.Identity)}</td>
                                                                    </>
                                                                }
                                                                <td align='center'>
                                                                    {toCurrency(item.Amount)}
                                                                </td>
                                                                <td align='center'>
                                                                    {
                                                                        item.TransactionStatus.Code === "3" ? Moment(item.Payment.PaymentDate).format("DD/MM/YYYY [às] HH:mm") : <span>-</span>
                                                                    }
                                                                </td>
                                                                <td align='center'>{Moment(item.CreatedDate).format("DD/MM/YYYY [às] HH:mm")}</td>
                                                                <td align='center'>
                                                                    {this.renderStatus(item.TransactionStatus.Code)}
                                                                </td>
                                                                <td align='right'>
                                                                    <div className='btn-group'>
                                                                        <button type="button" className='btn btn-secondary' onClick={() => { this.showDetail(item) }} >
                                                                            <i className="fas fas fa-bars" />
                                                                        </button>
                                                                        <button type="button" className='btn btn-success' onClick={() => { this.share(item) }} >
                                                                            <i className="fas fa-share-alt" />
                                                                        </button>
                                                                        {
                                                                            item.TransactionStatus.Code === "1" &&
                                                                            <button type="button" className='btn btn-danger' onClick={() => { this.delete(item) }} >
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        }
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <Loading />
                    }
                </div>
                <Dialog onClose={(e) => this.setState({ isOpenChargeModal: false })} fullWidth maxWidth="sm" open={this.state.isOpenChargeModal}>
                    <MuiDialogTitle className='text-center'>
                        Detalhes da cobrança
                    </MuiDialogTitle>
                    {
                        this.state.isLoadingModal === false ? <>
                            <MuiDialogContent>
                                <div className='row'>
                                    <div className='col-lg-12'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Cliente
                                                <h5>
                                                    {this.state.selected?.Customer?.Name}
                                                </h5>
                                            </div>
                                            <div className='col-lg-6'>
                                                Documento
                                                <h5>
                                                    {documentMask(this.state.selected?.Customer?.Identity)}
                                                </h5>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                Valor
                                                <h5 className='text-success'>
                                                    {toCurrency(this.state.selected?.Amount)}
                                                </h5>
                                            </div>
                                            <div className='col-lg-12'>
                                                {this.renderStatus(this.state.selectedItem?.TransactionStatus?.Code)}
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Descrição da cobrança
                                                {
                                                    this.state.selected?.Products?.map(e => (
                                                        <h5>{e.Description}</h5>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isOpenChargeModal: false })} className='btn btn-secondary'>Fechar</button>
                                    <button onClick={(e) => this.shareSelected()} className='btn btn-success'>Copiar link</button>
                                    <button onClick={(e) => this.openBill()} className='btn btn-primary'>Visualizar</button>
                                    {
                                        this.state.selectedItem?.TransactionStatus?.Code === "1" &&
                                        <button onClick={(e) => this.deleteSelected()} className='btn btn-danger'>Cancelar</button>
                                    }
                                </div>
                            </MuiDialogActions>
                        </> : <Loading />
                    }
                </Dialog>

                <Dialog fullWidth maxWidth="sm" open={this.state.isOpenGenerateBill}>
                    <MuiDialogTitle className='text-center'>
                        Gerar cobrança
                    </MuiDialogTitle>
                    {
                        this.state.isLoadingModal === false ? <>
                            <MuiDialogContent>
                                {
                                    this.state.chargeResult !== null &&
                                    <div className='text-center'>
                                        <h5>Cobrança gerada com sucesso!</h5>
                                        {
                                            this.state.notifyCustomer === true ? <span>O cliente já foi notificado sobre a nova cobrança.</span> :
                                                <>
                                                    <span>Link de cobrança para encaminhar ao cliente</span>
                                                    <textarea rows={2} value={this.state.chargeResult.saleUrl} className="form-control mt-3" disabled />
                                                </>
                                        }
                                    </div>
                                }
                                {
                                    this.state.chargeResult === null &&
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            {
                                                this.state.isFromMatriz === false &&
                                                <div className='row'>
                                                    <ComboBoxCollection key={this.state.random}
                                                        id={this.state.estacionamentoId}
                                                        title='Selecionar estacionamento'
                                                        collection='estacionamentos'
                                                        onSelected={this.onEstacionamentoSelected} />
                                                </div>
                                            }
                                            {
                                                this.state.parkSelected !== null &&
                                                <div className='row'>
                                                    <div className='col-lg-12'>
                                                        <div className="alert alert-secondary">
                                                            <div className='row'>
                                                                <div className='col-lg-4'>
                                                                    <span>Representante</span>
                                                                    <h6>{this.state.parkSelected?.representante}</h6>
                                                                </div>
                                                                <div className='col-lg-4'>
                                                                    <span>Documento</span>
                                                                    <h6>{documentMask(this.state.parkSelected?.documento)}</h6>
                                                                </div>
                                                                <div className='col-lg-4'>
                                                                    <span>Celular</span>
                                                                    <h6>{this.state.parkSelected?.celular}</h6>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.isParkCompleted() &&
                                                                <div className='row'>
                                                                    <div className="col-lg-12">
                                                                        <label>Endereço</label>
                                                                        <h6>{this.state.parkSelected?.endereco?.logradouro}, {this.parkSelected?.endereco?.numero} - {this.parkSelected?.endereco?.cep} | {this.parkSelected?.endereco?.cidade}</h6>
                                                                    </div>
                                                                    {
                                                                        this.state.isFromMatriz === false &&
                                                                        <div className='col-lg-12 mt-3'>
                                                                            <button type="button" onClick={this.openParkDetail} className="btn btn-secondary">
                                                                                Visualizar estabelecimento
                                                                                <i className="fas fa-eye mx-2" />
                                                                            </button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                        {
                                                            (!this.isParkCompleted()) ? <>
                                                                <div className="alert alert-danger">
                                                                    <strong>Atenção:</strong> Antes de enviar qualquer cobrança para o cliente, é preciso ter todos os dados atualizados, como endereço, documento, celular e etc.
                                                                    {
                                                                        this.state.isFromMatriz === false &&
                                                                        <div className='row'>
                                                                            <div className='col-lg-12 mt-3'>
                                                                                <button type="button" onClick={this.openParkDetail} className="btn btn-danger">
                                                                                    Visualizar estabelecimento
                                                                                    <i className="fas fa-eye mx-2" />
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </> :
                                                                <>
                                                                    <div className='row'>
                                                                        <div className='col-lg-12'>
                                                                            <label>Tipo de cobrança</label>
                                                                            <select className="form-select" value={this.state.typeCharge} onChange={(e) => this.setState({ typeCharge: e.target.value })}>
                                                                                <option value="">Selecionar</option>
                                                                                <option value="COBRANCA_MANUAL">Cobrança manual</option>
                                                                                <option value="MENSALIDADE">Mensalidade</option>
                                                                            </select>
                                                                        </div>
                                                                        {
                                                                            this.state.typeCharge === "MENSALIDADE" && <>
                                                                                {
                                                                                    this.state.licenceSelected !== null &&
                                                                                    <div className="col-lg-12">
                                                                                        <div className="alert alert-warning mt-3">
                                                                                            <div className='row'>
                                                                                                <div className='col-lg-12'>
                                                                                                    {
                                                                                                        (new Date() <= this.state.licenceSelected?.vencimento?.toDate()) ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    this.state.licenceSelected?.plano === undefined ?
                                                                                                                        <>
                                                                                                                            Este estacionamento esta usando um plano gratuito que tem vencimento em
                                                                                                                        </> :
                                                                                                                        <>
                                                                                                                            Plano atual: {this.state.licenceSelected?.plano?.nome} A licença para este estacionamento vence em
                                                                                                                        </>
                                                                                                                }
                                                                                                                <strong>
                                                                                                                    {Moment(this.state.licenceSelected?.vencimento?.toDate()).format("[: ] DD/MM/YYYY")}
                                                                                                                </strong>
                                                                                                            </> :
                                                                                                            <>
                                                                                                                A licença deste estacionamento venceu em
                                                                                                                <strong>
                                                                                                                    {Moment(this.state.licenceSelected?.vencimento?.toDate()).format("[: ] DD/MM/YYYY")}
                                                                                                                </strong>
                                                                                                            </>
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-lg-12 mt-3">
                                                                                                <MobileDatePicker
                                                                                                    label="Data da nova licença após pagamento"
                                                                                                    inputFormat="DD/MM/yyyy"
                                                                                                    value={this.state.licenceDate}
                                                                                                    onChange={this.onChangeLicenceDate}
                                                                                                    renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className='row'>
                                                                        <div className="col-lg-12">
                                                                            <label>Valor da cobrança</label>
                                                                            <CurrencyInput value={this.state.amount && this.state.amount} prefix="R$" className='form-control' onChangeEvent={this.changeAmount} />
                                                                        </div>
                                                                        <div className="col-lg-12 mt-3">
                                                                            <MobileDatePicker
                                                                                label="Data limite para pagamento"
                                                                                inputFormat="DD/MM/yyyy"
                                                                                value={this.state.dueDate}
                                                                                onChange={this.onChangeDueDate}
                                                                                renderInput={(params) => <TextField {...params} size="small" className='form-control' />}
                                                                            />
                                                                        </div>
                                                                        <div className="col-lg-12">
                                                                            <div className="form-check form-switch my-2">
                                                                                <input className="form-check-input" id="cobrancaWhatsApp" checked={this.state.notifyCustomer} onChange={(e) => this.setState({ notifyCustomer: e.target.checked })} type="checkbox" />
                                                                                <label className="form-check-label" htmlFor="cobrancaWhatsApp">
                                                                                    Enviar cobrança via WhatsApp automaticamente
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            this.state.notifyCustomer && <>
                                                                                <div className="col-lg-12">
                                                                                    <div className="alert alert-info">
                                                                                        <strong>Modelo de mensagem que será enviada para o cliente:</strong> <p />
                                                                                        <span>Olá, aqui é da equipe SelfParking.<p />Acabamos de gerar uma cobrança para o seu estabelecimento, você pode consultar clicando no link abaixo:<p /><strong>LINK GERADO</strong><p />Ah, fique atento este boleto vence em DD/MM/YYYY <p />Até logo!</span>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </>
                                                        }
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                }
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isOpenGenerateBill: false })} className='btn btn-secondary'>Fechar</button>
                                    {
                                        this.isParkCompleted() && this.state.chargeResult === null &&
                                        <button onClick={(e) => this.generateBill()} className='btn btn-success'>Gerar cobrança</button>
                                    }
                                </div>
                            </MuiDialogActions>
                        </> : <Loading />
                    }
                </Dialog>
            </>)
    }

}

export { RevendaFinanceiroGerenciarCobrancas, RevendaFinanceiroGerenciarCobrancasBody }