import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { generateUUIDV7, reloadWithAlert, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess, toastWarning } from '../../shared/utils';
import { Loading } from '../../components/common/commons';
import { getAllParksByClientId } from '../../store/collections/parkWorker';
import Tooltip from '@mui/material/Tooltip';
import { addDoc } from '../../store/collections/collectionBaseWorker';
import { getUsuarioCompact } from '../../config/auth';
import { sendLogByUser } from '../analytics';

class DuplicateDataButton extends React.Component {

	constructor(props) {
		super(props)
		const item = props.item
		this.state = {
			selectAll: true,
			isShowingModal: false,
			estacionamentoId: item.estacionamentoId,
			clienteId: item.clienteId,
			item: item,
			collection: props.collection,
			message: props.message
		}
	}

	loadData = async () => {
		toastLoading("Buscando estabelecimentos...")
		const parks = await getAllParksByClientId({ clientId: this.state.clienteId })
		toastDismissLoading()
		if (parks.length === 1) {
			toastWarning("Não é possível duplicar este item, pois existe apenas um estabelecimento.")
			return
		}
		this.setState({
			parks: parks.filter(e => e.id !== this.state.estacionamentoId).map(e => {
				return {
					...e,
					isChecked: true
				}
			}),
		})
		this.setState({ isShowingModal: true, state: "fill" })
	}

	changeItem = (item, isChecked) => {
		item.isChecked = isChecked
		this.setState({ parks: this.state.parks })
		this.setState({ selectAll: this.state.parks.filter(e => e.isChecked).length === this.state.parks.length })
	}

	changeAll = (isChecked) => {
		this.setState({ selectAll: isChecked })
		this.setState({
			parks: this.state.parks.map(e => {
				e.isChecked = isChecked
				return e
			})
		})
	}

	onClose = () => {
		this.setState({ isShowingModal: false })
	}

	confirm = async () => {
		const parks = this.state.parks.filter(e => e.isChecked)
		if (parks.length === 0) {
			toastWarning("Selecione pelo menos um estabelecimento para duplicar o item.")
			return
		}
		this.setState({ state: "processing" })
		var item = this.state.item
		const groupId = generateUUIDV7()
		const user = getUsuarioCompact()
		for (const park of parks) {
			const nome = takeIfIsNotNullOrEmpty(park.nomeInterno) ?? park.nome
			this.setState({ messageMigration: `Duplicando item no estabelecimento ${nome}...` })
			sendLogByUser("Duplicar", `Duplicado o registro ${this.state.collection}/${item.id} no estabelecimento ${nome}`, item)
			item.id = generateUUIDV7()
			item.estacionamentoId = park.id
			item.groupId = groupId
			item.createdAt = new Date()
			//Se tiver o objeto usuário
			if (item.createdBy?.email) {
				item.createdBy = user
			} else {
				//Provavelmente é um email salvo
				item.createdBy = user.email
			}
			await addDoc({ collection: this.state.collection, data: item })
		}
		toastDismissLoading()
		reloadWithAlert("Itens duplicados com sucesso!")
	}

	render() {
		return (
			<>
				<Tooltip title="Duplicar" placement="top">
					<button type="button" onClick={() => { this.loadData() }} className="btn btn-danger text-white">
						<i className="fas fa-copy" />
					</button>
				</Tooltip>
				{
					this.state.isShowingModal &&
					<Dialog maxWidth={"xs"} fullWidth open={this.state.isShowingModal}>
						{
							this.state.state === "loading" &&
							<MuiDialogContent>
								<Loading />
							</MuiDialogContent>
						}
						{
							this.state.state === "processing" &&
							<MuiDialogContent className='text-center'>
								<h5>{this.state.messageMigration}</h5>
								<Loading />
							</MuiDialogContent>
						}
						{
							this.state.state === "fill" && <>
								<MuiDialogTitle className='text-center'>
									{this.state.message}
								</MuiDialogTitle>
								<MuiDialogContent>
									<table className='table table-responsive'>
										<tbody>
											<tr>
												<td align='left' width={10}>
													<div className="form-check form-switch">
														<input className="form-check-input" id="selectAll" checked={this.state.selectAll} onChange={(e) => this.changeAll(e.target.checked)} type="checkbox" />
													</div>
												</td>
												<td><strong>Selecionar todos</strong></td>
											</tr>
											{
												this.state.parks.map(item => {
													return <tr key={item.id}>
														<td align='left' width={10}>
															<div className="form-check form-switch">
																<input className="form-check-input" id="exportFieldType" checked={item.isChecked ?? false} onChange={(e) => this.changeItem(item, e.target.checked)} type="checkbox" value={item.id} />
															</div>
														</td>
														<td>{takeIfIsNotNullOrEmpty(item.nomeInterno) ?? item.nome}</td>
													</tr>
												})
											}
										</tbody>
									</table>
								</MuiDialogContent>
								<MuiDialogActions>
									<div className="btn-group m-3">
										<button onClick={(e) => this.onClose()} className='btn btn-secondary'>Cancelar</button>
										<button onClick={(e) => this.confirm()} className='btn btn-danger'>Confirmar</button>
									</div>
								</MuiDialogActions>
							</>
						}
					</Dialog>
				}
			</>
		)
	}
}

export { DuplicateDataButton }