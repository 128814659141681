import React from 'react'
import { getRevendaById } from "../../../../store/collections/revendaWorker.js"
import { NavBar } from '../../../../components/navbar'
import { goToWindow, queryString, toastWarning } from '../../../../shared/utils.js';
import { fetchRest } from '../../../../shared/function-request.js';
import { Loading } from '../../../../components/common/commons.js';

class RevendaAuthRDStation extends React.Component {

    constructor(props) {
        super(props)
        this.authRDStation();
    }

    authRDStation = async () => {
        const code = queryString("code")
        const state = queryString("state")
        if (code && state) {
            const revenda = await getRevendaById({ id: state })
            if (revenda) {
                // const options = {
                //     method: 'POST',
                //     url: `https://api.rd.services/auth/token?token_by=${code}`,
                //     headers: { accept: 'application/json', 'content-type': 'application/json' },
                //     body: JSON.stringify({
                //         client_id: 'f11d5f81-7527-45b9-a985-55268e11ccbf',
                //         client_secret: 'CLIENT_SECRET',
                //         code: code
                //     })
                // };
                // const result = await fetchRest(options)
                // console.log(result);
                // alert(result)
                // if (!result.errors) {
                //     const data = {
                //         rdstationToken: rdstationToken.data.token
                //     }
                //     await updateRevenda({ id: revenda.id, data: data })
                // }
                // toastSuccess("Token do RDStation autenticado com sucesso!")
                // goToWindow(`/revenda/detalhes/${revenda.id}`)
            } else {
                toastWarning("Revenda não encontrada")
            }
        } else {
            goToWindow("/")
        }
    }

    render() {
        return (
            <NavBar>
                <div className="row">
                    <h5>Autenticando com RDStation...</h5>
                    <Loading />
                </div>
            </NavBar>
        )
    }
}

export { RevendaAuthRDStation }