import React from 'react'
import { NavBar } from '../../../components/navbar'
import firebase from '../../../config/firebase';
import 'firebase/auth';
import 'firebase/storage';
import { Loading, EmptyContent } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Tooltip from '@mui/material/Tooltip';
import { dangerConfirm, delay, goToNewWindow, isJSON, normalizedField, queryString, reloadWindow, reloadWithAlert, setPathWindow, takeIfIsNotNullOrEmpty, toastDismissLoading, toastError, toastLoading, toastSuccess, toastWarning, warningConfirm } from '../../../shared/utils';
import { deleteDoc, updateDoc } from '../../../store/collections/collectionBaseWorker';
import { Collection } from '../../../shared/constants';
import { docsToItems } from '../../../store/transform.docs';
import { fetchFunction } from '../../../shared/function-request';
import ReactJson from 'react-json-view'
import { AlertDanger, AlertSecondary, AlertSuccess } from '../../../shared/alert-manager';
import { deleteQueuesById, getAllQueuesByDate } from '../../../store/collections/queueWorker';

class QueueCallbackCards extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            state: "loading",
            random: Math.random(),
            items: []
        }
        this.load()
    }

    load = async () => {
        getAllQueuesByDate({ days: 1 }).then(items => {
            this.setState({ inQueue: items.filter(e => e.status === "in-queue") })
            this.setState({ processing: items.filter(e => e.status === "processing") })
            this.setState({ success: items.filter(e => e.status === "processed") })
            this.setState({ error: items.filter(e => e.status === "error") })
            this.setState({ state: "fill" })
        })
    }

    showItems = (items, showProcessButton) => {
        this.setState({ random: Math.random() })
        this.setState({ items: items })
        this.setState({ showProcessButton: showProcessButton })
        this.setState({ isOpenModal: true })
    }

    processAll = async () => {
        var confirm = await warningConfirm({ message: `Deseja mesmo processar os itens da lista?` });
        if (confirm) {
            this.setState({ isOpenModal: false })
            toastLoading("Processando itens da fila...")
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: {
                    ids: this.state.items.map(e => e.id)
                }
            }
            await fetchFunction("/callbackQueue/executeAllByIds", options)
            toastDismissLoading()
            reloadWithAlert("Itens enviados para processamento.")
        }
    }

    removeAll = async () => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo apagar todos os itens da lista?` });
        if (confirm) {
            for (let index = 0; index < this.state.items.length; index++) {
                const item = this.state.items[index];
                toastLoading(`Apagando ${(index + 1)} de ${this.state.items.length}`)
                await deleteQueuesById({ id: item.id })
            }
            this.setState({ isOpenModal: false })
            toastDismissLoading()
            reloadWithAlert("Itens removidos com sucesso.")
        }
    }

    render() {
        return (
            <>
                {
                    this.state.state === "fill" && <>
                        <div className='col-lg-2' role='button' onClick={(e) => this.showItems(this.state.inQueue, true)}>
                            <div className="card text-light bg-secondary mb-3">
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Na fila</h5>
                                    <h3 className="card-text text-center text-white">
                                        {this.state.inQueue?.length}
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">itens na fila</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-2' role='button' onClick={(e) => this.showItems(this.state.error, true)}>
                            <div className="card text-light bg-danger mb-3">
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Erro</h5>
                                    <h3 className="card-text text-center text-white">
                                        {this.state.error?.length}
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">itens com erro</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3' role='button' onClick={(e) => this.showItems(this.state.processing, true)}>
                            <div className="card text-light bg-warning mb-3">
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Processando</h5>
                                    <h3 className="card-text text-center text-white">
                                        {this.state.processing?.length}
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">Itens em processamento</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3' role='button' onClick={(e) => this.showItems(this.state.success)}>
                            <div className="card text-light bg-success mb-3">
                                <div className="card-body">
                                    <h5 className="card-title text-center text-white">Sucesso</h5>
                                    <h3 className="card-text text-center text-white">
                                        {this.state.success?.length}
                                    </h3>
                                    <div className="text-center">
                                        <small className="card-text text-white">Itens processados com sucesso</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                <Dialog maxWidth={'md'} fullWidth onClose={(e) => this.setState({ isOpenModal: false })} open={this.state.isOpenModal}>
                    <MuiDialogContent>
                        <QueueCallbackBody items={this.state.items} key={this.state.random} showProcessButton={this.state.showProcessButton} />
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isOpenModal: false })} className='btn btn-secondary'>Fechar</button>
                            {
                                this.state.showProcessButton && this.state.items.length > 0 &&
                                <>
                                    <button onClick={(e) => this.removeAll()} className='btn btn-danger'>
                                        Apagar
                                    </button>
                                    <button onClick={(e) => this.processAll()} className='btn btn-warning text-white'>
                                        Processar novamente
                                    </button>
                                </>
                            }
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </>
        )
    }
}

class QueueCallbackBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: props.items,
            auxList: props.items,
            showProcessButton: props.showProcessButton,
            value: ""
        }
    }

    renderStatus(status) {
        return (
            <>
                {status === 'in-queue' && <h5><span className="badge text-bg-secondary btn-block text-white my-2 w-100">Na fila</span></h5>}
                {status === 'processing' && <h5><span className="badge text-bg-warning btn-block text-white my-2 w-100">Processando</span></h5>}
                {status === 'processed' && <h5><span className="badge text-bg-success btn-block text-white my-2 w-100">Processada</span></h5>}
                {status === 'error' && <h5><span className="badge text-bg-danger btn-block text-white my-2 w-100">Erro</span></h5>}
            </>
        )
    }

    showRetryModal = (item) => {
        this.setState({ log: JSON.stringify(item, undefined, 4) })
        if (item.errorLog !== undefined) {
            this.setState({ errorLog: JSON.stringify(item.errorLog, undefined, 4) })
        } else {
            this.setState({ errorLog: null })
        }
        this.setState({ currentItem: item })
        this.setState({ isShowRetry: true })
    }

    forceRetry = async () => {
        this.setState({ isShowRetry: false })
        const item = this.state.currentItem
        const data = {
            status: 'in-queue'
        }
        await updateDoc({ collection: Collection.CALLBACK_QUEUE, id: item.id, data: data })
        const options = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: {
                id: item.id
            }
        }
        toastLoading("Re-enviando processo...")
        const result = await fetchFunction("/callbackQueue/executeById", options)
        toastDismissLoading()
        if (result.success) {
            toastSuccess(result.message)
            this.load()
        } else {
            toastError(result.message)
        }
    }

    showDetail = (item) => {
        var pretty = JSON.stringify(item, undefined, 4);
        this.setState({ currentItem: item })
        this.setState({ log: pretty })
        this.setState({ logType: "out" })
        this.setState({ isShowLog: true })
    }

    showResultDetail = (item) => {
        var pretty = JSON.stringify(item.result, undefined, 4);
        this.setState({ log: pretty })
        this.setState({ logType: "in" })
        this.setState({ isShowLog: true })
    }

    removeDetail = async (item) => {
        const items = this.state.items.filter(e => e.id !== item.id)
        this.setState({ items: items })
        this.setState({ auxList: items })
        await deleteDoc({ collection: Collection.CALLBACK_QUEUE, id: item.id })
        if (items.length === 0) {
            reloadWindow()
        }
    }

    removeItems = async () => {
        var confirm = await dangerConfirm({ message: `Confirma mesmo remover todos os itens da lista?` });
        if (confirm) {
            toastWarning(`Removendo os ${this.state.items.length} itens da lista...`)
            for (const item of this.state.items) {
                await deleteDoc({ collection: Collection.CALLBACK_QUEUE, id: item.id })
            }
            reloadWithAlert("Itens removidos com sucesso.")
        }
    }

    search = (e) => {
        const value = e.target.value?.toUpperCase()
        this.setState({ value: value })
        if (value === "") {
            this.setState({ items: this.state.auxList })
        } else {
            const result = this.state.auxList.filter(e => e.id?.toUpperCase().includes(value) === true ||
                e.origin?.toUpperCase().includes(value) === true ||
                e.createdBy?.nome?.toUpperCase().includes(value) === true ||
                e.createdBy?.email?.toUpperCase().includes(value) === true)
            this.setState({ items: result })
        }
    }

    render() {
        return (<>
            {
                this.state.items.length === 0 && this.state.value === "" ?
                    <EmptyContent text='Nenhum item na fila com este filtro' />
                    :
                    <>
                        <div className='card-body'>
                            <div className='col-lg-12'>
                                <input type="text" placeholder='Busque por id ou origem...' onChange={this.search} className="form-control form-control-lg" />
                            </div>
                        </div>
                        {
                            this.state.items.length === 0 ? <EmptyContent text='Nenhum item na fila com este filtro' /> :
                                <div className='table-responsive mt-2'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td width="10"></td>
                                                <td><strong>Origem</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Operador</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Data</strong></td>
                                                <td className="d-none d-sm-table-cell" align='center'><strong>Tentativas</strong></td>
                                                <td width="10"></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.items.map(item =>
                                                    <tr key={item.id}>
                                                        <td>
                                                            <Tooltip title={item.id} placement='top'>
                                                                <span>{this.renderStatus(item.status)}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td>{item.origin}</td>
                                                        <td align='center'>
                                                            <Tooltip title={item.createdBy?.email} placement='top'>
                                                                <span>{takeIfIsNotNullOrEmpty(item.createdBy?.nome) ?? "-"}</span>
                                                            </Tooltip>
                                                        </td>
                                                        <td className="d-none d-sm-table-cell" align='center'>{Moment(item.updatedAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                        <td className="d-none d-sm-table-cell" align='center'>
                                                            {
                                                                !item.times ? <span>1</span> : <span>{item.times}</span>
                                                            }
                                                        </td>
                                                        <td align='right'>
                                                            <div className="btn-group">
                                                                {
                                                                    (item.status !== 'processed') &&
                                                                    <Tooltip title="Enviar novamente" placement='top'>
                                                                        <button type="button" onClick={() => { this.showRetryModal(item) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-sync" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip title="Dados enviados" placement='top'>
                                                                    <button type="button" onClick={() => { this.showDetail(item) }} className="btn btn-secondary">
                                                                        <i className="fas fa-upload" />
                                                                    </button>
                                                                </Tooltip>
                                                                {
                                                                    item.result &&
                                                                    <Tooltip title="Dados recebidos" placement='top'>
                                                                        <button type="button" onClick={() => { this.showResultDetail(item) }} className={item.status === "error" ? "btn btn-danger" : "btn btn-success"}>
                                                                            <i className="fas fa-download" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    item.body?.ticketId &&
                                                                    <Tooltip title="Visualizar ticket" placement='top'>
                                                                        <button type="button" onClick={() => { goToNewWindow(`/buscar/ticket/?id=${item.body?.ticketId}&tab=suporte`) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-ticket-alt" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    item.body?.cashierId &&
                                                                    <Tooltip title="Visualizar caixa" placement='top'>
                                                                        <button type="button" onClick={() => { goToNewWindow(`/caixas/${item.body?.cashierId}`) }} className="btn btn-warning text-white">
                                                                            <i className="fas fa-money-bill-wave" />
                                                                        </button>
                                                                    </Tooltip>
                                                                }
                                                                {
                                                                    item.body?.clienteId &&
                                                                    <Tooltip title="Visualizar matriz" placement='top'>
                                                                        {
                                                                            item.body?.estacionamentoId ?
                                                                                <a href={`/revenda/cliente/${item.body.clienteId}?e=${item.body.estacionamentoId}`} target="_blank" className="btn btn-primary" rel="noreferrer">
                                                                                    <i className="fas fa-eye" />
                                                                                </a> :
                                                                                <a href={`/revenda/cliente/${item.body.clienteId}`} target="_blank" className="btn btn-primary" rel="noreferrer">
                                                                                    <i className="fas fa-eye" />
                                                                                </a>
                                                                        }
                                                                    </Tooltip>
                                                                }
                                                                <Tooltip title="Remover item" placement='top'>
                                                                    <button type="button" onClick={() => { this.removeDetail(item) }} className="btn btn-danger">
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                </Tooltip>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                        }
                    </>
            }
            <Dialog maxWidth={'lg'} onClose={(e) => this.setState({ isShowRetry: false })} open={this.state.isShowRetry}>
                <MuiDialogTitle className='text-center'>
                    Deseja mesmo executar novamente o processo abaixo?
                </MuiDialogTitle>
                <MuiDialogContent>
                    <div className='row'>
                        {
                            this.state.errorLog ? <>
                                <div className='col-lg-6'>
                                    <AlertSuccess tag="Dado enviado">
                                        <div className='mt-2'>
                                            {
                                                <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.log)} enableClipboard={false} />
                                            }
                                        </div>
                                    </AlertSuccess>
                                </div>
                                <div className='col-lg-6'>
                                    <AlertDanger tag="Dado recebido">
                                        <div className='mt-2'>
                                            {
                                                isJSON(this.state.errorLog) ?
                                                    <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.errorLog)} enableClipboard={false} /> :
                                                    <span>{this.state.errorLog}</span>
                                            }
                                        </div>
                                    </AlertDanger>
                                </div>
                            </> :
                                <div className='col-lg-12'>
                                    {
                                        this.state.log &&
                                        <AlertSuccess tag="Dado enviado">
                                            <div className='mt-2'>
                                                {
                                                    <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.log)} enableClipboard={false} />
                                                }
                                            </div>
                                        </AlertSuccess>
                                    }
                                </div>
                        }
                    </div>
                    <div className='my-3'>
                        <h5>Origem: {this.state.currentItem?.origin}</h5>
                    </div>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='btn-group m-3'>
                        <button onClick={(e) => this.setState({ isShowRetry: false })} className='btn btn-secondary'>Cancelar</button>
                        <button onClick={(e) => this.forceRetry()} className='btn btn-danger'>Confirmar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
            <Dialog maxWidth="md" fullWidth onClose={(e) => this.setState({ isShowLog: false })} open={this.state.isShowLog}>
                <MuiDialogTitle className='text-center'>
                    Detalhes do log
                </MuiDialogTitle>
                <MuiDialogContent>
                    <AlertSecondary tag={this.state.logType === "out" ? "Dado enviado" : "Dado recebido"}>
                        <div className='mt-2'>
                            {
                                isJSON(this.state.log) ?
                                    <ReactJson name={false} displayDataTypes={false} src={JSON.parse(this.state.log)} enableClipboard={false} /> :
                                    <span>{this.state.log}</span>
                            }
                        </div>
                    </AlertSecondary>
                </MuiDialogContent>
                <MuiDialogActions>
                    <div className='m-3'>
                        <button onClick={(e) => this.setState({ isShowLog: false })} className='btn btn-secondary'>Fechar</button>
                    </div>
                </MuiDialogActions>
            </Dialog>
        </>)
    }
}

class QueueCallback extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            queues: [],
            isShowLog: false,
            log: '',
            type: queryString("type") ?? 'processed',
            limit: queryString("limit") ?? 30
        }
    }

    componentDidMount = async () => {
        await this.load()
    }

    load = async () => {
        this.setState({ isLoading: true })
        var query = firebase.firestore().collection('callback-queue')
        if (this.state.type !== '') {
            query = query.where("status", "==", this.state.type)
        }
        const result = await query.orderBy("createAt", "desc").limit(parseInt(this.state.limit)).get()
        const docs = docsToItems(result)
        this.setState({ queues: docs.sort((a, b) => a.status?.localeCompare(b.status) || b.createAt.toDate() - a.createAt.toDate()) })
        this.setState({ isLoading: false })
    }

    changeURL = () => {
        const type = this.state.type
        const limit = this.state.limit
        setPathWindow(`/desenvolvimento/filas-de-callback/?type=${type}&limit=${limit}`)
    }

    changeType = (e) => {
        this.setState({ type: e.target.value }, async () => {
            this.changeURL()
            await this.load()
        })
    }

    changeLimit = (e) => {
        this.setState({ limit: e.target.value }, async () => {
            this.changeURL()
            await this.load()
        })
    }

    removeItems = async () => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover os items abaixo?` });
        if (confirm) {
            toastLoading("Removendo filas de processamento...")
            for (const item of this.state.queues) {
                await deleteQueuesById({ id: item.id })
            }
            toastDismissLoading()
            this.load()
        }
    }

    render() {
        return (
            <NavBar>
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        Filas de processamentos
                        <div className='col-lg-4'>
                            <div className='row'>
                                <div className='col-lg-5'>
                                    <select className='form-select form-select-sm' value={this.state.limit} onChange={this.changeLimit}>
                                        <option value={30}>30 registros</option>
                                        <option value={50}>50 registros</option>
                                        <option value={100}>100 registros</option>
                                        <option value={200}>200 registros</option>
                                        <option value={1000}>1000 registros</option>
                                        <option value={10000}>10000 registros</option>
                                    </select>
                                </div>
                                <div className='col-lg-5'>
                                    <select className='form-select form-select-sm' value={this.state.type} onChange={this.changeType}>
                                        <option value=''>Todas</option>
                                        <option value='in-queue'>Na fila</option>
                                        <option value='processed'>Processadas</option>
                                        <option value='error'>Error</option>
                                    </select>
                                </div>
                                {
                                    (this.state.type !== "processed") ?
                                        <div className='col-lg-2'>
                                            <button onClick={(e) => this.removeItems()} className="btn btn-danger w-100 btn-sm">
                                                <i className="fas fa-trash" />
                                            </button>
                                        </div> :
                                        <div className='col-lg-2'>
                                            <button onClick={(e) => toastWarning("Não é possível remover itens processados.")} className="btn btn-secondary w-100 btn-sm">
                                                <i className="fas fa-trash" />
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                    {
                        this.state.isLoading === false && this.state.queues.length > 0 ?
                            <QueueCallbackBody items={this.state.queues} key={this.state.queues.length} /> :
                            <Loading />
                    }
                </div>
            </NavBar >)
    }
}


export { QueueCallback, QueueCallbackCards, QueueCallbackBody };