import React from 'react';
import { NavBar } from '../../../components/navbar'
import 'firebase/auth';
import 'firebase/storage';
import firebase from '../../../config/firebase';
import { Loading, EmptyContent } from '../../../components/common/commons'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { addUser, getAllUsersByAssociatedRessales } from '../../../store/collections/userWorker';
import NumberFormat from 'react-number-format';
import Tooltip from '@mui/material/Tooltip';
import { getRevenda, getUsuario, isPlatformOwner } from '../../../config/auth';
import { dangerConfirm, reloadWithAlert, toastError, toastWarning } from '../../../shared/utils';
import moment from 'moment';
import { getRevendaById } from '../../../store/collections/revendaWorker';
import Select from 'react-select';

class RevendedoresRevenda extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoading: true,
            isOpenModal: false,
            state: "loading",
            items: [],
            allUsers: [],
            platformOwner: isPlatformOwner(),
            formaCobranca: getRevenda()?.formaCobranca,
            acessoRevendedorCliente: true,
            acessoRevendedorRevendedores: false,
            acessoRevendedorCentralSuporte: false,
            acessoRevendedorConfiguracao: false,
            acessoRevendedorChamados: false,
            acessoRevendedorContratos: false,
            acessoRevendedorUsuarios: true,
            acessoRevendedorLogs: false,
            acessoRevendedorConsumo: false,
            acessoRevendedorCaixas: false,
            acessoRevendedorMensalistas: false,
            acessoRevendedorPlanos: false,
            acessoRevendedorAssinaturas: false,
            acessoRevendedorAssinaturas: false,
            acessoRevendedorNotasFiscais: false,
            acessoRevendedorEmailNovoCliente: true,
            acessoRevendedorAlertaLinkAssinaturaGerado: true,
            acessoRevendedorMensagemAssinaturaClienteCliente: false,
            acessoRevendedorCobrancas: false,
            acessoRevendedorVersaoMinima: false,
            acessoRevendedorDesenvolvedorErroApi: false,
            acessoRevendedorMarcasEModelos: false,
            acessoRevendedorAplicacoes: false,
            acessoRevendedorBackup: false,
            acessoRevendedorClienteAssociarSuporte: false,
            acessoRevendedorClienteAssociarRevendedor: false,
            acessoRevendedorPermissao: "ASSOCIADOS",
            acessoRevendedorDesenvolvedor: false,
            allRevendas: []
        }
        this.loadAll()
    }

    loadAll = async () => {
        this.setState({ state: "loading" })
        const revendasAssociadas = getUsuario().revendasAssociadas ?? []
        const users = await getAllUsersByAssociatedRessales({ associatedResales: revendasAssociadas })
        const results = users.sort((a, b) => (moment((b.lastAccess?.seconds ?? 0) * 1000)).toDate() - (moment((a.lastAccess?.seconds ?? 0) * 1000)).toDate())
        this.setState({ items: results.filter(e => e.revendedor === true).sort((a, b) => b.revendaId.localeCompare(a.revendaId)) })
        if (results.length > 0) {
            this.setState({ allUsers: results.filter(e => (e.revendedor === undefined || e.revendedor === false)) })
            this.setState({ state: "resselers" })
        } else {
            this.setState({ state: "empty" })
        }
        this._loadRevendas()
    }

    _loadRevendas = async () => {
        const user = getUsuario()
        const revendasAssociadas = user.revendasAssociadas ?? []
        if (revendasAssociadas.length > 0) {
            this.setState({ revendasAssociadasForm: revendasAssociadas })
        } else {
            const revenda = await getRevendaById({ id: user.revendaId })
            this.setState({
                revendasAssociadasForm: [{
                    value: revenda.id,
                    label: revenda.nome
                }]
            })
        }
    }

    setUserToReseller = async (user) => {
        this.setState({ currentUser: user })
        this.setState({ userName: user.nome })
        this.setState({ userCellphone: user.cellphone })
        this.setState({ email: user.email })
        this.setState({ showEmailField: false })
        this.setState({ state: "permissions" })
    }

    createNewUserAsResseler = () => {
        this.setState({ currentUser: null })
        this.setState({ userName: "" })
        this.setState({ userCellphone: "" })
        this.setState({ email: "" })
        this.setState({ showEmailField: true })
        this.setState({ state: "permissions" })
    }

    showModalToAddReseller = async () => {
        this.setState({ currentUser: null })
        this.setState({ isOpenModal: true })
    }

    onEdit = (user) => {
        this.setUserToReseller(user)
        this.setState({ acessoRevendedorDashboard: user.acessoRevendedorDashboard ?? false })
        this.setState({ acessoRevendedorCliente: user.acessoRevendedorCliente ?? false })
        this.setState({ acessoRevendedorRevendedores: user.acessoRevendedorRevendedores ?? false })
        this.setState({ acessoRevendedorCentralSuporte: user.acessoRevendedorCentralSuporte ?? false })
        this.setState({ acessoRevendedorConfiguracao: user.acessoRevendedorConfiguracao ?? false })
        this.setState({ acessoRevendedorChamados: user.acessoRevendedorChamados ?? false })
        this.setState({ acessoRevendedorContratos: user.acessoRevendedorContratos ?? false })
        this.setState({ acessoRevendedorLogs: user.acessoRevendedorLogs ?? false })
        this.setState({ acessoRevendedorConsumo: user.acessoRevendedorConsumo ?? false })
        this.setState({ acessoRevendedorCaixas: user.acessoRevendedorCaixas ?? false })
        this.setState({ acessoRevendedorMensalistas: user.acessoRevendedorMensalistas ?? false })

        this.setState({ acessoRevendedorPlanos: user.acessoRevendedorPlanos ?? false })
        this.setState({ acessoRevendedorAssinaturas: user.acessoRevendedorAssinaturas ?? false })
        this.setState({ acessoRevendedorNotasFiscais: user.acessoRevendedorNotasFiscais ?? false })
        this.setState({ acessoRevendedorEmailNovoCliente: user.acessoRevendedorEmailNovoCliente ?? false })
        this.setState({ acessoRevendedorAlertaLinkAssinaturaGerado: user.acessoRevendedorAlertaLinkAssinaturaGerado ?? false })
        this.setState({ acessoRevendedorMensagemAssinaturaClienteCliente: user.acessoRevendedorMensagemAssinaturaClienteCliente ?? false })

        this.setState({ acessoRevendedorCobrancas: user.acessoRevendedorCobrancas ?? false })
        this.setState({ acessoRevendedorVersaoMinima: user.acessoRevendedorVersaoMinima ?? false })
        this.setState({ acessoRevendedorDesenvolvedorErroApi: user.acessoRevendedorDesenvolvedorErroApi ?? false })
        this.setState({ acessoRevendedorMarcasEModelos: user.acessoRevendedorMarcasEModelos ?? false })
        this.setState({ acessoRevendedorAplicacoes: user.acessoRevendedorAplicacoes ?? false })
        this.setState({ acessoRevendedorBackup: user.acessoRevendedorBackup ?? false })
        this.setState({ acessoRevendedorUsuarios: user.acessoRevendedorUsuarios ?? false })
        this.setState({ acessoRevendedorDesenvolvedor: user.acessoRevendedorDesenvolvedor ?? false })

        this.setState({ acessoRevendedorPermissao: user.acessoRevendedorPermissao ?? "ASSOCIADOS" })

        this.setState({ acessoRevendedorClienteAssociarSuporte: user.acessoRevendedorClienteAssociarSuporte ?? false })
        this.setState({ acessoRevendedorClienteAssociarRevendedor: user.acessoRevendedorClienteAssociarRevendedor ?? false })
        this.setState({ revendasAssociadas: user.revendasAssociadas ?? [] })
        this.setState({ allRevendas: user.revendasAssociadas ?? this.state.revendasAssociadasForm })
        this.setState({ revendas: user.revendasAssociadas })
        this.setState({ random: Math.random() })
        this.setState({ isLoading: false })
        this.setState({ isOpenModal: true })
    }

    confirmUserToResseler = async () => {
        if (this.state.userName === "" || this.state.userCellphone === "") {
            toastWarning("Informe os dados do revendedor.")
            return
        }
        const user = this.state.currentUser
        if (user) {
            await this.updateNewUser(user)
        } else {
            await this.createNewUser()
        }
    }

    createNewUser = async () => {
        const result = await addUser({
            nome: this.state.userName,
            email: this.state.email,
            phone: this.state.userCellphone,
            password: this.state.password,
            revendaId: this.state.revendasAssociadas[0].value
        })
        if (result.success) {
            await this.updateNewUser({
                id: result.id
            })
        } else {
            toastError(result.message)
        }
    }

    updateNewUser = async (user) => {
        await firebase.firestore().collection('usuarios').doc(user.id).set({
            nome: this.state.userName,
            celular: this.state.userCellphone,
            revendedor: true,
            administrativo: true,
            acessoRevendedorDashboard: this.state.acessoRevendedorDashboard,
            acessoRevendedorCliente: this.state.acessoRevendedorCliente,
            acessoRevendedorRevendedores: this.state.acessoRevendedorRevendedores,
            acessoRevendedorCentralSuporte: this.state.acessoRevendedorCentralSuporte,
            acessoRevendedorConfiguracao: this.state.acessoRevendedorConfiguracao,
            acessoRevendedorChamados: this.state.acessoRevendedorChamados,
            acessoRevendedorContratos: this.state.acessoRevendedorContratos,
            acessoRevendedorUsuarios: this.state.acessoRevendedorUsuarios,
            acessoRevendedorLogs: this.state.acessoRevendedorLogs,
            acessoRevendedorConsumo: this.state.acessoRevendedorConsumo,
            acessoRevendedorCaixas: this.state.acessoRevendedorCaixas,
            acessoRevendedorMensalistas: this.state.acessoRevendedorMensalistas,
            acessoRevendedorPlanos: this.state.acessoRevendedorPlanos,
            acessoRevendedorAssinaturas: this.state.acessoRevendedorAssinaturas,
            acessoRevendedorNotasFiscais: this.state.acessoRevendedorNotasFiscais,
            acessoRevendedorEmailNovoCliente: this.state.acessoRevendedorEmailNovoCliente,
            acessoRevendedorAlertaLinkAssinaturaGerado: this.state.acessoRevendedorAlertaLinkAssinaturaGerado,
            acessoRevendedorMensagemAssinaturaClienteCliente: this.state.acessoRevendedorMensagemAssinaturaClienteCliente,
            acessoRevendedorCobrancas: this.state.acessoRevendedorCobrancas,
            acessoRevendedorVersaoMinima: this.state.acessoRevendedorVersaoMinima,
            acessoRevendedorDesenvolvedorErroApi: this.state.acessoRevendedorDesenvolvedorErroApi,
            acessoRevendedorMarcasEModelos: this.state.acessoRevendedorMarcasEModelos,
            acessoRevendedorAplicacoes: this.state.acessoRevendedorAplicacoes,
            acessoRevendedorBackup: this.state.acessoRevendedorBackup,
            acessoRevendedorPermissao: this.state.acessoRevendedorPermissao,
            acessoRevendedorClienteAssociarSuporte: this.state.acessoRevendedorClienteAssociarSuporte,
            acessoRevendedorClienteAssociarRevendedor: this.state.acessoRevendedorClienteAssociarRevendedor,
            revendasAssociadas: this.state.revendasAssociadas,
            acessoRevendedorDesenvolvedor: this.state.acessoRevendedorDesenvolvedor
        }, { merge: true })
        reloadWithAlert("Informações alteradas com sucesso!")
    }

    confirmRemoveUsertoResseler = async (user) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover este usuário dos acessos de revendedor?` })
        if (confirm) {
            await firebase.firestore().collection('usuarios').doc(user.id).set({
                revendedor: false,
                clienteId: null,
                estacionamentoId: null,
                administrativo: false,
                acessoRevendedorDashboard: false,
                acessoRevendedorCliente: false,
                acessoRevendedorRevendedores: false,
                acessoRevendedorCentralSuporte: false,
                acessoRevendedorConfiguracao: false,
                acessoRevendedorChamados: false,
                acessoRevendedorContratos: false,
                acessoRevendedorUsuarios: false,
                acessoRevendedorLogs: false,
                acessoRevendedorConsumo: false,
                acessoRevendedorCaixas: false,
                acessoRevendedorMensalistas: false,
                acessoRevendedorPlanos: false,
                acessoRevendedorAssinaturas: false,
                acessoRevendedorNotasFiscais: false,
                acessoRevendedorEmailNovoCliente: false,
                acessoRevendedorAlertaLinkAssinaturaGerado: false,
                acessoRevendedorMensagemAssinaturaClienteCliente: false,
                acessoRevendedorCobrancas: false,
                acessoRevendedorVersaoMinima: false,
                acessoRevendedorDesenvolvedorErroApi: false,
                acessoRevendedorMarcasEModelos: false,
                acessoRevendedorAplicacoes: false,
                acessoRevendedorBackup: false,
                acessoRevendedorClienteAssociarSuporte: false,
                acessoRevendedorClienteAssociarRevendedor: false,
                acessoRevendedorDesenvolvedor: false,
                acessoRevendedorPermissao: "ASSOCIADOS",
                revendasAssociadas: []
            }, { merge: true })
            reloadWithAlert("Informações alteradas com sucesso!")
        }
    }

    changePermission = (e) => {
        this.setState({ acessoRevendedorPermissao: e.target.value })
        this.setState({ acessoRevendedorDashboard: false })
        this.setState({ acessoRevendedorRevendedores: false })
        this.setState({ acessoRevendedorCentralSuporte: false })
        this.setState({ acessoRevendedorConfiguracao: false })
        this.setState({ acessoRevendedorChamados: false })
        this.setState({ acessoRevendedorContratos: false })
        this.setState({ acessoRevendedorUsuarios: false })
        this.setState({ acessoRevendedorLogs: false })
        this.setState({ acessoRevendedorConsumo: false })
        this.setState({ acessoRevendedorCaixas: false })
        this.setState({ acessoRevendedorMensalistas: false })
        this.setState({ acessoRevendedorPlanos: false })
        this.setState({ acessoRevendedorAssinaturas: false })
        this.setState({ acessoRevendedorNotasFiscais: false })
        this.setState({ acessoRevendedorCobrancas: false })
        this.setState({ acessoRevendedorVersaoMinima: false })
        this.setState({ acessoRevendedorDesenvolvedorErroApi: false })
        this.setState({ acessoRevendedorMarcasEModelos: false })
        this.setState({ acessoRevendedorAplicacoes: false })
        this.setState({ acessoRevendedorBackup: false })
        this.setState({ acessoRevendedorClienteAssociarRevendedor: false })
        this.setState({ acessoRevendedorClienteAssociarSuporte: false })
        this.setState({ acessoRevendedorDesenvolvedor: false })
    }

    handleRevendas = (selected) => {
        this.setState({ revendasAssociadas: selected })
    };

    render() {
        return (
            <>
                <NavBar>
                    <div className='row'>
                        <div className="col-lg-12">
                            <div className='card mb-4'>
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Revendedores
                                    <button type="button" onClick={this.showModalToAddReseller} className="btn btn-sm btn-warning text-white">
                                        <i className="fas fa-address-book mx-2" />
                                        Adicionar revendedor
                                    </button>
                                </div>
                                <div className='row'>
                                    {
                                        this.state.state !== "loading" ?
                                            <div className='col-md-12'>
                                                <div className='table-responsive'>
                                                    <table className="table table-striped ">
                                                        <thead>
                                                            <tr>
                                                                <th width={10}></th>
                                                                <th scope="col">Usuário</th>
                                                                <th scope="col">Celular</th>
                                                                <th scope="col">E-mail</th>
                                                                <th scope="col">Último acesso</th>
                                                                <th scope="col" width="10"></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                this.state.items.map(user =>
                                                                    <tr key={user.id}>
                                                                        <td>
                                                                            {user.revendaId === "UhkTEfwE0GH4wpLpfZZm" && <span className="badge text-bg-success w-100">Dimep</span>}
                                                                            {user.revendaId === "feHh4buNASnnh6ahmYJ3" && <span className="badge text-bg-primary w-100">Madis</span>}
                                                                            {user.revendaId === "yDDlIMYGASii6Scv1Un8" && <span className="badge w-100" style={{ backgroundColor: "#44377b" }}>SelfParking</span>}
                                                                            {user.revendaId === "bjhYBaWdULPY9XO7K0VH" && <span className="badge w-100" style={{ backgroundColor: "#1f2021" }}>NeoTagus</span>}
                                                                        </td>
                                                                        <td>{user.nome}</td>
                                                                        <td>{user.celular ?? "Não disponível"}</td>
                                                                        <td>{user.email}</td>
                                                                        <td>{user.lastAccess ? Moment(user.lastAccess?.seconds * 1000).format("DD/MM/YYYY HH:mm") : "-"}</td>
                                                                        <td>
                                                                            <div className='btn-group'>
                                                                                <Tooltip role="button" title="Editar revendedor" placement="top">
                                                                                    <button type="button" onClick={() => { this.onEdit(user) }} className="btn btn-secondary">
                                                                                        <i className="fas fa-edit" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                                <Tooltip role="button" title="Remover acesso" placement="top">
                                                                                    <a type="button" onClick={(e) => this.confirmRemoveUsertoResseler(user)} className="btn btn-warning text-white">
                                                                                        <i className="fas fa-user-times" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div> :
                                            <div className='col-md-12'>
                                                <Loading />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <Dialog fullWidth maxWidth={'sm'} open={this.state.isOpenModal}>
                        <MuiDialogTitle className='text-center'>
                            Configurar revendedor
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.state === "loading" && <Loading />
                            }
                            {
                                this.state.state === "empty" && <EmptyContent text="Nenhum usuário cadastrado até o momento." />
                            }
                            {
                                this.state.state === "resselers" &&
                                <div className='col-md-12'>
                                    <div className='alert alert-warning'>
                                        <h5>Selecione um usuário já ativo ou crie um novo.</h5>
                                        <button className='btn btn-warning text-white' onClick={this.createNewUserAsResseler}>Criar um novo usuário como revendedor</button>
                                    </div>
                                    <div className='table-responsive'>
                                        <table className="table table-striped ">
                                            <thead>
                                                <tr>
                                                    <th scope="col">E-mail</th>
                                                    <th scope="col">Último acesso</th>
                                                    <th scope="col" width="10"></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    this.state.allUsers.map(user =>
                                                        <tr key={user.id}>
                                                            <td>{user.email}</td>
                                                            <td>{user.lastAccess ? Moment(user.lastAccess.seconds * 1000).format("DD/MM/YYYY HH:mm") : "-"}</td>
                                                            <td>
                                                                <a type="button" onClick={(e) => this.setUserToReseller(user, true)} className="btn btn-success">
                                                                    <i className="fa-solid fa-arrow-right" />
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            }
                            {
                                this.state.state === "permissions" &&
                                <div className='col-md-12'>
                                    <div className='alert alert-warning'>
                                        <h5 className='text-center'>Dados do revendedor</h5>
                                        <div className='row'>
                                            <div className="col-lg-6">
                                                <label>Nome</label>
                                                <input type="text" onChange={(e) => this.setState({ userName: e.target.value })} value={this.state.userName} className="form-control" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Celular</label>
                                                <NumberFormat className="form-control" value={this.state.userCellphone} mask='_' onChange={(e) => this.setState({ userCellphone: e.target.value })} format="(##)#####-####" />
                                            </div>
                                        </div>
                                        {
                                            this.state.showEmailField ? <div className='row'>
                                                <div className="col-lg-6">
                                                    <label>E-mail</label>
                                                    <input type="email" onChange={(e) => this.setState({ email: e.target.value })} value={this.state.email} className="form-control" />
                                                </div>
                                                <div className="col-lg-6">
                                                    <label>Senha</label>
                                                    <input type="password" onChange={(e) => this.setState({ password: e.target.value })} value={this.state.password} className="form-control" />
                                                </div>
                                            </div> : <strong className='mt-2'>{this.state.currentUser?.email}</strong>
                                        }
                                    </div>
                                    <div className='alert alert-secondary'>
                                        <h5>Acessos</h5>
                                        <label>Visualizar estabelecimentos:</label>
                                        <select className="form-select" value={this.state.acessoRevendedorPermissao} onChange={(e) => this.changePermission(e)}>
                                            <option value="ASSOCIADOS">Apenas os associados ao revendedor</option>
                                            <option value="TODOS">Todos os da revenda</option>
                                        </select>
                                        {
                                            this.state.acessoRevendedorPermissao === "TODOS" ? <div className='mt-2'>
                                                <h5>Revendas</h5>
                                                <Select
                                                    key={this.state.random}
                                                    isMulti
                                                    name="revendas"
                                                    defaultValue={this.state.revendas}
                                                    options={this.state.revendasAssociadasForm}
                                                    onChange={this.handleRevendas}
                                                    className="basic-multi-select"
                                                    classNamePrefix="Selecionar as revendas associadas"
                                                />

                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorDashboard" checked={this.state.acessoRevendedorDashboard} onChange={(e) => this.setState({ acessoRevendedorDashboard: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorDashboard">
                                                        Dashboard
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorCliente" checked={this.state.acessoRevendedorCliente} onChange={(e) => this.setState({ acessoRevendedorCliente: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorCliente">
                                                        Clientes
                                                    </label>
                                                </div>
                                                {
                                                    this.state.acessoRevendedorCliente && <div className='alert alert-secondary'>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input" id="acessoRevendedorClienteAssociarRevendedor" checked={this.state.acessoRevendedorClienteAssociarRevendedor} onChange={(e) => this.setState({ acessoRevendedorClienteAssociarRevendedor: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoRevendedorClienteAssociarRevendedor">
                                                                Associar revendedor
                                                            </label>
                                                        </div>
                                                        <div className="form-check form-switch mt-2">
                                                            <input className="form-check-input" id="acessoRevendedorClienteAssociarSuporte" checked={this.state.acessoRevendedorClienteAssociarSuporte} onChange={(e) => this.setState({ acessoRevendedorClienteAssociarSuporte: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoRevendedorClienteAssociarSuporte">
                                                                Associar central de suporte
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorRevendedores" checked={this.state.acessoRevendedorRevendedores} onChange={(e) => this.setState({ acessoRevendedorRevendedores: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorRevendedores">
                                                        Revendedores
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorChamados" checked={this.state.acessoRevendedorChamados} onChange={(e) => this.setState({ acessoRevendedorChamados: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorChamados">
                                                        Chamados
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorContratos" checked={this.state.acessoRevendedorContratos} onChange={(e) => this.setState({ acessoRevendedorContratos: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorContratos">
                                                        Contratos
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorCentralSuporte" checked={this.state.acessoRevendedorCentralSuporte} onChange={(e) => this.setState({ acessoRevendedorCentralSuporte: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorCentralSuporte">
                                                        Centrais de suporte
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorConfiguracao" checked={this.state.acessoRevendedorConfiguracao} onChange={(e) => this.setState({ acessoRevendedorConfiguracao: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorConfiguracao">
                                                        Configuração
                                                    </label>
                                                </div>
                                                <h5>Analytics</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorLogs" checked={this.state.acessoRevendedorLogs} onChange={(e) => this.setState({ acessoRevendedorLogs: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorLogs">
                                                        Logs
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorUsuarios" checked={this.state.acessoRevendedorUsuarios} onChange={(e) => this.setState({ acessoRevendedorUsuarios: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorUsuarios">
                                                        Usuários
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorConsumo" checked={this.state.acessoRevendedorConsumo} onChange={(e) => this.setState({ acessoRevendedorConsumo: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorConsumo">
                                                        Consumo
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorCaixas" checked={this.state.acessoRevendedorCaixas} onChange={(e) => this.setState({ acessoRevendedorCaixas: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorCaixas">
                                                        Caixas
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorMensalistas" checked={this.state.acessoRevendedorMensalistas} onChange={(e) => this.setState({ acessoRevendedorMensalistas: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorMensalistas">
                                                        Mensalistas
                                                    </label>
                                                </div>
                                                <h5>Cadastros</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorVersaoMinima" checked={this.state.acessoRevendedorVersaoMinima} onChange={(e) => this.setState({ acessoRevendedorVersaoMinima: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorVersaoMinima">
                                                        Versão mínima
                                                    </label>
                                                </div>
                                                <h5>Financeiro</h5>
                                                {
                                                    this.state.platformOwner &&
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="acessoRevendedorCobrancas" checked={this.state.acessoRevendedorCobrancas} onChange={(e) => this.setState({ acessoRevendedorCobrancas: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="acessoRevendedorCobrancas">
                                                            Cobranças
                                                        </label>
                                                    </div>
                                                }
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorPlanos" checked={this.state.acessoRevendedorPlanos} onChange={(e) => this.setState({ acessoRevendedorPlanos: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorPlanos">
                                                        Planos
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorAssinaturas" checked={this.state.acessoRevendedorAssinaturas} onChange={(e) => this.setState({ acessoRevendedorAssinaturas: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorAssinaturas">
                                                        Assinaturas
                                                    </label>
                                                </div>
                                                {
                                                    this.state.formaCobranca === "SELFPARKING" &&
                                                    <div className="form-check form-switch my-2">
                                                        <input className="form-check-input" id="acessoRevendedorNotasFiscais" checked={this.state.acessoRevendedorNotasFiscais} onChange={(e) => this.setState({ acessoRevendedorNotasFiscais: e.target.checked })} type="checkbox" />
                                                        <label className="form-check-label" htmlFor="acessoRevendedorNotasFiscais">
                                                            Notas fiscais
                                                        </label>
                                                    </div>
                                                }
                                                <h5>Comunicação</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorEmailNovoCliente" checked={this.state.acessoRevendedorEmailNovoCliente} onChange={(e) => this.setState({ acessoRevendedorEmailNovoCliente: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorEmailNovoCliente">
                                                        Receber mensagem de novo cliente cadastrado
                                                    </label>
                                                </div>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorAlertaLinkAssinaturaGerado" checked={this.state.acessoRevendedorAlertaLinkAssinaturaGerado} onChange={(e) => this.setState({ acessoRevendedorAlertaLinkAssinaturaGerado: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorAlertaLinkAssinaturaGerado">
                                                        Receber mensagem de link de assinatura gerado por cliente.
                                                    </label>
                                                </div>
                                                {/* <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorMensagemAssinaturaClienteCliente" checked={this.state.acessoRevendedorMensagemAssinaturaClienteCliente} onChange={(e) => this.setState({ acessoRevendedorMensagemAssinaturaClienteCliente: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorMensagemAssinaturaClienteCliente">
                                                        Receber mensagem de nova assinatura feita.
                                                    </label>
                                                </div> */}
                                                {
                                                    this.state.platformOwner &&
                                                    <>
                                                        <h5>Desenvolvedor</h5>
                                                        <div className="form-check form-switch my-2">
                                                            <input className="form-check-input" id="acessoRevendedorDesenvolvedor" checked={this.state.acessoRevendedorDesenvolvedor} onChange={(e) => this.setState({ acessoRevendedorDesenvolvedor: e.target.checked })} type="checkbox" />
                                                            <label className="form-check-label" htmlFor="acessoRevendedorDesenvolvedor">
                                                                Habilitar visão desenvolvedor
                                                            </label>
                                                        </div>
                                                        {
                                                            this.state.acessoRevendedorDesenvolvedor && <>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="acessoRevendedorDesenvolvedorErroApi" checked={this.state.acessoRevendedorDesenvolvedorErroApi} onChange={(e) => this.setState({ acessoRevendedorDesenvolvedorErroApi: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="acessoRevendedorDesenvolvedorErroApi">
                                                                        Alertas de erros na API
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="acessoRevendedorMarcasEModelos" checked={this.state.acessoRevendedorMarcasEModelos} onChange={(e) => this.setState({ acessoRevendedorMarcasEModelos: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="acessoRevendedorMarcasEModelos">
                                                                        Marcas e modelos
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="acessoRevendedorAplicacoes" checked={this.state.acessoRevendedorAplicacoes} onChange={(e) => this.setState({ acessoRevendedorAplicacoes: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="acessoRevendedorAplicacoes">
                                                                        Aplicações
                                                                    </label>
                                                                </div>
                                                                <div className="form-check form-switch my-2">
                                                                    <input className="form-check-input" id="acessoRevendedorBackup" checked={this.state.acessoRevendedorBackup} onChange={(e) => this.setState({ acessoRevendedorBackup: e.target.checked })} type="checkbox" />
                                                                    <label className="form-check-label" htmlFor="acessoRevendedorBackup">
                                                                        Backup
                                                                    </label>
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </div> : <div className='mt-2'>
                                                <h5>Revenda</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorCliente" checked={this.state.acessoRevendedorCliente} onChange={(e) => this.setState({ acessoRevendedorCliente: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorCliente">
                                                        Clientes
                                                    </label>
                                                    {
                                                        this.state.acessoRevendedorCliente && <div className='alert alert-secondary'>
                                                            <div className="form-check form-switch">
                                                                <input className="form-check-input" id="acessoRevendedorClienteAssociarRevendedor" checked={this.state.acessoRevendedorClienteAssociarRevendedor} onChange={(e) => this.setState({ acessoRevendedorClienteAssociarRevendedor: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="acessoRevendedorClienteAssociarRevendedor">
                                                                    Associar revendedor
                                                                </label>
                                                            </div>
                                                            <div className="form-check form-switch mt-2">
                                                                <input className="form-check-input" id="acessoRevendedorClienteAssociarSuporte" checked={this.state.acessoRevendedorClienteAssociarSuporte} onChange={(e) => this.setState({ acessoRevendedorClienteAssociarSuporte: e.target.checked })} type="checkbox" />
                                                                <label className="form-check-label" htmlFor="acessoRevendedorClienteAssociarSuporte">
                                                                    Associar central de suporte
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <h5>Comunicação</h5>
                                                <div className="form-check form-switch my-2">
                                                    <input className="form-check-input" id="acessoRevendedorEmailNovoCliente" checked={this.state.acessoRevendedorEmailNovoCliente} onChange={(e) => this.setState({ acessoRevendedorEmailNovoCliente: e.target.checked })} type="checkbox" />
                                                    <label className="form-check-label" htmlFor="acessoRevendedorEmailNovoCliente">
                                                        Receber mensagem de novo cliente cadastrado
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </MuiDialogContent>
                        <MuiDialogActions className='m-3'>
                            <div className='btn-group'>
                                {
                                    this.state.state === "permissions" && <>
                                        <button onClick={(e) => this.setState({ state: "resselers" })} className='btn btn-secondary'>Voltar</button>
                                        <button onClick={this.confirmUserToResseler} className='btn btn-success'>Confirmar</button>
                                    </>
                                }
                                {
                                    this.state.state === "resselers" && <>
                                        <button onClick={(e) => this.setState({ isOpenModal: false })} className='btn btn-secondary'>Fechar</button>
                                    </>
                                }
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar>
            </>)
    }
}

export { RevendedoresRevenda }