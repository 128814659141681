import { isNullOrEmpty } from "../shared/utils"
import { addQueue } from "../store/collections/queueWorker"

export const createCallbackVerifyPayment = ({ ticket, parameters }) => {
    if (isNullOrEmpty(ticket.placa)) {
        return
    }
    var verifyTags = []
    if (parameters?.habilitarSemParar) {
        verifyTags.push("semparar")
    }
    if (parameters?.habilitarConectCar) {
        verifyTags.push("conectcar")
    }
    if (parameters?.habilitarVeloe) {
        verifyTags.push("veloe")
    }
    if (parameters?.habilitarTaggy) {
        verifyTags.push("taggy")
    }
    if (!isNullOrEmpty(verifyTags)) {
        const data = {
            origin: "Tags Verify",
            type: "TAGS_VERIFY",
            body: {
                estacionamentoId: parameters?.estacionamentoId,
                clienteId: parameters?.clienteId,
                revendaId: parameters?.revendaId,
                ticketId: ticket.id,
                verifyTags: verifyTags
            }
        }
        addQueue({ data: data })
    }
}
