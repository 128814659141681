import React from 'react'
import moment from 'moment';
import { verifyTicketType, isNullOrEmpty } from '../../shared/utils';

class PrintEstacionados extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tickets: props.allTickets.filter((e) => e.status === "Estacionado"),
        }
    }
    render() {
        return (
            <>
                {
                    this.state.tickets.length > 0 ? (
                    
                    <div className='text-center'>
                        <hr class="border border-primary border-2 opacity-100"></hr>
                        <div className="text-center m-2">ESTACIONADOS</div>
                        <div className="row">
                            <div className="text-start col-4">PLACA</div>
                            <div className="text-start col-4">TICKET</div>
                            <div className="text-center col-4">DATA</div>
                        </div>
                        {
                            this.state.tickets.map((item, index) => (
                                <div className="row" key={`row_${index}`}>
                                    <div className="text-start col-4 text-nowrap">{verifyTicketType(item).tipoResume} - {!isNullOrEmpty(item.placa) ? item.placa : "Sem placa"}</div>
                                    <div className="text-start col-4 text-nowrap">{item.ticket}</div>
                                    <div className="text-start col-2 text-nowrap">{moment(item.createAt.seconds * 1000).format("DD/MM-HH:mm")}</div>
                                </div>
                        ))}
                    </div>
                    ) : (
                        <div>
                            <hr class="border border-primary border-2 opacity-100"></hr>
                            <div className="text-center m-2">NENHUM VEICULO ESTACIONADO</div>
                        </div>
                    )
                }
            </>
        )
    }
}

class PrintPagosEstacionados extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tickets: props.allTickets.filter((e) => e.status === "Pago e Estacionado"),
        }
    }
    render() {
        return (
            <>
                {
                    this.state.tickets.length > 0 ? (
                    
                    <div className='text-center'>
                        <hr class="border border-primary border-2 opacity-100"></hr>
                        <div className="text-center m-2 text-nowrap">PAGOS E ESTACIONADOS</div>
                        <div className="row">
                            <div className="text-start col-4">PLACA</div>
                            <div className="text-start col-4">TICKET</div>
                            <div className="text-center col-4">DATA</div>
                        </div>
                        {
                            this.state.tickets.map((item, index) => (
                                <div className="row" key={`row_${index}`}>
                                    <div className="text-start col-4 text-nowrap">{verifyTicketType(item).tipoResume} - {!isNullOrEmpty(item.placa) ? item.placa : "Sem placa"}</div>
                                    <div className="text-start col-4 text-nowrap">{item.ticket}</div>
                                    <div className="text-start col-2 text-nowrap">{moment(item.createAt.seconds * 1000).format("DD/MM-HH:mm")}</div>
                                </div>
                        ))}
                    </div>
                    ) : (
                        <div>
                            <hr class="border border-primary border-2 opacity-100"></hr>
                            <div className="text-center m-2">NENHUM VEICULO PAGO E ESTACIONADO</div>
                        </div>
                    )
                }
            </>
        )
    }
}

class PrintPagosAguardando extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            tickets: props.allTickets.filter((e) => e.status === "Pago e Aguardando"),
        }
    }
    render() {
        return (
            <>
                {
                    this.state.tickets.length > 0 ? (
                    
                    <div className='text-center'>
                        <hr class="border border-primary border-2 opacity-100"></hr>
                        <div className="text-center m-2 text-nowrap">PAGOS E AGUARDANDO</div>
                        <div className="row">
                            <div className="text-start col-4">PLACA</div>
                            <div className="text-start col-4">TICKET</div>
                            <div className="text-center col-4">DATA</div>
                        </div>
                        {
                            this.state.tickets.map((item, index) => (
                                <div className="row" key={`row_${index}`}>
                                    <div className="text-start col-4 text-nowrap">{verifyTicketType(item).tipoResume} - {!isNullOrEmpty(item.placa) ? item.placa : "Sem placa"}</div>
                                    <div className="text-start col-4 text-nowrap">{item.ticket}</div>
                                    <div className="text-start col-2 text-nowrap">{moment(item.createAt.seconds * 1000).format("DD/MM-HH:mm")}</div>
                                </div>
                        ))}
                    </div>
                    ) : (
                        <div>
                            <hr class="border border-primary border-2 opacity-100"></hr>
                            <div className="text-center m-2">NENHUM VEICULO PAGO E AGUARDANDO</div>
                        </div>
                    )
                }
            </>
        )
    }
}

class PrintPagosEntregues extends React.Component {

    constructor(props) {
        super(props)
        var tickets = []
         if (props.parameters.typeCashierOpened === "user") {
            const user = props.user
            tickets = props.allTickets.filter((e) => e.status === "Pago e Entregue" && e.closedAt && e.paymentsLogged.map(e => e.user.id).includes(user.id))
        } else {
            tickets = props.allTickets.filter((e) => e.status === "Pago e Entregue" && e.closedAt)
        }
        this.state = {
            tickets: tickets,
        }
    }
    render() {
        return (
            <>
                {
                    this.state.tickets.length > 0 ? (
                    
                    <div className='text-center'>
                        <hr class="border border-primary border-2 opacity-100"></hr>
                        <div className="text-center m-2 text-nowrap">PAGOS E ENTREGUES</div>
                        <div className="row">
                            <div className="text-start col-4">PLACA</div>
                            <div className="text-start col-4">TICKET</div>
                            <div className="text-center col-4">DATA</div>
                        </div>
                        {
                            this.state.tickets.map((item, index) => (
                                <div className="row" key={`row_${index}`}>
                                    <div className="text-start col-4 text-nowrap">{verifyTicketType(item).tipoResume} - {!isNullOrEmpty(item.placa) ? item.placa : "Sem placa"}</div>
                                    <div className="text-start col-4 text-nowrap">{item.ticket}</div>
                                    <div className="text-start col-2 text-nowrap">{moment(item.createAt.seconds * 1000).format("DD/MM-HH:mm")}</div>
                                </div>
                        ))}
                    </div>
                    ) : (
                        <div>
                            <hr class="border border-primary border-2 opacity-100"></hr>
                            <div className="text-center m-2">NENHUM VEICULO PAGO E ENTREGUE</div>
                        </div>
                    )
                }
            </>
        )
    }
}

class PrintPatioLegend extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            user: props.user,
            cashier: props.cashier
        }
    }

    render() {
        return (
            <>
                {
                    <div className='text-center'>
                        <hr class="border border-primary border-2 opacity-100 mb-1"></hr>
                        <div className="text-center">Legenda</div>
                        <div>Avulso - A | Mensalista - M</div>
                        <div>Credenciado - C | Venda Avulsa - V</div>
                        <hr class="border border-primary border-1 opacity-100 m-1"></hr>
                        <div>Operador: {this.state.user.nome}</div>
                        {
                            this.state?.cashier?.sequence &&
                            <div>Caixa em aberto: {this.state.cashier.sequence}</div>
                        }
                        <div>{moment(new Date()).format("DD/MM/YYYY [às] HH:mm")}</div>
                    </div>
                }
            </>
        )
    }
}

export { PrintEstacionados, PrintPagosEstacionados, PrintPagosAguardando, PrintPagosEntregues, PrintPatioLegend }