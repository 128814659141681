import React from 'react'
import { documentMask, isNullOrEmpty, takeIfIsNotNullOrEmpty, toCurrency, timestampToSeconds, toDateFormat } from '../utils'
import { getRevenda } from '../../config/auth'
import { urlTicketOnlineFromTicket } from '../../shared/url-manager.js';
import { QRCodeSVG } from 'qrcode.react';
import moment from 'moment'

class PrinterHeaderComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoNome && <>
                        {
                            this.state.parameters.tamanhoDoNomeDoEstacionamento === "grande" ?
                                <h5>{this.state.park.nome}</h5> :
                                <span>{this.state.park.nome}</span>
                        }
                    </>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoDocumento &&
                    <div>{documentMask(this.state.park.documento)}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoEndereco &&
                    <div>{this.state.park.endereco?.logradouro}, {this.state.park.endereco?.numero}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoHorarioFuncionamento &&
                    <div>{this.state.park.horarios}</div>
                }
                {
                    this.state.parameters?.habilitarImpressaoDadosEstabelecimentoCelular &&
                    <div>{this.state.park.celular}</div>
                }
            </div>
        )
    }
}


class PrinterCaixaHeaderComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park,
            resultado: props.resultado,
        }
    }

    render() {
        return (
            <div className='text-start mt-2'>
                {
                    this.state.resultado.caixas.length > 1 ? (
                        <div> RELATÓRIO DE FECHAMENTO DOS CAIXAS:</div>
                    ) : (
                        <div>FECHAMENTO DE CAIXA - {this.state.resultado.caixas[0].sequence}</div>
                    )
                }
                <div>Unidade: {this.state.park.nome}</div>
                <div>Endereço: {this.state.park.endereco?.logradouro}, {this.state.park.endereco?.numero}</div>
                {
                    this.state.resultado.caixas.length == 1 &&
                    <div>Operador: {this.state.resultado.caixas[0].closeBy?.nome ? this.state.resultado.caixas[0].openBy?.nome : ""}</div>
                }
                <hr class="border border-primary border-2 opacity-100"></hr>
            </div>
        )
    }
}

class MessageInTicketComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            position: props.position
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.parameters.habilitarMensagemImpressaNoTicket && this.state.position === this.state.parameters.mensagemLancamentoTicketLocal &&
                    <div>
                        {this.state.parameters.mensagemLancamentoTicket}
                    </div>
                }
            </div>
        )
    }
}

class BodyTicketInComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }
    render() {
        return (
            <div className='text-start mt-2'>
                {
                    this.state.ticket.paymentsLogged &&
                    <div className='text-center fs-6'>RECIBO DE PAGAMENTO</div>
                }
                {
                    <div className='text-center mb-1'><MessageInTicketComponent parameters={this.state.parameters} position={"comeco"} /></div>
                }
                {
                    this.state.ticket.type !== "SALES" && <>
                        {
                        }
                        {
                            <div>Entrada: {toDateFormat(this.state.ticket.createAt)}</div>
                        }
                        {
                            this.state.ticket.status === "Pago e Entregue" &&
                            <div>Saida: {toDateFormat(this.state.ticket.closedAt)} </div>
                        }
                        {
                            this.state.ticket.status === "Pago e Entregue" &&
                            <div>Permanência: {
                                (() => {
                                    const diff = timestampToSeconds(this.state.ticket.closedAt) - timestampToSeconds(this.state.ticket.createAt)
                                    if (diff < 60) {
                                        return "1 minuto";
                                    }
                                    const horas = Math.floor(diff / 3600);
                                    const minutos = Math.floor((diff % 3600) / 60);
                                    return horas > 0 ? `${horas} hora${horas !== 1 ? 's' : ''} e ${minutos} minuto${minutos !== 1 ? 's' : ''}` : `${minutos} minuto${minutos !== 1 ? 's' : ''}`;
                                })()
                            }</div>
                        }
                    </>
                }
                {
                    <div>Operador: {this.state.ticket.createByName}</div>
                }
                {
                    this.state.ticket.prisma?.length > 0 &&
                    <div>{this.state.parameters.descricaoPrisma + this.state.ticket.prisma}</div>
                }
                {
                    this.state.ticket.placa?.length > 0 &&
                    <div className='fs-6'>Placa: {this.state.ticket.placa}</div>
                }
                {
                    !this.state.parameters.habilitarTicketResumido &&
                    <>
                        {
                            this.state.ticket.setor &&
                            <div>Setor: {this.state.ticket.setor}</div>
                        }
                        {
                            this.state.ticket.vehicler && (
                                <div>
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.marca) &&
                                        <div>Marca: {this.state.ticket.vehicler.marca}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.modelo) &&
                                        <div>Modelo: {this.state.ticket.vehicler.modelo}<br /></div>
                                    }
                                    {
                                        takeIfIsNotNullOrEmpty(this.state.ticket.vehicler.cor) > 0 &&
                                        <div>Cor: {this.state.ticket.vehicler.cor}<br /></div>
                                    }
                                </div>
                            )
                        }
                    </>
                }
                {
                    this.state.ticket.tableSelectedInCheckIn && !this.state.parameters.habilitarTicketResumido &&
                    <div>Tabela: {this.state.ticket.tableSelectedInCheckIn.nome}</div>
                }
            </div>
        )
    }
}

class BodyMonthlyChargeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            charge: props.charge
        }
    }
    render() {
        return (
            <div className='text-start mt-2'>
                {
                    <div className='text-center fs-6'>RECIBO DO MENSALISTA</div>
                }
                {
                    <>
                        <div>Cliente</div>
                        <div>{this.state.charge.clienteNome}</div>
                    </>
                }
                {
                    this.state.charge.newNote ?
                        <>
                            {
                                isNullOrEmpty(this.state.charge.newNote?.document) ?
                                    <div className='text-center fs-6'>Documento não informado</div> :
                                    <div>Documento: {documentMask(this.state.charge.newNote?.document)}</div>
                            }
                        </> : <>
                            {
                                this.state.parameters?.habilitarImprimirTituloDocumentoNaoFiscal === true && this.state.charge.newNote?.document != null &&
                                <div>Não é documento fiscal</div>
                            }
                        </>
                }
                {
                    <>
                        <div className='text-center fs-6'>PAGAMENTO</div>
                        <div className="row justify-content-between">
                            <div className="col-6">Data do pagamento</div>
                            <div className="col-6 text-end">{toDateFormat(this.state.charge.payment?.dataPagamento)}</div>
                        </div>
                        <div className="row justify-content-between">
                            <div className="col-6">Data do vencimento</div>
                            <div className="col-6 text-end">{toDateFormat(this.state.charge.payment?.dataFatura)}</div>
                        </div>
                        {
                            this.state.charge.payment?.formaPagamento?.tipo &&
                            <div className="row justify-content-between">
                                <div className="col-6">Forma pagamento</div>
                                <div className="col-6 text-end">{this.state.charge.payment?.formaPagamento?.tipo}</div>
                            </div>
                        }
                        <div className="row justify-content-between">
                            <div className="col-6">Valor pago</div>
                            <div className="col-6 text-end">{toCurrency(this.state.charge.payment?.total ?? 0.0)}</div>
                        </div>
                    </>
                }
                {
                    <>
                        <div className='text-center fs-6'>PLANO</div>
                        <div className="row justify-content-between">
                            <div className="col-6">{this.state.charge.plan?.nome}</div>
                            <div className="col-6 text-end">Das {this.state.charge.plan?.horarioInicio} às {this.state.charge.plan?.horarioFim}</div>
                        </div>
                    </>
                }
                {
                    <>
                        <div className="text-center">VEÍCULOS ({this.state.charge.vehicle?.length ?? 0})</div>
                        <div className="row fw-bold text-start">
                            <div className="col-4 text-start">Placa</div>
                            <div className="col-4 text-center">Modelo</div>
                            <div className="col-4 text-end">Cor</div>
                        </div>
                        {
                            this.state.charge.vehicle?.map((vehicle, index) => (
                                <div key={index} className="row text-start">
                                    <div className="col-4 text-start">{takeIfIsNotNullOrEmpty(vehicle.placa) ?? "-"}</div>
                                    <div className="col-4 text-center">{takeIfIsNotNullOrEmpty(vehicle.modelo) ?? "-"}</div>
                                    <div className="col-4 text-end">{takeIfIsNotNullOrEmpty(vehicle.cor) ?? "-"}</div>
                                </div>
                            ))
                        }
                    </>
                }
            </div>
        )
    }
}

class PrinterPaymentsComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            ticket: props.ticket
        }
    }


    render() {
        return (
            <div>
                {
                    this.state.ticket.paymentsLogged?.length > 0 && (
                        <>
                            <div className='fs-6'>{this.state.ticket.paymentsLogged.length === 1 ? 'PAGAMENTO' : 'PAGAMENTOS'}</div>
                            {this.state.ticket.paymentsLogged.map(pagamento => (
                                <ul key={pagamento.id} className="text-start list-unstyled">
                                    <li>Data: {toDateFormat(pagamento.createAt)}</li>
                                    <li>Tipo: {pagamento.formaPagamento?.nome ?? "Não disponível"}</li>
                                    <li>Operador: {pagamento.user.nome}</li>
                                    <li>Valor: {toCurrency(pagamento.resumo.valorCobrado)}</li>
                                </ul>
                            ))}
                        </>
                    )
                }
            </div>
        )
    }
}

class PrinterInternalReviewComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div>
                {
                    this.state.ticket.interno?.length > 0 && !this.state.parameters.habilitarTicketResumido &&
                    <div>Avaliações internas:
                        {
                            this.state.ticket.interno.map((item) => {
                                return <div> {item.quantidade} - {item.nome} </div>
                            })
                        }
                    </div>
                }
            </div>
        )
    }
}

class PrinterProductsComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        var totalProdutos = 0
        return (
            <div className='text-start mt-2'>
                {
                    (this.state.ticket.produtos?.filter(item => item.printInTicket)?.length ?? 0) > 0 && (!this.state.parameters.habilitarTicketResumido || this.state.ticket.type === "SALES") &&
                    <div>
                        <div className='fs-6'>PRODUTOS</div>
                        {
                            this.state.ticket.produtos.filter(item => item.printInTicket).map((item) => {
                                totalProdutos += (Number(item.quantidade) * Number(item.valor))
                                return <div>
                                    ({item.quantidade}x) {toCurrency(item.valor)} - {item.descricao}
                                    {
                                        item.printDescriptionInTicket && !isNullOrEmpty(item.observacao) && <div>{item.observacao.split("\n").map((d, index) => <div key={`ticket_product_${index}`}>{d}</div>)}<br /></div>
                                    }
                                </div>
                            })
                        }
                        Total Produtos: {toCurrency(totalProdutos)}
                    </div>

                }
            </div>
        )
    }
}

class PrinterQRCodeComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            ticket: props.ticket
        }
    }

    render() {
        return (
            <div className='mt-2'>
                {
                    this.state.parameters.imprimirTicketNoFormato === "QRCode" &&
                    <div>
                        <p>Aponte a sua câmera para o QRCode abaixo.</p>
                        <QRCodeSVG value={urlTicketOnlineFromTicket(this.state.ticket, "payment-receipt")} size={100} />
                    </div>
                }
                {
                    this.state.parameters.datahabilitarNumeroImpressoNoTicket &&
                    <div className='fs-1'>{this.state.ticket.ticket}</div>
                }
            </div>
        )
    }
}

class PrinterFooterComponent extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            parameters: props.parameters,
            park: props.park,
            revenda: props.revenda
        }
    }

    render() {
        var revenda = getRevenda()
        var siteRevenda = revenda.site.split("/")[2]
        return (
            <div className="mt-2">
                {
                    <div>{siteRevenda}</div>
                }
                {
                    <div>
                        Impresso {new Date().toLocaleDateString('pt-BR')} às {new Date().toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}
                    </div>
                }
            </div>
        )
    }
}

class CashierReportTotalsDocument extends React.Component {
    constructor(props) {
        super(props)
        const impressaoListagemMensalistasCaixasCategories = props.impressaoListagemMensalistasCaixasCategories ?? []
        var categories = (props.resultado.totais.monthlyByCategory ?? []).filter(e => !impressaoListagemMensalistasCaixasCategories.map(f => f.category).includes(e.category))
        var totalCategories = categories.reduce((a, b) => a + b.total, 0)
        var total = {
            valorEstacionamento: props.resultado.totais.tickets.valorEstacionamento,
            valorDesconto: props.resultado.totais.descontoManual,
            valorProdutos: props.resultado.totais.tickets.valorProdutos,
            valorCobrado: props.resultado.totais.tickets.valorCobrado,
            monthly: props.resultado.totais.monthly - totalCategories,
            valorTotal: props.resultado.totais.faturamento,
            convenios: props.resultado.totais.convenios,
            faturamento: props.resultado.totais.faturamento - totalCategories,
            faturamentoLiquido: props.resultado.totais.faturamentoLiquido - totalCategories,
            movementEarn: props.resultado.totais.movementEarn,
            movementSpends: props.resultado.totais.movementSpends,
        }
        this.state = {
            total: total
        }
    }
    render() {
        return (
            <>
                <div className="text-start mt-2">
                    <div>RECEITA</div>
                    <div>ESTACIONAMENTO: {toCurrency(this.state.total.valorEstacionamento)}</div>
                    <div>MENSALISTAS: {toCurrency(this.state.total.monthly)}</div>
                    {
                        this.state.total.valorProdutos > 0 &&
                        <div>PRODUTOS E SERVICOS: {toCurrency(this.state.total.valorProdutos)}</div>
                    }
                    {
                        this.state.total.movementSpends > 0 &&
                        <div>Movimentações ( Entradas ): {toCurrency(this.state.total.movementEarn)}</div>
                    }
                    {
                        this.state.total.movementSpends > 0 &&
                        <div>Movimentações ( Saídas ): {toCurrency(this.state.total.movementSpends)}</div>
                    }
                    {
                        this.state.total.convenios > 0 &&
                        <div>(-) CONVENIOS: {toCurrency(this.state.total.convenios)}</div>
                    }
                    {
                        this.state.total.descontoManual > 0 &&
                        <div>(-) DESC MANUAIS: {toCurrency(this.state.total.descontoManual)}</div>
                    }
                    <div>TOTAL BRUTO: {toCurrency(this.state.total.faturamento)}</div>
                    <div>TOTAL (- CONV/DESC): {toCurrency(this.state.total.faturamentoLiquido)}</div>
                    <hr class="border border-primary border-2 opacity-100"></hr>
                </div>
            </>
        )
    }
}

class CashierReportFormasDePagamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        const impressaoListagemMensalistasCaixasCategories = props.impressaoListagemMensalistasCaixasCategories ?? []
        var categories = (props.resultado.totais.monthlyByCategory ?? []).filter(e => !impressaoListagemMensalistasCaixasCategories.map(f => f.category).includes(e.category))
        var paymentMethods = (props.resultado?.totais?.tickets?.byWayPayment ?? []).map(e => {
            return {
                nome: e.wayPayment?.nome,
                total: e.total - categories.filter(f => f.wayPayment.id === e.wayPayment?.id).reduce((a, b) => a + b.total, 0),
            }
        })
        this.state = {
            paymentMethods: paymentMethods ?? []
        }
    }

    render() {
        return (
            <>
                {
                    this.state.paymentMethods.length > 0 &&
                    <div>
                        <div className="text-start">FATURAMENTO DETALHADO</div>
                        {
                            this.state.paymentMethods.map((item, index) =>
                                item?.total > 0 && (
                                    <div className="row" key={`way_payment_${index}`}>
                                        <div className="text-start col-4 col-sm-4">{item?.nome}</div>
                                        <div className="text-start col-8 col-sm-4">: {toCurrency(item.total)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            )
                        }
                        <hr class="border border-primary border-2 opacity-100"></hr>
                    </div>
                }
            </>
        )
    }
}

class CashierReportListagemMensalistasCaixas extends React.Component {

    constructor(props) {
        super(props)
        var mensalistas = props.resultado.mensalistas
        const impressaoListagemMensalistasCaixasCategories = props.impressaoListagemMensalistasCaixasCategories?.map(e => e.category) ?? []
        if (isNullOrEmpty(impressaoListagemMensalistasCaixasCategories)) {
            this.state = {
                mensalistas: mensalistas,
                total: mensalistas.map(e => e.payment?.total ?? 0).reduce((a, b) => a + b, 0)
            }
        } else {
            mensalistas = mensalistas.filter(e => impressaoListagemMensalistasCaixasCategories.includes(e.category))
            this.state = {
                mensalistas: mensalistas,
                total: mensalistas.map(e => e.payment?.total ?? 0).reduce((a, b) => a + b, 0)
            }
        }
    }

    render() {
        return (
            <>
                {
                    this.state.mensalistas.length > 0 && (
                        <div>
                            <div className="text-start">PAGAMENTO MENSALISTA DETALHADO</div>
                            <div className="row">
                                <div className="text-start col-4 col-sm-4">NOME</div>
                                <div className="text-start col-4 col-sm-4">TIPO PAG.</div>
                                <div className="text-start col-4 col-sm-4">VALOR</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.mensalistas.map((item, index) => (
                                    <div className="row" key={`row_${index}`}>
                                        <div className="text-start col-4 col-sm-4">{(item?.clienteNome ?? "-").substring(0, 9)}</div>
                                        <div className="text-start col-4 col-sm-4">{(item.payment?.formaPagamento?.nome ?? "-").substring(0, 10)}</div>
                                        <div className="text-start col-4 col-sm-4">{toCurrency(item.payment?.total) ?? "-"}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                ))
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportTabelasUtilizadasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.tabelasUseds.length > 0 && (
                        <div>
                            <div className="text-start">TABELAS DE PREÇO DETALHADO</div>
                            <div className="row">
                                <div className="text-start col-6 col-sm-4">NOME</div>
                                <div className="text-start col-2 col-sm-4">QTD</div>
                                <div className="text-start col-4 col-sm-4">TOTAL</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.tabelasUseds.map((item, index) =>
                                    <div className="row" key={`way_payment_table_${index}`}>
                                        <div className="text-start col-6 col-sm-4">{item.tableSelected.resumo.tabelaPreco.nome.substring(0, 10)}</div>
                                        <div className="text-start col-2 col-sm-4">{item.quantityUsed}</div>
                                        <div className="text-start col-4 col-sm-4">{toCurrency(item.total)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportConveniosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.convenios.length > 0 && (
                        <div>
                            <div className="text-start">CONVENIOS DETALHADOS</div>
                            <div className="row">
                                <div className="text-start col-6 col-sm-4">NOME</div>
                                <div className="text-start col-2 col-sm-4">QTD</div>
                                <div className="text-start col-4 col-sm-4">VALOR</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.convenios.map((item, index) =>
                                    <div className="row" key={`convenios_${index}`}>
                                        <div className="text-start col-6 col-sm-4">{item.convenio.resumo.convenio.nome.substring(0, 10)}</div>
                                        <div className="text-start col-2 col-sm-4">{item.quantityUsed}</div>
                                        <div className="text-start col-4 col-sm-4">{toCurrency(item.total)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportDescontosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.descontoManual.length > 0 && (
                        <div>
                            <div className="text-start">DESCONTOS DETALHADOS</div>
                            <div className="row">
                                <div className="text-start col-3 col-sm-4">DATA</div>
                                <div className="text-start col-5 col-sm-4">OPERADOR</div>
                                <div className="text-start col-4 col-sm-4">VALOR</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.descontoManual.map((item, index) =>
                                    <div className="row" key={`discount_${index}`}>
                                        <div className="text-start col-3 col-sm-4">{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                        <div className="text-start col-5 col-sm-4">{(item.descontoManual?.user?.nome ?? "-").substring(0, 10)}</div>
                                        <div className="text-start col-4 col-sm-4">-R$ {item.descontoManual.discount.toFixed(2)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportCaixasAnalisados extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.caixas && (
                        <div className='mt-2'>
                            <table className='table table-sm' style={{ borderColor: 'black' }}>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>DATA</th>
                                        <th>HORA</th>
                                        <th>NUMERO</th>
                                    </tr>
                                </thead>
                                <tbody className='table-group-divider'>
                                    {
                                        this.state.resultado.caixas.map((item, index) => (
                                            <>
                                                <tr>
                                                    <td>
                                                        <div className='text-start'>ABERTURA</div>
                                                        <div className='text-start'>FECHAMENTO</div>
                                                    </td>
                                                    <td>
                                                        <div>{moment(item.openAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                                        <div>{isNullOrEmpty(item.closeAt) ? "Caixa em aberto" : moment(item.closeAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                                    </td>
                                                    <td>
                                                        <div>{moment(item.openAt.seconds * 1000).format("HH:mm:ss")}</div>
                                                        <div>{isNullOrEmpty(item.closeAt) ? "-" : moment(item.closeAt.seconds * 1000).format("HH:mm:ss")}</div>
                                                    </td>
                                                    <td>
                                                        <div>{item.sequence}</div>
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportMovimentacoesCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.movements.length > 0 && (
                        <div>
                            <div className="text-start">MOVIMENTAÇÕES DETALHADAS</div>
                            <div className="row">
                                <div className="text-start col-3 col-sm-4">DATA</div>
                                <div className="text-start col-3 col-sm-4">TIPO</div>
                                <div className="text-start col-3 col-sm-4">FORMA</div>
                                <div className="text-start col-3 col-sm-4">VALOR</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.movements.map((item, index) =>
                                    <div className="row" key={`movements_${index}`}>
                                        <div className="text-start col-3 col-sm-4">{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                        <div className="text-start col-3 col-sm-4">{item.type}</div>
                                        <div className="text-start col-3 col-sm-4">{item.formaPagamento?.nome ?? '-'}</div>
                                        <div className="text-start col-3 col-sm-4">{toCurrency(item.price)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportRetiradasCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.retiradas.length > 0 && (
                        <div>
                            <div className="text-start">RETIRADAS DETALHADAS</div>
                            <div className="row">
                                <div className="text-start col-3 col-sm-4">DATA</div>
                                <div className="text-start col-5 col-sm-4">OPERADOR</div>
                                <div className="text-start col-4 col-sm-4">VALOR</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.retiradas.map((item, index) =>
                                    <div className="row" key={`retiradas_${index}`}>
                                        <div className="text-start col-3 col-sm-4">{moment(item.createAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                        <div className="text-start col-5 col-sm-4">{item.user?.nome.substring(0, 10)}</div>
                                        <div className="text-start col-4 col-sm-4">{toCurrency(item.price)}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}

class CashierReportCancelamentosCaixas extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            resultado: props.resultado
        }
    }

    render() {
        return (
            <>
                {
                    this.state.resultado.ticketsCanceled.length > 0 ? (
                        <div>
                            <div className="text-start">CANCELAMENTOS DETALHADOS</div>
                            <div className="row">
                                <div className="text-start col-3 col-sm-4">DATA</div>
                                <div className="text-start col-3 col-sm-4">OPERADOR</div>
                                <div className="text-start col-3 col-sm-4">PLACA</div>
                                <div className="text-start col-3 col-sm-4">TICKET</div>
                                <div class="w-100 d-none d-md-block"></div>
                            </div>
                            {
                                this.state.resultado.ticketsCanceled.map((item, index) =>
                                    <div className="row" key={`canceled_${index}`}>
                                        <div className="text-start col-3 col-sm-4">{moment(item.statusLogged.filter(e => e.status === "Cancelado")[0]?.createAt.seconds * 1000).format("DD/MM/YYYY")}</div>
                                        <div className="text-start col-3 col-sm-4">{item.statusLogged.filter(e => e.status === "Cancelado")[0]?.createBy.nome.substring(0, 8) ?? "-"}</div>
                                        <div className="text-start col-3 col-sm-4">{item.placa ?? "-"}</div>
                                        <div className="text-start col-3 col-sm-4">{item.ticket}</div>
                                        <div class="w-100 d-none d-md-block"></div>
                                    </div>
                                )
                            }
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    ) : (
                        <div>
                            <div>NÃO HOUVE CANCELAMENTOS NESSE CAIXA</div>
                            <hr class="border border-primary border-2 opacity-100"></hr>
                        </div>
                    )
                }
            </>
        )
    }
}




export { PrinterHeaderComponent, PrinterCaixaHeaderComponent, BodyTicketInComponent, BodyMonthlyChargeComponent, PrinterPaymentsComponent, PrinterInternalReviewComponent, PrinterProductsComponent, PrinterFooterComponent, PrinterQRCodeComponent, MessageInTicketComponent, CashierReportTotalsDocument, CashierReportFormasDePagamentosCaixas, CashierReportListagemMensalistasCaixas, CashierReportTabelasUtilizadasCaixas, CashierReportConveniosCaixas, CashierReportDescontosCaixas, CashierReportCaixasAnalisados, CashierReportMovimentacoesCaixas, CashierReportRetiradasCaixas, CashierReportCancelamentosCaixas }