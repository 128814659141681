import React from 'react'
import { NavBar } from '../../../components/navbar/index.js'
import { Loading, EmptyContent } from '../../../components/common/commons.js'
import Moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { getEstacionamento, isAdministrador } from '../../../config/auth.js';
import Tooltip from '@mui/material/Tooltip';
import { getRemoteConfigByKey } from '../../../store/collections/remoteConfigWorker.js';
import { TicketListModal } from '../../ticket/index.js';
import { getAllCamerasLPRByParkId } from '../../../store/collections/cameraLPRWorker.js';
import { CancelaCameraLPRPreview } from '../lpr/index.js';
import { AlertWarning } from '../../../shared/alert-manager.js';
import { deleteDevice, getAllDevicesByParkId, updateDevice } from '../../../store/collections/deviceWorker.js';
import { dangerConfirm, isNull, isNullOrEmpty, takeIfIsNotNullOrEmpty, toastDismissLoading, toastLoading, toastSuccess } from '../../../shared/utils.js';
import { saveLastUpdate } from '../../../store/collections/lastUpdateWorker.js';
import { getParameterByParkId } from '../../../store/collections/parameterWorker.js';
import { getAllEquipmentsByParkId } from '../../../store/collections/equipmentWorker.js';

class CancelaListarAparelhos extends React.Component {

    render() {
        return (
            <NavBar>
                <CancelaListarAparelhosBody />
            </NavBar>
        )
    }
}

class CancelaListarAparelhosBody extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            isAdministrador: isAdministrador(),
            items: [],
            totems: [],
            totemId: "",
            totem: null,
            isLoading: true
        }
    }

    componentDidMount = async () => {
        await this.load(true)
    }

    load = async () => {
        this.setState({ isLoading: true })
        var items = await getAllDevicesByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ allEmergencyModel: items.filter(e => e.emergency === true).length === items.length })
        this.setState({ items: items })
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ parameter: parameter })
        this.setState({ isLoading: false })
    }

    removeDevice = async (item) => {
        var confirm = await dangerConfirm({ message: `Confirma mesmo a remoção deste aparelho?` });
        if (confirm) {
            toastLoading("Apagando...")
            await deleteDevice({ id: item.id })
            toastDismissLoading()
            await this.load()
        }
    }

    editPrinter = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ printerName: item.printerName ?? "" })
        this.setState({ isShowingPrinterModal: true })
    }

    savePrinter = async () => {
        toastLoading("Salvando...")
        const item = this.state.itemSelected
        item.printerName = this.state.printerName
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingPrinterModal: false })
        toastDismissLoading()
        toastSuccess("Configurações salvas com sucesso.")
        await this.load()
    }

    editTEF = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ tefName: item.tefName ?? "" })
        this.setState({ isShowingTEFModal: true })
    }

    saveTEF = async () => {
        toastLoading("Salvando...")
        const item = this.state.itemSelected
        item.tefName = this.state.tefName
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingTEFModal: false })
        toastDismissLoading()
        toastSuccess("Configurações salvas com sucesso.")
        await this.load()
    }

    editUseMode = async (item) => {
        this.setState({ itemSelected: item })
        this.setState({ useMode: item.useMode ?? "" })
        this.setState({ isShowingUseMode: true })
    }

    editTotem = async (item) => {
        toastLoading("Aguarde...")
        this.setState({ itemSelected: item })
        this.setState({ totemId: item.totem?.id ?? "" })
        this.setState({ openGate: item.totem?.openGate ?? false })
        var totems = await getAllEquipmentsByParkId({ estacionamentoId: this.state.estacionamentoId })
        const totem = totems.find(e => e.id === item.totem?.id)
        if (totem) {
            this.setState({ totem: totem })
        }
        this.setState({ totems: totems.sort((a, b) => parseInt(a.equipmentId ?? 0) - parseInt(b.equipmentId ?? 0)) })
        toastDismissLoading()
        this.setState({ isShowingTotem: true })
    }

    saveUseMode = async () => {
        toastLoading("Salvando...")
        const item = this.state.itemSelected
        item.useMode = this.state.useMode
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingUseMode: false })
        toastDismissLoading()
        toastSuccess("Configurações salvas com sucesso.")
        await this.load()
    }

    changeTotemSelected = (e) => {
        const totemId = e.target.value
        this.setState({ totemId: totemId })
        const totem = this.state.totems.find(e => e.id === totemId)
        if (totem) {
            this.setState({ totem: totem })
        }
    }

    saveEquipment = async () => {
        toastLoading("Salvando...")
        const item = this.state.itemSelected
        if (!item.totem) {
            item.totem = {}
        }
        item.totem.openGate = this.state.openGate
        item.totem.name = `${this.state.totem?.equipmentId} - ${this.state.totem?.name}`
        item.totem.id = this.state.totem?.id
        await updateDevice({ id: item.id, data: item })
        this.setState({ isShowingTotem: false })
        toastDismissLoading()
        toastSuccess("Configurações salvas com sucesso.")
        await this.load()
    }

    removeAll = async () => {
        const confirm = await dangerConfirm({ message: "Deseja mesmo apagar todos os aparelhos?" })
        if (!confirm) {
            return
        }
        toastLoading("Apagando...")
        for (const item of this.state.items) {
            toastLoading(`Apagando ${item.model}`)
            await deleteDevice({ id: item.id })
            this.setState({ items: this.state.items.filter(e => e.id !== item.id) })
        }
        toastDismissLoading()
        toastSuccess("Aparelhos apagados com sucesso.")
    }

    render() {
        return (
            <div className="card">
                <div className="card-header d-flex justify-content-between align-items-center">
                    Aparelhos
                    {
                        this.state.isAdministrador &&
                        <div className='btn-group'>
                            <button type="button" onClick={this.removeAll} className="btn btn-sm btn-danger">
                                <i className="fas fa-trash mx-2" />
                                Apagar todos
                            </button>
                        </div>
                    }
                </div>
                <div className="mb-4">
                    {
                        this.state.items.length === 0 && !this.state.isLoading ?
                            <EmptyContent text='Faça login em um aparelho e ele aparecerá aqui.' />
                            :
                            !this.state.isLoading ?
                                <div className="row">
                                    <div className='col-md-12'>
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <td align='center' width='10' />
                                                        <td><strong>Modelo</strong></td>
                                                        <td><strong>Usuário logado</strong></td>
                                                        <td><strong>Bateria</strong></td>
                                                        <td><strong>TEF</strong></td>
                                                        <td><strong>Modo de uso</strong></td>
                                                        <td align='right' ><strong>Atualizado</strong></td>
                                                        <td align='right' />
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        this.state.items.map((item, index) =>
                                                            <tr key={index}>
                                                                <td className="d-none d-sm-table-cell" align='left' width={10}>
                                                                    <Tooltip title={item.id}>
                                                                        <span className="badge text-bg-success mx-2 w-100">
                                                                            <span> {item.version} | </span>
                                                                            {item.flavor?.toLowerCase()?.includes("pagSeguro") && ('PagSeguro')}
                                                                            {item.flavor?.toLowerCase()?.includes("cielo") && ('Cielo')}
                                                                            {item.flavor?.toLowerCase()?.includes("getnet") && ('GetNet')}
                                                                            {item.flavor?.toLowerCase()?.includes("Stone") && ('Stone')}
                                                                            {item.flavor?.toLowerCase()?.includes("safra") && ('Safra')}
                                                                            {item.flavor?.toLowerCase()?.includes("store") && ('Google Play')}
                                                                            {item.flavor?.toLowerCase()?.includes("rede") && ('Rede')}
                                                                            {item.flavor?.toLowerCase()?.includes("site") && 'Site'}
                                                                        </span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>{item.model ?? "Não informado"}</td>
                                                                <td>{item.currentUser?.nome ?? "Não informado"}</td>
                                                                <td>{item.batery != "-" ? <span>{item.batery}%</span> : "Não disponível"}</td>
                                                                <td>{takeIfIsNotNullOrEmpty(item.tefName) ?? "Padrão aparelho"}</td>
                                                                <td>
                                                                    <span>{!item.useMode && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "SALES" && "Vendas"}</span>
                                                                    <span>{item.useMode === "PARK" && "Estacionamento"}</span>
                                                                    <span>{item.useMode === "NOTES" && "Emissor de notas"}</span>
                                                                    <span>{item.useMode === "TOTEM" && "Auto atendimento"}</span>
                                                                    <span>{item.useMode === "AUTHENTICATOR" && "Autenticador"}</span>
                                                                </td>
                                                                <td align='right'>{Moment(item.updatedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</td>
                                                                <td align='right' width={10}>
                                                                    <div className='btn-group'>
                                                                        {
                                                                            item.flavor?.toLowerCase()?.includes("store") && <>
                                                                                <Tooltip title="Editar TEF" placement="top">
                                                                                    <button className='btn btn-primary' onClick={(e) => this.editTEF(item)}>
                                                                                        <i className="fa-solid fa-cash-register" />
                                                                                    </button>
                                                                                </Tooltip>
                                                                            </>
                                                                        }
                                                                        {
                                                                            this.state.parameter?.habilitarCancela && (isNullOrEmpty(item.useMode) || item.useMode === "PARK") &&
                                                                            <Tooltip title={isNullOrEmpty(item.totem?.id) ? "Associar totem" : "Editar totem"} placement="top">
                                                                                <button className={isNullOrEmpty(item.totem?.id) ? "btn btn-secondary" : "btn btn-success"} onClick={(e) => this.editTotem(item)}>
                                                                                    <i className="fas fa-parking" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        }
                                                                        <Tooltip title="Editar modo de uso" placement="top">
                                                                            <button className='btn btn-primary' onClick={(e) => this.editUseMode(item)}>
                                                                                <i className="fa-solid fa-user-tie" />
                                                                            </button>
                                                                        </Tooltip>
                                                                        <Tooltip title="Remover aparelho" placement="top">
                                                                            <button className='btn btn-danger' onClick={(e) => this.removeDevice(item)}>
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Loading />
                    }
                </div>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingTotem} onClose={(e) => this.setState({ isShowingTotem: false })}>
                    <MuiDialogTitle className='text-center'>
                        Associar totem
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Totens</label>
                            <select className="form-select" value={this.state.totemId} onChange={(e) => this.changeTotemSelected(e)}>
                                <option value="">Selecionar</option>
                                {
                                    this.state.totems?.map(item =>
                                        <option key={item.id} value={item.id}>{item.equipmentId} - {item.type === "IN" ? "Entrada" : item.type === "OUT" ? "Saída" : item.type === "AUTO" ? "Via dupla" : "Outro"} - {item.name}</option>
                                    )
                                }
                            </select>
                        </div>
                        {
                            !isNullOrEmpty(this.state.totemId) &&
                            <div className="form-check form-switch my-2">
                                <input className="form-check-input" id="openGate" checked={this.state.openGate} onChange={(e) => this.setState({ openGate: e.target.checked })} type="checkbox" />
                                <label className="form-check-label" htmlFor="openGate">
                                    Abrir automaticamente cancela após pagamento.
                                </label>
                            </div>
                        }
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingTotem: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveEquipment()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingPrinterModal} onClose={(e) => this.setState({ isShowingPrinterModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar impressora
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Software de impressão</label>
                            <select className="form-select" value={this.state.printerName} onChange={(e) => this.setState({ printerName: e.target.value })}>
                                <option value="">Padrão aparelho</option>
                                <option value="SK210">SK210</option>
                                <option value="POSTech">POSTech</option>
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingPrinterModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.savePrinter()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingTEFModal} onClose={(e) => this.setState({ isShowingTEFModal: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar TEF
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Software TEF</label>
                            <select className="form-select" value={this.state.tefName} onChange={(e) => this.setState({ tefName: e.target.value })}>
                                <option value="">Padrão aparelho</option>
                                <option value="Destaxa">Destaxa</option>
                                <option value="PayGo" disabled>PayGo</option>
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingTEFModal: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveTEF()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingUseMode} onClose={(e) => this.setState({ isShowingUseMode: false })}>
                    <MuiDialogTitle className='text-center'>
                        Editar modo de uso
                    </MuiDialogTitle>
                    <MuiDialogContent>
                        <div className="col-lg-12">
                            <label>Modo de uso</label>
                            <select className="form-select" value={this.state.useMode} onChange={(e) => this.setState({ useMode: e.target.value })}>
                                <option value="PARK">Estacionamento</option>
                                <option value="SALES">Vendas</option>
                                <option value="NOTES">Emissor de notas</option>
                                <option value="TOTEM">Auto atendimento</option>
                                {/* <option value="AUTHENTICATOR">Autenticador</option> */}
                            </select>
                        </div>
                    </MuiDialogContent>
                    <MuiDialogActions>
                        <div className='btn-group m-3'>
                            <button onClick={(e) => this.setState({ isShowingUseMode: false })} className='btn btn-secondary'>Fechar</button>
                            <button onClick={() => this.saveUseMode()} className='btn btn-success'>Salvar</button>
                        </div>
                    </MuiDialogActions>
                </Dialog>
            </div>
        )
    }
}

export { CancelaListarAparelhos }