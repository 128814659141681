import React from 'react'
import { Tabs, Tab, Button } from 'react-bootstrap';
import { getCliente, getEstacionamento, getRevenda, getRevendaIdByPark, getUsuario, getUsuarioCompact, isAdministrador, isDeveloper } from '../../config/auth'
import { Link } from 'react-router-dom';
import { NavBar } from '../../components/navbar'
import { DatePickerDialog, EmptyContent, Loading } from '../../components/common/commons'
import { ProductsSimplyList } from '../dashboard/dashboard-products'
import firebase from '../../config/firebase'
import 'firebase/storage'
import NumberFormat from 'react-number-format';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Moment from 'moment';
import { sendClickButton, sendLogByUser, sendScreenView } from '../../shared/analytics';
import { getTicketSettings, updateTicketSettings } from '../../store/collections/ticketSettings';
import { saveLastUpdate } from '../../store/collections/lastUpdateWorker';
import Tooltip from '@mui/material/Tooltip';
import Select from 'react-select';
import { TotalsValorCobrado, TotalsConvenios, TotalsPermanenciaMedia, TotalsCredenciados, TotalsTicketsCanceled } from './totalsSearch';
import { exportAndDownloadXLS, generateUUIDV7, goToNewWindow, goToWindowWithAlert, isNullOrEmpty, isValidatePlate, onlyNumbers, queryString, reloadWithAlert, secondsToDescription, setPathWindow, takeIfIsNotNullOrEmpty, ticketPermanenceFromNowDescription, toastDismissLoading, toastLoading, toastSuccess, toastWarning, reloadWindow, goToFirebase, capitalizeField, isNotNull, documentMask, delay, dangerConfirm, warningConfirm } from '../../shared/utils'
import { AverageTimeTicketAccessCharts, DistributedColumnsOperatorsCharts, HeatMapWeakTimesEndTickets, HeatMapWeakTimesStartTickets, StackedColumnsTicketAccessCharts, TreeMapAccreditedsCharts, TreeMapPaymentTableCharts, TreeMapSectorsCharts } from '../relatorios/charts';
import { getTicketById, getTicketByNumberAndParkId, getTicketByPlateAndParkId, removeTicketById, ticketsByPlatesAndClientId, ticketsParkedByAccreditedId, ticketsParkedByMonthlyId, updateTicket, updateTicketById } from '../../store/collections/ticketWorker';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import { activeCashier, getAllCashiers, getCashierById } from '../../store/collections/cashierWorker';
import CurrencyInput from '../../components/currencyInput/CurrencyInput'
import { getFormasPagamentosAtivas } from '../../store/collections/formaPagamentoWorker';
import { getParameter, getParameterByParkId } from '../../store/collections/parameterWorker';
import { fetchFunction } from '../../shared/function-request'
import { getAllActivePaymentTables, getAllPaymentTables } from '../../store/collections/paymentTableWorker';
import { urlTicketOnlineFromTicket } from '../../shared/url-manager';
import { TicketSearchAndRegisterPaymentModal } from '../ticket';
import { AlertDanger, AlertInfo, AlertSecondary, AlertSuccess, AlertWarning } from '../../shared/alert-manager';
import { getNewNoteById } from '../../store/collections/newNotesWorker';
import { PrinterTicketComponent } from '../../shared/printer/printer-ticket';
import ReactToPrint from 'react-to-print';
import { getAllBranchesAndModels } from '../../store/collections/branchesAndModelsWorker';
import { getVehicleByPlateAndClientId, getVehicleByPlateAndMonthlyId } from '../../store/collections/veiculosWorker';
import { getAccreditedsByPlateAndClientId } from '../../store/collections/accreditedWorker';
import { UserPermissionButton } from '../operation/permission';
import { TicketStatus } from '../../shared/constants';
import { ContactInfoCard } from '../access/contact-info';
import { getAllQueuesByTicketId } from "../../store/collections/queueWorker"
import { getAllRelationshipsByParkId } from '../../store/collections/relationshipWorker';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MensalistasListagemModal } from '../mensalista/clientes';
import { docsToItems } from '../../store/transform.docs';
import { CredenciadosListagemModal } from '../credenciados';
import { QueueCallbackBody } from '../developer/queues/queues';
import { getAllAccessCardsByClientIdAndNumber } from '../../store/collections/accessCardWorker';
import { getParkById } from '../../store/collections/parkWorker';
import { F360SyncAllTickets } from '../../managers/f360Manager';

class TicketSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: "",
            allTickets: [],
            isLoading: false,
            revendaId: getRevendaIdByPark(),
            estacionamentoId: getEstacionamento()?.id,
            clienteId: getCliente()?.id,
            isDeveloper: isDeveloper(),
            isLoadingSettings: true,
            isFound: false,
            caixaFechado: false,
            advancedSearch: false,
            random: Math.random(),
            isAdministrador: isAdministrador(),
            noteReceived: null,
            urlVehicleImagem: null,
            urlVehicleOutImagem: null,
            urlImagesExternalReview: null,
            isOpenManualDiscountModal: false,
            isOpenStatusLoggedModal: false,
            isOpenPaymentsLoggedModal: false,
            editingPayedValue: 0.0,
            editingParkingValue: 0.0,
            isOpenSettingsModal: false,
            advancedSearchDate: null,
            advancedSearchModelOptions: [],
            isOpenModalToRegisterPayment: false,
            formasPagamentos: [],
            advancedSearchModel: '',
            newStatusSelected: "",
            estacionamentoNome: "",
            advancedSearchStatus: "",
            advancedSearchOrder: 'desc',
            settingsAllowToPark: "PERMITIR",
            settingsAllowToParkReason: "",
            advancedSearchLimit: 5,
            key: "externa",
            searchKey: "tickets",
            statusSelected: null,
            statusIndexSelected: 0,
            allMonthliesVehicle: [],
            allAcreditedsVehicle: [],
            allAccessCards: [],
            isOpenAllTicketsModal: false,
            isMenuOpen: false,
            isEditingLimitPermanenceDate: false,
            isOpenAccreditedAssociationModal: false,
            newNumberTicket: "",
            cancelReason: ""
        }
        this.loadParameters()
    }

    componentDidMount = async () => {
        const id = queryString("id")
        const placa = queryString("placa")
        if (placa !== null) {
            this.setState({ isLoading: true })
            this.setState({ value: placa })
            sendClickButton("Buscar", "Por placa: " + placa)
            firebase.firestore().collection('ticket').where('placa', '==', placa.toUpperCase()).where('clienteId', '==', this.state.clienteId).get().then((results) => {
                this._showAllResults(results)
            })
            return
        }
        if (id) {
            this.setState({ isLoading: true })
            const ticket = await getTicketById({ id: id })
            if (ticket) {
                this.fill(ticket)
                this.loadNewNoteIfNeed(ticket)
            } else {
                toastWarning('Não existe um ticket com este número associado ao estacionamento.')
                window.location = '/buscar/ticket'
            }
            this.setState({ isLoading: false })
            return
        }
        const value = queryString("value")
        if (value !== null) {
            this.setState({ value: value }, () => {
                this.search()
            })
        }
        const from = queryString("from")
        if (from === "email") {
            sendScreenView("Buscar", "Ticket | Por e-mail")
        } else {
            sendScreenView("Buscar", "Ticket")
        }
    }

    loadNewNoteIfNeed = async (ticket) => {
        const id = ticket.newNote?.id
        if (id) {
            const newNote = await getNewNoteById({ id: id })
            this.setState({ newNote: newNote })
        }
    }

    loadParameters = async () => {
        const parameters = await getParameter()
        this.setState({ parameters: { ...parameters } })
    }

    search = () => {
        if (isNullOrEmpty(this.state.value)) {
            return
        }
        this.setState({ isLoading: true })
        this.loadTickets()
        this.loadMonthlies()
        this.loadAccrediteds()
        this.loadAccessCardsIfNeed()
        this.setState({ isLoading: false })
    }

    loadTickets = async () => {
        const isPlate = isValidatePlate(this.state.value)
        if (!isPlate) {
            sendClickButton("Buscar", "Por ticket: " + this.state.value)
            const result = await firebase.firestore().collection('ticket').where('ticket', '==', this.state.value).where('clienteId', '==', this.state.clienteId).get()
            const tickets = docsToItems(result)
            if (tickets.length === 0) {
                sendLogByUser("Buscar", `Nenhum ticket encontrado com esta busca ${this.state.value}.`)
                toastWarning("Nenhum ticket encontrado com esta busca.")
            } else if (tickets.length === 1) {
                sendLogByUser("Buscar", "Encontrou 1 resultado")
                this.fill(tickets[0])
            } else {
                this._showAllResults(result)
            }
        } else if (this.state.plate !== '') {
            sendClickButton("Buscar", "Por placa: " + this.state.value)
            const result = await firebase.firestore().collection('ticket').where('placa', '==', this.state.value.toUpperCase()).where('clienteId', '==', this.state.clienteId).get()
            const items = docsToItems(result)
            if (items.length !== 0) {
                this._showAllResults(result)
            } else {
                toastWarning("Nenhum ticket encontrado com esta busca.")
            }
        }
    }

    loadMonthlies = async () => {
        const isPlate = isValidatePlate(this.state.value)
        if (isPlate) {
            const vehicles = await getVehicleByPlateAndClientId({ clientId: this.state.clienteId, placa: this.state.value })
            this.setState({ allMonthliesVehicle: vehicles.filter(e => e.mensalistaId) })
            if (this.state.isOpenAllTicketsModal === false) {
                this.setState({ isOpenAllTicketsModal: vehicles.length > 0 });
            }
        }
    }

    loadAccrediteds = async () => {
        const isPlate = isValidatePlate(this.state.value)
        if (isPlate) {
            const accrediteds = await getAccreditedsByPlateAndClientId({ clienteId: this.state.clienteId, plate: this.state.value })
            this.setState({ allAcreditedsVehicle: accrediteds })
            if (this.state.isOpenAllTicketsModal === false) {
                this.setState({ isOpenAllTicketsModal: accrediteds.length > 0 });
            }
        }
    }

    loadAccessCardsIfNeed = async () => {
        try {
            const accessCards = await getAllAccessCardsByClientIdAndNumber({ clienteId: this.state.clienteId, accessCard: this.state.value })
            this.setState({ allAccessCards: accessCards })
            if (this.state.isOpenAllTicketsModal === false) {
                this.setState({ isOpenAllTicketsModal: accessCards.length > 0 });
            }
        } catch (error) {
            console.log(error)
        }
    }

    _showAllResults = (results) => {
        this.setState({ isLoading: false })
        if (!results.empty) {
            if (results.docs.length === 1) {
                sendLogByUser("Buscar", "1 resultado encontrado")
                this.fill({
                    ...results.docs[0].data(),
                    id: results.docs[0].id
                })
            } else {
                var list = [];
                results.docs.forEach(doc => {
                    list.push({
                        ...doc.data(),
                        id: doc.id
                    })
                })
                sendLogByUser("Buscar", list.length + " resultados encontrados")
                if (this.state.advancedSearchOrder === 'desc') {
                    this.setState({ allTickets: list.sort((a, b) => b.createAt.toDate() - a.createAt.toDate()) });
                } else {
                    this.setState({ allTickets: list.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()) });
                }
                if (this.state.isOpenAllTicketsModal === false) {
                    this.setState({ isOpenAllTicketsModal: list.length > 0 });
                }
            }
        } else {
            toastWarning('Não existe um ticket com este número associado ao estacionamento.')
            sendLogByUser("Buscar", "Nenhum ticket encontrado.")
        }
    }

    advancedSearch = async () => {
        sendClickButton("Buscar", "Por ticket avançada")
        this.setState({ isLoading: true })
        this.setState({ allMonthliesVehicle: [] })
        this.setState({ allAcreditedsVehicle: [] })
        var query = firebase.firestore().collection('ticket')
        query = query.where('clienteId', '==', this.state.clienteId)
        if (!isNullOrEmpty(this.state.advancedSearchStatus)) {
            query = query.where('status', '==', this.state.advancedSearchStatus)
        }
        if (this.state.advancedSearchModel !== '') {
            var model = this.state.advancedSearchModel.split(" ")
            model.shift()
            const modelCapitalized = model.join(" ").charAt(0).toUpperCase() + model.join(" ").slice(1)
            query = query.where('vehicler.modelo', '==', modelCapitalized)
        }
        try {
            const results = await query.orderBy("createAt", this.state.advancedSearchOrder).limit(parseInt(this.state.advancedSearchLimit)).get()
            this._showAllResults(results)
        } catch (error) {
            console.log(error);
        }
    }

    onModelAdvancedSearchFocus = async (e) => {
        const models = await getAllBranchesAndModels()
        const list = models.map(e => { return { title: e.value } })
        this.setState({ advancedSearchModelOptions: list })
    }

    fillTotals = (ticket) => {
        if (ticket.paymentsLogged) {
            const resumes = ticket.paymentsLogged.filter(e => e.canceled !== true).map(e => e.resumo)
            if (resumes.length > 0) {
                var resumo = {
                    ...resumes[0],
                    valorCobrado: resumes.map(e => e.valorCobrado).reduce((a, b) => a + b, 0),
                    valorDesconto: resumes.map(e => e.valorDesconto).reduce((a, b) => a + b, 0),
                    valorEstacionamento: resumes.map(e => e.valorEstacionamento).reduce((a, b) => a + b, 0),
                    valorProdutos: (resumes.map(e => e.valorProdutos).reduce((a, b) => a + b, 0)) + (resumes.map(e => e.valorServicos).reduce((a, b) => a + b, 0)),
                    valorServicos: resumes.map(e => e.valorServicos).reduce((a, b) => a + b, 0),
                }
                this.setState({ resumo: resumo })
            }
        } else {
            this.setState({ resumo: null })
        }
    }

    fill = (ticket) => {
        if (isAdministrador() || ticket.clienteId === getCliente().id) {
            this.setState({ ticket: ticket })
            if (ticket.type === "SALES") {
                this.setState({ key: "produtos" })
            } else {
                const tab = queryString("tab")
                this.setState({ key: tab ?? "externa" })
            }
            this.fillTotals(ticket)
            this.setState({ id: ticket.id }, () => {
                this.updateURL()
            })
            this.setState({ isFound: true })
            this.downloadVehiclerImage()
            this.downloadExternalImage()
            this.downloadVehiclerImages()
            this.loadingNote()
            this.loadParkByTicket(ticket)
            this.setState({ random: Math.random() })
        } else {
            toastWarning('Não existe um ticket com este número associado ao estacionamento.')
            window.location = '/'
        }
    }

    loadParkByTicket = async (ticket) => {
        const park = await getParkById({ id: ticket.estacionamentoId })
        this.setState({ park: park })
    }

    showOnline = () => {
        const url = urlTicketOnlineFromTicket(this.state.ticket, "adm")
        window.open(url, '_blank');
    }

    selectTicket = (ticket) => {
        this.setState({ isOpenAllTicketsModal: false })
        this.fill(ticket)
    }

    downloadVehiclerImages = () => {
        if (this.state.ticket.urlExternalReview && this.state.ticket.urlExternalReview.length !== 0) {
            var storage = firebase.storage();
            var storageRef = storage.ref();
            var list = []
            this.state.ticket.urlExternalReview.forEach(path => {
                storageRef.child(path).getDownloadURL().then((url) => {
                    list.push(url)
                    if (list.length === this.state.ticket.urlExternalReview.length) {
                        this.setState({ urlImagesExternalReview: list })
                    }
                })
            })
        }
    }

    showManualDiscount = (discount) => {
        this.setState({ manualDiscount: discount })
        this.setState({ isOpenManualDiscountModal: true })
    }

    loadingNote = async () => {
        if (this.state.ticket.rps) {
            firebase.firestore().collection('notes').where('clienteId', '==', this.state.ticket.clienteId).where('rpsSerie.serie', '==', this.state.ticket.rps.serie).where('rpsSerie.numero', '==', this.state.ticket.rps.numero).get().then((result) => {
                if (result.docs.length > 0) {
                    const noteReceived = result.docs[0].data()
                    this.setState({ noteReceived: noteReceived.noteReceived })
                }
            })
        }
    }

    downloadVehiclerImage = () => {
        //Entrada
        if (this.state.ticket.urlVehicle) {
            var storage = firebase.storage();
            var storageRef = storage.ref();
            storageRef.child(this.state.ticket.urlVehicle).getDownloadURL().then((url) => {
                this.setState({ urlVehicleImagem: url })
            })
        }
        //Saída
        if (this.state.ticket.urlVehicleOut) {
            var storage = firebase.storage();
            var storageRef = storage.ref();
            storageRef.child(this.state.ticket.urlVehicleOut).getDownloadURL().then((url) => {
                this.setState({ urlVehicleOutImagem: url })
            })
        }
    }

    downloadExternalImage = () => {
        if (this.state.ticket.urlExterno && this.state.ticket.urlExterno !== '') {
            // urlExterno
            var storage = firebase.storage();
            var storageRef = storage.ref();
            storageRef.child(this.state.ticket.urlExterno).getDownloadURL().then((url) => {
                this.setState({ urlImagem: url })
            })
        }
    }

    showSettings = () => {
        this.setState({ isOpenSettingsModal: true, isMenuOpen: false })
        this.loadSettings()
    }

    showMonthlyAssociation = () => {
        this.setState({ isMenuOpen: false })
        if (this.state.ticket.relationshipApplied) {
            toastWarning("Para associar um mensalista você deve primeiro remover o convênio aplicado.")
            return
        }
        this.setState({ isOpenMonthlyAssociationModal: true })
    }

    onConfirmMonthlyAssociation = async (item, plate) => {
        this.setState({ isMenuOpen: false })
        toastLoading("Associando mensalista...")
        const vehiclesInPark = await ticketsParkedByMonthlyId({ monthlyId: item.id })
        if (vehiclesInPark.length >= (item.vagas ?? 1)) {
            if (vehiclesInPark.length === 1) {
                toastDismissLoading()
                toastWarning(`Já existe um veículo ocupando a vaga deste mensalista:\n\n${vehiclesInPark[0].placa ?? "Placa não disponível"}.`)
                return
            } else {
                toastDismissLoading()
                toastWarning(`Já existe ${vehiclesInPark.length} no pátio associado a este mensalista:\n\n${vehiclesInPark.map(e => e.placa).join(", ")}.`)
                return
            }
        }
        const vehicle = plate ? await getVehicleByPlateAndMonthlyId({ placa: plate, monthlyId: item.id }) : null;
        const ticket = this.state.ticket
        ticket.monthly = item
        ticket.placa = plate
        ticket.vehicler = vehicle
        const message = `Mensalista ${item.nome} - ${plate} associado.`
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact(),
            log: item
        })
        await updateTicket(ticket.id, ticket)
        sendLogByUser("Ticket", `${message} no ticket ${ticket.ticket}`)
        toastDismissLoading()
        reloadWithAlert("Mensalista associado com sucesso.")
    }

    onRemoveMonthlyAssociation = async () => {
        this.setState({ isMenuOpen: false })
        const confirm = await dangerConfirm({ message: `Deseja mesmo remover o mensalista associado a este ticket?` });
        if (confirm) {
            toastLoading("Removendo mensalista...")
            const ticket = this.state.ticket
            if (!ticket.changeLogged) {
                ticket.changeLogged = []
            }
            const message = `Mensalista ${ticket.monthly?.nome} removido.`
            ticket.changeLogged.push({
                identifier: generateUUIDV7(),
                message: message,
                createdAt: new Date(),
                createdBy: getUsuarioCompact(),
                log: ticket.monthly
            })
            ticket.monthly = null
            await updateTicket(ticket.id, ticket)
            sendLogByUser("Ticket", message)
            toastDismissLoading()
            reloadWithAlert("Mensalista removido com sucesso.")
        }
    }

    showAccreditedAssociation = () => {
        this.setState({ isMenuOpen: false })
        if (this.state.ticket.relationshipApplied) {
            toastWarning("Para associar um credenciado você deve primeiro remover o convênio aplicado.")
            return
        }
        this.setState({ isOpenAccreditedAssociationModal: true })
    }

    onConfirmAccreditedAssociation = async (accredited, vehicle) => {
        this.setState({ isMenuOpen: false })
        toastLoading("Associando credenciado...")
        const vehiclesInPark = await ticketsParkedByAccreditedId({ accreditedId: accredited.id })
        if (vehiclesInPark.length >= (accredited.vagas ?? 1)) {
            if (vehiclesInPark.length === 1) {
                toastDismissLoading()
                toastWarning(`Já existe um veículo ocupando a vaga deste credenciado:\n\n${vehiclesInPark[0].placa ?? "Placa não disponível"}.`)
                return
            } else {
                toastDismissLoading()
                toastWarning(`Já existe ${vehiclesInPark.length} no pátio associado a este credenciado:\n\n${vehiclesInPark.map(e => e.placa).join(", ")}.`)
                return
            }
        }
        const ticket = this.state.ticket
        ticket.accredited = accredited
        ticket.placa = vehicle.placa
        ticket.vehicler = vehicle
        const message = `Credenciado ${accredited.nome} - ${vehicle.placa} associado.`
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact(),
            log: accredited
        })
        await updateTicket(ticket.id, ticket)
        sendLogByUser("Ticket", `${message} no ticket ${ticket.ticket}`)
        toastDismissLoading()
        reloadWithAlert("Credenciado associado com sucesso.")
    }

    onRemoveAccreditedAssociation = async () => {
        this.setState({ isMenuOpen: false })
        const confirm = await dangerConfirm({ message: `Deseja mesmo remover o credenciado associado a este ticket?` });
        if (confirm) {
            toastLoading("Removendo credenciado...")
            const ticket = this.state.ticket
            if (!ticket.changeLogged) {
                ticket.changeLogged = []
            }
            const message = `Credenciado ${ticket.accredited?.nome} removido.`
            ticket.changeLogged.push({
                identifier: generateUUIDV7(),
                message: message,
                createdAt: new Date(),
                createdBy: getUsuarioCompact(),
                log: ticket.accredited
            })
            ticket.accredited = null
            await updateTicket(ticket.id, ticket)
            sendLogByUser("Ticket", message)
            toastDismissLoading()
            reloadWithAlert("Credenciado removido com sucesso.")
        }
    }

    onRemoveChangeLog = async (item, index) => {
        const confirm = await dangerConfirm({ message: `Deseja mesmo remover esta mudança?` });
        if (confirm) {
            const ticket = this.state.ticket
            ticket.changeLogged.splice(index, 1)
            this.setState({ ticket: ticket })
            await updateTicket(ticket.id, ticket)
        }
    }

    hideSettings = () => {
        this.setState({ isOpenSettingsModal: false })
    }

    loadSettings = async () => {
        this.setState({ isLoadingSettings: true })
        const settings = await getTicketSettings({
            plate: this.state.ticket.placa?.replaceAll(" ", "")?.replaceAll("-", "") ?? ""
        })
        if (settings) {
            this.setState({ settingsAllowToPark: settings.allowToPark })
            this.setState({ settingsAllowToParkReason: settings.allowToParkReason })
        }
        this.setState({ isLoadingSettings: false })
    }

    saveSettings = async () => {
        if (!this.verifySettingsFields()) {
            return
        }
        this.setState({ isLoadingSettings: true })
        await updateTicketSettings({
            plate: this.state.ticket.placa?.replaceAll(" ", "")?.replaceAll("-", "") ?? "",
            allowToPark: this.state.settingsAllowToPark,
            allowToParkReason: this.state.settingsAllowToParkReason
        })
        toastSuccess("Configurações do veículo alteradas com sucesso!")
        await saveLastUpdate()
        this.hideSettings()
    }

    verifySettingsFields = () => {
        if (this.state.settingsAllowToPark !== "PERMITIR" && this.state.settingsAllowToParkReason === "") {
            toastWarning("Informe o motivo da exclusão do veículo")
            return false
        }
        return true
    }

    removeTicket = async () => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo apagar este ticket?\nEle não aparecerá mais no relatório de caixa.` });
        if (confirm) {
            toastLoading("Apagando...")
            const id = this.state.ticket.id
            await removeTicketById(id)
            toastDismissLoading()
            goToWindowWithAlert("/buscar/ticket", "Ticket apagado com sucesso.")
        }
    }

    showIsCancelingTicketModal = () => {
        this.setState({ isCancelingTicket: true })
    }

    cancelTicket = async () => {
        toastLoading("Cancelando...")
        const ticket = this.state.ticket
        const user = getUsuarioCompact()
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        if (!ticket.statusLogged) {
            ticket.statusLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: `Ticket ${ticket.ticket} Cancelado`,
            createdAt: new Date(),
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email
            }
        })
        ticket.statusLogged.push({
            status: TicketStatus.CANCELED,
            createBy: user,
            createAt: new Date(),
            currentCashier: ticket.cachier,
            canceled: false,
            reason: this.state.cancelReason
        })
        if (ticket.status !== TicketStatus.PARKING && !isNullOrEmpty(ticket.paymentsLogged)) {
            for (let index = 0; index < ticket.paymentsLogged.length; index++) {
                ticket.paymentsLogged[index].canceled = true
            }
        }
        ticket.status = TicketStatus.CANCELED
        sendLogByUser("Ticket", `Cancelou o ticket ${this.state.ticket.ticket}`, this.state.ticket)
        await updateTicketById({ id: ticket.id, data: ticket })
        toastDismissLoading()
        toastSuccess("Ticket cancelado com sucesso!")
        reloadWindow()
    }

    verifyCancelTicket = () => {
        if (isNullOrEmpty(this.state.cancelReason)) {
            toastWarning("Você precisa informar um motivo para cancelar esse ticket.")
        } else {
            this.cancelTicket()
        }
    }

    removeRelationshipApplied = async () => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover o convênio associado a este ticket?\nEle não aparecerá mais no relatório de caixa.` });
        if (confirm) {
            const ticket = this.state.ticket
            const user = getUsuarioCompact()
            sendLogByUser("Ticket", `Removeu o convênio associado ${ticket.relationshipApplied?.nome}`, this.state.ticket)
            ticket.relationshipApplied = null
            await updateTicket(ticket.id, ticket)
            this.setState({ ticket: ticket })
        }
    }

    showAccessHistory = async () => {
        toastLoading("Carregando...")
        const tickets = await ticketsByPlatesAndClientId({ placas: [this.state.ticket.placa.toUpperCase()] })
        this.setState({ tickets: tickets })
        this.setState({ showModalAccessHistory: true })
        toastDismissLoading()
    }

    onCloseAnalyticsDialog = () => {
        this.setState({ showModalAccessHistory: false })
    }

    onChangePaymentCashier = async (payment) => {
        this.setState({ currentPayment: payment })
        const currentCashier = await getCashierById({ id: payment.caixaId })
        const allLastCashiers = await getAllCashiers({ estacionamentoId: this.state.ticket.estacionamentoId })
        this.setState({ currentCashier: currentCashier })
        this.setState({ allLastCashiers: allLastCashiers.filter(e => e.id !== currentCashier.id) })
        this.setState({ isOpenChangePaymentCashierModal: true })
    }

    onChangePaymentSelectCashier = (e) => {
        const newCashier = this.state.allLastCashiers.filter(f => f.id === e.target.value)
        this.setState({ newCashier: newCashier[0] })
    }

    confirmChangePaymentFromCashier = async () => {
        var confirm = await warningConfirm({ message: `Deseja mesmo alterar o caixa deste pagamento?` });
        if (confirm) {
            var currentPayment = this.state.currentPayment
            var ticket = this.state.ticket
            const message = `Caixa alterado de ${this.state.currentCashier.sequence} para ${this.state.newCashier.sequence}.`

            this.saveChangeLogged(message)
            ticket.caixasIds = ticket.caixasIds.filter(e => e !== currentPayment.caixaId)
            currentPayment.caixaId = this.state.newCashier.id
            ticket.caixasIds.push(this.state.newCashier.id)
            await updateTicket(ticket.id, ticket)
            sendLogByUser("Ticket", message, ticket)
            reloadWithAlert("Caixa do pagamento alterado com sucesso!")
        }
    }

    saveChangeLogged = (message) => {
        const user = getUsuario()
        var ticket = this.state.ticket
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: {
                id: user.id,
                nome: user.nome,
                email: user.email
            }
        })
    }

    onChangePaymentValues = async (payment, indexPayment) => {
        if (payment.seniorSyncStatus === "sync") {
            toastWarning("Não é possível editar um pagamento já sincronizado com a Sênior.")
            return
        }
        toastLoading("Aguarde...")
        this.setState({ currentPayment: payment })
        this.setState({ indexPayment: indexPayment })
        this.setState({ editingParkingValue: payment.resumo.valorEstacionamento })
        this.setState({ editingPayedValue: payment.resumo.valorCobrado })
        this.setState({ editingDiscountValue: payment.resumo.valorDesconto })
        const formasPagamentos = await getFormasPagamentosAtivas({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ formasPagamentos: formasPagamentos })
        const formaPagamento = formasPagamentos.filter(e => e.id === payment.formaPagamento.id)
        if (formaPagamento.length > 0) {
            this.setState({ formaDePagamentoSelecionada: formaPagamento[0].id })
        }
        var paymentTables = await getAllPaymentTables({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ paymentTables: paymentTables })
        this.setState({ paymentTableSelected: payment.resumo.tabelaPreco?.id })

        var relationships = await getAllRelationshipsByParkId({ estacionamentoId: this.state.estacionamentoId })
        this.setState({ relationships: relationships })
        this.setState({ relationshipSelected: payment.resumo.convenio?.id })

        this.setState({ isOpenChangePaymentValueModal: true })
        toastDismissLoading()
    }

    onRemovePayment = async (payment, index) => {
        if (payment.seniorSyncStatus === "sync") {
            toastWarning("Para remover um pagamento sincronizado na Sênior, primeiro é preciso cancela-lo.")
            return
        }
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover este pagamento?` });
        if (confirm) {
            toastLoading("Removendo...")
            var ticket = this.state.ticket
            const message = `Removeu um pagamento em ${payment.formaPagamento?.nome} no valor de R$${payment.resumo.valorCobrado.toFixed(2)} do ticket ${ticket.ticket}.`
            ticket.paymentsLogged = ticket.paymentsLogged.filter((_, i) => i !== index)
            this.saveChangeLogged(message)
            ticket.caixasIds = ticket.paymentsLogged.map(e => e.caixaId)
            await updateTicket(ticket.id, ticket)
            this.setState({ ticket: ticket })
            sendLogByUser("Ticket", message, ticket)
            toastDismissLoading()
            reloadWithAlert("Pagamento removido com sucesso.")
        }
    }

    changeValorCobradoTotal = (a, b, floatvalue) => {
        this.setState({ editingPayedValue: floatvalue })
    }

    changeValorEstacionadoTotal = (a, b, floatvalue) => {
        this.setState({ editingParkingValue: floatvalue })
    }

    changeValorDescontoTotal = (a, b, floatvalue) => {
        this.setState({ editingDiscountValue: floatvalue })
    }

    confirmChangePaymentValue = async () => {
        const formaDePagamentoSelecionada = this.state.formaDePagamentoSelecionada
        if (isNullOrEmpty(formaDePagamentoSelecionada)) {
            toastWarning("Informa a forma de pagamento.")
            return
        }
        const paymentTableSelected = this.state.paymentTableSelected
        if (isNullOrEmpty(paymentTableSelected)) {
            toastWarning("Informa uma tabela de preço.")
            return
        }
        var confirm = await warningConfirm({ message: `Deseja mesmo alterar o valor pago neste pagamento?` });
        if (confirm) {
            toastLoading("Salvando...")
            const formaPagamento = this.state.formasPagamentos.filter(e => e.id === formaDePagamentoSelecionada)[0]
            var currentPayment = this.state.currentPayment
            var ticket = this.state.ticket
            const message = `Alterou o valor pago do ticket ${ticket.ticket} de ${currentPayment.resumo.valorCobrado.toFixed(2)} para ${this.state.editingPayedValue.toFixed(2)}.`
            this.saveChangeLogged(message)
            var findPayment = this.state.ticket.paymentsLogged[this.state.indexPayment]
            findPayment.formaPagamento = formaPagamento
            findPayment.resumo.valorCobrado = this.state.editingPayedValue
            findPayment.resumo.valorEstacionamento = this.state.editingParkingValue
            if (!isNullOrEmpty(this.state.editingDiscountValue)) {
                findPayment.resumo.valorDesconto = this.state.editingDiscountValue
            }
            const table = this.state.paymentTables.filter(e => e.id === this.state.paymentTableSelected)[0]
            if (table) {
                findPayment.resumo.tabelaPreco = {
                    id: table.id,
                    tipo: table.tipo,
                    nome: table.nome
                }
            }
            if (!isNullOrEmpty(this.state.relationshipSelected)) {
                const relationship = this.state.relationships.filter(e => e.id === this.state.relationshipSelected)[0]
                findPayment.resumo.convenio = relationship
            } else {
                findPayment.resumo.convenio = undefined
            }
            var tipo = `${findPayment.resumo?.order?.tipo?.split(" - ")[0]} - ${formaPagamento.tipo?.toUpperCase()}`
            if (findPayment.resumo?.order?.tipo) {
                findPayment.resumo.order.tipo = tipo
            }
            sendLogByUser("Ticket", message, ticket)
            await updateTicket(ticket.id, ticket)
            toastDismissLoading()
            reloadWithAlert("Valor do pagamento alterado com sucesso!")
        }
    }

    syncSeniorBadge = (payment, index) => {
        if (!this.state.parameters?.habilitarSeniorERP || payment.resumo?.valorCobrado === 0) {
            return <></>
        }
        if (!payment.seniorSyncStatus) {
            return <Tooltip title="Este pagamento ainda não foi sincronizado com a Sênior." placement="top">
                <div className='badge text-bg-warning btn-block mx-3 text-white' role='button' onClick={(e) => this.showSeniorSyncModal(payment, index)}>
                    Não sincronizado
                    <i className="fas fa-long-arrow-right mx-1" />
                </div>
            </Tooltip>

        }
        if (payment.seniorSyncStatus === "sync") {
            return <Tooltip title="Este pagamento já foi sincronizado com a Sênior." placement="top">
                <div className='badge text-bg-success btn-block mx-3' role='button' onClick={(e) => this.showSeniorCancelSyncModal(payment, index)}>
                    <span className='mx-3 text-white'>Sincronizado</span>
                </div>
            </Tooltip>
        }
        if (payment.seniorSyncStatus === "error") {
            return <Tooltip title="Erro ao tentar sincronizar pagamento com a Sênior." placement="top">
                <div className='badge text-bg-danger btn-block mx-3' role='button' onClick={(e) => this.showSeniorSyncModal(payment, index)}>
                    <span className='mx-3 text-white'>Erro ao sincronizar</span>
                </div>
            </Tooltip>
        }
        if (payment.seniorSyncStatus === "canceled") {
            return <Tooltip title="Este pagamento não esta mais sincronizado a Sênior." placement="top">
                <div className='badge text-bg-danger btn-block mx-3' role='button' onClick={(e) => this.showSeniorSyncModal(payment, index)}>
                    <span className='mx-3 text-white'>Sincronismo cancelado</span>
                </div>
            </Tooltip>
        }
    }

    syncF360Badge = (payment, index) => {
        if (this.state.ticket.status === TicketStatus.CANCELED || !this.state.parameters?.habilitarF360ERP || payment.resumo?.valorCobrado === 0) {
            return <></>
        }
        if (!payment.F360SyncStatus) {
            return <Tooltip title="Este pagamento ainda não foi sincronizado com a F360." placement="top">
                <div className='badge text-bg-warning btn-block mx-3 text-white' role='button' onClick={(e) => this.showF360SyncModal(payment, index)}>
                    F360 não sincronizado
                    <i className="fas fa-long-arrow-right mx-1" />
                </div>
            </Tooltip>

        }
        if (payment.F360SyncStatus === "synced") {
            return <Tooltip title={`Este pagamento já foi sincronizado com a F360: ${payment.f360SyncCoupon}`} placement="top">
                <div className='badge text-bg-success btn-block mx-3' role='button' onClick={(e) => this.showF360SyncModal(payment, index)}>
                    <span className='mx-3 text-white'>F360 Sincronizado</span>
                </div>
            </Tooltip>
        }
        if (payment.F360SyncStatus === "error") {
            return <Tooltip title="Erro ao tentar sincronizar pagamento com a F360." placement="top">
                <div className='badge text-bg-danger btn-block mx-3' role='button' onClick={(e) => this.showF360SyncModal(payment, index)}>
                    <span className='mx-3 text-white'>F360 erro ao sincronizar</span>
                </div>
            </Tooltip>
        }
    }

    onlinePaymentBadge = (payment, index) => {
        if (payment.online) {
            return <Tooltip title="Este pagamento foi feito de forma online." placement="top">
                <div className='badge text-bg-success btn-block mx-3 text-white' role='button'>
                    Pagamento online
                </div>
            </Tooltip>
        }
        return <></>
    }

    showSeniorSyncModal = (payment, index) => {
        this.setState({ currentPayment: payment })
        this.setState({ currentPaymentIndex: index })
        this.setState({ isShowingSeniorSyncModal: true })
    }

    showF360SyncModal = (payment, index) => {
        this.setState({ currentPayment: payment })
        this.setState({ currentPaymentIndex: index })
        this.setState({ isShowingF360SyncModal: true })
    }

    hideSeniorSyncModal = () => {
        this.setState({ isShowingSeniorSyncModal: false })
    }

    hideF360SyncModal = () => {
        this.setState({ isShowingF360SyncModal: false })
    }

    confirmSeniorSyncModal = async () => {
        this.executeSeniorAction('POST')
    }

    showSeniorCancelSyncModal = (payment, index) => {
        this.setState({ currentPayment: payment })
        this.setState({ currentPaymentIndex: index })
        this.setState({ isShowingSeniorCancelSyncModal: true })
    }

    confirmSeniorCancelSync = async () => {
        this.executeSeniorAction('DELETE')
    }

    executeSeniorAction = async (type) => {
        this.setState({ isShowingSeniorLoading: true })
        const index = this.state.currentPaymentIndex
        const option = {
            method: type
        };
        const result = await fetchFunction(`/senior/ticket/${this.state.id}/payment/${index}`, option)
        if (result.success) {
            reloadWithAlert(result.message)
        } else {
            toastWarning(result.message)
            this.setState({ isShowingSeniorLoading: false })
        }
    }

    confirmF360SyncModal = async () => {
        toastLoading("Aguarde...")
        const parameter = await getParameterByParkId({ estacionamentoId: this.state.ticket.estacionamentoId })
        await F360SyncAllTickets({ parameters: parameter, tickets: [this.state.ticket] })
        await delay(1000)
        toastDismissLoading()
        reloadWithAlert("Pagamento enviados para processamento.")
    }

    hideSeniorCancelSyncModal = () => {
        this.setState({ isShowingSeniorCancelSyncModal: false })
    }

    changeInfoMenu = (key) => {
        this.setState({ key: key }, () => {
            this.updateURL()
        })
    }

    updateURL = () => {
        const id = this.state.id
        if (id) {
            setPathWindow(`/buscar/ticket?id=${id}&tab=${this.state.key}`)
        } else {
            setPathWindow(`/buscar/ticket?value=${this.state.value}&tab=${this.state.key}`)
        }
    }

    showModalToRegisterPayment = async () => {
        this.setState({ isOpenPaymentTicketModal: true })
    }

    showModalBackToPark = async () => {
        this.setState({ isOpenModalBackToPark: true })
    }

    confirmBackToPark = async () => {
        const ticket = this.state.ticket
        ticket.caixaFechado = false
        await updateTicketById({ id: ticket.id, data: ticket })
        reloadWithAlert("Ticket alterado com sucesso!")
    }

    confirmFinishTicket = async () => {
        toastLoading('Alterando...')
        const ticket = this.state.ticket
        ticket.caixaFechado = true
        await updateTicketById({ id: ticket.id, data: ticket })
        toastDismissLoading()
        reloadWithAlert("Ticket alterado com sucesso!")
    }

    changeValorEstacionadoPaymentTotal = (a, b, floatvalue) => {
        this.setState({ editingParkingValue: floatvalue })
        this.setState({ editingPayedValue: floatvalue })
    }

    onSelectedCashier = (cashier) => {
        this.setState({ newCashier: cashier })
    }

    changeStatus = (item, index) => {
        this.setState({ statusSelected: item })
        this.setState({ statusIndexSelected: index })
        this.setState({ isOpenModalChangeStatus: true })
    }

    removeStatus = async (item, index) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo remover este status?` });
        if (confirm) {
            var ticket = this.state.ticket
            ticket.statusLogged = ticket.statusLogged.filter((_, i) => i !== index)
            const message = `Removeu o status ${item.status} do ticket.`
            this.saveChangeLogged(message)
            sendLogByUser("Ticket", message, ticket)
            await updateTicket(ticket.id, ticket)
            reloadWithAlert("Status removido com sucesso.")
        }
    }

    confirmNewStatus = async () => {
        var newStatus = this.state.newStatusSelected
        if (!isNullOrEmpty(newStatus)) {
            toastLoading("Aguarde...")
            var ticket = this.state.ticket
            const message = `Alterou o último status do ticket de ${ticket.status} para ${newStatus}.`
            if (newStatus === TicketStatus.PAYED_AND_DELIVERED) {
                if (isNullOrEmpty(ticket.paymentsLogged)) {
                    const cashier = await activeCashier({ estacionamentoId: this.state.estacionamentoId })
                    if (!cashier) {
                        toastDismissLoading()
                        toastWarning("É preciso ter um caixa aberto para executar esta ação.")
                        return
                    }
                    const user = getUsuarioCompact()
                    if (isNullOrEmpty(ticket.changeLogged)) {
                        ticket.changeLogged = []
                    }
                    if (isNullOrEmpty(ticket.statusLogged)) {
                        ticket.statusLogged = []
                    }
                    if (isNullOrEmpty(ticket.paymentsLogged)) {
                        ticket.paymentsLogged = []
                    }
                    if (isNullOrEmpty(ticket.caixasIds)) {
                        ticket.caixasIds = []
                    }
                    var now = Moment(ticket.createAt.toDate())
                    var end = Moment(new Date())
                    var duration = Moment.duration(end.diff(now)).asMinutes() ?? 0
                    const payment = {
                        caixaId: cashier.id,
                        identifier: generateUUIDV7(),
                        user: user,
                        resumo: {
                            valorEstacionamento: 0,
                            valorProdutos: 0,
                            valorServicos: 0,
                            valorDesconto: 0,
                            valorCobrado: 0,
                            startParking: ticket.createAt,
                            finishParking: new Date(),
                            permanencia: duration,
                            permanenciaMaxima: end.toDate(),
                            type: "NORMAL_DELIVERY",
                            order: {
                                tipo: `ADMINISTRATIVO - NÃO INFORMADO`
                            },
                        },
                        createAt: new Date(),
                        canceled: false,
                        online: false
                    }
                    ticket.paymentsLogged.push(payment)
                    ticket.caixasIds.push(cashier.id)
                    ticket.closedAt = new Date()
                    ticket.closedBy = user.email
                }
                ticket.caixaFechado = this.state.caixaFechado ?? false
            }
            ticket.statusLogged[this.state.statusIndexSelected].status = newStatus
            ticket.status = newStatus
            this.saveChangeLogged(message)
            await updateTicketById({ id: ticket.id, data: ticket })
            sendLogByUser("Ticket", message, ticket)
            toastDismissLoading()
            reloadWithAlert("Status alterado com sucesso.")
        } else {
            toastWarning("Informe o novo status")
        }
    }

    statusClassName = (item) => {
        switch (item.status) {
            case 'Cancelado': return "badge text-bg-danger btn-block w-100"
            case 'Estacionado': return "badge text-bg-primary btn-block w-100"
            case 'Pago e Estacionado': return "badge text-bg-info text-white btn-block w-100"
            case 'Pago e Entregue': return "badge text-bg-success btn-block w-100"
            case 'Pago e Aguardando': return "badge text-bg-warning text-white btn-block w-100"
        }
    }

    colorCard = (item) => {
        switch (item.status) {
            case 'Cancelado': return "danger"
            case 'Estacionado': return "primary"
            case 'Pago e Estacionado': return "info"
            case 'Pago e Entregue': return "success"
            case 'Pago e Aguardando': return "warning"
        }
    }

    removeTicketWithItem = async (item) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo apagar este ticket?\nEsta ação não poderá ser desfeita.` });
        if (confirm) {
            const allTickets = this.state.allTickets.filter(e => e.id !== item.id)
            this.setState({ allTickets: allTickets })
            const user = getUsuarioCompact()
            sendLogByUser(`Removeu o ticket ${item.ticket}`, this.state.ticket)
            await removeTicketById(item.id)
        }
    }

    showModalChangeLogged = () => {
        this.setState({ isOpenModalChangeLogged: true })
    }

    onChangeNewNoteForm = (data) => {
        this.setState({ note: data })
    }

    onCloseSearchAndPaymentModal = () => {
        this.setState({ isOpenPaymentTicketModal: false })
    }

    showExportChangesItems = () => {
        const items = this.state.ticket.changeLogged.map(item => {
            return {
                "Ticket": this.state.ticket.ticket,
                "Criado em": Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss"),
                "Operador": item.createdBy.nome,
                "Status": item.message
            }
        })
        exportAndDownloadXLS(items, `Mudanças de status do ticket ${this.state.ticket.ticket}`, ",")
    }

    editObservation = async () => {
        this.setState({ observation: this.state.ticket.observation })
        this.setState({ isNoteEditing: true })
    }

    isPaymentTableEditing = async () => {
        toastLoading("Aguarde...")
        const paymentTables = await getAllActivePaymentTables({ estacionamentoId: this.state.ticket.estacionamentoId })
        toastDismissLoading()
        if (isNullOrEmpty(paymentTables)) {
            toastWarning("É preciso ter pelo menos uma tabela de preço para editar a tabela de preço.")
        } else {
            this.setState({ paymentTables: paymentTables }, () => {
                this.setState({ newTableSelectedInCheckInId: this.state.ticket.tableSelectedInCheckIn?.id ?? "" })
            })
            this.setState({ isOpenChangePaymentTableModal: true })
        }
    }

    confirmNewPaymentTable = async () => {
        if (isNullOrEmpty(this.state.newTableSelectedInCheckInId)) {
            toastWarning("É preciso selecionar uma tabela de preço.")
            return
        }
        if (!isNullOrEmpty(this.state.ticket.tableSelectedInCheckIn) && this.state.ticket.tableSelectedInCheckIn?.id === this.state.newTableSelectedInCheckInId) {
            toastWarning("Esta tabela de preço já está associada a este ticket.")
            return
        }
        toastLoading("Salvando...")
        const ticket = this.state.ticket
        const newTable = this.state.paymentTables.find(e => e.id === this.state.newTableSelectedInCheckInId)
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        var message = ""
        if (isNullOrEmpty(ticket.tableSelectedInCheckIn)) {
            message = `Adicionou a tabela de preço para ${newTable?.nome}`
        } else {
            message = `Alterou a tabela de preço de ${ticket.tableSelectedInCheckIn?.nome} para ${newTable?.nome}`
        }
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        ticket.tableSelectedInCheckIn = newTable
        await updateTicketById({ id: ticket.id, data: ticket })
        this.setState({ isOpenChangePaymentTableModal: false })
        toastDismissLoading()
        reloadWithAlert("Tabela de preço alterada com sucesso.")
    }

    openNewPlateModal = () => {
        this.setState({ isOpenNewPlateModal: true })
    }

    saveNewPlate = async () => {
        if (isNullOrEmpty(this.state.placa)) {
            toastWarning("Informe uma placa.")
            return
        }
        const isPlate = isValidatePlate(this.state.placa)
        if (!isPlate) {
            toastWarning("Informe uma placa válida.")
            return
        }
        if (this.state.placa === this.state.ticket.placa) {
            toastWarning("Esta placa já esta associada a este ticekt.")
            return
        }
        toastLoading("Verificando...")
        const existed = await getTicketByPlateAndParkId({ plate: this.state.placa, parkId: this.state.ticket.estacionamentoId })
        if (existed && existed.id !== this.state.ticket.id) {
            toastDismissLoading()
            toastWarning("Já existe um ticket com esta placa.")
            return
        }
        toastLoading("Salvando...")
        const ticket = this.state.ticket
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        const message = `Adicionou a placa ${this.state.placa?.toUpperCase()} no ticket `
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        ticket.placa = this.state.placa?.toUpperCase()
        await updateTicketById({ id: ticket.id, data: ticket })
        sendLogByUser("Ticket", message, ticket)
        toastDismissLoading()
        reloadWithAlert("Placa adicionada com sucesso.")
    }

    saveNewObservation = async () => {
        if (isNullOrEmpty(this.state.observation)) {
            toastWarning("É preciso preencher o campo de observação.")
            return
        }
        toastLoading("Salvando observação...")
        const ticket = this.state.ticket
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        const message = `Editou a observação do ticket de ${this.state.ticket.observation} para ${this.state.observation}`
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        ticket.observation = this.state.observation
        await updateTicketById({ id: ticket.id, data: ticket })
        sendLogByUser("Ticket", message, ticket)
        toastDismissLoading()
        reloadWithAlert("Observação salva com sucesso.")
    }

    handleClick = (event) => {
        this.anchorEl = event.currentTarget
        this.setState({ isMenuOpen: true })
    }

    handleClose = () => {
        this.anchorEl = null
        this.setState({ isMenuOpen: false })
    }

    showLimitPermanenceModal = () => {
        this.setState({ isEditingLimitPermanenceDate: true })
    }

    hideLimitedPermanenceModal = () => {
        this.setState({ isEditingLimitPermanenceDate: false })
    }

    changeLimitPermanenceDate = async (newValue) => {
        if (Moment(newValue).isBefore(Moment())) {
            toastWarning("Não é possível definir uma data máxima de saída menor que hoje.")
            return
        }
        toastLoading("Salvando nova data de permanência máxima...")
        const ticket = this.state.ticket
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        const message = `Editou a data de permanência do ticket de ${Moment(ticket.limitPermanence.seconds * 1000).format("DD/MM/YYYY HH:mm")} para ${Moment(newValue).format("DD/MM/YYYY HH:mm")}`
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        ticket.limitPermanence = newValue.toDate()
        await updateTicketById({ id: ticket.id, data: ticket })
        sendLogByUser("Ticket", message, ticket)
        toastDismissLoading()
        reloadWithAlert("Nova data de permanência máxima definida com sucesso")
    }

    showDatadog = () => {
        const url = `https://us5.datadoghq.com/logs?query=%40ticket_id%3A${this.state.ticket.id}&agg_m=count&agg_m_source=base&agg_t=count&cols=host%2Cservice&fromUser=true&messageDisplay=inline&refresh_mode=sliding&storage=hot&stream_sort=desc&viz=stream`
        goToNewWindow(url)
    }

    showGCP = () => {
        const url = `https://console.cloud.google.com/logs/query;query=SEARCH%2528%22${this.state.ticket.ticket}%22%2529`
        goToNewWindow(url)
    }

    showFirebase = () => {
        goToFirebase({ collection: 'ticket', id: this.state.ticket.id })
    }

    showProccess = async () => {
        toastLoading("Buscando processo...")
        const items = await getAllQueuesByTicketId({ ticketId: this.state.ticket.id })
        this.setState({ callbackItems: items })
        toastDismissLoading()
        this.setState({ isOpenCallbacks: true })
    }

    hideCallbacks = () => {
        this.setState({ isOpenCallbacks: false })
    }

    editNumerTicket = async () => {
        this.setState({ editingNumberTicketState: "search" })
        this.setState({ isEditingNumberTicket: true })
        this.setState({ newNumberTicket: this.state.ticket.ticket })
    }

    confirmNewTicketNumber = async () => {
        const number = parseInt(this.state.newNumberTicket).toString()
        if (this.state.ticket.ticket === number) {
            toastWarning("Você precisa informar um número diferente.")
            return
        }
        if (isNullOrEmpty(number)) {
            toastWarning("Informe o novo número do ticket.")
            return
        }
        if (!onlyNumbers(number)) {
            toastWarning("O número do ticket não pode conter letras.")
            return
        }
        if (number.length < 6) {
            toastWarning("O número do ticket deve ter no minímo 6 dígitos.")
            return
        }
        if (number.length > 13) {
            toastWarning("O número do ticket deve ter no máximo 13 dígitos.")
            return
        }
        toastLoading("Aguarde...")
        const ticketExisted = await getTicketByNumberAndParkId({ ticket: number, parkId: this.state.estacionamentoId })
        if (ticketExisted) {
            toastDismissLoading()
            this.setState({ ticketExisted: ticketExisted })
            this.setState({ editingNumberTicketState: "existed" })
        } else {
            toastLoading("Salvando...")
            const ticket = this.state.ticket
            if (!ticket.changeLogged) {
                ticket.changeLogged = []
            }
            ticket.changeLogged.push({
                identifier: generateUUIDV7(),
                message: `Editou o número do ticket de ${ticket.ticket} para ${number}`,
                createdAt: new Date(),
                createdBy: getUsuarioCompact()
            })
            sendLogByUser("Ticket", `Editou o número do ticket de ${ticket.ticket} para ${number}`, ticket)
            ticket.ticket = number
            await updateTicketById({ id: ticket.id, data: ticket })
            toastDismissLoading()
            reloadWithAlert("Novo número do ticket definido com sucesso.")
        }
    }

    confirmNewTicketNumberAssociating = async () => {
        toastLoading("Salvando...")
        const number = parseInt(this.state.newNumberTicket).toString()
        const ticketExisted = this.state.ticketExisted
        const ticket = this.state.ticket
        if (!ticket.changeLogged) {
            ticket.changeLogged = []
        }
        const message = `Editou o número do ticket de ${ticket.ticket} para ${number} removendo o registro antigo.`
        ticket.changeLogged.push({
            identifier: generateUUIDV7(),
            message: message,
            createdAt: new Date(),
            createdBy: getUsuarioCompact()
        })
        sendLogByUser("Ticket", message, ticket)
        ticket.ticket = number
        if (isNullOrEmpty(ticket.placa)) {
            ticket.placa = ticketExisted.placa
        }
        await updateTicketById({ id: ticket.id, data: ticket })
        await removeTicketById(ticketExisted.id)
        toastDismissLoading()
        reloadWithAlert("Novo número do ticket definido com sucesso.")
    }

    render() {
        return (
            <>
                <NavBar>
                    <div className="row">
                        <div className='col-lg-3 mb-4'>
                            <div className="card">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    Buscar registro
                                </div>
                                <div className="card-body">
                                    <AlertSuccess tag="Novidades" message="Agora procuramos o tickets lançados, veículos de mensalistas e credenciados entre todos os estabelecimentos da rede de uma só vez." />
                                    {
                                        this.state.advancedSearch === false && <>
                                            <div className="row">
                                                <div className='col-lg-12'>
                                                    {
                                                        this.state.parameters?.habilitarCartaoParaAcesso ?
                                                            <label>Número do ticket, cartão de acesso ou placa.</label> :
                                                            <label>Número do ticket ou placa.</label>
                                                    }
                                                    <input type="text" value={this.state.value} onChange={(e) => { this.setState({ value: e.target.value?.toUpperCase() }) }} className="form-control" required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                {
                                                    this.state.isLoading === false &&
                                                    <div className="col-lg-12 my-3">
                                                        <div className="btn-group">
                                                            <Button className='btn btn-primary' onClick={this.search} variant="default">Buscar</Button>
                                                            <Button className='btn btn-secondary' onClick={(e) => this.setState({ advancedSearch: true })} variant="default">Busca avançada</Button>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.isLoading === true &&
                                                    <div className="col-lg-12 my-3">
                                                        <Loading />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.advancedSearch === true && <>
                                            <div className="row">
                                                <div className='col-lg-12'>
                                                    <label>Modelo do veículo</label>
                                                    <Autocomplete
                                                        id="asynchronous-demo"
                                                        open={this.props.isOpen}
                                                        onOpen={(e) => this.setState({ isOpen: true })}
                                                        onClose={(e) => this.setState({ isOpen: false })}
                                                        onFocus={this.onModelAdvancedSearchFocus}
                                                        onChange={(e, newValue) => this.setState({ advancedSearchModel: newValue.title })}
                                                        isOptionEqualToValue={(option, value) => option.title === value.title}
                                                        getOptionLabel={(option) => option.title}
                                                        options={this.state.advancedSearchModelOptions}
                                                        loading={this.state.advancedSearchModelIsLoading}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                size="small" className='btn-block'
                                                                label="Selecionar"
                                                                InputProps={{
                                                                    ...params.InputProps,
                                                                    endAdornment: (
                                                                        <React.Fragment>
                                                                            {this.state.advancedSearchModelIsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                            {params.InputProps.endAdornment}
                                                                        </React.Fragment>
                                                                    ),
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                    <label>Status</label>
                                                    <select className="form-select" value={this.state.advancedSearchStatus} onChange={(e) => this.setState({ advancedSearchStatus: e.target.value })}>
                                                        <option value="">Todos</option>
                                                        <option value="Estacionado">Estacionado</option>
                                                        <option value="Pago e Estacionado">Pago e Estacionado</option>
                                                        <option value="Pago e Entregue">Pago e Entregue</option>
                                                        <option value="Pago e Aguardando">Pago e Aguardando</option>
                                                        <option value="Cancelado">Cancelado</option>
                                                    </select>
                                                    <label>Ordenar por</label>
                                                    <select className="form-select" value={this.state.advancedSearchOrder} onChange={(e) => this.setState({ advancedSearchOrder: e.target.value })}>
                                                        <option value="desc">Mais recente</option>
                                                        <option value="asc">Menos recente</option>
                                                    </select>
                                                    <label>Limitar em</label>
                                                    <select className="form-select" value={this.state.advancedSearchLimit} onChange={(e) => this.setState({ advancedSearchLimit: e.target.value })}>
                                                        <option value={5}>5 registros</option>
                                                        <option value={20}>20 registros</option>
                                                        <option value={30}>30 registros</option>
                                                        <option value={40}>40 registros</option>
                                                        <option value={50}>50 registros</option>
                                                        <option value={70}>70 registros</option>
                                                        <option value={100}>100 registros</option>
                                                        <option value={150}>150 registros</option>
                                                        <option value={200}>200 registros</option>
                                                        <option value={300}>300 registros</option>
                                                        <option value={400}>400 registros</option>
                                                        <option value={500}>500 registros</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="row">
                                                {
                                                    this.state.isLoading === false &&
                                                    <div className="col-lg-12 my-3">
                                                        <div className="btn-group">
                                                            <Button className='btn btn-primary' onClick={this.advancedSearch} variant="default">Buscar</Button>
                                                            <Button className='btn btn-secondary' onClick={(e) => this.setState({ advancedSearch: false })} variant="default">Busca simplificada</Button>
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    this.state.isLoading === true &&
                                                    <div className="col-lg-12 my-3">
                                                        <Loading />
                                                    </div>
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                        {
                            this.state.isFound === true ?
                                <>
                                    {
                                        this.state.type === "SALES" && <>
                                            <div className='col-lg-4'>
                                                <div className="card mb-4">
                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                        Detalhes da venda
                                                    </div>
                                                    <div key={this.state.random} className="card-body">
                                                        <div className="row">
                                                            <div className='col-lg-12'>
                                                                <div className="alert alert-success">
                                                                    <span>Pago</span>
                                                                </div>
                                                            </div>
                                                            {
                                                                this.state.ticket.ticket !== null &&
                                                                <h6 className='col-lg-12'>
                                                                    <strong>Controle: </strong> {this.state.ticket.ticket}
                                                                </h6>
                                                            }
                                                            <div className='col-lg-12' role={"button"}>
                                                                <div className="alert alert-secondary">
                                                                    <button type="button" onClick={(e) => this.showOnline()} className="btn btn-secondary">
                                                                        <i className="fas fa-share" />
                                                                    </button>
                                                                    <span className='mx-2'>Visualizar ticket</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-5'>
                                                <div className="card">
                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                        Informações do Ticket
                                                    </div>
                                                    <div className="card-body">
                                                        <Tabs key={this.state.random} className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.changeInfoMenu(k)}>
                                                            <Tab eventKey="produtos" className='col-lg-12' title="Produtos e Serviços">
                                                                <div className="row my-2">
                                                                    {
                                                                        this.state.ticket.produtos === null || this.state.ticket.produtos.length === 0 ? <EmptyContent text="Não existem produtos e serviços vendidos neste ticket." /> :
                                                                            <ProductsSimplyList key={this.state.random} showSellers={true} tickets={[this.state.ticket]} />
                                                                    }
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="pagamentos" title="Pagamentos">
                                                                <div className='row my-2'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='row my-2'>
                                                                            {
                                                                                this.state.ticket.noteReceived?.ChaveNFe !== undefined &&
                                                                                <div className='col-lg-12 '>
                                                                                    <div className="alert alert-success">
                                                                                        <a href={'https://nfe.prefeitura.sp.gov.br/contribuinte/notaprint.aspx?ccm=' + this.state.ticket.noteReceived.ChaveNFe.InscricaoPrestador + '&nf=' + this.state.ticket.noteReceived.ChaveNFe.NumeroNFe + '&cod=' + this.state.ticket.noteReceived.ChaveNFe.CodigoVerificacao} target='_blank' className="btn btn-success">
                                                                                            <i className="fas fa-eye" />
                                                                                        </a>
                                                                                        <span className='mx-2'>Nota fiscal gerada com sucesso</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.ticket.rps &&
                                                                                <div className='col-lg-12'>
                                                                                    {
                                                                                        this.state.noteReceived?.currentStatus === "RPS_CREATED" ?
                                                                                            <>
                                                                                                <div className="alert alert-warning">
                                                                                                    <a href={'/nota-fiscal/listar?id=' + this.state.ticket.rps?.id} target='_blank' className="btn btn-warning text-white">
                                                                                                        <i className="fas fa-eye" />
                                                                                                    </a>
                                                                                                    <span className='mx-2'>RPS criado</span>
                                                                                                </div>
                                                                                            </> : <>
                                                                                                {
                                                                                                    (this.state.ticket.noteReceived?.currentStatus === "NOTE_GENERATED_ERROR" ||
                                                                                                        this.state.ticket.noteReceived?.currentStatus === "NOTE_CANCELED" ||
                                                                                                        this.state.ticket.noteReceived?.currentStatus === "RPS_CANCELED") && <>
                                                                                                        <div className="alert alert-danger">
                                                                                                            <a href={'/nota-fiscal/listar?id=' + this.state.ticket.rps?.id} target='_blank' className="btn btn-danger">
                                                                                                                <i className="fas fa-eye" />
                                                                                                            </a>
                                                                                                            <span className='mx-2'>
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "NOTE_GENERATED_ERROR" && 'Falha na emissão'}
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "NOTE_CANCELED" && 'Nota cancelada'}
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "RPS_CANCELED" && 'RPS cancelado'}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            !this.state.resumo ? <EmptyContent text="Este ticket não teve pagamentos." /> :
                                                                                <>
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <tbody>
                                                                                                {
                                                                                                    this.state.ticket.paymentsLogged?.length === 1 &&
                                                                                                    <>
                                                                                                        {
                                                                                                            this.state.resumo?.caixaId &&
                                                                                                            <tr>
                                                                                                                <td>Caixa</td>
                                                                                                                <td width='10' align='right'>
                                                                                                                    <Link to={'/caixas/' + this.state.resumo?.caixaId + "?ticket=" + this.state.ticket.ticket}>
                                                                                                                        <button type="button" className="btn btn-secondary">
                                                                                                                            <i className="fas fa-eye" />
                                                                                                                        </button>
                                                                                                                    </Link>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.resumo?.tabelaPreco !== null &&
                                                                                                            <tr>
                                                                                                                <td>Tabela de preços</td>
                                                                                                                <td align="right"><strong>{this.state.resumo?.tabelaPreco?.nome ?? "Não informado"}</strong></td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.ticket.formaPagamento !== undefined &&
                                                                                                            <tr>
                                                                                                                <td>Forma de pagamento</td>
                                                                                                                <td align="right"><strong>{this.state.ticket.formaPagamento?.nome}</strong></td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    this.state.resumo.valorProdutos !== 0 &&
                                                                                                    <tr>
                                                                                                        <td>Valor de produtos</td>
                                                                                                        <td align="right">
                                                                                                            <strong className='text-success'>
                                                                                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo.valorProdutos} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                            </strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                <tr>
                                                                                                    <td><h5>Valor cobrado total</h5></td>
                                                                                                    <td align="right">
                                                                                                        <h5>
                                                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                        </h5>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {
                                                                                            (!this.state.ticket.monthly && !this.state.ticket.accredited) && (this.state.ticket.status === "Estacionado" || this.state.ticket.status === "Pago e Estacionado") &&
                                                                                            <button className='btn btn-success' onClick={this.showModalToRegisterPayment}>
                                                                                                <i className="fas fa-money-bill-wave mx-2" />
                                                                                                Registrar pagamento
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            {
                                                                isAdministrador() &&
                                                                <Tab eventKey="suporte" title="Suporte">
                                                                    <div className='row my-3'>
                                                                        <AlertDanger message="Esta aba esta disponível apenas para conta de revenda." />
                                                                        {
                                                                            this.state.ticket.fastIn &&
                                                                            <div className='col-lg-12'>
                                                                                <strong>Modo de lançamento: </strong> {this.state.ticket.fastIn ? "Forma rápida" : "Detalhada"}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.ticket.system && <>
                                                                                <div className='col-lg-12'>
                                                                                    <strong>Sistema: </strong> {this.state.ticket.system.app}
                                                                                </div>
                                                                                <div className='col-lg-12'>
                                                                                    <strong>Versão: </strong> {this.state.ticket.system.version}
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <div className='col-lg-12 mt-3'>
                                                                            <button onClick={this.removeTicket} className='btn btn-danger'>Remover ticket</button>
                                                                        </div>
                                                                    </div>
                                                                </Tab>
                                                            }
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.type !== "SALES" && <>
                                            <div className='col-lg-4'>
                                                <div className='row'>
                                                    <div className='btn-group'>
                                                        <Tooltip title="Visualizar mudanças" placement="bottom">
                                                            <button className='btn btn-warning text-white w-25 text-white' onClick={this.showModalChangeLogged}>
                                                                <i className="fas fa-clipboard" />
                                                            </button>
                                                        </Tooltip>
                                                        <Tooltip title="Ticket online" placement="bottom">
                                                            <button className='btn btn-info text-white w-25' onClick={this.showOnline}>
                                                                <i className="fas fa-globe-americas" />
                                                            </button>
                                                        </Tooltip>
                                                        {
                                                            this.state.ticket.placa &&
                                                            <Tooltip title="Histórico de acessos" placement="bottom">
                                                                <button className='btn btn-warning text-white w-25' onClick={this.showAccessHistory}>
                                                                    <QueryStatsIcon />
                                                                </button>
                                                            </Tooltip>
                                                        }
                                                        <Button
                                                            anchorEl={el => (this.anchorEl = el)}
                                                            aria-controls={this.state.isMenuOpen ? 'basic-menu' : undefined}
                                                            aria-haspopup="true"
                                                            className='btn btn-secondary'
                                                            aria-expanded={this.state.isMenuOpen}
                                                            onClick={this.handleClick}>
                                                            <i className="fa-solid fa-ellipsis-vertical" />
                                                        </Button>
                                                        <Menu
                                                            id="basic-menu"
                                                            anchorEl={this.anchorEl}
                                                            onClose={this.handleClose}
                                                            open={this.state.isMenuOpen}
                                                            MenuListProps={{
                                                                'aria-labelledby': 'basic-button',
                                                            }}>
                                                            {
                                                                this.state.ticket.placa &&
                                                                <MenuItem onClick={this.showSettings}>
                                                                    <ListItemIcon>
                                                                        <i className="fa-solid fa-shield" />
                                                                    </ListItemIcon>
                                                                    Permissão de acesso
                                                                </MenuItem>
                                                            }
                                                            {
                                                                this.state.ticket.status === TicketStatus.PARKING && this.state.parameters.habilitarTicketAvulsoParaMensalista &&
                                                                <>
                                                                    {
                                                                        this.state.ticket.monthly ?
                                                                            <MenuItem onClick={this.onRemoveMonthlyAssociation}>
                                                                                <ListItemIcon>
                                                                                    <i className="fa-solid fa-calendar" />
                                                                                </ListItemIcon>
                                                                                Remover mensalista
                                                                            </MenuItem> :
                                                                            <MenuItem onClick={this.showMonthlyAssociation}>
                                                                                <ListItemIcon>
                                                                                    <i className="fa-solid fa-calendar" />
                                                                                </ListItemIcon>
                                                                                Associar mensalista
                                                                            </MenuItem>
                                                                    }
                                                                    {
                                                                        this.state.ticket.accredited ?
                                                                            <MenuItem onClick={this.onRemoveAccreditedAssociation}>
                                                                                <ListItemIcon>
                                                                                    <i className="fa-solid fa-user-circle" />
                                                                                </ListItemIcon>
                                                                                Remover credenciado
                                                                            </MenuItem> :
                                                                            <MenuItem onClick={this.showAccreditedAssociation}>
                                                                                <ListItemIcon>
                                                                                    <i className="fa-solid fa-user-circle" />
                                                                                </ListItemIcon>
                                                                                Associar credenciado
                                                                            </MenuItem>
                                                                    }
                                                                </>
                                                            }
                                                        </Menu>
                                                        {
                                                            this.state.isOpenMonthlyAssociationModal && <MensalistasListagemModal
                                                                onClose={(e) => this.setState({ isOpenMonthlyAssociationModal: false })}
                                                                onConfirm={this.onConfirmMonthlyAssociation}
                                                                showDetail={true}
                                                            />
                                                        }
                                                        {
                                                            this.state.isOpenAccreditedAssociationModal && <CredenciadosListagemModal
                                                                onClose={(e) => this.setState({ isOpenAccreditedAssociationModal: false })}
                                                                onConfirm={this.onConfirmAccreditedAssociation}
                                                                showDetail={true}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card mb-4 mt-3">
                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                        {this.state.ticket.type === "SALES" ? "Detalhes da venda" : "Detalhes do veículo"}
                                                    </div>
                                                    <div key={this.state.random} className="card-body">
                                                        <div className="row">
                                                            <div className='col-lg-12'>
                                                                {
                                                                    this.state.ticket.relationshipApplied &&
                                                                    <div className="alert alert-warning d-flex align-items-end">
                                                                        <div className='row'>
                                                                            <div className='col-lg-12'>
                                                                                Convênio <strong>{this.state.ticket.relationshipApplied?.nome}</strong> aplicado por <strong>{this.state.ticket.relationshipApplied?.appliedBy?.nome}</strong>
                                                                                <strong>{Moment(this.state.ticket.relationshipApplied?.appliedAt.toDate()).format("[ em] DD/MM/YYYY [às] HH:mm:ss")}</strong>
                                                                            </div>
                                                                            {
                                                                                this.state.ticket.status !== "Pago e Entregue" &&
                                                                                <div className='col-lg-8 mt-2'>
                                                                                    <button onClick={this.removeRelationshipApplied} className='btn btn-warning text-white'>
                                                                                        <i className="fas fa-trash mx-2" />
                                                                                        Remover convênio
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                            {
                                                                this.state.ticket.status !== TicketStatus.PAYED_AND_DELIVERED && this.state.ticket.limitPermanence &&
                                                                <div className='col-lg-12'>
                                                                    {
                                                                        Moment(this.state.ticket.limitPermanence.seconds * 1000).isAfter(Moment()) ?
                                                                            <AlertSecondary tag="Previsão de saída">
                                                                                <div className='mt-2'>
                                                                                    <UserPermissionButton permission="TICKET_EDIT_MAX_PERMANENCE" action={this.showLimitPermanenceModal} className='btn btn-secondary text-white'>
                                                                                        <i className="fas fa-clock mx-2" />
                                                                                        Editar
                                                                                    </UserPermissionButton>
                                                                                    <span className='mx-2'>{Moment(this.state.ticket.limitPermanence.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</span>
                                                                                </div>
                                                                            </AlertSecondary> :
                                                                            <AlertDanger tag="Previsão de saída expirada">
                                                                                <div className='mt-2'>
                                                                                    <UserPermissionButton permission="TICKET_EDIT_MAX_PERMANENCE" action={this.showLimitPermanenceModal} className='btn btn-danger text-white'>
                                                                                        <i className="fas fa-clock mx-2" />
                                                                                        Editar
                                                                                    </UserPermissionButton>
                                                                                    <span className='mx-2'>{Moment(this.state.ticket.limitPermanence.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</span>
                                                                                </div>
                                                                            </AlertDanger>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                this.state.newNote?.currentStatus === "NOTE_GENERATED" &&
                                                                <div className='col-lg-12'>
                                                                    <Tooltip title={this.state.newNote?.id} placement="top">
                                                                        <div className="alert alert-success">
                                                                            {
                                                                                (this.state.newNote?.pdf_nfse) &&
                                                                                <Tooltip title="Visualizar PDF" placement="top">
                                                                                    <a href={(this.state.newNote?.pdf_nfse)} target='_blank' className="btn btn-success">
                                                                                        <i className="fas fa-file-pdf" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            }
                                                                            {
                                                                                (this.state.newNote?.xml) &&
                                                                                <Tooltip title="Visualizar XML" placement="top">
                                                                                    <a href={(this.state.newNote?.xml)} target='_blank' className="btn btn-success mx-2">
                                                                                        <i className="fas fa-file-code" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            }
                                                                            <span className='mx-2'>Existe uma {this.state.newNote?.noteType} emitida para este ticket.</span>
                                                                        </div>
                                                                    </Tooltip>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.newNote?.currentStatus === "NOTE_PROCESSING" &&
                                                                <div className='col-lg-12'>
                                                                    <div className="alert alert-warning">
                                                                        <Tooltip title="Visualizar" placement="top">
                                                                            <a href={`/nota-fiscal/listar/?id=${this.state.newNote?.id}`} target='_blank' className="btn btn-warning text-white me-2">
                                                                                <i className="fas fa-search" />
                                                                            </a>
                                                                        </Tooltip>
                                                                        Existe uma {this.state.newNote?.noteType} em processamento para este ticket.
                                                                    </div>
                                                                </div>
                                                            }
                                                            <div className='col-lg-12' role="button" onClick={(e) => this.setState({ isOpenStatusLoggedModal: true })} >
                                                                {
                                                                    this.state.ticket.inBatch &&
                                                                    <div className="alert alert-danger">
                                                                        <span>O status do ticket foi alterado manualmente por <br /><strong>{this.state.ticket.inBatch.user.nome}</strong></span>
                                                                        {Moment(this.state.ticket.inBatch.date.toDate()).format("[ em] DD/MM/YYYY [às] HH:mm:ss")}
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.ticket.inCustomDate &&
                                                                    <div className="alert alert-danger">
                                                                        <span>A data de entrada deste ticket foi alterado manualmente por <br /><strong>{this.state.ticket.inCustomDate.user?.nome ?? "Suporte"}</strong></span>
                                                                        {Moment(this.state.ticket.inCustomDate.date.toDate()).format("[ em] DD/MM/YYYY [às] HH:mm:ss")}{Moment(this.state.ticket.inCustomDate.customDate.toDate()).format("[ para] DD/MM/YYYY [às] HH:mm:ss")}
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.ticket.status !== TicketStatus.PAYED_AND_DELIVERED && this.state.ticket.limitPermanence &&
                                                                    <div className='col-lg-12'>
                                                                        {
                                                                            Moment(this.state.ticket.limitPermanence.seconds * 1000).isBefore(Moment()) && <>
                                                                                <div className="alert alert-danger">
                                                                                    <button type="button" className="btn btn-danger">
                                                                                        <i className="fas fa-bars mx-2" />
                                                                                        Histórico
                                                                                    </button>
                                                                                    <span className='mx-2'>Pendente de pagamento</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                }
                                                                <>
                                                                    {
                                                                        this.state.ticket.status === "Cancelado" &&
                                                                        <div className="alert alert-danger">
                                                                            <button type="button" className="btn btn-danger">
                                                                                <i className="fas fa-bars mx-2" />
                                                                                Histórico
                                                                            </button>
                                                                            <span className='mx-2'>Cancelado</span>
                                                                            {
                                                                                this.state.ticket.statusLogged?.filter(e => e.status === "Cancelado")[0]?.reason &&
                                                                                <>
                                                                                    <p /><span className='mt-3'>
                                                                                        <strong>Motivo: </strong> {this.state.ticket.statusLogged?.filter(e => e.status === "Cancelado")[0]?.reason}
                                                                                    </span>
                                                                                </>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.ticket.status === "Estacionado" &&
                                                                        <div className="alert alert-primary">
                                                                            <button type="button" className="btn btn-primary">
                                                                                <i className="fas fa-bars mx-2" />
                                                                                Histórico
                                                                            </button>
                                                                            <span className='mx-2'>Estacionado</span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.ticket.status === "Pago e Estacionado" &&
                                                                        <div className="alert alert-info">
                                                                            <button type="button" className="btn btn-info text-white">
                                                                                <i className="fas fa-bars mx-2" />
                                                                                Histórico
                                                                            </button>
                                                                            <span className='mx-2'>Pago e Estacionado</span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.ticket.status === "Pago e Entregue" &&
                                                                        <div className="alert alert-success">
                                                                            <button type="button" className="btn btn-success">
                                                                                <i className="fas fa-bars mx-2" />
                                                                                Histórico
                                                                            </button>
                                                                            <span className='mx-2'>Pago e Entregue</span>
                                                                        </div>
                                                                    }
                                                                    {
                                                                        this.state.ticket.status === "Pago e Aguardando" &&
                                                                        <div className="alert alert-warning">
                                                                            <button type="button" className="btn btn-warning text-white">
                                                                                <i className="fas fa-bars mx-2" />
                                                                                Histórico
                                                                            </button>
                                                                            <span className='mx-2'>Pago e Aguardando</span>
                                                                        </div>
                                                                    }
                                                                </>
                                                            </div>
                                                            {
                                                                this.state.ticket.monthly &&
                                                                <a rel="noopener noreferrer" target="_blank" href={'/visualizar/mensalista/' + this.state.ticket.monthly.id}>
                                                                    <div className="alert alert-success">
                                                                        <button type="button" className="btn btn-success">
                                                                            <i className="fas fa-calendar" />
                                                                        </button>
                                                                        <span className='mx-2'>
                                                                            <strong>Mensalista: </strong>
                                                                            {this.state.ticket.monthly?.nome}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            }
                                                            {
                                                                this.state.ticket.accredited &&
                                                                <a rel="noopener noreferrer" target="_blank" href={'/cadastrar/credenciado/?id=' + this.state.ticket.accredited?.id}>
                                                                    <div className="alert alert-info">
                                                                        <span className="btn btn-info text-white">
                                                                            <i className="fas fa-user-circle" />
                                                                        </span>
                                                                        <span className='mx-2'>
                                                                            <strong>Credenciado: </strong>
                                                                            {this.state.ticket.accredited?.nome}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            }
                                                            {
                                                                this.state.ticket.type !== "SALES" ? <div>
                                                                    <div className='col-lg-12'>
                                                                        <strong>Entrada: </strong>
                                                                        {Moment(this.state.ticket.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss A")}
                                                                    </div>
                                                                    {
                                                                        (this.state.ticket.status === TicketStatus.PAYED_AND_DELIVERED || this.state.ticket.status === TicketStatus.PAYED_AND_WAITING_BACK) &&
                                                                        <>
                                                                            {
                                                                                this.state.ticket.closedAt &&
                                                                                <>
                                                                                    <div className='col-lg-12'>
                                                                                        {
                                                                                            this.state.ticket.status === TicketStatus.PAYED_AND_WAITING_BACK ?
                                                                                                <strong>Saída temporária: </strong> :
                                                                                                <strong>{this.state.ticket.gate ? "Baixa:" : "Saída:"}  </strong>
                                                                                        }
                                                                                        {
                                                                                            this.state.ticket.closedAt?.toDate() &&
                                                                                            Moment(this.state.ticket.closedAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss A")
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                    }
                                                                    {
                                                                        this.state.ticket.gate?.closedAt &&
                                                                        <div className='col-lg-12'>
                                                                            <strong>Passou na cancela: </strong> {Moment(this.state.ticket.gate.closedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")} <br />
                                                                            <strong>Terminal: </strong> {this.state.ticket.gate.terminal}
                                                                        </div>
                                                                    }
                                                                    <div className='col-lg-12'>
                                                                        <strong>Permanência: </strong>
                                                                        {ticketPermanenceFromNowDescription(this.state.ticket)}
                                                                    </div>
                                                                </div> : <div>
                                                                    <div className='col-lg-12'>
                                                                        <strong>Data: </strong>
                                                                        {Moment(this.state.ticket.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm:ss A")}
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <ContactInfoCard ticket={this.state.ticket} />
                                                <div key={this.state.random} className="card mb-4">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {
                                                                this.state.ticket.ticket &&
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span>
                                                                        <strong>{this.state.ticket.type !== "SALES" ? "Ticket:" : "Controle:"} </strong> {this.state.ticket.ticket}
                                                                    </span>
                                                                    {
                                                                        this.state.parameters?.habilitarEdicaoNumeroTicketNoTicket &&
                                                                        <Tooltip title="Editar número" placement="top">
                                                                            <span>
                                                                                <UserPermissionButton className="col-auto btn btn-secondary btn-sm text-white" permission="TICKET_EDIT_NUMBER" action={this.editNumerTicket}>
                                                                                    <i className="fas fa-edit" />
                                                                                </UserPermissionButton>
                                                                            </span>
                                                                        </Tooltip>
                                                                    }
                                                                </div>
                                                            }
                                                            {
                                                                this.state.ticket.type !== "SALES" && <div>
                                                                    <div className="d-flex justify-content-between align-items-center mt-2">
                                                                        <span>
                                                                            <strong>Placa: </strong> {takeIfIsNotNullOrEmpty(this.state.ticket?.placa) ?? "Não informada"}
                                                                        </span>
                                                                        <div className='btn-group'>
                                                                            {
                                                                                !isNullOrEmpty(this.state.ticket.placa) &&
                                                                                <Tooltip title="Clique para buscar o tickets lançados com esta placa." placement="top">
                                                                                    <a href={'/buscar/ticket?placa=' + this.state.ticket.placa?.toUpperCase()} className='btn btn-secondary btn-sm'>
                                                                                        <i className="fas fa-eye" />
                                                                                    </a>
                                                                                </Tooltip>
                                                                            }
                                                                            <Tooltip title="Editar placa" placement="top">
                                                                                <button className="btn btn-sm btn-secondary text-white" onClick={this.openNewPlateModal}>
                                                                                    <i className="fas fa-edit" />
                                                                                </button>
                                                                            </Tooltip>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        isNullOrEmpty(this.state.ticket.monthly) && isNullOrEmpty(this.state.ticket.accredited) &&
                                                                        <div className="d-flex justify-content-between align-items-center mt-2">
                                                                            <span>
                                                                                <strong>Tabela de preço: </strong> <span>{takeIfIsNotNullOrEmpty(this.state.ticket.tableSelectedInCheckIn?.nome) ?? "Não informada"}</span>
                                                                            </span>
                                                                            {
                                                                                (this.state.ticket.status !== TicketStatus.CANCELED || this.state.ticket.status !== TicketStatus.PAYED_AND_DELIVERED) &&
                                                                                <div className='btn-group'>
                                                                                    <Tooltip title={isNullOrEmpty(this.state.ticket.tableSelectedInCheckIn?.nome) ? "Adicionar tabela de preço" : "Alterar tabela de preço"} placement="top">
                                                                                        <span>
                                                                                            <UserPermissionButton className="col-auto btn btn-secondary btn-sm text-white" permission="CHANGE_PRICE_TABLE" action={this.isPaymentTableEditing}>
                                                                                                <i className="fas fa-edit" />
                                                                                            </UserPermissionButton>
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !isNullOrEmpty(this.state.ticket.vehicler?.modelo) &&
                                                                        <div className='col-lg-12 mt-2'>
                                                                            <strong>Modelo: </strong> {this.state.ticket.vehicler?.modelo}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !isNullOrEmpty(this.state.ticket.vehicler?.marca) &&
                                                                        <div className='col-lg-12 mt-2'>
                                                                            <strong>Fabricante: </strong> {this.state.ticket.vehicler?.marca}
                                                                        </div>
                                                                    }
                                                                    {
                                                                        !isNullOrEmpty(this.state.ticket.prisma) &&
                                                                        <div className='col-lg-12 mt-2'>
                                                                            <strong>Prisma: </strong> {this.state.ticket.prisma}
                                                                        </div>
                                                                    }
                                                                    <div className='col-lg-12 mt-2'>
                                                                        <strong>Tipo do veículo: </strong> {capitalizeField(this.state.ticket.typeVehicler.replaceAll("_", " "))}
                                                                    </div>
                                                                </div>
                                                            }
                                                            {
                                                                !isNullOrEmpty(this.state.ticket.setor) &&
                                                                <div className='col-lg-12 mt-2'>
                                                                    <strong>Setor: </strong> {this.state.ticket.setor}
                                                                </div>
                                                            }
                                                            {
                                                                this.state.parameters.observacaoEstacionamento &&
                                                                <div className='col-lg-12 mt-2'>
                                                                    <AlertInfo tag="Observação">
                                                                        {
                                                                            this.state.isNoteEditing ?
                                                                                <div className='mt-2'>
                                                                                    <textarea rows={6} onChange={(e) => this.setState({ observation: e.target.value })} value={this.state.observation} className="form-control" />
                                                                                    <div className='btn-group mt-3'>
                                                                                        <button className="btn btn-sm btn-info text-white" onClick={(e) => this.setState({ isNoteEditing: false })}>
                                                                                            <i className="fas fa-chevron-left mx-2" />
                                                                                            Voltar
                                                                                        </button>
                                                                                        <button className="btn btn-sm btn-info text-white" onClick={this.saveNewObservation}>
                                                                                            <i className="fas fa-save mx-2" />
                                                                                            Salvar
                                                                                        </button>
                                                                                    </div>
                                                                                </div> :
                                                                                <div className='mt-2'>
                                                                                    <div>
                                                                                        {this.state.ticket.observation}
                                                                                    </div>
                                                                                    {
                                                                                        isNullOrEmpty(this.state.ticket.observation) ?
                                                                                            <div className='btn-group mt-2'>
                                                                                                <button className="btn btn-sm btn-info text-white" onClick={this.editObservation}>
                                                                                                    <i className="fas fa-edit mx-2" />
                                                                                                    Adicionar
                                                                                                </button>
                                                                                            </div> :
                                                                                            <div className='mt-2'>
                                                                                                <button className="btn btn-sm btn-info text-white" onClick={this.editObservation}>
                                                                                                    <i className="fas fa-edit mx-2" />
                                                                                                    Editar
                                                                                                </button>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                        }
                                                                    </AlertInfo>
                                                                </div>
                                                            }
                                                            {
                                                                this.state.park &&
                                                                <div className='mt-2'>
                                                                    <AlertSecondary tag="Estabelecimento">
                                                                        {takeIfIsNotNullOrEmpty(this.state.park?.nomeInterno) ?? this.state.park?.nome}<br />
                                                                        {documentMask(this.state.park?.documento)}
                                                                    </AlertSecondary>
                                                                </div>
                                                            }
                                                            <div className='btn-group'>
                                                                <ReactToPrint
                                                                    trigger={() => {
                                                                        return <a href='#' className='btn btn-secondary text-white' >
                                                                            <i className="fas fa-print mx-2" />
                                                                            Imprimir
                                                                        </a>
                                                                    }}
                                                                    content={() => this.componentRef}
                                                                />
                                                                {
                                                                    this.state.isAdministrador &&
                                                                    <UserPermissionButton
                                                                        permission="REMOVE_TICKET"
                                                                        className="btn btn-danger"
                                                                        action={this.removeTicket}>
                                                                        <i className="fas fa-trash mx-2" />
                                                                        Apagar ticket
                                                                    </UserPermissionButton>
                                                                }
                                                                {
                                                                    this.state.ticket.status !== TicketStatus.CANCELED &&
                                                                    <UserPermissionButton
                                                                        permission="CANCEL_TICKET"
                                                                        className="btn btn-danger"
                                                                        action={this.showIsCancelingTicketModal}>
                                                                        <i className="fas fa-coins mx-2" />
                                                                        Cancelar ticket
                                                                    </UserPermissionButton>
                                                                }
                                                            </div>
                                                            {
                                                                this.state.ticket &&
                                                                <div style={{ display: "none" }}>
                                                                    <PrinterTicketComponent
                                                                        ticket={this.state.ticket}
                                                                        park={getEstacionamento()}
                                                                        ref={el => (this.componentRef = el)} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.ticket.semparar?.allow ||
                                                    this.state.ticket.veloe?.allow ||
                                                    this.state.ticket.conectcar?.allow ||
                                                    this.state.ticket.taggy?.allow &&
                                                    <div className='card mb-4'>
                                                        <div className='card-body row'>
                                                            {
                                                                this.state.ticket.semparar?.allow &&
                                                                <div className='col-lg-3'>
                                                                    <img alt='SemParar' width='100%' height='100%' src={require('../../files/integracao/semparar.png')} />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.ticket.veloe?.allow &&
                                                                <div className='col-lg-3'>
                                                                    <img alt='Veloe' width='100%' height='100%' src={require('../../files/integracao/veloe.png')} />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.ticket.conectcar?.allow &&
                                                                <div className='col-lg-3'>
                                                                    <img alt='ConectCar' width='100%' height='100%' src={require('../../files/integracao/conectcar.png')} />
                                                                </div>
                                                            }
                                                            {
                                                                this.state.ticket.taggy?.allow &&
                                                                <div className='col-lg-3'>
                                                                    <img alt='Taggy' width='100%' height='100%' src={require('../../files/integracao/taggy.png')} />
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    (!isNullOrEmpty(this.state.urlVehicleImagem) || !isNullOrEmpty(this.state.urlVehicleOutImagem)) &&
                                                    <div className='card mb-4'>
                                                        <div className='card-body'>
                                                            <div className='row'>
                                                                <strong>Foto do veículo</strong>
                                                                {
                                                                    this.state.urlVehicleImagem &&
                                                                    <div className='col-lg-6'>
                                                                        <img alt='Imagem do veículo' className="img-thumbnail my-2" src={this.state.urlVehicleImagem} height='144' width='300' />
                                                                        <span className="badge text-bg-success">Entrada</span>
                                                                    </div>
                                                                }
                                                                {
                                                                    this.state.urlVehicleOutImagem &&
                                                                    <div className='col-lg-6'>
                                                                        <img alt='Imagem do veículo' className="img-thumbnail my-2" src={this.state.urlVehicleOutImagem} height='144' width='300' />
                                                                        <span className="badge text-bg-success">Saída</span>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className='col-lg-5'>
                                                <div className="card">
                                                    <div className="card-header d-flex justify-content-between align-items-center">
                                                        Informações do Ticket
                                                    </div>
                                                    <div className="card-body">
                                                        {
                                                            this.state.ticket.spunByChange &&
                                                            <a href={`/visualizar/mensalista/${this.state.ticket.monthly.id}?showCharges=true&chargeId=${this.state.ticket.spunByChange.id}`} target='_blank'>
                                                                <div className="alert alert-warning" role='button'>
                                                                    <a className="btn btn-warning text-white">
                                                                        <i className="fas fa-eye" />
                                                                    </a>
                                                                    <span className='mx-2'>A cobrança deste ticket foi lançada na próxima mensalidade do mensalista. </span>
                                                                </div>
                                                            </a>
                                                        }
                                                        <Tabs key={this.state.random} className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.changeInfoMenu(k)}>
                                                            <Tab eventKey="externa" title="Avaliação Externa">
                                                                {
                                                                    this.state.urlImagem === undefined ? <EmptyContent text="Não existe avaliação externa neste ticket." /> :
                                                                        <>
                                                                            <div className='col-lg-12 my-2 text-center'>
                                                                                <img alt='Avaliações do veículo' style={{ width: 50 + '%' }} src={this.state.urlImagem} />
                                                                            </div>
                                                                            {
                                                                                !isNullOrEmpty(this.state.ticket.itemsExternalReview) &&
                                                                                <div className='col-lg-12 my-2'>
                                                                                    <h6><strong>Checklist</strong></h6>
                                                                                    {
                                                                                        this.state.ticket.itemsExternalReview?.map(item =>
                                                                                            <h6 key={item.id}>{item.category} - {item.value}</h6>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </>
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="interna" title="Avaliação Interna">
                                                                {
                                                                    (!this.state.ticket.interno || this.state.ticket.interno.length === 0) ? <EmptyContent text="Não existem avaliações internas neste ticket." /> :
                                                                        <div className="table-responsive my-2">
                                                                            <table className="table table-striped">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td><strong>Quantidade</strong></td>
                                                                                        <td><strong>Nome</strong></td>
                                                                                    </tr>
                                                                                    {
                                                                                        this.state.ticket.interno.map((product, index) =>
                                                                                            <tr key={index}>
                                                                                                <td>{product.quantidade}</td>
                                                                                                <td>{product.nome}</td>
                                                                                            </tr>
                                                                                        )
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                }
                                                            </Tab>
                                                            <Tab eventKey="produtos" className='col-lg-12' title="Produtos e Serviços">
                                                                <div className="row my-2">
                                                                    {
                                                                        !this.state.ticket.produtos || this.state.ticket.produtos.length === 0 ? <EmptyContent text="Não existem produtos e serviços vendidos neste ticket." /> :
                                                                            <ProductsSimplyList key={this.state.random} showSellers={true} tickets={[this.state.ticket]} />
                                                                    }
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="imagens" title="Imagens do veículo">
                                                                <div className='row'>
                                                                    {
                                                                        this.state.urlImagesExternalReview === null || this.state.urlImagesExternalReview.length === 0 ? <EmptyContent text="Não existem imagens do veículo neste ticket." /> :
                                                                            <>
                                                                                {
                                                                                    this.state.urlImagesExternalReview.map(item =>
                                                                                        <div className='col-lg-6'>
                                                                                            <img alt='Imagem do veículo' className="img-thumbnail my-2" src={item} />
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </>
                                                                    }
                                                                </div>
                                                            </Tab>
                                                            <Tab eventKey="pagamentos" title="Pagamentos">
                                                                <div className='row my-2'>
                                                                    <div className='col-lg-12'>
                                                                        <div className='row my-2'>
                                                                            {
                                                                                this.state.ticket.noteReceived?.ChaveNFe !== undefined &&
                                                                                <div className='col-lg-12 '>
                                                                                    <div className="alert alert-success">
                                                                                        <a href={'https://nfe.prefeitura.sp.gov.br/contribuinte/notaprint.aspx?ccm=' + this.state.ticketnoteReceived.ChaveNFe.InscricaoPrestador + '&nf=' + this.state.ticket.noteReceived.ChaveNFe.NumeroNFe + '&cod=' + this.state.ticket.noteReceived.ChaveNFe.CodigoVerificacao} target='_blank' className="btn btn-success">
                                                                                            <i className="fas fa-eye" />
                                                                                        </a>
                                                                                        <span className='mx-2'>Nota fiscal gerada com sucesso</span>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                            {
                                                                                this.state.ticket.rps !== undefined && this.state.ticket.rps !== undefined &&
                                                                                <div className='col-lg-12'>
                                                                                    {
                                                                                        this.state.ticket.noteReceived?.currentStatus === "RPS_CREATED" ?
                                                                                            <>
                                                                                                <div className="alert alert-warning">
                                                                                                    <a href={'/nota-fiscal/listar?id=' + this.state.ticket.rps?.id} target='_blank' className="btn btn-warning text-white">
                                                                                                        <i className="fas fa-eye" />
                                                                                                    </a>
                                                                                                    <span className='mx-2'>RPS criado</span>
                                                                                                </div>
                                                                                            </> : <>
                                                                                                {
                                                                                                    (this.state.ticket.noteReceived?.currentStatus === "NOTE_GENERATED_ERROR" ||
                                                                                                        this.state.ticket.noteReceived?.currentStatus === "NOTE_CANCELED" ||
                                                                                                        this.state.ticket.noteReceived?.currentStatus === "RPS_CANCELED") && <>
                                                                                                        <div className="alert alert-danger">
                                                                                                            <a href={'/nota-fiscal/listar?id=' + this.state.ticket.rps?.id} target='_blank' className="btn btn-danger">
                                                                                                                <i className="fas fa-eye" />
                                                                                                            </a>
                                                                                                            <span className='mx-2'>
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "NOTE_GENERATED_ERROR" && 'Falha na emissão'}
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "NOTE_CANCELED" && 'Nota cancelada'}
                                                                                                                {this.state.ticket.noteReceived?.currentStatus === "RPS_CANCELED" && 'RPS cancelado'}
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        {
                                                                            (!this.state.resumo) ?
                                                                                <div className='text-center'>
                                                                                    <EmptyContent text="Não existem pagamentos ativos para este ticket até o momento." />
                                                                                    {
                                                                                        (!this.state.ticket.monthly && !this.state.ticket.accredited) && (this.state.ticket.status === "Estacionado" || this.state.ticket.status === "Pago e Estacionado") &&
                                                                                        <button className='btn btn-success' onClick={this.showModalToRegisterPayment}>
                                                                                            <i className="fas fa-money-bill-wave mx-2" />
                                                                                            Registrar pagamento
                                                                                        </button>
                                                                                    }
                                                                                </div> :
                                                                                <>
                                                                                    <div className="table-responsive">
                                                                                        {
                                                                                            this.state.ticket.paymentsLogged?.length > 1 &&
                                                                                            <div className='row my-2' role='button' onClick={(e) => this.setState({ isOpenPaymentsLoggedModal: true })} >
                                                                                                <div className='col-lg-12'>
                                                                                                    <div className="alert alert-info">
                                                                                                        <button type="button" className="btn btn-info">
                                                                                                            <i className="fas fa-eye" />
                                                                                                        </button>
                                                                                                        <span className='mx-2'>Este ticket teve {this.state.ticket.paymentsLogged?.length} pagamentos.</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        }
                                                                                        <table className="table table-striped">
                                                                                            <tbody>
                                                                                                {
                                                                                                    this.state.resumo &&
                                                                                                    <>
                                                                                                        {
                                                                                                            this.state.ticket.isSpun !== true && this.state.ticket.paymentsLogged.length === 1 &&
                                                                                                            <tr>
                                                                                                                <td>Caixa do pagamento</td>
                                                                                                                <td align='right'>
                                                                                                                    {this.onlinePaymentBadge(this.state.ticket.paymentsLogged[0])}
                                                                                                                    {this.syncSeniorBadge(this.state.ticket.paymentsLogged[0], 0)}
                                                                                                                    {this.syncF360Badge(this.state.ticket.paymentsLogged[0], 0)}
                                                                                                                    <div className='btn-group'>
                                                                                                                        <Tooltip title="Alterar caixa de pagamento" placement="top">
                                                                                                                            <button type="button" className="btn btn-info text-white" onClick={(e) => this.onChangePaymentCashier(this.state.ticket.paymentsLogged[0])}>
                                                                                                                                <i className="fas fa-random" />
                                                                                                                            </button>
                                                                                                                        </Tooltip>
                                                                                                                        <Tooltip title="Visualizar ticket no caixa" placement="top">
                                                                                                                            <a target='_blank' className="btn btn-primary" href={('/caixas/' + this.state.ticket.paymentsLogged[0]?.caixaId + "?ticket=" + this.state.ticket.id)}>
                                                                                                                                <i className="fas fa-eye" />
                                                                                                                            </a>
                                                                                                                        </Tooltip>
                                                                                                                    </div>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.resumo?.tabelaPreco &&
                                                                                                            <tr>
                                                                                                                <td>Tabela de preços</td>
                                                                                                                <td align="right"><strong>{this.state.resumo?.tabelaPreco?.nome ?? "Não informado"}</strong></td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                        {
                                                                                                            this.state.resumo?.order &&
                                                                                                            <tr>
                                                                                                                <td>Forma de pagamento</td>
                                                                                                                <td align="right"><strong>{this.state.resumo?.order?.tipo}</strong></td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                    </>
                                                                                                }
                                                                                                {
                                                                                                    this.state.resumo?.valorDesconto !== 0 &&
                                                                                                    <tr>
                                                                                                        <td>Desconto convênio</td>
                                                                                                        <td align="right">
                                                                                                            {
                                                                                                                this.state.resumo?.valorDesconto === 0 ?
                                                                                                                    <strong>
                                                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo?.valorDesconto} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                                    </strong> :
                                                                                                                    <strong className='text-danger'>
                                                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo?.valorDesconto} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                                    </strong>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {
                                                                                                    this.state.ticket.type !== "SALES" && this.state.resumo?.descontoManual &&
                                                                                                    <tr>
                                                                                                        {
                                                                                                            this.state.ticket.manyDiscounts === false ?
                                                                                                                <td>
                                                                                                                    <button type="button" onClick={(e) => this.showManualDiscount(this.state.resumo?.descontoManual)} className="btn btn-info">
                                                                                                                        <i className="fas fa-eye" />
                                                                                                                    </button>
                                                                                                                    <span className='mx-2'>Desconto manual</span>
                                                                                                                </td> :
                                                                                                                <td>Desconto manual</td>
                                                                                                        }
                                                                                                        <td align="right">
                                                                                                            <strong className='text-danger'>
                                                                                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={-this.state.resumo?.descontoManual?.discount} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                            </strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {
                                                                                                    this.state.resumo?.convenio &&
                                                                                                    <tr>
                                                                                                        <td>Convênio</td>
                                                                                                        <td align="right">
                                                                                                            {this.state.resumo?.convenio?.nome}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {
                                                                                                    this.state.ticket.type !== "SALES" &&
                                                                                                    <tr>
                                                                                                        <td>Valor do estacionamento</td>
                                                                                                        <td align="right">
                                                                                                            <strong className='text-success'>
                                                                                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo?.valorEstacionamento} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                            </strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                {
                                                                                                    this.state.resumo?.valorProdutos !== 0 &&
                                                                                                    <tr>
                                                                                                        <td>Valor de produtos</td>
                                                                                                        <td align="right">
                                                                                                            <strong className='text-success'>
                                                                                                                <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.resumo?.valorProdutos} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                            </strong>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                }
                                                                                                <tr>
                                                                                                    <td><h5>Valor cobrado total</h5></td>
                                                                                                    <td align="right">
                                                                                                        <h5>
                                                                                                            <NumberFormat decimalScale={2} className='mx-2' fixedDecimalScale={true} value={this.state.resumo.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                                            {
                                                                                                                this.state.ticket.paymentsLogged?.length === 1 &&
                                                                                                                <div className='btn-group'>
                                                                                                                    {
                                                                                                                        this.state.resumo &&
                                                                                                                        <Tooltip title="Editar pagamento" placement="top">
                                                                                                                            <button className="btn btn-warning text-white" onClick={(e) => this.onChangePaymentValues(this.state.ticket.paymentsLogged[0], 0)}>
                                                                                                                                <i className="fas fa-edit" />
                                                                                                                            </button>
                                                                                                                        </Tooltip>
                                                                                                                    }
                                                                                                                    <Tooltip title="Remover pagamento" placement="top">
                                                                                                                        <button className="btn btn-danger" onClick={(e) => this.onRemovePayment(this.state.ticket.paymentsLogged[0], 0)}>
                                                                                                                            <i className="fas fa-trash" />
                                                                                                                        </button>
                                                                                                                    </Tooltip>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </h5>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                        {
                                                                                            (!this.state.ticket.monthly && !this.state.ticket.accredited) && (this.state.ticket.status === "Estacionado" || this.state.ticket.status === "Pago e Estacionado") &&
                                                                                            <button className='btn btn-success' onClick={this.showModalToRegisterPayment}>
                                                                                                <i className="fas fa-money-bill-wave mx-2" />
                                                                                                Registrar pagamento
                                                                                            </button>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                        {
                                                                            this.state.isOpenPaymentTicketModal && <TicketSearchAndRegisterPaymentModal ticket={this.state.ticket} onClose={this.onCloseSearchAndPaymentModal} />
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Tab>
                                                            {
                                                                isAdministrador() &&
                                                                <Tab eventKey="suporte" title="Suporte">
                                                                    <div className='row my-3'>
                                                                        <AlertSecondary tag="Administradores" message="Esta aba esta disponível apenas para conta de revenda e administradores." />
                                                                        {
                                                                            this.state.ticket.fastIn &&
                                                                            <div className='col-lg-12'>
                                                                                <strong>Modo de lançamento: </strong> {this.state.ticket.fastIn ? "Forma rápida" : "Detalhada"}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.ticket.gate?.closedAt &&
                                                                            <div className='col-lg-12'>
                                                                                <strong>Saíu na cancela: </strong> {Moment(this.state.ticket.gate.closedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")} - Terminal {this.state.ticket.gate.terminal}
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.ticket.system && <>
                                                                                <div className='col-lg-12'>
                                                                                    <strong>Sistema: </strong> {this.state.ticket.system.app}
                                                                                </div>
                                                                                <div className='col-lg-12'>
                                                                                    <strong>Terminal: </strong> {this.state.ticket.system.terminal}
                                                                                </div>
                                                                                {
                                                                                    this.state.ticket.system.sequence &&
                                                                                    <div className='col-lg-12'>
                                                                                        <strong>Sequência: </strong> {this.state.ticket.system.sequence}
                                                                                    </div>
                                                                                }
                                                                                <div className='col-lg-12'>
                                                                                    <strong>Versão: </strong> {this.state.ticket.system.version}
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <div className='col-lg-12'>
                                                                            <strong>Lançamento interrompido: </strong> {this.state.ticket.interrupted ? "Sim" : "Não"}
                                                                        </div>
                                                                        {
                                                                            this.state.ticket.caixaFechado &&
                                                                            <div className='col-lg-12 mt-2'>
                                                                                <div className="alert alert-danger" role='button' onClick={(e) => this.showModalBackToPark()}>
                                                                                    <button type="button" className="btn btn-danger">
                                                                                        <i className="fas fa-store-alt-slash" />
                                                                                    </button>
                                                                                    <span className='mx-2'>Este ticket já foi dado baixa em um caixa fechado anteriormente.</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (this.state.ticket.status === TicketStatus.PAYED_AND_DELIVERED || this.state.ticket.status === TicketStatus.CANCELED) && !this.state.ticket.caixaFechado &&
                                                                            <div className='col-lg-12 mt-2'>
                                                                                <div className="alert alert-danger" role='button' onClick={(e) => this.confirmFinishTicket()}>
                                                                                    <button type="button" className="btn btn-danger">
                                                                                        <i className="fas fa-store-alt-slash" />
                                                                                    </button>
                                                                                    <span className='mx-2'>Dar baixa no veículo ( remover da listagem ).</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            (this.state.ticket.gate && this.state.ticket.status === TicketStatus.PARKING) &&
                                                                            <div className='col-lg-12 mt-2'>
                                                                                <div className="alert alert-danger" role='button' onClick={(e) => this.confirmFinishTicket()}>
                                                                                    <button type="button" className="btn btn-danger">
                                                                                        <i className="fas fa-store-alt-slash" />
                                                                                    </button>
                                                                                    <span className='mx-2'>Passou na cancela e não foi dado baixa.</span>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                        {
                                                                            this.state.isDeveloper &&
                                                                            <div className='col-lg-12 mt-2'>
                                                                                <h5>Desenvolvedor</h5>
                                                                                <div className='btn-group'>
                                                                                    <button type="button" className="btn text-white" style={{ backgroundColor: "#3f24af", borderColor: "#3f24af" }} onClick={(e) => this.showDatadog()}>
                                                                                        <i className="fas fa-database mx-2" />
                                                                                        Datadog
                                                                                    </button>
                                                                                    <button type="button" className="btn text-white" style={{ backgroundColor: "#1b73e8", borderColor: "#1b73e8" }} onClick={(e) => this.showGCP()}>
                                                                                        <i className="fas fa-database mx-2" />
                                                                                        Google Cloud
                                                                                    </button>
                                                                                    <button type="button" className="btn text-white" style={{ backgroundColor: "#ffc403", borderColor: "#ffc403" }} onClick={(e) => this.showFirebase()}>
                                                                                        <i className="fas fa-database mx-2" />
                                                                                        Firebase
                                                                                    </button>
                                                                                    <button type="button" className="btn text-white btn-secondary" onClick={(e) => this.showProccess()}>
                                                                                        <i className="fas fa-database mx-2" />
                                                                                        Processos
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </Tab>
                                                            }
                                                        </Tabs>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </> :
                                <div className="col-lg-9">
                                </div>
                        }
                        <div>
                            <Dialog maxWidth='xs' fullWidth open={this.state.isOpenAllTicketsModal} onClose={(e) => this.setState({ isOpenAllTicketsModal: false })}>
                                <MuiDialogTitle className='text-center'>
                                    Registros encontrados
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='row'>
                                        <Tabs key={this.state.random} className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.searchKey} onSelect={k => this.setState({ searchKey: k })}>
                                            {
                                                this.state.allTickets.length > 0 &&
                                                <Tab eventKey="tickets" className='col-lg-12' title={`${this.state.allTickets.length} Tickets`}>
                                                    <div className='row mt-3'>
                                                        {
                                                            this.state.allTickets.map((item) =>
                                                                <div className={`col-lg-12 alert alert-${this.colorCard(item)}`}>
                                                                    <div className='row'>
                                                                        <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                                            {item.status === 'Pago e Aguardando' && <span className="mt-1 badge text-bg-warning text-white">Este veículo esta pago e aguardando volta</span>}
                                                                            <h6><span className={`mt-1 badge text-bg-${this.colorCard(item)} text-white`}>{item.status}</span></h6>
                                                                            <div><strong>Ticket:</strong> {item.ticket}</div>
                                                                            <div><strong>Placa:</strong> {item.placa ? item.placa : "Não informada"}</div>
                                                                            <div><strong>Modelo:</strong> {item.vehicler?.modelo ? item.vehicler?.modelo : "Não informado"}</div>
                                                                            {
                                                                                takeIfIsNotNullOrEmpty(item.setor) &&
                                                                                <div><strong>Setor:</strong> {item.setor}</div>
                                                                            }
                                                                            <div><strong>Entrada:</strong> {Moment(item.createAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}</div>
                                                                            <div>
                                                                                {
                                                                                    item.closedAt &&
                                                                                    <>
                                                                                        <strong>Saída: </strong>
                                                                                        {Moment(item.closedAt.toDate()).format("DD/MM/YYYY [às] HH:mm A")}
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                            {
                                                                                item.observation &&
                                                                                <div>
                                                                                    <strong>Observação: </strong> <br />
                                                                                    {item.observation}
                                                                                </div>
                                                                            }
                                                                            <h6>
                                                                                {
                                                                                    item.urlVehicle &&
                                                                                    <span className={`m-1 badge text-bg-${this.colorCard(item)}`}>Imagem do veículo</span>
                                                                                }
                                                                                {
                                                                                    item.urlExterno &&
                                                                                    <span className={`m-1 badge text-bg-${this.colorCard(item)}`}>Avaliação externa</span>
                                                                                }
                                                                                {
                                                                                    (item.interno?.length ?? 0) > 0 &&
                                                                                    <span className={`m-1 badge text-bg-${this.colorCard(item)}`}>Avaliação interna</span>
                                                                                }
                                                                                {
                                                                                    (item.produtos?.length ?? 0) > 0 &&
                                                                                    <span className={`m-1 badge text-bg-${this.colorCard(item)}`}>Produtos</span>
                                                                                }
                                                                                {
                                                                                    (item.urlExternalReview?.length ?? 0) > 0 &&
                                                                                    <span className={`m-1 badge text-bg-${this.colorCard(item)}`}>Imagens</span>
                                                                                }
                                                                            </h6>
                                                                            <div className='btn-group'>
                                                                                <a href={"/buscar/ticket?id=" + item.id} className={`btn btn-${this.colorCard(item)} text-white`}>
                                                                                    <i className="fas fa-eye mx-2" />
                                                                                    {
                                                                                        item.monthly ?
                                                                                            <span>Mensalista</span> :
                                                                                            <>
                                                                                                {
                                                                                                    item.accredited ?
                                                                                                        <span>Credenciado</span> :
                                                                                                        <span>Detalhes</span>
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </a>
                                                                                {/* {
                                                                                    isAdministrador() &&
                                                                                    <button onClick={(e) => this.removeTicketWithItem(item)} className='btn btn-danger'>
                                                                                        <i className="fas fa-trash mx-2" />
                                                                                        Remover ticket
                                                                                    </button>
                                                                                } */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Tab>
                                            }
                                            {
                                                this.state.allMonthliesVehicle.length > 0 &&
                                                <Tab eventKey="mensalistas" className='col-lg-12' title={`${this.state.allMonthliesVehicle.length == 1 ? "Mensalista" : "Mensalistas"}`}>
                                                    <div className='row mt-3'>
                                                        {
                                                            this.state.allMonthliesVehicle.map((item) =>
                                                                <div className={`col-lg-12 alert alert-secondary`}>
                                                                    <div className='row'>
                                                                        <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                                            <div><strong>Placa:</strong> {takeIfIsNotNullOrEmpty(item.placa) ?? "Não informado"}</div>
                                                                            <div><strong>Marca:</strong> {takeIfIsNotNullOrEmpty(item.marca) ?? "Não informado"}</div>
                                                                            <div><strong>Modelo:</strong> {takeIfIsNotNullOrEmpty(item.modelo) ?? "Não informado"}</div>
                                                                            <div><strong>Cor:</strong> {takeIfIsNotNullOrEmpty(item.cor) ?? "Não informado"}</div>
                                                                            <div className='btn-group'>
                                                                                <a target='_blank' href={`/visualizar/mensalista/${item.mensalistaId}`} className={`btn btn-secondary text-white`}>
                                                                                    <i className="fas fa-eye mx-2" />
                                                                                    <span>Detalhes</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Tab>
                                            }
                                            {
                                                this.state.allAcreditedsVehicle.length > 0 &&
                                                <Tab eventKey="credenciados" className='col-lg-12' title={`${this.state.allAcreditedsVehicle.length == 1 ? "Credenciado" : "Credenciados"}`}>
                                                    <div className='row mt-3'>
                                                        {
                                                            this.state.allAcreditedsVehicle.map((item) =>
                                                                <div className={`col-lg-12 alert alert-info`}>
                                                                    <div className='row'>
                                                                        <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                                            <div><strong>Nome:</strong> {takeIfIsNotNullOrEmpty(item.nome) ?? "Não informado"}</div>
                                                                            <div><strong>Celular:</strong> {takeIfIsNotNullOrEmpty(item.celular) ?? "Não informado"}</div>
                                                                            <div><strong>Documento:</strong> {takeIfIsNotNullOrEmpty(item.documento) ?? "Não informado"}</div>
                                                                            <div>
                                                                                {
                                                                                    item.veiculos?.map(veiculo => {
                                                                                        return <span className={`m-1 badge text-bg-info text-white`}>{veiculo.placa}</span>
                                                                                    })
                                                                                }
                                                                                {
                                                                                    item.setores?.map(sector => {
                                                                                        return <span className={`m-1 badge text-bg-info text-white`}>{sector}</span>
                                                                                    })
                                                                                }
                                                                                {
                                                                                    item.tipos?.map(tipo => {
                                                                                        return <span className={`m-1 badge text-bg-info text-white`}>{tipo}</span>
                                                                                    })
                                                                                }
                                                                            </div>
                                                                            <div className='btn-group'>
                                                                                <a target='_blank' href={`/cadastrar/credenciado/?id${item.id}`} className={`btn btn-info text-white`}>
                                                                                    <i className="fas fa-eye mx-2" />
                                                                                    <span>Detalhes</span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Tab>
                                            }
                                            {
                                                this.state.allAccessCards.length > 0 &&
                                                <Tab eventKey="cartoes-de-acesso" className='col-lg-12' title={`${this.state.allAccessCards.length == 1 ? "Cartão de acesso" : "Cartões de acesso"}`}>
                                                    <div className='row mt-3'>
                                                        {
                                                            this.state.allAccessCards.map((item) =>
                                                                <div className={`col-lg-12 alert alert-secondary`}>
                                                                    {console.log(item)}
                                                                    <div className='row'>
                                                                        <div className='col-lg-10 col-md-11 col-10 col-sm-10'>
                                                                            <div><strong>Número:</strong> {takeIfIsNotNullOrEmpty(item.cardNumber) ?? "Não informado"}</div>
                                                                            <div className='btn-group'>
                                                                                {
                                                                                    item.type == "monthly" &&
                                                                                    <a target='_blank' href={`/visualizar/mensalista/${item.referenceId}`} className={`btn btn-secondary text-white`}>
                                                                                        <i className="fas fa-eye mx-2" />
                                                                                        <span>Mensalista</span>
                                                                                    </a>
                                                                                }
                                                                                {
                                                                                    item.type == "accredited" &&
                                                                                    <a target='_blank' href={`/cadastrar/credenciado/?id=${item.id}`} className={`btn btn-secondary text-white`}>
                                                                                        <i className="fas fa-eye mx-2" />
                                                                                        <span>Credenciado</span>
                                                                                    </a>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </Tab>
                                            }
                                        </Tabs>
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <button onClick={(e) => this.setState({ isOpenAllTicketsModal: false })} className='btn btn-secondary m-3'>Fechar</button>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={this.hideSettings} fullWidth maxWidth='sm' open={this.state.isOpenSettingsModal}>
                                {
                                    this.state.isLoadingSettings === true && <Loading />
                                }
                                {
                                    this.state.isLoadingSettings === false &&
                                    <>
                                        <MuiDialogTitle className='text-center'>
                                            Configurações do veículo
                                        </MuiDialogTitle>
                                        <MuiDialogContent>
                                            <div className='row'>
                                                {
                                                    this.state.ticket.placa === '' &&
                                                    <AlertWarning message="Só é possível definir configurações para tickets lançados com a placa do veículo." />
                                                }
                                                {
                                                    this.state.ticket.placa !== '' &&
                                                    <>
                                                        <div className="col-lg-12">
                                                            <AlertWarning message="Todas as configurações salvas são associadas a placa do veículo para o estabelecimento selecionado." />
                                                            <label>Lista de exclusão</label>
                                                            <select className='form-select' value={this.state.settingsAllowToPark} onChange={(e) => this.setState({ settingsAllowToPark: e.target.value })}>
                                                                <option value='PERMITIR'>Permitir que este veículo estacione no estabelecimento.</option>
                                                                <option value='NAO_PERMITIR'>**Não** permitir que este veículo estacione no estabelecimento.</option>
                                                                <option value='ALERTAR_OPERADOR'>Permitir estacionar, mas alertar o operador sobre este veículo.</option>
                                                            </select>
                                                            {
                                                                this.state.settingsAllowToPark !== "PERMITIR" &&
                                                                <div className='col-lg-12'>
                                                                    <label>Motivo</label>
                                                                    <textarea rows='4' onChange={(e) => this.setState({ settingsAllowToParkReason: e.target.value })} value={this.state.settingsAllowToParkReason} className="form-control" />
                                                                </div>
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={this.hideSettings} className='btn btn-secondary'>Fechar</button>
                                                {
                                                    this.state.placa !== '' &&
                                                    <button onClick={this.saveSettings} className='btn btn-success'>Salvar alterações</button>
                                                }
                                            </div>
                                        </MuiDialogActions>
                                    </>
                                }
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenModalBackToPark: false })} fullWidth maxWidth={"xs"} open={this.state.isOpenModalBackToPark}>
                                <MuiDialogTitle className='text-center'>
                                    Voltar ticket para o pátio
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <AlertDanger message=" Você deseja mesmo que este ticket volte para o pátio do estabelecimento?" />
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenModalBackToPark: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.confirmBackToPark()} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenChangePaymentTableModal: false })} fullWidth maxWidth={"xs"} open={this.state.isOpenChangePaymentTableModal}>
                                <MuiDialogTitle className='text-center'>
                                    Selecionar tabela de preço
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <select className='form-select form-control-lg' value={this.state.newTableSelectedInCheckInId} onChange={(e) => this.setState({ newTableSelectedInCheckInId: e.target.value })}>
                                        <option value=''>Selecione</option>
                                        {
                                            this.state.paymentTables?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                        }
                                    </select>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenChangePaymentTableModal: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.confirmNewPaymentTable()} className='btn btn-success'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenNewPlateModal: false })} fullWidth maxWidth={"xs"} open={this.state.isOpenNewPlateModal}>
                                <MuiDialogTitle className='text-center'>
                                    Informar nova placa
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <label>Placa</label>
                                    <input type='text' placeholder={this.state.ticket?.placa} value={this.state.placa} onChange={(e) => this.setState({ placa: e.target.value })} className='form-control form-control-lg' />
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenNewPlateModal: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.saveNewPlate()} className='btn btn-success'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isEditingNumberTicket: false })} fullWidth maxWidth={"xs"} open={this.state.isEditingNumberTicket}>
                                <MuiDialogTitle className='text-center'>
                                    Editar número do ticket
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    {
                                        this.state.editingNumberTicketState === "search" && <>
                                            <div className='col-lg-12'>
                                                <label>Número do ticket</label>
                                                <input type='text' value={this.state.newNumberTicket} onChange={(e) => this.setState({ newNumberTicket: e.target.value })} className='form-control form-control-lg' />
                                            </div>
                                        </>
                                    }
                                    {
                                        this.state.editingNumberTicketState === "existed" && <>
                                            <div className='col-lg-12'>
                                                <AlertDanger message="Este número de ticket esta sendo utilizado por outro veículo no pátio, caso confirme o listado abaixo será removido do sistema." />
                                            </div>
                                            <div className='col-lg-12'>
                                                <h4>Ticket encontrado</h4>
                                                <div className='col-lg-12'>
                                                    <div><strong>Número antigo:</strong> {this.state.ticket?.ticket}</div>
                                                    <div><strong>Número atual:</strong> {this.state.ticketExisted?.ticket}</div>
                                                    <div><strong>Entrada:</strong> {Moment(this.state.ticketExisted?.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</div>
                                                    <div><strong>Placa:</strong> {takeIfIsNotNullOrEmpty(this.state.ticketExisted?.placa) ?? "Não informada"}</div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        {
                                            this.state.editingNumberTicketState === "search" && <>
                                                <button onClick={(e) => this.setState({ isEditingNumberTicket: false })} className='btn btn-secondary'>Fechar</button>
                                                <button onClick={() => this.confirmNewTicketNumber()} className='btn btn-success'>Confirmar</button>
                                            </>
                                        }
                                        {
                                            this.state.editingNumberTicketState === "existed" &&
                                            <>
                                                <button onClick={(e) => this.setState({ editingNumberTicketState: "search" })} className='btn btn-secondary'>Cancelar</button>
                                                <button onClick={() => this.confirmNewTicketNumberAssociating()} className='btn btn-danger'>Confirmar número {this.state.ticketExisted?.ticket}</button>
                                            </>
                                        }
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenModalChangeLogged: false })} fullWidth maxWidth={"md"} open={this.state.isOpenModalChangeLogged}>
                                <MuiDialogTitle className='text-center'>
                                    Histórico de mudanças
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    {
                                        !this.state.ticket?.changeLogged || this.state.ticket?.changeLogged.length === 0 ? <EmptyContent text="Nenhuma mudança identificada até o momento." /> :
                                            <table className="table-striped table-responsive table">
                                                <thead>
                                                    <tr>
                                                        <td><strong>Operador</strong></td>
                                                        <td><strong>Ação</strong></td>
                                                        <td align='right'><strong>Data</strong></td>
                                                        {
                                                            this.state.isAdministrador &&
                                                            <td align='right' width={10}></td>
                                                        }
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ticket?.changeLogged?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td>{item.createdBy?.nome}</td>
                                                            <td>{item.message}</td>
                                                            <td align='right'>{Moment(item.createdAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss")}</td>
                                                            <td align='right'>
                                                                {
                                                                    this.state.isAdministrador &&
                                                                    <button type="button" className="btn btn-danger btn-sm" onClick={(e) => this.onRemoveChangeLog(item, index)}>
                                                                        <i className="fas fa-trash" />
                                                                    </button>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        {
                                            !isNullOrEmpty(this.state.ticket?.changeLogged) &&
                                            <button onClick={this.showExportChangesItems} className='btn btn-success'>
                                                <i className="fas fa-table mx-2" />
                                                Exportar
                                            </button>
                                        }
                                        <button onClick={(e) => this.setState({ isOpenModalChangeLogged: false })} className='btn btn-secondary'>Fechar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>

                            <Dialog onClose={(e) => this.setState({ isOpenChangePaymentValueModal: false })} fullWidth={true} maxWidth={"sm"} open={this.state.isOpenChangePaymentValueModal}>
                                <MuiDialogTitle className='text-center'>
                                    Alterar valores pagos
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='col-lg-12'>
                                        <AlertDanger message="Você esta alterando um valor de pagamento e este valor não será atualizado na adquirente caso tenha sido pago em uma maquininha de cartão." Ï />
                                        <label>Forma de pagamento</label>
                                        <select className='form-select' value={this.state.formaDePagamentoSelecionada} onChange={(e) => this.setState({ formaDePagamentoSelecionada: e.target.value })}>
                                            <option value=''>Selecione</option>
                                            {
                                                this.state.formasPagamentos?.map(item => { return <option value={item.id}>{item.nome === item.tipo ? item.nome : `${item.tipo} - ${item.nome}`}</option> })
                                            }
                                        </select>
                                        <label>Tabela de preço</label>
                                        <select className='form-select' value={this.state.paymentTableSelected} onChange={(e) => this.setState({ paymentTableSelected: e.target.value })}>
                                            <option value=''>Selecione</option>
                                            {
                                                this.state.paymentTables?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                            }
                                        </select>
                                        <label>Convênio</label>
                                        <select className='form-select' value={this.state.relationshipSelected} onChange={(e) => this.setState({ relationshipSelected: e.target.value })}>
                                            <option value=''>Selecione</option>
                                            {
                                                this.state.relationships?.map(item => { return <option value={item.id}>{item.nome}</option> })
                                            }
                                        </select>
                                        {
                                            !isNullOrEmpty(this.state.relationshipSelected) &&
                                            <>
                                                <label>Valor desconto</label>
                                                <CurrencyInput value={this.state.editingDiscountValue && this.state.editingDiscountValue} prefix="R$" className='form-control' onChangeEvent={this.changeValorDescontoTotal} />
                                            </>
                                        }
                                        <label>Valor estacionamento</label>
                                        <CurrencyInput value={this.state.editingParkingValue && this.state.editingParkingValue} prefix="R$" className='form-control' onChangeEvent={this.changeValorEstacionadoTotal} />
                                        <label>Valor cobrado total</label>
                                        <CurrencyInput value={this.state.editingPayedValue && this.state.editingPayedValue} prefix="R$" className='form-control' onChangeEvent={this.changeValorCobradoTotal} />
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenChangePaymentValueModal: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.confirmChangePaymentValue()} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog fullWidth maxWidth="lg" onClose={this.hideCallbacks} open={this.state.isOpenCallbacks}>
                                <MuiDialogContent>
                                    <QueueCallbackBody key={this.state.id} items={this.state.callbackItems} />
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <button onClick={this.hideCallbacks} className='btn btn-secondary m-3'>Fechar</button>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingF360SyncModal}>
                                {
                                    this.state.isShowingF360Loading ? <div className='text-center'>
                                        <Loading />
                                    </div> : <>
                                        <MuiDialogTitle className='text-center'>
                                            Sincronizar pagamento com a F360
                                        </MuiDialogTitle>
                                        <MuiDialogContent className='text-center'>
                                            <div className='col-lg-12'>
                                                Deseja mesmo enviar este pagamento para a F360? <p />
                                                <h1 className='text-success'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.currentPayment?.resumo?.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </h1>
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={this.hideF360SyncModal} className='btn btn-secondary'>Fechar</button>
                                                <button onClick={this.confirmF360SyncModal} className='btn btn-success'>Confirmar sincronismo</button>
                                            </div>
                                        </MuiDialogActions>
                                    </>
                                }
                            </Dialog>
                            <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingSeniorSyncModal}>
                                {
                                    this.state.isShowingSeniorLoading ? <div className='text-center'>
                                        <Loading />
                                    </div> : <>
                                        <MuiDialogTitle className='text-center'>
                                            Sincronizar pagamento com a sênior
                                        </MuiDialogTitle>
                                        <MuiDialogContent className='text-center'>
                                            <div className='col-lg-12'>
                                                Deseja mesmo enviar este pagamento para a sênior? <p />
                                                <h1 className='text-success'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.currentPayment?.resumo?.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </h1>
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={this.hideSeniorSyncModal} className='btn btn-secondary'>Fechar</button>
                                                <button onClick={this.confirmSeniorSyncModal} className='btn btn-success'>Confirmar sincronismo</button>
                                            </div>
                                        </MuiDialogActions>
                                    </>
                                }
                            </Dialog>
                            <Dialog fullWidth maxWidth={"xs"} open={this.state.isShowingSeniorCancelSyncModal}>
                                {
                                    this.state.isShowingSeniorLoading ? <div className='text-center'>
                                        <Loading />
                                    </div> : <>
                                        <MuiDialogTitle className='text-center'>
                                            Cancelar sincronismo com a sênior
                                        </MuiDialogTitle>
                                        <MuiDialogContent className='text-center'>
                                            <div className='col-lg-12'>
                                                Deseja mesmo remover este pagamento da sênior? <p />
                                                <h1 className='text-danger'>
                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={this.state.currentPayment?.resumo?.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                </h1>
                                            </div>
                                        </MuiDialogContent>
                                        <MuiDialogActions>
                                            <div className='btn-group m-3'>
                                                <button onClick={this.hideSeniorCancelSyncModal} className='btn btn-secondary'>Fechar</button>
                                                <button onClick={this.confirmSeniorCancelSync} className='btn btn-danger'>Confirmar cancelamento</button>
                                            </div>
                                        </MuiDialogActions>
                                    </>
                                }
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenChangePaymentCashierModal: false })} fullWidth maxWidth={"md"} open={this.state.isOpenChangePaymentCashierModal}>
                                <MuiDialogTitle className='text-center'>
                                    Alterar caixa do pagamento
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='col-lg-12'>
                                        <div className='row'>
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <td width={500}>
                                                            <div className='alert alert-warning'>
                                                                {
                                                                    this.state.currentCashier &&
                                                                    <>
                                                                        <strong>Caixa: </strong><span>{this.state.currentCashier.sequence}</span>
                                                                        <Tooltip title="Visualizar caixa" placement="top">
                                                                            <a href={`/caixas/${this.state.currentCashier.id}`} target="_blank">
                                                                                <i className="fas fa-eye mx-2" />
                                                                            </a>
                                                                        </Tooltip>
                                                                        <br />
                                                                        <strong>Status: </strong><span>{this.state.currentCashier.status.toUpperCase()}</span> <br />
                                                                        <strong>Aberto em: </strong><span>{Moment(this.state.currentCashier.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                                                        <strong>Aberto por: </strong><span>{this.state.currentCashier.openBy.nome}</span> <br />
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td width={50}>
                                                            <div className='text-center'>
                                                                <i className="fas fa-arrow-right fa-2xl" />
                                                            </div>
                                                        </td>
                                                        <td width={500}>
                                                            <div className='alert alert-success'>
                                                                <select className='form-select w-100' value={this.state.newCashier?.id} onChange={(e) => this.onChangePaymentSelectCashier(e)}>
                                                                    <option>Selecionar caixa</option>
                                                                    {
                                                                        this.state.allLastCashiers?.map(item => { return <option value={item.id}>{item.sequence}</option> })
                                                                    }
                                                                </select>
                                                                {
                                                                    this.state.newCashier &&
                                                                    <>
                                                                        <strong>Status: </strong><span>{this.state.newCashier.status.toUpperCase()}</span>
                                                                        <Tooltip title="Visualizar caixa" placement="top">
                                                                            <a href={`/caixas/${this.state.newCashier.id}`} target="_blank">
                                                                                <i className="fas fa-eye mx-2" />
                                                                            </a>
                                                                        </Tooltip><br />
                                                                        <strong>Aberto em: </strong><span>{Moment(this.state.newCashier.openAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</span> <br />
                                                                        <strong>Aberto por: </strong><span>{this.state.newCashier.openBy.nome}</span> <br />
                                                                    </>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenChangePaymentCashierModal: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={() => this.confirmChangePaymentFromCashier()} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenManualDiscountModal: false })} fullWidth open={this.state.isOpenManualDiscountModal}>
                                <MuiDialogTitle className='text-center'>
                                    Desconto manual
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <div className='row'>
                                        <div className='col-lg-12'>
                                            <div className='row'>
                                                <div className='col-lg-4'>
                                                    Valor
                                                    <h5 className='text-danger'>
                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={-this.state.manualDiscount?.discount} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                    </h5>
                                                </div>
                                                <div className='col-lg-6'>
                                                    Operador responsável
                                                    <h5>
                                                        {
                                                            this.state.manualDiscount?.user === null ?
                                                                "Não informado" : this.state.manualDiscount?.user?.nome
                                                        }
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    Motivo
                                                    <h5>
                                                        {this.state.manualDiscount?.reason}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <button onClick={(e) => this.setState({ isOpenManualDiscountModal: false })} className='btn btn-secondary'>Fechar</button>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenPaymentsLoggedModal: false })} maxWidth="lg" open={this.state.isOpenPaymentsLoggedModal}>
                                <MuiDialogTitle className='text-center'>
                                    Histórico de pagamentos do ticket
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    {
                                        this.state.ticket?.paymentsLogged === undefined ? <EmptyContent text="Nenhum histórico de pagamento disponível" /> :
                                            <table className="table-striped table-responsive table">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>Operador</th>
                                                        <th>Data e horário</th>
                                                        <th>Forma pagamento</th>
                                                        <th>Convênio</th>
                                                        <th>Desconto manual</th>
                                                        <th>Estacionamento</th>
                                                        <th>Produtos</th>
                                                        <th>Total</th>
                                                        <th width="100"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.ticket?.paymentsLogged?.map((item, index) =>
                                                        <tr key={index}>
                                                            <td align='center'>
                                                                {item.canceled === true && <h5><span className="badge text-bg-danger w-100">Cancelado</span></h5>}
                                                                {item.canceled === false && <h5><span className="badge text-bg-success w-100">Pago</span></h5>}
                                                            </td>
                                                            <td>
                                                                {item.online ? <span>Pagamento online</span> : <span>{item.user.nome}</span>}
                                                            </td>
                                                            <td align='center'>
                                                                {Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}
                                                            </td>
                                                            <td>{item?.formaPagamento?.nome}</td>
                                                            <td>
                                                                {
                                                                    item.resumo.valorDesconto > 0 ?
                                                                        <strong className='text-danger'>
                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorDesconto} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </strong> :
                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorDesconto} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                }
                                                            </td>
                                                            {
                                                                !item.resumo.descontoManual ? <td>R$ 0,00</td> :
                                                                    <td>
                                                                        <strong className='text-danger'>
                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={-(item.resumo?.descontoManual?.discount)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </strong>
                                                                        <button type="button" onClick={(e) => this.showManualDiscount(item.resumo?.descontoManual)} className="btn btn-danger mx-2">
                                                                            <i className="fas fa-hand-holding-usd" />
                                                                        </button>
                                                                    </td>
                                                            }
                                                            <td><NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorEstacionamento} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                            <td><NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorProdutos + item.resumo.valorServicos} displayType={'text'} thousandSeparator={true} prefix={'R$'} /></td>
                                                            <td>
                                                                {
                                                                    item.canceled === true ?
                                                                        <strong className='text-danger'>
                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </strong> :
                                                                        <strong className='text-success'>
                                                                            <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.resumo.valorCobrado} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                        </strong>
                                                                }
                                                            </td>
                                                            <td align='center'>
                                                                {this.syncSeniorBadge(item, index)}
                                                                {this.syncF360Badge(item, index)}
                                                                <div className='btn-group'>
                                                                    <Tooltip title="Alterar caixa de pagamento" placement="top">
                                                                        <button type="button" className="btn btn-info text-white" onClick={(e) => this.onChangePaymentCashier(item)}>
                                                                            <i className="fas fa-random" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Editar pagamento" placement="top">
                                                                        <button className="btn btn-warning text-white" onClick={(e) => this.onChangePaymentValues(item, index)}>
                                                                            <i className="fas fa-edit" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Remover pagamento" placement="top">
                                                                        <button className="btn btn-danger" onClick={(e) => this.onRemovePayment(item, index)}>
                                                                            <i className="fas fa-trash" />
                                                                        </button>
                                                                    </Tooltip>
                                                                    <Tooltip title="Visualizar ticket no caixa" placement="top">
                                                                        <a target='_blank' className="btn btn-primary" href={('/caixas/' + item.caixaId + "?ticket=" + this.state.ticket.id)}>
                                                                            <i className="fas fa-eye" />
                                                                        </a>
                                                                    </Tooltip>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <button onClick={(e) => this.setState({ isOpenPaymentsLoggedModal: false })} className='btn btn-secondary m-3'>Fechar</button>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenStatusLoggedModal: false })} fullWidth maxWidth={"sm"} open={this.state.isOpenStatusLoggedModal}>
                                <MuiDialogTitle className='text-center'>
                                    Histórico de status do ticket
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    {
                                        (this.state.ticket?.statusLogged?.length ?? 0) === 0 ? <EmptyContent text="Nenhum histórico adicionado até o momento." /> :
                                            <div className='table-responsive'>
                                                <table className="table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <td align='center'><strong>Operador</strong></td>
                                                            <td align='center'><strong>Data e Horário</strong></td>
                                                            <td align='center'><strong>Status</strong></td>
                                                            {this.state.isAdministrador && <td align='right'></td>}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {this.state.ticket?.statusLogged?.map((item, index) =>
                                                            <tr key={index}>
                                                                <td align='center'>{item.online ? <span>Pagamento online</span> : item.createBy.nome}</td>
                                                                <td align='center'>{Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}</td>
                                                                <td align='center' width={80}>
                                                                    <h6>
                                                                        <span className={this.statusClassName(item)}>
                                                                            {item.status}
                                                                        </span>
                                                                    </h6>
                                                                </td>
                                                                <td width={10} align='right'>
                                                                    <div className='btn-group'>
                                                                        {
                                                                            this.state.ticket.statusLogged.length === (index + 1) &&
                                                                            <UserPermissionButton permission="CHANGE_STATUS_TICKET_IN_BATCH" action={() => { this.changeStatus(item, index) }} className='btn btn-secondary text-white'>
                                                                                <Tooltip title="Alterar status" placement="top">
                                                                                    <i className="fas fa-random" />
                                                                                </Tooltip>
                                                                            </UserPermissionButton>
                                                                        }
                                                                        <Tooltip title="Remover status" placement="top">
                                                                            <button type="button" onClick={() => { this.removeStatus(item, index) }} className="btn btn-danger btn-sm">
                                                                                <i className="fas fa-trash" />
                                                                            </button>
                                                                        </Tooltip>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <button onClick={(e) => this.setState({ isOpenStatusLoggedModal: false })} className='btn btn-secondary m-3'>Fechar</button>
                                </MuiDialogActions>
                            </Dialog>
                            <Dialog onClose={(e) => this.setState({ isOpenModalChangeStatus: false })} fullWidth maxWidth={"xs"} open={this.state.isOpenModalChangeStatus}>
                                <MuiDialogTitle className='text-center'>
                                    Alterar status
                                </MuiDialogTitle>
                                <MuiDialogContent>
                                    <AlertDanger message="Alterando este status você alterará o status principal do ticket." />
                                    <label>Selecionar novo status</label>
                                    <select className="form-select" value={this.state.newStatusSelected} onChange={(e) => this.setState({ newStatusSelected: e.target.value })}>
                                        <option value="">Selecionar</option>
                                        <option value="Estacionado">Estacionado</option>
                                        <option value="Pago e Estacionado">Pago e Estacionado</option>
                                        <option value="Pago e Entregue">Pago e Entregue</option>
                                        <option value="Pago e Aguardando">Pago e Aguardando</option>
                                        <option value="Cancelado">Cancelado</option>
                                    </select>
                                    {
                                        this.state.newStatusSelected === TicketStatus.PAYED_AND_DELIVERED && this.state.isAdministrador && <>
                                            <div className="form-check form-switch mt-2">
                                                <input className="form-check-input" id="exportFieldType" checked={this.state.caixaFechado} onChange={(e) => this.setState({ caixaFechado: e.target.checked })} type="checkbox" />
                                                <label className="form-check-label" htmlFor="exportFieldType">
                                                    Dar baixa no veículo ( remover da listagem ).
                                                </label>
                                            </div>
                                        </>
                                    }
                                </MuiDialogContent>
                                <MuiDialogActions>
                                    <div className='btn-group m-3'>
                                        <button onClick={(e) => this.setState({ isOpenModalChangeStatus: false })} className='btn btn-secondary'>Fechar</button>
                                        <button onClick={(e) => this.confirmNewStatus()} className='btn btn-danger'>Confirmar</button>
                                    </div>
                                </MuiDialogActions>
                            </Dialog>
                        </div>
                    </div>
                    {
                        this.state.showModalAccessHistory && <TicketAnalyticsDialog tickets={this.state.tickets} onClose={this.onCloseAnalyticsDialog} />
                    }
                    {
                        this.state.isEditingLimitPermanenceDate && this.state.ticket?.limitPermanence && <DatePickerDialog
                            onClose={this.hideLimitedPermanenceModal}
                            date={Moment(this.state.ticket.limitPermanence.seconds * 1000).toDate()}
                            onConfirm={this.changeLimitPermanenceDate} />
                    }
                    <Dialog onClose={() => this.setState({ isCancelingTicket: false })} fullWidth maxWidth={"xs"} open={this.state.isCancelingTicket}>
                        <MuiDialogTitle className='text-center'>
                            Cancelamento de ticket
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            {
                                this.state.isFound &&
                                <>
                                    {this.state.ticket.status === TicketStatus.PARKING ?
                                        <AlertDanger message={`Você está cancelando o ticket: ${this.state.ticket.ticket}`} /> :
                                        <AlertDanger message={`Você está cancelando o ticket: ${this.state.ticket.ticket} e todos os seus pagamentos.`} />}
                                </>
                            }
                            Informe o motivo do cancelamento desse ticket:
                            <textarea rows={6} onChange={(e) => this.setState({ cancelReason: e.target.value })} value={this.state.cancelReason} className="form-control" />
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='btn-group m-3'>
                                <button onClick={() => this.setState({ isCancelingTicket: false })} className='btn btn-secondary'>Fechar</button>
                                <button onClick={() => this.verifyCancelTicket()} className='btn btn-danger'>Cancelar ticket</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </NavBar >
            </>
        )
    }
}


class TicketAnalyticsDialog extends React.Component {

    closeTicketCompletedDetail = () => {
        sendClickButton("Relatório", "Fechou tickets detalhados")
        this.onClose()
    }

    onClose = () => {
        this.props.onClose()
    }

    render() {
        return (
            <>
                <Dialog onClose={this.closeTicketCompletedDetail} fullWidth maxWidth="xl" open={true}>
                    <MuiDialogContent>
                        <TicketAnalyticsBody {...this.props} />
                    </MuiDialogContent>
                </Dialog>
            </>
        )
    }
}

class TicketAnalyticsBody extends React.Component {

    constructor(props) {
        super(props);
        const tickets = this.props.tickets.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()) ?? []
        const ticketsCompletedF360 = tickets?.filter(e => e.status !== TicketStatus.CANCELED && (e.paymentsLogged?.filter(e => e.canceled !== true)?.map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0) ?? 0) > 0)

        this.state = {
            estacionamentoId: getEstacionamento()?.id,
            tickets: tickets,
            auxTickets: tickets,
            ticketeCompleteDetail: true,
            ticketsCompleted: tickets,
            ticketsCompletedF360: ticketsCompletedF360,
            ticketsCompletedF360Total: ticketsCompletedF360.length,
            key: "graficos",
            allConvenios: [],
            hasPrisms: false,
            allTabelaPrecos: [],
            allOperadores: [],
            allFormaPagamento: [],
            allCaixas: [],
            allModelos: [],
            allMarcas: [],
            allMensalistas: [],
            allStores: [],
            allTerminais: [],

            conveniosSelecionados: [],
            tabelasSelecionadas: [],
            operadoresSelecionados: [],
            formasPagamentosSelecionados: [],
            setoresSelecionados: [],
            statusSelecionados: [],
            tiposTicketSelecionados: [],
            terminaisSelecionados: [],
            tipoVeiculosSelecionados: [],
            allSetores: [],
            allStatus: [],
            allTiposTicket: [],
            allVehicleTypes: [],
            exportFieldType: true,
            exportFieldPlate: true,
            exportFieldTicket: true,
            exportFieldModel: true,
            exportFieldIn: true,
            exportFieldOut: true,
            exportFieldPermanence: true,
            exportFieldUser: true,
            exportFieldPaymentMethod: true,
            exportFieldRelationship: true,
            exportFieldPaymentTable: true,
            exportFieldValuePark: true,
            exportFieldValueDiscount: true,
            exportFieldValueProducts: true,
            exportFieldValueTotal: true,
            exportFieldLink: true,
            exportFieldPayment: true,
            exportFieldDelimiter: ","
        }
    }

    componentDidMount = () => {
        this.openCompletedTickets()
        this.loadParameters()
    }

    loadParameters = async () => {
        const parameters = await getParameter()
        this.setState({ parameters: { ...parameters } })
    }

    searchTickets = (e) => {
        const value = e.target.value.toUpperCase()
        const result = this.props.tickets.filter(e => e.ticket.toUpperCase().includes(value) ||
            e.placa?.toUpperCase().includes(value) ||
            e.vehicler?.modelo?.toUpperCase().includes(value) ||
            e.vehicler?.cor?.toUpperCase().includes(value))
        this.setState({ tickets: result.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()) })
    }

    statusTicket = (item) => {
        if (item.status === "Pago e Estacionado") {
            return "btn btn-info w-100 text-white"
        }
        if (item.status === "Pago e Entregue") {
            return "btn btn-success w-100"
        }
        if (item.status === "Pago e Aguardando") {
            return "btn btn-warning text-white w-100"
        }
        if (item.status === "Cancelado") {
            return "btn btn-danger w-100"
        }
        return "btn btn-primary w-100"
    }

    tiposTicket = (type) => {
        if (type === "SALES") {
            return "Venda avulsa"
        }
        if (type === "MONTHLY") {
            return "Mensalista"
        }
        if (type == "ACCREDITED") {
            return "Credenciado"
        }
        return "Avulso"
    }

    openCompletedTickets = () => {
        sendClickButton("Relatório", "Abriu tickets detalhados")
        const tickets = this.props.tickets

        const payments = tickets.flatMap(e => e.paymentsLogged).filter(e => e)

        const allConvenios = [...(new Set(payments.filter(e => e?.resumo?.convenio).map(e => e.resumo?.convenio?.nome ?? "")))].filter(e => e !== "").map(e => { return { value: e, label: e } })
        this.setState({ allConvenios: allConvenios })

        this.setState({ hasPrisms: tickets.filter(e => takeIfIsNotNullOrEmpty(e.prisma)).length > 0 })
        const allTabelaPrecos = [...(new Set(payments.map(e => e.resumo?.tabelaPreco?.nome ?? "")))].filter(e => e !== "").map(e => { return { value: e, label: e } })
        this.setState({ allTabelaPrecos: allTabelaPrecos })

        const allOperadores = [...(new Set(payments.map(e => e.user.nome)))].filter(e => e !== "").map(e => { return { value: e, label: e } })
        this.setState({ allOperadores: allOperadores })

        const allFormaPagamento = [...(new Set(payments.filter(e => e.formaPagamento).map(e => e.formaPagamento?.nome ?? "")))].filter(e => e !== "").map(e => { return { value: e, label: e } })
        this.setState({ allFormaPagamento: allFormaPagamento })

        const sectores = [...(new Set(tickets.filter(e => e.setor).map(e => e.setor)))]
        const allSetores = sectores.map(e => { return { value: e, label: e } })
        this.setState({ allSetores: allSetores })

        const status = [...(new Set(tickets.filter(e => e.status).map(e => e.status)))]
        const allStatus = status.map(e => { return { value: e, label: e } })
        this.setState({ allStatus: allStatus })

        const tipos = [...(new Set(tickets.filter(e => e.internalType).map(e => e.internalType)))]
        const allTiposTicket = tipos.map(e => { return { value: e, label: this.tiposTicket(e) } })
        this.setState({ allTiposTicket: allTiposTicket })

        const terminais = [...(new Set(tickets.filter(e => e.systemTerminal).map(e => e.systemTerminal)))]
        const allTerminais = terminais.map(e => { return { value: e, label: e } })
        this.setState({ allTerminais: allTerminais })

        const typeVehicler = [...(new Set(tickets.filter(e => e.typeVehicler).map(e => e.typeVehicler)))]
        const allTypeVehicler = typeVehicler.map(e => { return { value: e, label: e } })
        this.setState({ allVehicleTypes: allTypeVehicler })
    }

    reloadCompletedList = () => {
        var tickets = this.state.tickets
        if (this.state.formasPagamentosSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allFormaPagamento = this.state.formasPagamentosSelecionados.map(e => e.label)
                const formas = e.paymentsLogged?.filter(e => e.formaPagamento).map(f => f.formaPagamento.nome) ?? []
                return formas.some(ai => allFormaPagamento.includes(ai))
            })
        }
        if (this.state.conveniosSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allConvenios = this.state.conveniosSelecionados.map(e => e.label)
                const convenios = e.paymentsLogged?.filter(e => e.resumo?.convenio).map(f => f.resumo.convenio.nome) ?? []
                return convenios.some(ai => allConvenios.includes(ai))
            })
        }
        if (this.state.tabelasSelecionadas.length > 0) {
            tickets = tickets.filter(e => {
                const allTables = this.state.tabelasSelecionadas.map(e => e.label)
                const tables = e.paymentsLogged?.map(f => f.resumo?.tabelaPreco?.nome ?? "") ?? []
                return tables.some(ai => allTables.includes(ai))
            })
        }
        if (this.state.operadoresSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allOperadores = this.state.operadoresSelecionados.map(e => e.label)
                const names = e.paymentsLogged?.map(f => f.user.nome) ?? []
                return names.some(ai => allOperadores.includes(ai))
            })
        }
        if (this.state.setoresSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allSetores = this.state.setoresSelecionados.map(e => e.value)
                return allSetores.includes(e.setor)
            })
        }
        if (this.state.statusSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allStatus = this.state.statusSelecionados.map(e => e.value)
                return allStatus.includes(e.status)
            })
        }
        if (this.state.tiposTicketSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allTiposTicket = this.state.tiposTicketSelecionados.map(e => e.value)
                return allTiposTicket.includes(e.internalType)
            })
        }

        if (this.state.terminaisSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allTerminais = this.state.terminaisSelecionados.map(e => e.value)
                return allTerminais.includes(e.systemTerminal)
            })
        }

        if (this.state.tipoVeiculosSelecionados.length > 0) {
            tickets = tickets.filter(e => {
                const allTerminais = this.state.tipoVeiculosSelecionados.map(e => e.value)
                return allTerminais.includes(e.typeVehicler)
            })
        }
        this.setState({ ticketsCompleted: tickets })
    }

    closeTicketCompletedDetail = () => {
        sendClickButton("Relatório", "Fechou tickets detalhados")
        this.setState({ ticketeCompleteDetail: false })
        this.setState({ tabelasSelecionadas: [] })
        this.setState({ operadoresSelecionados: [] })
        this.setState({ formasPagamentosSelecionados: [] })
        this.setState({ conveniosSelecionados: [] })
        this.setState({ ticketsCompleted: [] })
        this.setState({ setoresSelecionados: [] })
        this.onClose()
    }

    exportData = () => {
        if (this.state.ticketsCompleted.length === 0) {
            return
        }
        const revenda = getRevenda()
        const rows = this.state.ticketsCompleted.map(e => {
            const payments = e.paymentsLogged?.filter(f => f).filter(g => g.canceled !== true) ?? []
            var data = {}
            if (this.state.exportFieldType) {
                data["TIPO"] = (this.tiposTicket(e.internalType))
            }
            if (this.state.exportFieldPlate) {
                data["PLACA"] = (e.placa ?? "-")
            }
            if (this.state.exportFieldTicket) {
                data["TICKET"] = (e.ticket)
            }
            if (this.state.exportFieldModel) {
                data["MODELO"] = (e.vehicler?.modelo?.toUpperCase() ?? "-")
            }
            if (this.state.exportFieldIn) {
                data["ENTRADA"] = (Moment(e.createAt.seconds * 1000).format("DD/MM/YYYY HH:mm:ss"))
            }
            if (this.state.exportFieldOut) {
                data["SAÍDA"] = (e.closedAt ? Moment(e.closedAt.seconds * 1000).format("DD/MM/YYYY HH:mm:ss") : "-")
            }
            if (this.state.exportFieldPermanence) {
                data["PERMANÊNCIA"] = ((e.closedAt ? secondsToDescription(((e.closedAt.seconds - e.createAt.seconds))) : "-"))
            }
            if (this.state.exportFieldUser) {
                data["OPERADOR"] = (payments.map(e => e.user.nome).join(","))
            }
            if (this.state.exportFieldPaymentMethod) {
                data["PAGAMENTO"] = (payments.map(e => e.formaPagamento?.nome ?? "").join(","))
            }
            if (this.state.exportFieldRelationship) {
                data["CONVÊNIO"] = (payments.map(e => e.resumo?.convenio?.nome ?? "").join(","))
            }
            if (this.state.exportFieldPaymentTable) {
                data["TABELA DE PREÇO"] = (payments.map(e => e.resumo?.tabelaPreco?.nome ?? "").join(","))
            }
            if (this.state.exportFieldValuePark) {
                data["VALOR ESTACIONADO"] = (payments.map(e => e.resumo?.valorEstacionamento ?? 0).reduce((a, b) => a + b, 0))
            }
            if (this.state.exportFieldValueDiscount) {
                data["VALOR DESCONTO"] = (-(payments.map(e => e.resumo?.valorDesconto ?? 0).reduce((a, b) => a + b, 0)))
            }
            if (this.state.exportFieldValueProducts) {
                data["VALOR PRODUTOS"] = ((payments.map(e => e.resumo?.valorProdutos ?? 0).reduce((a, b) => a + b, 0)) + (payments.map(e => e.resumo?.valorServicos ?? 0).reduce((a, b) => a + b, 0)))
            }
            if (this.state.exportFieldValueTotal) {
                data["VALOR COBRADO"] = (payments.map(e => e.resumo?.valorCobrado ?? 0).reduce((a, b) => a + b, 0))
            }
            if (this.state.exportFieldPayment) {
                const bin = (payments.filter(e => !isNullOrEmpty(e.resumo?.order?.bin)).map(e => e.resumo?.order?.bin ?? "").join(","))
                if (!isNullOrEmpty(bin)) {
                    data["CARTAO"] = bin
                } else {
                    data["CARTAO"] = ""
                }
                const brand = (payments.filter(e => !isNullOrEmpty(e.resumo?.order?.brand)).map(e => e.resumo?.order?.brand ?? "").join(","))
                if (!isNullOrEmpty(brand)) {
                    data["BANDEIRA"] = brand
                } else {
                    data["BANDEIRA"] = ""
                }
                const nsu = (payments.filter(e => !isNullOrEmpty(e.resumo?.order?.nsu)).map(e => e.resumo?.order?.nsu ?? "").join(","))
                if (!isNullOrEmpty(nsu)) {
                    data["NSU"] = nsu
                } else {
                    data["NSU"] = ""
                }
                const authCode = (payments.filter(e => !isNullOrEmpty(e.resumo?.order?.authCode)).map(e => e.resumo?.order?.authCode ?? "").join(","))
                if (!isNullOrEmpty(authCode)) {
                    data["AUTORIZAÇÃO"] = authCode
                } else {
                    data["AUTORIZAÇÃO"] = ""
                }
            }
            if (this.state.exportFieldLink) {
                data["DETALHES"] = (`${revenda.siteAdmin}/buscar/ticket?id=${e.id}`)
            }
            if (this.state.parameters?.habilitarF360ERP) {
                data["F360 ID"] = payments.map(e => e.F360SyncId).join(",")
                data["F360 CUPOM"] = payments.map(e => e.f360SyncCoupon).join(",")
                data["F360 STATUS"] = payments.map(e => e.F360SyncStatus).join(",")
            }
            return data
        })
        exportAndDownloadXLS(rows, `VEICULOS_POR_PERIODO`, this.state.exportFieldDelimiter)
        sendClickButton("Relatório", "Tocou em exportar dados", null, JSON.stringify(rows))
    };

    handleAllTabelaPrecos = (selected) => {
        this.setState({ tabelasSelecionadas: selected }, () => {
            this.reloadCompletedList()
        })
    };

    handleOperadores = (selected) => {
        this.setState({ operadoresSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    };

    handleFormaPagamentos = (selected) => {
        this.setState({ formasPagamentosSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    };

    handleConvenios = (selected) => {
        this.setState({ conveniosSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    };

    handleSetores = (selected) => {
        this.setState({ setoresSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    };
    handleStatus = (selected) => {
        this.setState({ statusSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    };

    handleTiposTicket = (selected) => {
        this.setState({ tiposTicketSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    }

    handleTerminais = (selected) => {
        this.setState({ terminaisSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    }

    handleTiposVeiculos = (selected) => {
        this.setState({ tipoVeiculosSelecionados: selected }, () => {
            this.reloadCompletedList()
        })
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className="col-lg-3">
                        <strong>Formas de pagamento</strong>
                        <Select
                            isMulti
                            name="tabela_precos"
                            options={this.state.allFormaPagamento}
                            onChange={this.handleFormaPagamentos}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar as Formas de pagamento"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Convênios</strong>
                        <Select
                            isMulti
                            name="convenios"
                            options={this.state.allConvenios}
                            onChange={this.handleConvenios}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os convênios"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Tabelas de preço</strong>
                        <Select
                            isMulti
                            name="tabela_precos"
                            options={this.state.allTabelaPrecos}
                            onChange={this.handleAllTabelaPrecos}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar tabela de preços"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Operadores</strong>
                        <Select
                            isMulti
                            name="tabela_precos"
                            options={this.state.allOperadores}
                            onChange={this.handleOperadores}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os operadores"
                        />
                    </div>
                    {/* {
                                    this.state.allSetores.length > 0 && */}
                    <div className="col-lg-3">
                        <strong>Setores</strong>
                        <Select
                            isMulti
                            name="setores"
                            options={this.state.allSetores}
                            onChange={this.handleSetores}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os setores"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Status</strong>
                        <Select
                            isMulti
                            name="Status"
                            options={this.state.allStatus}
                            onChange={this.handleStatus}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os setores"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Tipo de ticket</strong>
                        <Select
                            isMulti
                            name="tipos_ticket"
                            options={this.state.allTiposTicket}
                            onChange={this.handleTiposTicket}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os tipos de ticket"
                        />
                    </div>
                    <div className="col-lg-3">
                        <strong>Tipo do veículo</strong>
                        <Select
                            isMulti
                            name="tipos_veiculo"
                            options={this.state.allVehicleTypes}
                            onChange={this.handleTiposVeiculos}
                            className="basic-multi-select"
                            classNamePrefix="Selecionar os tipos de veiculo"
                        />
                    </div>
                    {
                        isAdministrador() &&
                        <div className="col-lg-3">
                            <strong>Terminais</strong>
                            <Select
                                isMulti
                                name="terminais"
                                options={this.state.allTerminais}
                                onChange={this.handleTerminais}
                                className="basic-multi-select"
                                classNamePrefix="Selecionar os terminais"
                            />
                        </div>
                    }
                </div>
                {
                    this.state.ticketsCompleted.length === 0 &&
                    <EmptyContent text="Selecione um filtro acima para encontrar os tickets lançados." />
                }
                {
                    this.state.ticketsCompleted.length !== 0 &&
                    <>
                        <div className='row my-3' key={this.state.ticketsCompleted.length}>
                            <h6>Totais dos tickets filtrados</h6>
                            <TotalsValorCobrado tickets={this.state.ticketsCompleted} />
                            <TotalsTicketsCanceled tickets={this.state.ticketsCompleted} />
                            <TotalsPermanenciaMedia tickets={this.state.ticketsCompleted} />
                            <TotalsConvenios tickets={this.state.ticketsCompleted} />
                            <TotalsCredenciados tickets={this.state.ticketsCompleted} />
                        </div>
                        <Tabs className='profile-dropdown-toggle' defaultActiveKey={1} activeKey={this.state.key} onSelect={k => this.setState({ key: k })}>
                            <Tab eventKey="graficos" title="Gráficos" onClick={e => sendClickButton("Relatório", "Visualizou gráficos")}>
                                <div className='row'>
                                    <AverageTimeTicketAccessCharts key={`AverageTime-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                </div>
                                <div className='row'>
                                    <StackedColumnsTicketAccessCharts key={`StackedColumn-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                </div>
                                <div className='row'>
                                    <HeatMapWeakTimesStartTickets key={`heatMapIn-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                    <HeatMapWeakTimesEndTickets key={`heatMapOut-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                </div>
                                <div className='row'>
                                    <TreeMapPaymentTableCharts key={`treeMap-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                    <DistributedColumnsOperatorsCharts key={`distributedColumns-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                    <TreeMapSectorsCharts key={`treeMap1-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                    <TreeMapAccreditedsCharts key={`treeMap3-${this.state.ticketsCompleted.length}`} tickets={this.state.ticketsCompleted} />
                                </div>
                            </Tab>
                            <Tab eventKey="dadosanalisados" title="Dados analisados">
                                <div className='table-responsive my-2'>
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <td width={170}></td>
                                                <td align='center'><strong>Ticket</strong></td>
                                                <td align='center'><strong>Placa</strong></td>
                                                <td align='center'><strong>Modelo</strong></td>
                                                <td align='center'><strong>Entrada</strong></td>
                                                <td align='center'><strong>Saída</strong></td>
                                                <td align='center'><strong>Permanência</strong></td>
                                                <td align='center'><strong>Operador</strong></td>
                                                <td align='center'><strong>Pagamento</strong></td>
                                                {
                                                    this.state.allConvenios.length > 0 &&
                                                    <td align='center'><strong>Convênio</strong></td>
                                                }
                                                {
                                                    this.state.allSetores.length > 0 &&
                                                    <td align='center'><strong>Setor</strong></td>
                                                }
                                                {
                                                    this.state.hasPrisms &&
                                                    <td align='center'><strong>Prisma</strong></td>
                                                }
                                                <td align='center'><strong>Tabela de preço</strong></td>
                                                <td align='center'><strong>Valor cobrado</strong></td>
                                                {
                                                    isAdministrador() &&
                                                    <>
                                                        <td align='center'><strong>Terminal</strong></td>
                                                        <td align='center'><strong>Sequência</strong></td>
                                                        <td align='center'><strong>Versão</strong></td>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.ticketsCompleted.map((item, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <a href={'/buscar/ticket?id=' + item.id + "&tab=pagamentos"} target="_blank">
                                                                <button type="button" className={this.statusTicket(item)}>
                                                                    <i className="fas fa-eye" />
                                                                    {
                                                                        <span className='mx-2'>{this.tiposTicket(item.internalType)}</span>
                                                                    }
                                                                </button>
                                                            </a>
                                                        </td>
                                                        <td align='center'>
                                                            <Tooltip title="Clique para buscar este ticket" placement="top">
                                                                <a href={'/buscar/ticket?id=' + item.id} target="_blank">
                                                                    <span>{item.ticket}</span>
                                                                    <i className="fas fa-eye mx-2" />
                                                                </a>
                                                            </Tooltip>
                                                        </td>
                                                        <td align='center'>
                                                            {
                                                                takeIfIsNotNullOrEmpty(item.placa) ?
                                                                    <Tooltip title="Clique para buscar esta placa" placement="top">
                                                                        <a href={'/buscar/ticket?placa=' + item.placa?.toUpperCase()} target="_blank">
                                                                            {item.placa?.toUpperCase() ?? "-"}
                                                                            <i className="fas fa-eye mx-2" />
                                                                        </a>
                                                                    </Tooltip> : <span>-</span>
                                                            }
                                                        </td>
                                                        <td align='center'>
                                                            {item.vehicler?.modelo?.toUpperCase() ?? "-"}
                                                        </td>
                                                        <td align='center'>
                                                            {Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}
                                                        </td>
                                                        {
                                                            item.closedAt ?
                                                                <td align='center'>
                                                                    {Moment(item.closedAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm:ss A")}
                                                                </td>
                                                                :
                                                                <td align='center'>
                                                                    -
                                                                </td>
                                                        }
                                                        {
                                                            item.closedAt ?
                                                                <td align='center'>
                                                                    {secondsToDescription(((item.closedAt.seconds - item.createAt.seconds)))}
                                                                </td>
                                                                : <td align='center'>-</td>
                                                        }
                                                        {
                                                            item.paymentsLogged ?
                                                                <td align='center'>
                                                                    {
                                                                        item.paymentsLogged.filter(e => e.canceled !== true).length === 0 ?
                                                                            <span>-</span> : item.paymentsLogged.filter(e => e.canceled !== true).length === 1 ?
                                                                                <span>{item.paymentsLogged[0]?.user.nome}</span> :
                                                                                <Tooltip title={item.paymentsLogged.filter(e => e.canceled !== true).map(e => e.user.nome).join(", ")} placement="top">
                                                                                    <span className="badge text-bg-warning text-white">{item.paymentsLogged.filter(e => e.canceled !== true).length} operadores</span>
                                                                                </Tooltip>
                                                                    }
                                                                </td>
                                                                : <td align='center'>-</td>
                                                        }
                                                        {
                                                            item.paymentsLogged ?
                                                                <td align='center'>
                                                                    {
                                                                        item.paymentsLogged?.length === 0 ?
                                                                            <span>-</span> : item.paymentsLogged.filter(e => e.canceled !== true).length === 1 ?
                                                                                <span>{item.paymentsLogged.filter(e => e.canceled !== true)[0]?.formaPagamento?.nome !== undefined ? item.paymentsLogged.filter(e => e.canceled !== true)[0]?.formaPagamento?.nome : "-"}</span> :
                                                                                <Tooltip title={item.paymentsLogged.filter(e => e.canceled !== true).map(e => e.formaPagamento?.nome ?? "").join(", ")} placement="top">
                                                                                    <span className="badge text-bg-warning text-white">{item.paymentsLogged.filter(e => e.canceled !== true).length} pagamentos</span>
                                                                                </Tooltip>
                                                                    }
                                                                </td>
                                                                : <td align='center'>-</td>
                                                        }
                                                        {
                                                            this.state.allConvenios?.length > 0 &&
                                                            <td align='center'>
                                                                {
                                                                    item.paymentsLogged?.length === 0 || item.paymentsLogged?.filter(e => e.resumo?.convenio !== null).length === 0 ?
                                                                        <span>-</span> : item.paymentsLogged?.length === 1 ?
                                                                            <span>{item.paymentsLogged[0]?.resumo?.convenio?.nome ?? "-"}</span> :
                                                                            <Tooltip title={item.paymentsLogged?.filter(e => e.canceled !== true)?.filter(e => e.resumo.convenio === null).map(e => e.resumo.convenio?.nome).join(", ")} placement="top">
                                                                                <span className="badge text-bg-warning text-white">{item.paymentsLogged?.filter(e => e.canceled !== true)?.length ?? 0} convênios</span>
                                                                            </Tooltip>
                                                                }
                                                            </td>
                                                        }
                                                        {
                                                            this.state.allSetores.length > 0 &&
                                                            <td align='center'>{takeIfIsNotNullOrEmpty(item.setor) ?? "-"}</td>
                                                        }
                                                        {
                                                            this.state.hasPrisms &&
                                                            <td align='center'>{takeIfIsNotNullOrEmpty(item.prisma) ?? "-"}</td>
                                                        }
                                                        {
                                                            item.paymentsLogged ?
                                                                <td align='center'>
                                                                    {
                                                                        item.paymentsLogged?.filter(e => e.canceled !== true).length === 0 ?
                                                                            <span>-</span> : item.paymentsLogged.filter(e => e.canceled !== true).length === 1 ?
                                                                                <span>{item.paymentsLogged.filter(e => e.canceled !== true)[0]?.resumo?.tabelaPreco?.nome ?? "-"}</span> :
                                                                                <Tooltip title={item.paymentsLogged.filter(e => e.canceled !== true).map(e => e.resumo.tabelaPreco?.nome).join(", ")} placement="top">
                                                                                    <span className="badge text-bg-warning text-white">{item.paymentsLogged.filter(e => e.canceled !== true).length} tabelas</span>
                                                                                </Tooltip>
                                                                    }
                                                                </td>
                                                                : <td align='center'>-</td>
                                                        }
                                                        {
                                                            item.paymentsLogged ?
                                                                <td align='center'>
                                                                    {
                                                                        item.paymentsLogged.filter(e => e.canceled !== true).length === 1 ? <>
                                                                            {
                                                                                item.canceled !== true ?
                                                                                    <strong className='text-success'>
                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                    </strong> :
                                                                                    <strong className='text-danger text-decoration-line-through'>
                                                                                        <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                    </strong>
                                                                            }
                                                                        </> :
                                                                            <Tooltip title={`A soma de todos os ${item.paymentsLogged.filter(e => e.canceled !== true).length} pagamentos`} placement="top">
                                                                                <strong className='text-success'>
                                                                                    <NumberFormat decimalScale={2} fixedDecimalScale={true} value={item.paymentsLogged.filter(e => e.canceled !== true).map(e => (e.resumo?.valorCobrado ?? 0)).reduce((a, b) => a + b, 0)} displayType={'text'} thousandSeparator={true} prefix={'R$'} />
                                                                                </strong>
                                                                            </Tooltip>
                                                                    }
                                                                </td>
                                                                : <td align='center'>-</td>
                                                        }
                                                        {
                                                            isAdministrador() &&
                                                            <>
                                                                <td align='center'>
                                                                    {
                                                                        <strong>{takeIfIsNotNullOrEmpty(item.systemTerminal) ?? "-"}</strong>
                                                                    }
                                                                </td>
                                                                <td align='center'>
                                                                    {
                                                                        <strong>{takeIfIsNotNullOrEmpty(item.systemSequence) ?? "-"}</strong>
                                                                    }
                                                                </td>
                                                                <td align='center'>
                                                                    {
                                                                        <strong>{takeIfIsNotNullOrEmpty(item.system?.version) ?? "-"}</strong>
                                                                    }
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Tab>
                        </Tabs>
                        <div className='m-3 btn-group'>
                            <button type="button" onClick={(e) => this.setState({ isShowingExportReportAsXLSModal: true })} className="btn btn-sm btn-success">
                                <i className="fas fa-table mx-2" />
                                Exportar
                            </button>
                        </div>
                        <Dialog fullWidth maxWidth='xs' open={this.state.isShowingExportReportAsXLSModal}>
                            <MuiDialogTitle className='text-center'>
                                Exportar relatório
                            </MuiDialogTitle>
                            <MuiDialogContent>
                                <AlertWarning message="Escolha os campos e configurações para a exportação." />
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldType" checked={this.state.exportFieldType} onChange={(e) => this.setState({ exportFieldType: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldType">
                                        Tipo
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldPlate" checked={this.state.exportFieldPlate} onChange={(e) => this.setState({ exportFieldPlate: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldPlate">
                                        Placa
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldTicket" checked={this.state.exportFieldTicket} onChange={(e) => this.setState({ exportFieldTicket: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldTicket">
                                        Número do ticket
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldModel" checked={this.state.exportFieldModel} onChange={(e) => this.setState({ exportFieldModel: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldModel">
                                        Modelo
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldIn" checked={this.state.exportFieldIn} onChange={(e) => this.setState({ exportFieldIn: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldIn">
                                        Data e hora de entrada
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldOut" checked={this.state.exportFieldOut} onChange={(e) => this.setState({ exportFieldOut: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldOut">
                                        Data e hora de saída
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldPermanence" checked={this.state.exportFieldPermanence} onChange={(e) => this.setState({ exportFieldPermanence: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldPermanence">
                                        Permanência
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldUser" checked={this.state.exportFieldUser} onChange={(e) => this.setState({ exportFieldUser: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldUser">
                                        Operador
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldPaymentMethod" checked={this.state.exportFieldPaymentMethod} onChange={(e) => this.setState({ exportFieldPaymentMethod: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldPaymentMethod">
                                        Forma de pagamento
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldRelationship" checked={this.state.exportFieldRelationship} onChange={(e) => this.setState({ exportFieldRelationship: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldRelationship">
                                        Convênio
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldPaymentTable" checked={this.state.exportFieldPaymentTable} onChange={(e) => this.setState({ exportFieldPaymentTable: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldPaymentTable">
                                        Tabela de preço
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldValuePark" checked={this.state.exportFieldValuePark} onChange={(e) => this.setState({ exportFieldValuePark: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldValuePark">
                                        Valor Estacionamento
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldValueDiscount" checked={this.state.exportFieldValueDiscount} onChange={(e) => this.setState({ exportFieldValueDiscount: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldValueDiscount">
                                        Valor Desconto
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldValueProducts" checked={this.state.exportFieldValueProducts} onChange={(e) => this.setState({ exportFieldValueProducts: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldValueProducts">
                                        Valor Produtos
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldValueTotal" checked={this.state.exportFieldValueTotal} onChange={(e) => this.setState({ exportFieldValueTotal: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldValueTotal">
                                        Valor Cobrado
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldLink" checked={this.state.exportFieldLink} onChange={(e) => this.setState({ exportFieldLink: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldLink">
                                        Link do ticket
                                    </label>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" id="exportFieldPayment" checked={this.state.exportFieldPayment} onChange={(e) => this.setState({ exportFieldPayment: e.target.checked })} type="checkbox" />
                                    <label className="form-check-label" htmlFor="exportFieldPayment">
                                        Dados de pagamentos
                                    </label>
                                </div>
                                <label>Delimitador da planilha</label>
                                <select className="form-select" value={this.state.exportFieldDelimiter} onChange={(e) => this.setState({ exportFieldDelimiter: e.target.value })}>
                                    <option value=",">Virgula ( , )</option>
                                    <option value=";">Ponto e virgula ( ; )</option>
                                </select>
                            </MuiDialogContent>
                            <MuiDialogActions>
                                <div className='btn-group m-3'>
                                    <button onClick={(e) => this.setState({ isShowingExportReportAsXLSModal: false })} className='btn btn-secondary'>Cancelar</button>
                                    <button onClick={(e) => this.exportData()} className='btn btn-success'>Confirmar</button>
                                </div>
                            </MuiDialogActions>
                        </Dialog>
                    </>
                }
            </>
        )
    }
}

class TicketSimplyList extends React.Component {

    constructor(props) {
        super(props);
        const currentId = queryString("ticket")
        this.state = {
            isAdministrador: isAdministrador(),
            tickets: this.props.tickets.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()),
            auxTickets: this.props.tickets.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()),
            currentId: currentId,
            ticketeCompleteDetail: false,
        }
        this.loadCashier()
    }

    loadCashier = async () => {
        const caixaId = this.props.caixaId
        if (caixaId) {
            const cashier = await getCashierById({ id: caixaId })
            const tickets = this.state.tickets.filter(e => e.status === "Pago e Entregue" && e.caixaFechado === false).length
            if (cashier.closeBy && tickets > 0) {
                this.setState({ messageToCloseAllTickets: `Existem ${tickets} não fechados neste caixa fechado.` })
            }
        }
    }

    closeAllTickets = async () => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo fechar todos os tickets deste caixa?` });
        if (confirm) {
            for (var ticket of this.state.tickets.filter(e => e.status === "Pago e Entregue" && e.caixaFechado === false)) {
                ticket.caixaFechado = true
                ticket.caixasIds = ticket.paymentsLogged?.map(e => e.caixaId)
                await updateTicket(ticket.id, ticket)
            }
            reloadWithAlert("Tickets fechados com sucesso.")
        }
    }

    searchTickets = (e) => {
        const value = e.target.value.toUpperCase()
        if (value === ">") {
            const tickets = this.state.tickets.filter(e => e.paymentsLogged?.length > 1)
            this.setState({ tickets: tickets.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()) })
        } else {
            const result = this.props.tickets.filter(e => e.ticket.toUpperCase().includes(value) ||
                e.placa?.toUpperCase().includes(value) ||
                e.vehicler?.modelo?.toUpperCase().includes(value) ||
                e.vehicler?.cor?.toUpperCase().includes(value))
            this.setState({ tickets: result.sort((a, b) => a.createAt.toDate() - b.createAt.toDate()) })
        }
    }

    removeTicket = async (ticket) => {
        var confirm = await dangerConfirm({ message: `Deseja mesmo apagar este ticket?\nEle não aparecerá mais no relatório de caixa.` });
        if (confirm) {
            await removeTicketById(ticket.id)
            const auxTickets = this.state.auxTickets.filter(e => e.id !== ticket.id)
            const tickets = this.state.tickets.filter(e => e.id !== ticket.id)
            this.setState({ tickets: tickets })
            this.setState({ auxTickets: auxTickets })
            sendLogByUser("Ticket", `Removeu o ticket ${ticket.ticket}`, ticket)
        }
    }

    statusTicket = (item) => {
        if (item.status === "Pago e Estacionado") {
            return "btn btn-info w-100 text-white"
        }
        if (item.status === "Pago e Entregue") {
            return "btn btn-success w-100 text-white"
        }
        if (item.status === "Pago e Aguardando") {
            return "btn btn-warning text-white w-100"
        }
        if (item.status === "Cancelado") {
            return "btn btn-danger w-100 text-white"
        }
        return "btn btn-primary w-100 text-white"
    }

    render() {
        return (
            <>
                <div className='row'>
                    <div className="col-lg-12">
                        <div className='row my-2'>
                            <div className="input-group">
                                <input type="text" onChange={this.searchTickets} placeholder="Faça uma busca por ticket, placa ou modelo." className="form-control" />
                                <span className="input-group-btn btn-group">
                                    <button type="button" onClick={(e) => this.setState({ legendInfo: true })} className="btn btn-secondary">
                                        Legendas
                                        <i className="fas fa-question mx-2" />
                                    </button>
                                </span>
                            </div>
                        </div>
                        {
                            this.state.messageToCloseAllTickets &&
                            <div role="button" onClick={(e) => this.closeAllTickets()} lassName='mt-3'>
                                <AlertDanger message={this.state.messageToCloseAllTickets} />
                            </div>
                        }
                        {
                            this.state.tickets.filter(e => e.status === "Cancelado").length > 0 &&
                            <div className='mt-3'>
                                <AlertDanger message="Tickets cancelados não entram no calculo de faturamento." />
                            </div>
                        }
                    </div>
                    {
                        this.state.tickets.length === 0 &&
                        <div className="col-lg-12">
                            <EmptyContent text="Nenhum ticket encontrado." />
                        </div>
                    }
                    {
                        this.state.tickets.length !== 0 &&
                        <div className='table-responsive my-2'>
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <td></td>
                                        <td><strong>Ticket</strong></td>
                                        <td><strong>Placa</strong></td>
                                        <td><strong>Modelo</strong></td>
                                        <td align='right'><strong>Entrada</strong></td>
                                        {
                                            this.state.isAdministrador &&
                                            <td align='right'></td>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.tickets.map((item, index) =>
                                            <tr key={index} className={item.id === this.state.currentId && "table-success"}>
                                                <td>
                                                    <a href={'/buscar/ticket?id=' + item.id} target="_blank">
                                                        <button type="button" className={this.statusTicket(item)}>
                                                            {
                                                                item.type === "SALES" &&
                                                                <>
                                                                    <i className="fas fa-utensils" />
                                                                    <span className='mx-2'>Venda avulsa {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                </>
                                                            }
                                                            {
                                                                item.type !== "SALES" && item.monthly &&
                                                                <>
                                                                    <i className="fas fa-calendar" />
                                                                    <span className='mx-2'>Mensalista {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                </>
                                                            }
                                                            {
                                                                item.type !== "SALES" && item.accredited &&
                                                                <>
                                                                    <i className="fas fa-user-circle" />
                                                                    <span className='mx-2'>Credenciado {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                </>
                                                            }
                                                            {
                                                                !item.monthly && !item.accredited && item.type !== "SALES" &&
                                                                <>
                                                                    <i className="fas fa-ticket-alt" />
                                                                    <span className='mx-2'>Avulso {item.paymentsLogged?.length > 1 && <span>( {item.paymentsLogged?.length} )</span>}</span>
                                                                </>
                                                            }
                                                        </button>
                                                    </a>
                                                </td>
                                                <td className={item.ticket === this.state.ticketSelected ? 'text-white' : ''}>
                                                    {item.ticket}
                                                </td>
                                                <td className={item.ticket === this.state.ticketSelected ? 'text-white' : ''}>
                                                    {item.placa?.toUpperCase() ?? "Não informada"}
                                                </td>
                                                <td className={item.ticket === this.state.ticketSelected ? 'text-white' : ''}>
                                                    {item.vehicler?.modelo?.toUpperCase() ?? "Não informado"}
                                                </td>
                                                <td align='right' className={item.ticket === this.state.ticketSelected ? 'text-white' : ''}>
                                                    {Moment(item.createAt.seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}
                                                </td>
                                                <td width={10}>
                                                    {
                                                        this.state.isAdministrador &&
                                                        <button className='btn btn-sm btn-danger' onClick={(e) => this.removeTicket(item)}>
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                    <Dialog onClose={(e) => this.setState({ legendInfo: false })} open={this.state.legendInfo}>
                        <MuiDialogTitle className='text-center'>
                            Legenda dos tickets
                        </MuiDialogTitle>
                        <MuiDialogContent>
                            <label>Cores</label>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-success" />
                                <span className='mx-2'>Pagos e entregues</span>
                            </div>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-info text-white" />
                                <span className='mx-2'>Pagos e estacionados</span>
                            </div>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-primary" />
                                <span className='mx-2'>Estacionados</span>
                            </div>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-warning text-white" />
                                <span className='mx-2'>Pagos e aguardando volta</span>
                            </div>
                            <div className='mt-2'>
                                <button type="button" className="btn btn-danger" />
                                <span className='mx-2'>Cancelados</span>
                            </div>
                            <div className='mt-2'>
                                <label>Ícones</label>
                                <div className='mt-2'>
                                    <i className="fas fa-ticket-alt" />
                                    <span className='mx-2'>Ticket</span>
                                </div>
                                <div className='mt-2'>
                                    <i className="fas fa-calendar-alt" />
                                    <span className='mx-2'>Mensalista</span>
                                </div>
                                <div className='mt-2'>
                                    <i className="fas fa-user-circle" />
                                    <span className='mx-2'>Credenciados</span>
                                </div>
                                <div className='mt-2'>
                                    <i className="fas fas fa-utensils" />
                                    <span className='mx-2'>Venda avulsa</span>
                                </div>
                            </div>
                        </MuiDialogContent>
                        <MuiDialogActions>
                            <div className='m-3'>
                                <button onClick={(e) => this.setState({ legendInfo: false })} className='btn btn-secondary'>Fechar</button>
                            </div>
                        </MuiDialogActions>
                    </Dialog>
                </div>
            </>
        )
    }
}

export { TicketSimplyList, TicketSearch, TicketAnalyticsBody, TicketAnalyticsDialog }