import { addDoc, deleteDoc, getAllDocsByParkId, getDocById, updateDoc } from './collectionBaseWorker';
import { Collection } from '../../shared/constants';

export const getBookingById = async ({ id }) => {
    return await getDocById({ collection: Collection.BOOKINGS, id: id })
}

export const getAllBookingsByParkId = async ({ estacionamentoId }) => {
    return await getAllDocsByParkId({ collection: Collection.BOOKINGS, estacionamentoId: estacionamentoId })
}

export const addBooking = async ({ data }) => {
    return await addDoc({ collection: Collection.BOOKINGS, data: data })
}

export const updateBookingById = async ({ id, data }) => {
    await updateDoc({ collection: Collection.BOOKINGS, id: id, data: data })
}

export const removeBookingById = async ({ id }) => {
    await deleteDoc({ collection: Collection.BOOKINGS, id: id })
}
